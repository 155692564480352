import React, { useState, useEffect } from "react";
import SideBarCompany from "../partials/sidebarCompany";
import Nav from "../partials/nav";
import "../dashboard.css";
import { useParams } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import checkAcceptImg from "assets/images/check-accept.svg";
import RejectImg from "assets/images/reject-button.svg";
import ClipLoader from "react-spinners/ClipLoader";

import { connect } from "react-redux";
import cogoToast from "cogo-toast";
import { TagsInput } from "react-tag-input-component";
import MultiRangeSlider from "components/multiRangeSlider/";
import { upload_preset,cloud_name,cloud_url, cvparserUrl  } from "helpers";

import greenApproveImage from "assets/images/green-approve-2.svg";
import grayApproveImage from "assets/images/gray-approve.svg";
import redRejectImage from "assets/images/red-reject.svg";
import grayRejectImage from "assets/images/gray-reject.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { numFormatter } from "helpers/index";

//redux
import {
  postJobOffer,
  fetchJobListingDetails,
  postJobOfferAdmin,
  postAdminUpdateJobOffer
} from "store/actions";
import SideBarAdmin from "../partials/sidebarAdmin";

const PostAJob = (props) => {
  let { id, title } = useParams();
  const {
    postJobOffer,
    postJobOfferAdmin,
    postAdminUpdateJobOffer,
    error,
    loading,
    job,
    jobs_details,
    fetchJobListingDetails,
  } = props;

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const job_id = params.get("id");

  const [currentTab, setCurrentTab] = useState(1);
  const [tags, setTags] = useState([]);
  const [anomyousAccept, setAnomyousAccept] = useState(10);
  const [acceptDigitalCurrency, setAcceptDigitalCurrency] = useState(10);
  const [userType, setUserType] = useState("");

  const [jobTitle, setjobTitle] = useState("");
  const [jobReferalLink, setJobReferalLink] = useState("");
  const [hasReferal, setHasReferal] = useState(true);

  const [jobTags, setJobTags] = useState("");
  const [workLocation, setWorkLocation] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [salary, setSalary] = useState(true);
  const [jobDescription, setJobDescription] = useState("");
  const [jobDescriptionCount, setJobDescriptionCount] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const fileUploadRef = React.useRef<HTMLInputElement>(null);
  const [isLoadingtoCloudinary, setIsLoadingtoCloudinary] = useState(false);
  const [logoFileName, setlogoFileName] = useState("");


  const [note, setNote] = useState("");
  const [noteCount, setNoteCount] = useState(0);

  const [requirements, setRequirement] = useState("");
  const [requirementCount, setRequirementCount] = useState(0);

  const [selectedTag, setSelectedTag] = useState(["Web3"]);

  const [priceTo, setPriceTo] = useState("100000");
  const [priceFrom, setPriceFrom] = useState("35000");
  const [logoURL, setLogoUrl] = useState("");


  const [currentPathName, setCurrentPathName] = useState(
    window.location.pathname
  );

  useEffect(() => {
    fetchData();
    fetchJobListingDetails(job_id);
  }, [userType]);

  const fetchData = async () => {
    let itemtoken = await localStorage.getItem("AuthData");
    if (itemtoken && JSON.parse(itemtoken).token !== null) {
      setUserType(itemtoken && JSON.parse(itemtoken).role);
    }
  };

  const isAdmin = () => {
    return userType === "admin" ? true : false;
  };

  const onChangeOnboardingLogoUpload =   (e) => {
    setIsLoadingtoCloudinary(true);
     const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("upload_preset", upload_preset);
    data.append("cloud_name", cloud_name);
    fetch(cloud_url, {
      method: "post",
      body: data,
    })
      .then((resp) => resp.json())
      .then((data) => {
        setIsLoadingtoCloudinary(false);
        cogoToast.success("CV Uploaded successfully.");
  
        setLogoUrl(data.secure_url);
        setlogoFileName(data.original_filename);

       // setHasUploadedCV(true)
      
      })
      .catch((err) => console.log(err));
   };

  useEffect(() => {
    if (currentPathName === "/admin/editjob") {
      let preData =
        jobs_details && jobs_details.listings && jobs_details.listings[0];
      if (isAdmin()) {
        setjobTitle(preData.jobTitle);
        setWorkLocation(preData.workLocation);
        setSelectedTag(preData.tags);
        setJobReferalLink(preData?.referral_link);
        // setHasReferal(preData?.referral == 1? true : false);
        setEmploymentType(preData.employmentType);
        if (jobDescriptionCount < 10000) {
          setJobDescription(preData.description);
          setJobDescriptionCount(preData.description.length);
        } else {
          setJobDescription(preData.description.substring(0, 10000));
        }
        if (noteCount < 10000) {
          setNote(preData.note);
          setNoteCount(preData.note.length);
        } else {
          setNote(note.substring(0, 10000));
        }

        if (requirementCount < 10000) {
          setRequirement(preData.requirements);
          setRequirementCount(preData.requirements.length);
        } else {
          setNote(note.substring(0, 10000));
        }
      }
    }
  }, [jobs_details]);

  return (
    <>
      <div>
        <Nav title={"My Dashboard"} />
        {userType === "admin" ? <SideBarAdmin /> : <SideBarCompany />}

        <div className="lg:ml-60 ml-0">
          <section className="is-hero-bar mt-20">
            <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
              <div>
                <h1 className=" text-small ArchivoBlack text-soft-gray">
                  {userType === "admin" && currentPathName.indexOf("/admin/postajob") !== -1 ? `Post Job for ${title} ` : "Post your Job"}
                </h1>
                <p className="text-graisuhx DMSansRegular text-xs bee-text">
                  {userType === "admin" ? "Job" : "Post your Job"}{" "}
                  offer with all the informations needed
                </p>
              </div>
            </div>

            <div className="flex space-x-2x justify-centexr mt-10 mb-10 border-b-2 ">
              <button
                type="button"
                onClick={() => {
                  setCurrentTab(1);
                }}
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                className={` DMSansBold ${
                  currentTab == 1
                    ? "activated-gray-tab DMSansBold"
                    : "no-border"
                } inline-block border-blacked  px-6 py-2.5 bg-white-600 text-black font-medium text-xs leading-tight uppercasex roundedx shadow-mdx   hover:shadow-lgx focus:bg-white-700 focus:shadow-lgx focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out text-soft-gray  `}
              >
                1. Job Information
              </button>

              <button
                type="button"
                onClick={() => {
                  setCurrentTab(2);
                }}
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                className={`DMSansBold ${
                  currentTab == 2
                    ? "activated-gray-tab  DMSansBold"
                    : "no-border"
                } inline-block border-blacked  px-6 py-2.5 bg-white-600 text-black font-medium text-xs leading-tight uppercasex roundedx shadow-mdx   hover:shadow-lgx focus:bg-white-700 focus:shadow-lgx focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out text-soft-gray  `}
              >
                2. Job Description
              </button>
            </div>

            {currentTab == 1 && (
              <div className="md:w-100 mr-5  md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                <span className="font-semibold title-font  text-soft-gray DMSansBold">
                  Basic Information
                </span>
                <div className="mt-1  desc-color text-sm DMSansRegular lg:ml-80"></div>
              </div>
            )}

            {currentTab == 2 && (
              <div className="md:w-100 mr-5  md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                <span className="font-semibold title-font  text-soft-gray DMSansBold">
                  Description
                </span>
                <div className="mt-1  desc-color text-sm DMSansRegular lg:ml-80"></div>
              </div>
            )}
          </section>

          {currentTab == 2 && (
            <>
              <section className="is-hero-bar mt-2x">
                <div className="py-8 flex flex-wrap md:flex-nowrap">
                  <div className="md:w-100 mr-5  md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                    <span className="font-semibold title-font  text-soft-gray DMSansBold">
                      Job description
                    </span>
                    <div className="mt-1  desc-color text-sm DMSansRegular lg:ml-80"></div>
                    {/* <span className="mt-1  desc-color text-sm DMSansRegular">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>*/}
                  </div>
                  <div className="md:flex-grow">
                    {/*<CKEditor
          editor={ ClassicEditor }
                    
                    config={ {
                     
                      toolbar: [ 'bold', 'italic', 'link', 'undo', 'redo', 'numberedList', 'bulletedList' ],
              
                    } }
                    data=""
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                     
                        setJobDescription(data.replace(/<[^>]*>?/gm, '').replace(/&nbsp;/g,""))
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
              } }
                />*/}

                    <ReactQuill
                      theme="snow"
                      value={jobDescription}
                      onChange={(v) => {
                        if (jobDescriptionCount < 10000) {
                          setJobDescription(v);
                          setJobDescriptionCount(v.length);
                        } else {
                          setJobDescription(jobDescription.substring(0, 10000));
                        }
                      }}
                      style={{ minHeight: "100px" }}
                    />
                    <p className="text-xs DMSansBold text-soft-gray ">
                      {jobDescriptionCount > 10000
                        ? "10000"
                        : jobDescriptionCount}{" "}
                      /10000
                    </p>
                  </div>
                </div>
              </section>

              <section className="is-hero-bar mt-2">
                <div className="py-8 flex flex-wrap md:flex-nowrap">
                  <div className="md:w-100 mr-5 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                    <span className="font-semibold title-font text-gray-700 DMSansBold">
                      What will you be doing?
                    </span>
                    <div className="mt-1  desc-color text-sm DMSansRegular lg:ml-80"></div>
                  </div>
                  <div className="md:flex-grow">
                    {/*<CKEditor
                    editor={ ClassicEditor }
                    
                    config={ {
                     
                      toolbar: [ 'bold', 'italic', 'link', 'undo', 'redo', 'numberedList', 'bulletedList' ],
              
                    } }
                    data=""
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                        setNote(data.replace(/<[^>]*>?/gm, '').replace(/&nbsp;/g,""))

                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />*/}

                    <ReactQuill
                      theme="snow"
                      value={note}
                      onChange={(v) => {
                        if (noteCount < 10000) {
                          setNote(v);
                          setNoteCount(v.length);
                        } else {
                          setNote(note.substring(0, 10000));
                        }
                      }}
                      style={{ minHeight: "100px" }}
                    />
                    <p className="text-xs DMSansBold text-soft-gray ">
                      {noteCount > 10000 ? "10000" : noteCount} /10000
                    </p>
                  </div>
                </div>
              </section>

              <section className="is-hero-bar mt-2">
                <div className="py-8 flex flex-wrap md:flex-nowrap">
                  <div className="md:w-100 mr-5 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                    <span className="font-semibold title-font text-gray-700 DMSansBold">
                      Requirements
                    </span>
                    <div className="mt-1  desc-color text-sm DMSansRegular lg:ml-80"></div>
                  </div>
                  <div className="md:flex-grow">
                    {/*<CKEditor
                    editor={ ClassicEditor }
                    
                    config={ {
                     
                      toolbar: [ 'bold', 'italic', 'link', 'undo', 'redo', 'numberedList', 'bulletedList' ],
              
                    } }
                    data=""
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                        setRequirement(data.replace(/<[^>]*>?/gm, '').replace(/&nbsp;/g,""))

                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />*/}

                    <ReactQuill
                      theme="snow"
                      value={requirements}
                      onChange={(v) => {
                        if (requirementCount < 10000) {
                          setRequirement(v);
                          setRequirementCount(v.length);
                        } else {
                          setNote(note.substring(0, 10000));
                        }
                      }}
                      style={{ minHeight: "100px" }}
                    />
                    <p className="text-xs DMSansBold text-soft-gray ">
                      {requirementCount > 10000 ? "10000" : requirementCount}{" "}
                      /10000
                    </p>
                  </div>
                </div>
              </section>

              <div className="flex justify-end	mr-10 mb-20 mt-10">
                {loading ? (
                  <div className="text-center">
                    <ClipLoader color={"#F1B729"} loading={true} size={50} />
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      if (
                        jobTitle === "" ||
                        jobDescription === "" ||
                        employmentType === "" ||
                        requirements === ""
                      ) {
                        cogoToast.error(
                          "Must fill in all fields on both pages to submit"
                        );
                      } else {
                        if (priceFrom !== "" && priceTo != "") {
                          let data = {
                            jobTitle: jobTitle,
                            salary: !salary
                              ? 0
                              : numFormatter(priceFrom) +
                                "-" +
                                numFormatter(priceTo),
                            description: jobDescription,
                            employmentType: employmentType,
                            workLocation: workLocation,
                            icon: "n/a",
                            // "applicationDeadline":"n/a",
                            // "expectedStartDate":"n/a",
                            methodOfApplication: "n/a",
                            applicationDeadline: "2022-05-04",
                            expectedStartDate: "2022-05-04",
                            remote: 0,
                            note: note,
                            requirements: requirements,
                            tags: selectedTag,
                            company_id: id || 0,
                            name: jobTitle,
                            jobId:job_id || 0,
                            logo: logoURL,
                            uid: id || 0,
                            referral_link: jobReferalLink,
                            referral: hasReferal,
                          };
                          if (isAdmin()) {
                            //update task
                            //check if its admin posting on behalf or admin editting: 
                            if(currentPathName === '/admin/editjob')
                            {
                              postAdminUpdateJobOffer(data);
                            }
                            else
                            {
                                postJobOfferAdmin(data);
                            }

                          } else {
                            postJobOffer(data);
                          }
                        } else {
                          cogoToast.error("Enter a salary range");
                        }
                      }
                    }}
                    className="nav-btn-custom-orange DMSansBold  text-white rounded-lg shadow text-sm p-10x"
                  >
                    Post the job
                  </button>
                )}
              </div>
            </>
          )}

          {currentTab == 1 && (
            <>
              <section className="is-hero-bar mt-2x">
                <div className="py-8 flex flex-wrap md:flex-nowrap">
                  <div className="md:w-100 mr-5  md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                    <span className="font-semibold title-font text-gray-700 DMSansBold">
                      Job Title{" "}
                    </span>
                    <div className="mt-1  desc-color text-sm DMSansRegular lg:ml-80"></div>
                  </div>
                  <div className="md:flex-grow">
                    <input
                      type="text"
                      onChange={(e) => {
                        setjobTitle(e.target.value);
                      }}
                      value={jobTitle}
                      placeholder="e.g. Web3 Engineer"
                      className="bg-white-50 with-input-field  mt-5  ml-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-yellow-500 block w-full p-2.5 dark:bg-white-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-yellow-500 dark:focus:border-yellow-500"
                    />
                    <span className="ml-20 bee-text text-xs">
                      At least 10 Characters
                    </span>
                  </div>
                </div>
              </section>
            
               
              <section className="is-hero-bar mt-2x">
                <div className="py-8 flex flex-wrap md:flex-nowrap">
                  <div className="md:w-100 mr-5  md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                    <span className="font-semibold title-font text-gray-700 DMSansBold">
                      Company Logo{" "}
                    </span>
                    <div className="mt-1  desc-color text-sm DMSansRegular lg:ml-80"></div>
                  </div>
                  <div className="md:flex-grow">
                                      <div>

                      {
                              isLoadingtoCloudinary? 
                               <ClipLoader
                               color={"#F1B729"}
                               loading={true}
                               size={50}
                             />
                             :
                            <>
                            <img src={logoURL} className="ml-20" />
                        <button
                                onClick={() => {
                                  setIsUploading(true);
                                  fileUploadRef.current?.click();
                                }}
                                className="inline-flex mb-0 lg:ml-20 text-white w-2/6 bg-bee-orange border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600x rounded text-sm DMSansRegular text-center"
                              >
                                {logoURL !=="" ? "Change Selected" :"Select Company Logo (optional) " }
                                
                                <span className="icon">
                                  <svg
                                    width="16"
                                    height="21"
                                    viewBox="0 0 16 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M3 2.70508C2.73478 2.70508 2.48043 2.81043 2.29289 2.99797C2.10536 3.18551 2 3.43986 2 3.70508V17.7051C2 17.9703 2.10536 18.2246 2.29289 18.4122C2.48043 18.5997 2.73478 18.7051 3 18.7051H13C13.2652 18.7051 13.5196 18.5997 13.7071 18.4122C13.8946 18.2246 14 17.9703 14 17.7051L14 8.11929L8.58589 2.70518L3 2.70508ZM0.87868 1.58376C1.44129 1.02115 2.20435 0.705078 3 0.705078H8.586C9.11634 0.705192 9.62513 0.915939 10.0001 1.29097L15.414 6.70486C15.789 7.07984 15.9999 7.58853 16 8.11886V17.7051C16 18.5007 15.6839 19.2638 15.1213 19.8264C14.5587 20.389 13.7957 20.7051 13 20.7051H3C2.20435 20.7051 1.44129 20.389 0.87868 19.8264C0.31607 19.2638 0 18.5007 0 17.7051V3.70508C0 2.90943 0.31607 2.14637 0.87868 1.58376ZM4 10.7051C4 10.1528 4.44772 9.70508 5 9.70508H11C11.5523 9.70508 12 10.1528 12 10.7051C12 11.2574 11.5523 11.7051 11 11.7051H5C4.44772 11.7051 4 11.2574 4 10.7051ZM4 14.7051C4 14.1528 4.44772 13.7051 5 13.7051H11C11.5523 13.7051 12 14.1528 12 14.7051C12 15.2574 11.5523 15.7051 11 15.7051H5C4.44772 15.7051 4 15.2574 4 14.7051Z"
                                      fill="#fff"
                                    ></path>
                                  </svg>
                                </span>
                        </button>
                        <input
                                      type="file"
                                      className={` hide-fileuploaded `}
                                      onChange={onChangeOnboardingLogoUpload}
                                      ref={fileUploadRef}
                                    />
                            
                            <div className="ml-20"> {logoFileName !=="" && `Selected Logo: ${logoFileName}` }</div> 
                        
                            </>

                              }
                      </div>
                  </div>
                </div>
              </section>

              <section className="is-hero-bar mt-2x">
                <div className="py-8 flex flex-wrap md:flex-nowrap">
                  <div className="md:w-100 mr-5  md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                    <span className="font-semibold title-font text-gray-700 DMSansBold">
                      Work Location
                    </span>
                    <div className="mt-1  desc-color text-sm DMSansRegular lg:ml-80"></div>
                  </div>
                  <div className="md:flex-grow">
                    <input
                      type="text"
                      onChange={(e) => {
                        setWorkLocation(e.target.value);
                      }}
                      value={workLocation}
                      placeholder="e.g. San Fransisco"
                      className="bg-white-50 with-input-field  mt-5  ml-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-yellow-500 block w-full p-2.5 dark:bg-white-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-yellow-500 dark:focus:border-yellow-500"
                    />
                  </div>
                </div>
              </section>

              <section className="is-hero-bar mt-2x">
                <div className="py-8 flex flex-wrap md:flex-nowrap">
                  <div className="md:w-100 mr-5  md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                    <span className="font-semibold title-font text-gray-700 DMSansBold">
                      Job Tags
                    </span>
                    <div className="mt-1  desc-color text-sm DMSansRegular lg:ml-80"></div>
                  </div>
                  <div className="md:flex-grow ml-20">
                    {/* <input 
        type="text" 
        placeholder="Add Tags"
         onChange={(e)=>{
          setJobTags(e.target.value)
          setSelectedTag([...selectedTag,e.target.value]);
        }}
        value={jobTags}

        className="bg-white-50 with-input-field  mt-5  ml-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-yellow-500 block w-full p-2.5 dark:bg-white-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-yellow-500 dark:focus:border-yellow-500" />
         */}

                    <TagsInput
                      value={selectedTag}
                      name="jobtags"
                      onChange={setSelectedTag}
                      placeHolder="e.g Rust enter 1 tag at a time"
                    />
                  </div>
                </div>
              </section>

              {/*
      <section className="is-hero-bar mt-2x">
    <div className="py-8 flex flex-wrap md:flex-nowrap">
        <div className="md:w-100 mr-5  md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span className="font-semibold title-font text-gray-700 DMSansBold">Work Location</span>
           <div className="mt-1  desc-color text-sm DMSansRegular lg:ml-80"></div> 
        </div>
        <div className="md:flex-grow">
        <input 
        type="text" 
        onChange={(e)=>{
          setWorkLocation(e.target.value)
        }}
        value={workLocation}

        placeholder="Add Location"
        className="bg-white-50 with-input-field  mt-5  ml-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-yellow-500 block w-full p-2.5 dark:bg-white-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-yellow-500 dark:focus:border-yellow-500" />
      
     
        </div>
      </div>
      </section>*/}

              <section className="is-hero-bar mt-2x">
                <div className="py-8 flex flex-wrap md:flex-nowrap">
                  <div className="md:w-100 mr-5  md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                    <span className="font-semibold title-font text-gray-700 DMSansBold">
                      Type of employment
                    </span>
                    <div className="mt-1  desc-color text-sm DMSansRegular lg:ml-80"></div>
                  </div>
                  <div className="md:flex-grow">
                    <div className="ml-20">
                      <div className="form-check">
                        <input
                          onChange={(e) => {
                            setEmploymentType("Full-Time");
                          }}
                          className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-sky-900 checked:border-sky-900 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked={employmentType === "Full-Time"}
                        />
                        <label className="form-check-label inline-block bee-text DMSansRegular text-sm">
                          Full-Time
                        </label>
                      </div>

                      <div className="form-check mt-2">
                        <input
                          onChange={(e) => {
                            setEmploymentType("Part-Time");
                          }}
                          className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-sky-900 checked:border-sky-900 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked={employmentType === "Part-Time"}
                        />
                        <label className="form-check-label inline-block bee-text DMSansRegular text-sm">
                          Part-Time
                        </label>
                      </div>

                      <div className="form-check mt-2">
                        <input
                          onChange={(e) => {
                            setEmploymentType("Internship");
                          }}
                          className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-sky-900 checked:border-sky-900 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked={employmentType === "Internship"}
                        />
                        <label className="form-check-label inline-block bee-text DMSansRegular text-sm">
                          Internship
                        </label>
                      </div>

                      <div className="form-check mt-2">
                        <input
                          onChange={(e) => {
                            setEmploymentType("Contract");
                          }}
                          className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-sky-900 checked:border-sky-900 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked={employmentType === "Contract"}
                        />
                        <label className="form-check-label inline-block bee-text DMSansRegular text-sm">
                          Contract
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="is-hero-bar mt-2x" id="here">
                <div className="py-8 flex flex-wrap md:flex-nowrap">
                  <div className="md:w-100 mr-5  md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                    <span className="font-semibold title-font text-gray-700 DMSansBold">
                      Accepting anonymous applicants
                    </span>
                  </div>
                  <div className="md:flex-grow ml-5">
                    <div className="ml-40 ">
                      <a
                        href="#accept-anonymous"
                        onClick={() => {
                          setAnomyousAccept(1);
                        }}
                      >
                        <img
                          src={
                            anomyousAccept == 1
                              ? greenApproveImage
                              : grayApproveImage
                          }
                          className={`${
                            anomyousAccept == 1
                              ? " border-yellow-300-imagex"
                              : "no-border"
                          } inline  `}
                        />
                      </a>

                      <a
                        href="#reject-anonymous"
                        onClick={() => {
                          setAnomyousAccept(2);
                        }}
                      >
                        <img
                          src={
                            anomyousAccept == 2
                              ? redRejectImage
                              : grayRejectImage
                          }
                          className={`ml-5 ${
                            anomyousAccept == 2
                              ? " border-yellow-300-imagex"
                              : "no-border"
                          } inline  `}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </section>

              <section className="is-hero-bar mt-2x" id="here">
                <div className="py-8 flex flex-wrap md:flex-nowrap">
                  <div className="md:w-100 mr-5  md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                    <span className="font-semibold title-font text-gray-700 DMSansBold">
                      Salary Range
                      <div className="flex items-center mt-5">
                        <input
                          onChange={(e) => {
                            setSalary(e.target.checked ? false : true);
                          }}
                          type="checkbox"
                          value=""
                          className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="mt-1 text-gray ml-2 text-sm  DMSansRegular">
                          Don't show salary{" "}
                        </label>
                      </div>
                    </span>
                    <span className="mt-1 text-white text-sm  DMSansRegular">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </span>
                  </div>
                  
                  <div className="md:flex-grow  ml-20">
                    {salary && (
                      <>
                        <div className="grid w-1/10 grid-cols-3 lg:grid-cols-3 gap-1 lg:gap-1 ml-2">
                          <input
                            type="number"
                            onChange={(e) => {
                              setPriceFrom(e.target.value);
                              //setPriceFrom(numFormatter(e.target.value))
                              // value={numFormatter(priceFrom)}
                            }}
                            disabled={false}
                            value={priceFrom}
                            placeholder="$35,000"
                            className="bg-white-50 with-input-field  mt-5  ml-20x mb-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-yellow-500 block w-full p-2.5 dark:bg-white-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-yellow-500 dark:focus:border-yellow-500"
                          />

                          <input
                            type="number"
                            onChange={(e) => {
                              setPriceTo(e.target.value);
                              //setPriceTo(numFormatter(e.target.value))
                              // value={numFormatter(priceTo)}
                            }}
                            disabled={false}
                            value={priceTo}
                            placeholder="$50,000"
                            className="bg-white-50 with-input-field  mt-5  ml-20x mb-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-yellow-500 block w-full p-2.5 dark:bg-white-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-yellow-500 dark:focus:border-yellow-500"
                          />
                        </div>

                        {/* Range slider*/}
                        <MultiRangeSlider
                          min={0}
                          setPriceFrom={setPriceFrom}
                          setPriceTo={setPriceTo}
                          max={2000000}
                          onChange={({ min, max }) => {
                            console.log(`min = ${min}, max = ${max}`);
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </section>


              <section className="is-hero-bar mt-2x" id="here">
                <div className="py-8 flex flex-wrap md:flex-nowrap">
                  <div className="md:w-100 mr-5  md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                    <span className="font-semibold title-font text-gray-700 DMSansBold">
                      Apply by Job Referal
                      <div className="flex items-center mt-5">
                        <input
                          onChange={(e) => {
                            setHasReferal(e.target.checked ? false : true);
                          }}
                          type="checkbox"
                          value=""
                          className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="mt-1 text-gray ml-2 text-sm  DMSansRegular">
                          Disabled referal link{" "}
                        </label>
                      </div>
                    </span>
                    <span className="mt-1 text-white text-sm  DMSansRegular">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </span>
                  </div>
                  
                  <div className="md:flex-grow  ml-20x">
                 {
                 hasReferal && 
                 <input
                      type="text"
                      onChange={(e) => {
                        setJobReferalLink(e.target.value);
                      }}
                      value={jobReferalLink}
                      placeholder="Enter Job Apply referral link"
                      className="bg-white-50 with-input-field  mt-5  ml-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-yellow-500 block w-full p-2.5 dark:bg-white-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-yellow-500 dark:focus:border-yellow-500"
                    />}
                  </div>
                </div>
              </section>

              <section className="is-hero-bar mt-2x" id="here2">
                <div className="py-8 flex flex-wrap md:flex-nowrap">
                  <div className="md:w-100 mr-5  md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                    <span className="font-semibold title-font text-gray-700 DMSansBold">
                      Salary paid in crypto currency?
                    </span>
                  </div>
                  <div className="md:flex-grow ml-5">
                    <div className="ml-10 ">
                      <a
                        href="#accept-anonymous"
                        onClick={() => {
                          setAcceptDigitalCurrency(1);
                        }}
                      >
                        <img
                          src={
                            acceptDigitalCurrency == 1
                              ? greenApproveImage
                              : grayApproveImage
                          }
                          className={`${
                            acceptDigitalCurrency == 1
                              ? " border-yellow-300-imagex"
                              : "no-border"
                          } inline  `}
                        />
                      </a>

                      <a
                        href="#reject-anonymous"
                        onClick={() => {
                          setAcceptDigitalCurrency(2);
                        }}
                      >
                        <img
                          src={
                            acceptDigitalCurrency == 2
                              ? redRejectImage
                              : grayRejectImage
                          }
                          className={`ml-5 ${
                            acceptDigitalCurrency == 2
                              ? " border-yellow-300-imagex"
                              : "no-border"
                          } inline  `}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </section>

              <div className="flex justify-end	mr-10 mb-20 mt-10">
                <button
                  onClick={() => {
                    setCurrentTab(2);
                  }}
                  className="nav-btn-custom-orange DMSansBold  text-white rounded-lg shadow text-sm p-10x"
                >
                  Next Step
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, jobs, jobs_details } = state.job;
  return { error, loading, jobs, jobs_details };
};

export default connect(mapStateToProps, {
  postJobOffer,
  postJobOfferAdmin,
  postAdminUpdateJobOffer,
  fetchJobListingDetails,
})(PostAJob);
