import { call, put, takeEvery } from "redux-saga/effects";
import cogoToast from "cogo-toast";
//services
import adminServices from "api/services/admin";
//redux
import {
  ADMIN_CVS_LISTING,
  ADMIN_DASHBOARD,
  ADMIN_JOB_LISTING,
  ADMIN_USER_LISTING,
  DELETE_POST_JOB_OFFER_ADMIN,
  POST_JOB_CATEGORY_ADMIN,
  FETCH_JOB_CATEGORY_ADMIN,
  DELETE_ADMIN_CATEGORY_LISTING,
} from "./constants";
import {
  fetchAdminDashboardSuccess,
  fetchAdminJobListingSuccess,
  fetchAdminUserListingSuccess,
  fetchAdminCVsListingSuccess,
  deleteJobOfferAdminSuccess,
  postAddCVCategorySuccess,
  fetchJobCategoryAdminsListingSuccess,
  deleteAdminDeleteCategoryListingSuccess,
} from "./actions";
import { ResponseGenerator } from "store/type";

function* fetchAdminDashboard({ payload }: any) {
  try {
    const { user } = payload;

    const response: ResponseGenerator = yield call(
      adminServices.fetchAdminDashboardURL,
      user
    );
    if (response.data.success) {
      if (response.data) {
        yield put(fetchAdminDashboardSuccess(response.data));
      }
    } else {
      cogoToast.error("Un-Authorized Access").then(() => {
        window.location.href = "/login";
      });
    }
  } catch (error) { }
}

function* fetchAdminJobListing({ payload }: any) {
  try {
    const { user } = payload;

    const response: ResponseGenerator = yield call(
      adminServices.fetchAdminJobListingURL,
      user
    );
    if (response.data.success) {
      if (response.data) {
        yield put(fetchAdminJobListingSuccess(response.data));
      }
    } else {
      cogoToast.error("Un-Authorized Access").then(() => {
        window.location.href = "/login";
      });
    }
  } catch (error) { }
}

function* fetchAdminUserListing({ payload }: any) {
  try {
    const { user } = payload;

    const response: ResponseGenerator = yield call(
      adminServices.fetchAdminUserListingURL,
      user
    );
    if (response.data.success) {
      if (response.data) {
        yield put(fetchAdminUserListingSuccess(response.data));
      }
    } else {
      cogoToast.error("Un-Authorized Access").then(() => {
        window.location.href = "/login";
      });
    }
  } catch (error) { }
}

function* fetchAdminCVsListing({ payload }: any) {
  try {
    const { user } = payload;

    const response: ResponseGenerator = yield call(
      adminServices.fetchAdminCVsListingURL,
      user
    );
    if (response.data.success) {
      if (response.data) {
        yield put(fetchAdminCVsListingSuccess(response.data));
      }
    } else {
      cogoToast.error("Un-Authorized Access").then(() => {
        window.location.href = "/login";
      });
    }
  } catch (error) { }
}

function* postAddCVCategory({ payload }: any) {
  try {
    const { data } = payload;

    const response: ResponseGenerator = yield call(
      adminServices.postAddCVCategoryURL,
      data
    );
    if (response.data.success) {
      yield put(postAddCVCategorySuccess(response.data));
      if (response.data) {
        cogoToast.success("Category Added successfully.").then(() => {
        //  window.location.href="/admin/cvs";
        });
      }
    }
  } catch (error) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    cogoToast.error("Error occured while adding category");
  }
}

 
function* fetchJobCategoryAdminsListing({ payload }: any) {
  try {
    const { user } = payload;

    const response: ResponseGenerator = yield call(
      adminServices.fetchAdminAllCVCategoryURL,
      user
    );
    if (response.data.success) {
      if (response.data) {
        yield put(fetchJobCategoryAdminsListingSuccess(response.data));
      }
    } else {
      cogoToast.error("Un-Authorized Access").then(() => {
        window.location.href = "/login";
      });
    }
  } catch (error) { }
}


function* deleteJobOfferAdmin({ payload }: any) {
  try {
    const { data } = payload;

    const response: ResponseGenerator = yield call(
      adminServices.deleteJobOfferAdmin,
      data
    );

    if (response.data.success) {
      yield put(deleteJobOfferAdminSuccess(response.data));

      if (response.data) {
        cogoToast
          .success("Congratulations! Your Job Offer has been Deleted")
          .then(() => {
            window.location.href = "/admin/jobs";
          });
      }
    }
  } catch (error) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    cogoToast.error("Error occured while Deleteing Job. Please try again");
    // window.location.href = "/admin/jobs";
  }
}
 

function* deleteAdminDeleteCategoryListing({ payload }: any) {
  try {
    const { data } = payload;

    const response: ResponseGenerator = yield call(
      adminServices.deleteAdminDeleteCategoryListingURL,
      data
    );

    if (response.data.success) {
      yield put(deleteAdminDeleteCategoryListingSuccess(response.data));

      if (response.data) {
       cogoToast
          .success("Category removed")
          .then(() => {
          
          });
      }
    }
  } catch (error) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    cogoToast.error("Error occured while Deleteing category. Please try again");
    // window.location.href = "/admin/jobs";
  }
}


function* adminSaga() {
  yield takeEvery(ADMIN_DASHBOARD, fetchAdminDashboard);
  yield takeEvery(ADMIN_JOB_LISTING, fetchAdminJobListing);
  yield takeEvery(ADMIN_USER_LISTING, fetchAdminUserListing);
  yield takeEvery(ADMIN_CVS_LISTING, fetchAdminCVsListing);
  yield takeEvery(DELETE_POST_JOB_OFFER_ADMIN, deleteJobOfferAdmin);
  yield takeEvery(POST_JOB_CATEGORY_ADMIN, postAddCVCategory);
  yield takeEvery(FETCH_JOB_CATEGORY_ADMIN, fetchJobCategoryAdminsListing);   
  yield takeEvery(DELETE_ADMIN_CATEGORY_LISTING, deleteAdminDeleteCategoryListing);   

   
}

export default adminSaga;
