import { generateActions } from "helpers/generateActions";

//login
export const LOGIN_USER = generateActions("LOGIN_USER");
export const LOGOUT_USER = generateActions("LOGOUT_USER");

//register
export const REGISTER_USER = generateActions("REGISTER_USER");

//validate phone and email
export const VALIDATE_PHONE_AND_EMAIL = generateActions(
  "VALIDATE_PHONE_AND_EMAIL"
);

//forgot password
export const FORGOT_PASSWORD = generateActions("FORGOT_PASSWORD");
export const FORGOT_PASSWORD_OTP_VERIFY = generateActions(
  "FORGOT_PASSWORD_OTP_VERIFY"
);
export const CHANGE_PASSWORD = generateActions("CHANGE_PASSWORD");

//email validation on registration
export const VALIDATE_REGISTRATION = generateActions("VALIDATE_REGISTRATION");

//resend verification
export const RESEND_VERIFICATION = generateActions("RESEND_VERIFICATION");

export const API_ERROR = "API_ERROR";

export const CLEAR_API_ERROR = generateActions("CLEAR_API_ERROR");

export const VERIFY_LOGIN = "VERIFY_LOGIN";
export const VERIFY_USER_LOGIN = "VERIFY_USER_LOGIN";

export const EMPLOYER_PROFILE = "EMPLOYER_PROFILE";
export const EMPLOYER_PROFILE_SUCCESS = "EMPLOYER_PROFILE_SUCCESS";

export const EMPLOYER_DASHBOARD = "EMPLOYER_DASHBOARD";
export const EMPLOYER_DASHBOARD_SUCCESS = "EMPLOYER_DASHBOARD_SUCCESS";

export const JOB_SEEKER_PROFILE = "JOB_SEEKER_PROFILE";
export const JOB_SEEKER_PROFILE_SUCCESS = "JOB_SEEKER_PROFILE_SUCCESS";

export const WALLET_REGISTER = "WALLET_REGISTER";
export const WALLET_REGISTER_SUCCESS = "WALLET_REGISTER_SUCCESS";

export const JOB_SEEKER_PROFILE_UPDATE = "JOB_SEEKER_PROFILE_UPDATE";
export const JOB_SEEKER_PROFILE_UPDATE_SUCCESS =
  "JOB_SEEKER_PROFILE_UPDATE_SUCCESS";

export const COMPANY_PROFILE_UPDATE = "COMPANY_PROFILE_UPDATE";
export const COMPANY_PROFILE_UPDATE_SUCCESS = "COMPANY_PROFILE_UPDATE_SUCCESS";

export const APPLICANT_DASHBOARD = "APPLICANT_DASHBOARD";
export const APPLICANT_DASHBOARD_SUCCESS = "APPLICANT_DASHBOARD_SUCCESS";

export const META_MASK_LOGIN = "META_MASK_LOGIN";
export const META_MASK_LOGIN_SUCCESS = "META_MASK_LOGIN_SUCCESS";

export const META_MASK_LOGIN_VERIFY = "META_MASK_LOGIN_VERIFY";
export const META_MASK_LOGIN_VERIFY_SUCCESS = "META_MASK_LOGIN_VERIFY_SUCCESS";

export const NEAR_LOGIN_VERIFY = "NEAR_LOGIN_VERIFY";
export const NEAR_LOGIN_VERIFY_SUCCESS = "NEAR_LOGIN_VERIFY_SUCCESS";

export const GOOGLE_CALLBACK_REDIRECT = "GOOGLE_CALLBACK_REDIRECT";
export const GOOGLE_CALLBACK_REDIRECT_SUCCESS =
  "GOOGLE_CALLBACK_REDIRECT_SUCCESS";

export const PASSWORD_RESET_TOP = "PASSWORD_RESET_TOP";
export const PASSWORD_RESET_TOP_SUCCESS = "PASSWORD_RESET_TOP_SUCCESS";

export const USER_ONBOARD_CV_UPLOAD = "USER_ONBOARD_CV_UPLOAD";
export const USER_ONBOARD_CV_UPLOAD_SUCCESS = "USER_ONBOARD_CV_UPLOAD_SUCCESS";

 