import {
  LOGIN_USER,
  REGISTER_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_OTP_VERIFY,
  VALIDATE_PHONE_AND_EMAIL,
  VALIDATE_REGISTRATION,
  RESEND_VERIFICATION,
  LOGOUT_USER,
  API_ERROR,
  CLEAR_API_ERROR,
  VERIFY_USER_LOGIN,
  VERIFY_LOGIN,
  EMPLOYER_PROFILE,
  CHANGE_PASSWORD,
  EMPLOYER_PROFILE_SUCCESS,
  JOB_SEEKER_PROFILE,
  JOB_SEEKER_PROFILE_SUCCESS,
  EMPLOYER_DASHBOARD,
  EMPLOYER_DASHBOARD_SUCCESS,
  WALLET_REGISTER,
  WALLET_REGISTER_SUCCESS,
  JOB_SEEKER_PROFILE_UPDATE,
  JOB_SEEKER_PROFILE_UPDATE_SUCCESS,
  COMPANY_PROFILE_UPDATE,
  COMPANY_PROFILE_UPDATE_SUCCESS,
  APPLICANT_DASHBOARD_SUCCESS,
  APPLICANT_DASHBOARD,
  META_MASK_LOGIN,
  META_MASK_LOGIN_SUCCESS,
  META_MASK_LOGIN_VERIFY,
  META_MASK_LOGIN_VERIFY_SUCCESS,
  NEAR_LOGIN_VERIFY,
  NEAR_LOGIN_VERIFY_SUCCESS,
  GOOGLE_CALLBACK_REDIRECT,
  GOOGLE_CALLBACK_REDIRECT_SUCCESS,
  PASSWORD_RESET_TOP,
  PASSWORD_RESET_TOP_SUCCESS,
  USER_ONBOARD_CV_UPLOAD,
  USER_ONBOARD_CV_UPLOAD_SUCCESS
} from "store/auth/constants";
import {
  IUserSignin,
  IUserSignUp,
  IUserForgotPassword,
  IValidatePhoneAndEmail,
} from "store/auth/types";

export const fetchEmployerProfile = (user: any) => {
  return {
    type: EMPLOYER_PROFILE,
    payload: user,
  };
};

export const fetchEmployerDashboard = (user: any) => {
  return {
    type: EMPLOYER_DASHBOARD,
    payload: user,
  };
};

export const fetchEmployerDashboardSuccess = (response: any) => {
  return {
    type: EMPLOYER_DASHBOARD_SUCCESS,
    payload: response,
  };
};

export const fetchApplicantDashboard = (user: any) => {
  return {
    type: APPLICANT_DASHBOARD,
    payload: user,
  };
};

export const fetchApplicantDashboardSuccess = (response: any) => {
  return {
    type: APPLICANT_DASHBOARD_SUCCESS,
    payload: response,
  };
};

export const fetchEmployerProfileSuccess = (response: any) => {
  return {
    type: EMPLOYER_PROFILE_SUCCESS,
    payload: response,
  };
};

export const metaMaskLoginMessage = (user: IUserSignin, redirect: string) => {
  return {
    type: META_MASK_LOGIN,
    payload: { user, redirect },
  };
};

export const metaMaskLoginMessageSuccess = (response: any) => {
  return {
    type: META_MASK_LOGIN_SUCCESS,
    payload: response,
  };
};

export const metaMaskLoginVefiry = (data: any) => {
  return {
    type: META_MASK_LOGIN_VERIFY,
    payload: data,
  };
};

export const metaMaskLoginVefirySuccess = (response: any) => {
  return {
    type: META_MASK_LOGIN_VERIFY_SUCCESS,
    payload: response,
  };
};

export const nearLoginVefiry = (data: any) => {
  return {
    type: NEAR_LOGIN_VERIFY,
    payload: data,
  };
};

export const nearLoginVefirySuccess = (response: any) => {
  return {
    type: NEAR_LOGIN_VERIFY_SUCCESS,
    payload: response,
  };
};

export const googleCallbackRedirect = (data: any) => {
  return {
    type: GOOGLE_CALLBACK_REDIRECT,
    payload: data,
  };
};

export const googleCallbackRedirectSuccess = (response: any) => {
  return {
    type: GOOGLE_CALLBACK_REDIRECT_SUCCESS,
    payload: response,
  };
};

export const loginUser = (user: IUserSignin, redirect: string) => {
  return {
    type: LOGIN_USER.REQUEST,
    payload: { user, redirect },
  };
};

export const loginSuccess = (response: any) => {
  return {
    type: LOGIN_USER.SUCCESS,
    payload: response,
  };
};

export const forgotPassword = (user: any, redirect: string) => {
  return {
    type: FORGOT_PASSWORD.REQUEST,
    payload: { user, redirect },
  };
};

export const forgotPasswordSuccess = (response: any) => {
  return {
    type: FORGOT_PASSWORD.SUCCESS,
    payload: response,
  };
};

export const verifyLogin = (response: any) => {
  return {
    type: VERIFY_LOGIN,
    payload: response,
  };
};

export const registerUser = (user: IUserSignUp) => {
  return {
    type: REGISTER_USER.REQUEST,
    payload: { user },
  };
};

export const registerSuccess = (response: any) => {
  return {
    type: REGISTER_USER.SUCCESS,
    payload: response,
  };
};

export const registerFailure = (response: any) => {
  return {
    type: REGISTER_USER.FAILURE,
    payload: response,
  };
};

export const validatePhoneAndEmail = (
  data: IValidatePhoneAndEmail,
  callback: any
) => {
  return {
    type: VALIDATE_PHONE_AND_EMAIL.REQUEST,
    payload: { data, callback },
  };
};

export const validatePhoneAndEmailSuccess = (response: any) => {
  return {
    type: VALIDATE_PHONE_AND_EMAIL.SUCCESS,
    payload: response,
  };
};

export const validateRegistration = (data: any, redirect: string) => {
  return {
    type: VALIDATE_REGISTRATION.REQUEST,
    payload: { data, redirect },
  };
};

export const validateRegistrationSuccess = (response: any) => {
  return {
    type: VALIDATE_REGISTRATION.SUCCESS,
    payload: response,
  };
};

export const resendVerification = (
  data: { email: string },
  redirect: string
) => {
  return {
    type: RESEND_VERIFICATION.REQUEST,
    payload: { data, redirect },
  };
};

export const resendVerificationSuccess = (response: any) => {
  return {
    type: RESEND_VERIFICATION.SUCCESS,
    payload: response,
  };
};

export const verifyUserLogin = (verify_payload: any) => {
  return {
    type: VERIFY_USER_LOGIN,
    payload: { verify_payload },
  };
};

export const forgotPasswordUser = (
  user: IUserSignin,
  callback: any,
  verifyTokenCallback: any
) => {
  return {
    type: FORGOT_PASSWORD.REQUEST,
    payload: { user, callback, verifyTokenCallback },
  };
};

export const forgotPasswordUserSuccess = (response: any) => {
  return {
    type: FORGOT_PASSWORD.SUCCESS,
    payload: response,
  };
};

export const VerifyOtpPasswordReset = (
  user: IUserSignin,
  callback: any,
  setResetTokenCallback: any
) => {
  return {
    type: FORGOT_PASSWORD_OTP_VERIFY.REQUEST,
    payload: { user, callback, setResetTokenCallback },
  };
};

export const VerifyOtpPasswordResetSuccess = (response: any) => {
  return {
    type: FORGOT_PASSWORD_OTP_VERIFY.SUCCESS,
    payload: response,
  };
};

export const postNewPassword = (user: any, callback: any) => {
  return {
    type: CHANGE_PASSWORD.REQUEST,
    payload: { user, callback },
  };
};

export const postNewPasswordSuccess = (response: any) => {
  return {
    type: CHANGE_PASSWORD.SUCCESS,
    payload: response,
  };
};

export const logoutUser = (history: any) => {
  return {
    type: LOGOUT_USER.REQUEST,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER.SUCCESS,
    payload: {},
  };
};

export const apiError = (error: any) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const clearErrors = (error: any) => {
  return {
    type: CLEAR_API_ERROR,
    payload: error,
  };
};

export const fetchJobSeekerProfile = (data: any) => {
  return {
    type: JOB_SEEKER_PROFILE,
    payload: { data },
  };
};

export const fetchJobSeekerProfileSuccess = (response: any) => {
  return {
    type: JOB_SEEKER_PROFILE_SUCCESS,
    payload: response,
  };
};

 



export const postUpdateApplicantProfile = (data: any) => {
  return {
    type: JOB_SEEKER_PROFILE_UPDATE,
    payload: { data },
  };
};

export const postUpdateApplicantProfileSuccess = (response: any) => {
  return {
    type: JOB_SEEKER_PROFILE_UPDATE_SUCCESS,
    payload: response,
  };
};

export const postUpdateCompanyProfile = (data: any) => {
  return {
    type: COMPANY_PROFILE_UPDATE,
    payload: { data },
  };
};

export const postUpdateCompanyProfileSuccess = (response: any) => {
  return {
    type: COMPANY_PROFILE_UPDATE_SUCCESS,
    payload: response,
  };
};

export const postWalletLogin = (user: any) => {
  return {
    type: WALLET_REGISTER,
    payload: user,
  };
};

export const postWalletLoginSuccess = (response: any) => {
  return {
    type: WALLET_REGISTER_SUCCESS,
    payload: response,
  };
};

export const passwordOtpReset = (user: any, callback: any) => {
  return {
    type: PASSWORD_RESET_TOP,
    payload: { user, callback },
  };
};

export const passwordOtpResetSuccess = (response: any) => {
  return {
    type: PASSWORD_RESET_TOP_SUCCESS,
    payload: response,
  };
};

export const userOnBoardCVUpload = (data: any) => {
  return {
    type: USER_ONBOARD_CV_UPLOAD,
    payload: { data },
  };
};



export const userOnBoardCVUploadSuccess = (response: any) => {
  return {
    type: USER_ONBOARD_CV_UPLOAD_SUCCESS,
    payload: response,
  };
};
