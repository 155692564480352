import React, { useState } from "react";
import StageComponent from "components/stageComponent";
import grayedAvatarImage from "../../assets/images/grayed-avatar.png";

const TableRowCustomApplicantApplications = ({
  data,
  jobs,
  setShowApplicationDetails,
  setApplicationID,
  setApplicantDetail,
  setUIDdata,
}) => {
  return (
    <>
      <div
        className={`flex flex-col md:flex-row py-10  border-gray-soft	 rounded-2xl w-full py-6  px-8 align-middle mt-4 hover:bg-gray-100 `}
      >
        <div className="lg:w-1/12">
          <input
            type="checkbox"
            value=""
            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
        </div>

        <div className="lg:w-4/12 flex">
          {data.company_applied.icon && (
            <img
              className="h-6 lg:h-6 w-6 object-cover mr-2 rounded-xl"
              src={data.company_applied.icon}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = `${grayedAvatarImage}`;
              }}
              alt=""
            />
          )}
          <span className="text-soft-gray whitespace-no-wrap DMSansBold ">
            {" "}
            {data.company_applied.name}
          </span>
        </div>

        <div className="lg:w-2/12">
          <div className="lg:ml-2">
            {" "}
            <StageComponent hiring_stage={data.hiring_stage} />
          </div>
        </div>

        <div className="lg:w-2/12">
          <p className="text-gray-900 whitespace-no-wrap DMSansRegular text-soft-gray">
            {new Date(data.created_at).toLocaleDateString(
              "en-us",
              { year: "numeric", month: "short", day: "numeric" }
            )}
          </p>
        </div>

        <div className="lg:w-2/12">
          <p className="text-gray-900 whitespace-no-wrap DMSansRegular text-soft-gray">
            {jobs &&
              jobs.data.data.jlid &&
              jobs.data.data.jlid.map((item2, index2) => {
                return (
                  <>
                    <a href={`/job-details/${data.jlid}`} target="_blank">
                      {item2.jlid == data.jlid ? item2.jobTitle : ""}
                    </a>
                  </>
                );
              })}
          </p>
        </div>

        {/*<div className="lg:w-4/12 text-center">
            <button 
            onClick={()=>{
              setShowApplicationDetails(true);
              setApplicationID(data.jlid)

              setApplicantDetail(data.company_applied);
              setUIDdata(data)


            }}
            className="nav-btn-custom ArchivoExtraBold w-6/12  shadowx text-xs p-10x">See Application</button>
        </div>*/}
      </div>
    </>
  );
};

export default TableRowCustomApplicantApplications;
