import { call, put, takeEvery } from "redux-saga/effects";
import cogoToast from "cogo-toast";

//services
import authServices from "api/services/auth";

//redux
import {
  LOGIN_USER,
  REGISTER_USER,
  VALIDATE_PHONE_AND_EMAIL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_OTP_VERIFY,
  VALIDATE_REGISTRATION,
  RESEND_VERIFICATION,
  CHANGE_PASSWORD,
  EMPLOYER_PROFILE,
  EMPLOYER_DASHBOARD,
  JOB_SEEKER_PROFILE,
  WALLET_REGISTER,
  JOB_SEEKER_PROFILE_UPDATE,
  COMPANY_PROFILE_UPDATE,
  APPLICANT_DASHBOARD,
  META_MASK_LOGIN,
  META_MASK_LOGIN_VERIFY,
  NEAR_LOGIN_VERIFY,
  GOOGLE_CALLBACK_REDIRECT,
  PASSWORD_RESET_TOP,
  USER_ONBOARD_CV_UPLOAD
} from "./constants";
import {
  apiError,
  loginSuccess,
  registerSuccess,
  registerFailure,
  forgotPasswordUserSuccess,
  VerifyOtpPasswordResetSuccess,
  validatePhoneAndEmailSuccess,
  validateRegistrationSuccess,
  resendVerificationSuccess,
  postNewPasswordSuccess,
  passwordOtpResetSuccess,
  fetchEmployerProfileSuccess,
  fetchEmployerDashboardSuccess,
  fetchJobSeekerProfileSuccess,
  postWalletLoginSuccess,
  postUpdateApplicantProfileSuccess,
  postUpdateCompanyProfileSuccess,
  fetchApplicantDashboardSuccess,
  metaMaskLoginMessageSuccess,
  metaMaskLoginVefirySuccess,
  nearLoginVefirySuccess,
  googleCallbackRedirectSuccess,
  userOnBoardCVUploadSuccess
} from "./actions";
import { ResponseGenerator } from "store/type";

function* loginUser({ payload }: any) {
  try {
    const { user, redirect } = payload;
    const response: ResponseGenerator = yield call(authServices.login, user);
    if (response.data.success) {
      yield put(loginSuccess(response.data));
      localStorage.setItem("AuthData", JSON.stringify(response.data));
      localStorage.setItem("token", JSON.stringify(response.data.token));
      localStorage.setItem("usericon", JSON.stringify(response.data.icon));

      const cvUploadData =  localStorage.getItem('cvUploadData');

      if (response.data.role == "employer") {
        window.location.href = "/company/dashboard";
      } else if (response.data.role == "admin") {
        window.location.href = "/admin/dashboard";
      } else if (response.data.role == "applicant") {
         
        if(cvUploadData !== null){
          window.location.href = "/jobseeker/profile";
        }else{
          window.location.href = "/jobseeker/dashboard";
        }
        
      } else {
        //do nothing
      }
    }
  } catch (error) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";

    yield put(apiError(message));
    cogoToast.error("Invalid Credentials. Please try again");
  }
}

function* metaMaskLoginMessage({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(
      authServices.metaMaskLoginMessage,
      payload
    );
    if (response.data) {
      yield put(metaMaskLoginMessageSuccess(response.data));
    }
  } catch (error) {
    const message = "error";
  }
}

function* postWalletLogin({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(
      authServices.postWalletLogin,
      payload
    );
    if (response.data) {
      yield put(postWalletLoginSuccess(response.data));
    }
  } catch (error) {
    const message = "error";
  }
}

function* metaMaskLoginVefiry({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(
      authServices.metaMaskLoginVefiry,
      payload
    );
    if (response.data) {
      yield put(metaMaskLoginVefirySuccess(response.data));

      localStorage.setItem("AuthData", JSON.stringify(response.data));
      localStorage.setItem("token", JSON.stringify(response.data.token));
      localStorage.setItem("usericon", ""); //none fordoxxed //response.data.icon

      cogoToast.success("Wallet connected successfully !").then(() => {
        window.location.href = "/jobseeker/dashboard";
      });
    }
  } catch (error) {
    const message = "error";
  }
}

function* nearLoginVefiry({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(
      authServices.nearLoginVefiry,
      payload
    );
    if (response.data) {
      yield put(nearLoginVefirySuccess(response.data));

      localStorage.setItem("AuthData", JSON.stringify(response.data));
      localStorage.setItem("token", JSON.stringify(response.data.token));
      localStorage.setItem("usericon", ""); //none fordoxxed //response.data.icon

      cogoToast.success("Wallet connected successfully !").then(() => {
        window.location.href = "/jobseeker/dashboard";
      });
    }
  } catch (error) {
    const message = "error";
  }
}

function* googleCallbackRedirect({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(
      authServices.getGoogleCallbackRedirect,
      payload
    );
    if (response.data) {
      yield put(googleCallbackRedirectSuccess(response.data));
      localStorage.setItem("AuthData", JSON.stringify(response.data));
      localStorage.setItem("token", JSON.stringify(response.data.token));
      localStorage.setItem("usericon", ""); //none fordoxxed //response.data.icon
      cogoToast.success("Google Account connected successfully !").then(() => {
        window.location.href = "/jobseeker/dashboard";
      });
    }
  } catch (error) {
    const message = "error";
  }
}

function* postUpdateApplicantProfile({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(
      authServices.postUpdateApplicantProfile,
      payload.data
    );
    if (response.data) {
      yield put(postUpdateApplicantProfileSuccess(response.data));
      const cvUploadData =  localStorage.getItem('cvUploadData');

      if(cvUploadData !==null){
          //dont reload
      }
      else{
        cogoToast.success("Profile Updated!").then(() => {
          window.location.href = "/jobseeker/profile";
        });
      }
    
    }
  } catch (error) {
    const message = "error";
    cogoToast.error("Profile Update failed. Check your network and Try again.");
  }
}

function* postUpdateCompanyProfile({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(
      authServices.postUpdateCompanyProfile,
      payload.data
    );
    if (response.data) {
      yield put(postUpdateCompanyProfileSuccess(response.data));
      cogoToast.success("Profile Updated!").then(() => {
        window.location.href = "/company/profile";
      });
    }
  } catch (error) {
    const message = "error";
    cogoToast.error("Profile Update failed. Check your network and Try again.");
  }
}

function* fetchEmployerProfile({ payload }: any) {
  try {
    const { user } = payload;

    const response: ResponseGenerator = yield call(
      authServices.fetchEmployerProfileURL,
      user
    );

    if (response.data) {
      yield put(fetchEmployerProfileSuccess(response.data));
    }
  } catch (error) {}
}

function* fetchJobSeekerProfile({ payload }: any) {
  try {
    const { user } = payload;

    const response: ResponseGenerator = yield call(
      authServices.fetchJobSeekerProfile,
      user
    );

    if (response.data) {
      yield put(fetchJobSeekerProfileSuccess(response.data));
    }
  } catch (error) {}
}

function* fetchEmployerDashboard({ payload }: any) {
  try {
    const { user } = payload;

    const response: ResponseGenerator = yield call(
      authServices.fetchEmployerDashboardURL,
      user
    );

    if (response.data) {
      yield put(fetchEmployerDashboardSuccess(response.data));
    }
  } catch (error) {}
}

function* fetchApplicantDashboard({ payload }: any) {
  try {
    const { user } = payload;

    const response: ResponseGenerator = yield call(
      authServices.fetchApplicantDashboard,
      user
    );

    if (response.data) {
      yield put(fetchApplicantDashboardSuccess(response.data));
    }
  } catch (error) {}
}

function* passwordOtpReset({ payload }: any) {
  try {
    const { user, callback } = payload;
    const response: ResponseGenerator = yield call(
      authServices.resetPasswordOTP,
      user
    );

    //if (response.data.status === "success") {
    yield put(passwordOtpResetSuccess(response.data));
    // callback(3);

    cogoToast.success("user password successfully reset").then(() => {
      window.location.href = "login";
    });
    // }
  } catch (error) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    yield put(apiError(message));
  }
}

function* forgotPasswordUser({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(
      authServices.forgotPassword,
      payload.user
    );
    if (response.data) {
      yield put(forgotPasswordUserSuccess(response.data));
      localStorage.setItem("forgotPasswordOTP", response.data.otp);
      cogoToast
        .success("Password reset link successfully sent to your email.")
        .then(() => {});
    }
  } catch (error) {
    const message = "error";
  }
}

function* VerifyOtpPasswordReset({ payload }: any) {
  try {
    const { user, callback, setResetTokenCallback } = payload;
    const response: ResponseGenerator = yield call(
      authServices.forgotPasswordOtp,
      user
    );
    if (response.data.status === "success") {
      yield put(VerifyOtpPasswordResetSuccess(response.data));
      setResetTokenCallback(response.data.data.password_reset_token);
      callback(3);
    }
  } catch (error) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";

    yield put(apiError(message));
  }
}

function* postNewPassword({ payload }: any) {
  try {
    const { user, callback } = payload;
    const response: ResponseGenerator = yield call(
      authServices.changePassword,
      user
    );

    if (response.data.success) {
      yield put(postNewPasswordSuccess(response.data));
      // callback(3);

      cogoToast.success("user password successfully reset").then(() => {
         window.location.href = "";
      });
    }
    else{
      cogoToast.error("Old Password did not match. Try again").then(() => {

     });
    }
  } catch (error) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    yield put(apiError(message));
  }
}

function* registerUser({ payload }: any) {
  try {
    const { user, redirect } = payload;
    let useService = authServices.register;
    if (user.role == "employer") {
      useService = authServices.registerCompany;
    }
    const response: ResponseGenerator = yield call(authServices.register, user);
    if (response.data.success) {
      yield put(registerSuccess(response.data));
      cogoToast.success(
        "Sign Up Successful. Check your mail for verification link."
      );

      setTimeout(() => {
        if (user.role == "applicant") {
        window.location.href = "onboarding/upload-cv";
        }
        else{
          window.location.href = "email-verification";

        }
      }, 2000);
    } else {
      yield put(registerFailure(response.data));
      cogoToast.error(response.data.message);
    }
  } catch (error) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    cogoToast.error(message);
    yield put(apiError(message));
  }
}


function* validatePhoneAndEmail({ payload }: any) {
  try {
    const { data, callback } = payload;
    const response: ResponseGenerator = yield call(
      authServices.validatePhoneAndEmail,
      data
    );
    if (response.data.status === "success") {
      yield put(validatePhoneAndEmailSuccess(response.data));
      callback(2);
    }
  } catch (error) {
    console.log(error.response.data.message);
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    yield put(apiError(message));
  }
}

function* validateRegistration({ payload }: any) {
  try {
    const { data, redirect } = payload;
    const response: ResponseGenerator = yield call(
      authServices.validateRegistration,
      data
    );
    console.log(response.data);
    if (response.data) {
      yield put(validateRegistrationSuccess(response.data));
      window.location.replace(`${window.location.origin}${redirect}`);
    }
  } catch (error) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    yield put(apiError(message));
  }
}

function* resendVerification({ payload }: any) {
  try {
    const { data, redirect } = payload;

    const response: ResponseGenerator = yield call(
      authServices.resendVerification,
      data
    );

    console.log(response.data.data);

    if (response.data) {
      yield put(resendVerificationSuccess(response.data.data));

      cogoToast.success("otp resent, check your e-mail").then(() => {
        window.location.replace(
          `${window.location.origin}${redirect}&email=${response.data.data.email}&token=${response.data.data.verify_token}`
        );
      });
    }
  } catch (error) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";

    yield put(apiError(message));
  }
}

function* userOnBoardCVUpload({ payload }: any) {
  try {
    const { data } = payload;
     const response: ResponseGenerator = yield call(
      authServices.userOnboardingCVUpload,
      data
    );
    
    // if (response.data.success) {
      yield put(userOnBoardCVUploadSuccess(response.data));
      localStorage.setItem("cvUploadData", JSON.stringify(response.data));

      //if (response.data) {
        cogoToast.success("CV Uploaded successfully").then(() => {
        //window.location.href="/company/job-listing";
        
        });
      //}
    //}
  } catch (error) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    yield put(apiError(message));
    cogoToast.error("Error occurred while uploading document");
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER.REQUEST, loginUser);
  yield takeEvery(REGISTER_USER.REQUEST, registerUser);
  yield takeEvery(FORGOT_PASSWORD.REQUEST, forgotPasswordUser);
  yield takeEvery(FORGOT_PASSWORD_OTP_VERIFY.REQUEST, VerifyOtpPasswordReset);
  yield takeEvery(CHANGE_PASSWORD.REQUEST, postNewPassword);
  yield takeEvery(USER_ONBOARD_CV_UPLOAD, userOnBoardCVUpload);
  yield takeEvery(VALIDATE_PHONE_AND_EMAIL.REQUEST, validatePhoneAndEmail);
  yield takeEvery(VALIDATE_REGISTRATION.REQUEST, validateRegistration);
  yield takeEvery(RESEND_VERIFICATION.REQUEST, resendVerification);
  yield takeEvery(EMPLOYER_PROFILE, fetchEmployerProfile);
  yield takeEvery(EMPLOYER_DASHBOARD, fetchEmployerDashboard);
  yield takeEvery(JOB_SEEKER_PROFILE, fetchJobSeekerProfile);
  yield takeEvery(WALLET_REGISTER, postWalletLogin);
  yield takeEvery(JOB_SEEKER_PROFILE_UPDATE, postUpdateApplicantProfile);
  yield takeEvery(COMPANY_PROFILE_UPDATE, postUpdateCompanyProfile);
  yield takeEvery(PASSWORD_RESET_TOP, passwordOtpReset);
  yield takeEvery(APPLICANT_DASHBOARD, fetchApplicantDashboard);
  yield takeEvery(META_MASK_LOGIN, metaMaskLoginMessage);
  yield takeEvery(META_MASK_LOGIN_VERIFY, metaMaskLoginVefiry);
  yield takeEvery(NEAR_LOGIN_VERIFY, nearLoginVefiry);
  yield takeEvery(GOOGLE_CALLBACK_REDIRECT, googleCallbackRedirect);   
}

export default authSaga;
