import React, { useState } from "react";
const PaymentBox = (props) => {
  return (
    <>
      <div className="p-2 lg:w-full bg-bee-gray rounded-2xl ">
        <div className="h-full  bg-opacity-75 px-4 pt-4 pb-4   overflow-hidden text-centerx relative">
          <h1 className="title-font sm:text-xl text-md font-medium  text-soft-gray  mb-3 ArchivoExtraBold">
            {props.title}
          </h1>
          <p className="leading-relaxed mb-3 text-soft-gray text-sm">
            {props.desc1}
            <br />
            <br />
            {props.desc2}
          </p>
          <div className="mb-3">
            <svg
              className="w-11/12"
              width="688"
              height="1"
              viewBox="0 0 688 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                strokeWidth="0.4"
                x1="0.5"
                y1="0.5"
                x2="687.5"
                y2="0.5"
                stroke="#25324B"
                stroke-linecap="round"
                stroke-dasharray="12 12"
              />
            </svg>
          </div>
          {props.plantype == "jobpost" ? (
            <div className="w-full bg-transparent-select">
              {/*<p className="text-soft-gray text-sm">{pricePostRange} post for <span className="text-soft-gray text-lg DMSansBold" >{pricePostAmount}</span>/month</p>
               */}
              <select
                className="rounded-lg text-smx bg-sub-plan"
                onChange={(e) => {
                  props.setPricePostAmount(e.target.value);
                }}
              >
                <option>Select Plan</option>
                <option value={169}>1-3 Posts $169/month</option>
                <option value={269}>4-6 Posts $269/month</option>
                <option value={400}>9+ Posts $400/month</option>
              </select>
            </div>
          ) : (
            <p className="text-soft-gray text-sm">
              <span className="text-soft-gray text-lg DMSansBold">$369</span>
              /month (50 downloads)
            </p>
          )}
          <button
            disabled={props.disabled}
            onClick={() => {
              if (props.plantype == "cv") {
                props.setPricePostAmount(369);
                props.setShowStripeModal(true);
              }
              if (props.plantype == "jobpost") {
                if (props.pricePostAmount !== 0) {
                  props.setShowStripeModal(true);
                }
              }
            }}
            className="nav-btn-custom-orange hover:bg-blue-dark  text-soft-grayx  text-sm py-2 px-4 rounded text-white DMSansRegular mt-5 w-4/12"
          >
            {props.actionText}
          </button>
        </div>
      </div>
    </>
  );
};

export default PaymentBox;
