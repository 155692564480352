const MenuItem = (currentPathName) => {
  return (
    <>
      <ul className="menu-list">
        <li
          className={`ml-2 ${
            currentPathName == "/jobseeker/dashboard" ? "bg-bee-gray " : ""
          }`}
        >
          <a href="/jobseeker/dashboard">
            <span className="icon">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 10H1L10 1L19 10H17"
                  stroke="#25324B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 10V17C3 17.5304 3.21071 18.0391 3.58579 18.4142C3.96086 18.7893 4.46957 19 5 19H15C15.5304 19 16.0391 18.7893 16.4142 18.4142C16.7893 18.0391 17 17.5304 17 17V10"
                  stroke="#25324B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span className="menu-item-label DMSansRegular">Dashboard</span>
          </a>
        </li>

        <li
          className={`ml-2 ${
            currentPathName == "/jobseeker/myapplication" ? "bg-bee-gray " : ""
          }`}
        >
          <a href="/jobseeker/myapplication">
            <span className="icon">
              <svg
                width="16"
                height="21"
                viewBox="0 0 16 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3 2.70508C2.73478 2.70508 2.48043 2.81043 2.29289 2.99797C2.10536 3.18551 2 3.43986 2 3.70508V17.7051C2 17.9703 2.10536 18.2246 2.29289 18.4122C2.48043 18.5997 2.73478 18.7051 3 18.7051H13C13.2652 18.7051 13.5196 18.5997 13.7071 18.4122C13.8946 18.2246 14 17.9703 14 17.7051L14 8.11929L8.58589 2.70518L3 2.70508ZM0.87868 1.58376C1.44129 1.02115 2.20435 0.705078 3 0.705078H8.586C9.11634 0.705192 9.62513 0.915939 10.0001 1.29097L15.414 6.70486C15.789 7.07984 15.9999 7.58853 16 8.11886V17.7051C16 18.5007 15.6839 19.2638 15.1213 19.8264C14.5587 20.389 13.7957 20.7051 13 20.7051H3C2.20435 20.7051 1.44129 20.389 0.87868 19.8264C0.31607 19.2638 0 18.5007 0 17.7051V3.70508C0 2.90943 0.31607 2.14637 0.87868 1.58376ZM4 10.7051C4 10.1528 4.44772 9.70508 5 9.70508H11C11.5523 9.70508 12 10.1528 12 10.7051C12 11.2574 11.5523 11.7051 11 11.7051H5C4.44772 11.7051 4 11.2574 4 10.7051ZM4 14.7051C4 14.1528 4.44772 13.7051 5 13.7051H11C11.5523 13.7051 12 14.1528 12 14.7051C12 15.2574 11.5523 15.7051 11 15.7051H5C4.44772 15.7051 4 15.2574 4 14.7051Z"
                  fill="#25324B"
                />
              </svg>
            </span>
            <span className="menu-item-label DMSansRegular">
              My Applications
            </span>
          </a>
        </li>

        <li className="ml-2">
          <a href="/job-board" target="_blank">
            <span className="icon">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="9.46897"
                  cy="9.80238"
                  r="8.46897"
                  stroke="#25324B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.3593 16.1325L18.6796 19.4442"
                  stroke="#25324B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span className="menu-item-label DMSansRegular">Find jobs</span>
          </a>
        </li>

        <li
          className={`ml-2 ${
            currentPathName == "/jobseeker/profile" ? "bg-bee-gray " : ""
          }`}
        >
          <a href="/jobseeker/profile">
            <span className="icon">
              <svg
                width="16"
                height="21"
                viewBox="0 0 16 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 5.70508C12 6.76594 11.5786 7.78336 10.8284 8.53351C10.0783 9.28365 9.06087 9.70508 8 9.70508C6.93913 9.70508 5.92172 9.28365 5.17157 8.53351C4.42143 7.78336 4 6.76594 4 5.70508C4 4.64421 4.42143 3.6268 5.17157 2.87665C5.92172 2.12651 6.93913 1.70508 8 1.70508C9.06087 1.70508 10.0783 2.12651 10.8284 2.87665C11.5786 3.6268 12 4.64421 12 5.70508V5.70508ZM8 12.7051C6.14348 12.7051 4.36301 13.4426 3.05025 14.7553C1.7375 16.0681 1 17.8486 1 19.7051H15C15 17.8486 14.2625 16.0681 12.9497 14.7553C11.637 13.4426 9.85652 12.7051 8 12.7051V12.7051Z"
                  stroke="#25324B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span className="menu-item-label DMSansRegular">
              My public profile
            </span>
          </a>
        </li>

        {/*    <li className="ml-2">
        <a href="#">
          <span className="icon">
          <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.6575 22.7024C15.3548 22.7015 15.0553 22.6422 14.7762 22.5277C14.4972 22.4131 14.2442 22.2458 14.0318 22.0352L8.99999 17.1499L3.96823 22.0384C3.64508 22.3586 3.23064 22.5762 2.77895 22.663C2.32726 22.7498 1.85927 22.7017 1.43599 22.525C1.00851 22.3571 0.642812 22.0671 0.386653 21.6931C0.130494 21.3191 -0.00422116 20.8783 0.000100828 20.4283V4.70029C0.000100828 3.64071 0.431101 2.62453 1.19829 1.87529C1.96547 1.12606 3.006 0.705139 4.09096 0.705139H13.909C14.4462 0.705139 14.9782 0.808477 15.4745 1.00925C15.9709 1.21003 16.4218 1.50431 16.8017 1.87529C17.1816 2.24627 17.4829 2.6867 17.6885 3.17141C17.8941 3.65612 17.9999 4.17564 17.9999 4.70029V20.4283C18.0045 20.8779 17.8702 21.3184 17.6147 21.6924C17.3591 22.0664 16.9941 22.3566 16.5673 22.525C16.2791 22.6427 15.9698 22.703 15.6575 22.7024ZM4.09096 2.3032C3.43998 2.3032 2.81567 2.55575 2.35536 3.00529C1.89505 3.45483 1.63644 4.06454 1.63644 4.70029V20.4283C1.63615 20.5615 1.67627 20.6917 1.75173 20.8026C1.82719 20.9135 1.93461 21.0001 2.06041 21.0514C2.18622 21.1027 2.32478 21.1164 2.45858 21.0908C2.59238 21.0653 2.71542 21.0016 2.81216 20.9077L8.42727 15.4576C8.58057 15.3087 8.78793 15.2252 9.00408 15.2252C9.22023 15.2252 9.4276 15.3087 9.5809 15.4576L15.1895 20.9061C15.2862 21 15.4092 21.0637 15.543 21.0892C15.6768 21.1148 15.8154 21.1011 15.9412 21.0498C16.067 20.9985 16.1744 20.9119 16.2499 20.801C16.3254 20.6901 16.3655 20.5599 16.3652 20.4267V4.70029C16.3652 4.06454 16.1066 3.45483 15.6463 3.00529C15.186 2.55575 14.5616 2.3032 13.9107 2.3032H4.09096Z" fill="#25324B"/>
          </svg>
          </span>
          <span className="menu-item-label DMSansRegular">Posts saved</span>
        </a>
      </li>
*/}

        <li
          className={`ml-2 mt-80 ${
            currentPathName == "/company/setting" ? "bg-bee-gray " : ""
          }`}
        >
          <a href="/jobseeker/profile">
            <span className="icon">
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.325 4.317C10.751 2.561 13.249 2.561 13.675 4.317C13.7389 4.5808 13.8642 4.82578 14.0407 5.032C14.2172 5.23822 14.4399 5.39985 14.6907 5.50375C14.9414 5.60764 15.2132 5.65085 15.4838 5.62987C15.7544 5.60889 16.0162 5.5243 16.248 5.383C17.791 4.443 19.558 6.209 18.618 7.753C18.4769 7.98466 18.3924 8.24634 18.3715 8.51677C18.3506 8.78721 18.3938 9.05877 18.4975 9.30938C18.6013 9.55999 18.7627 9.78258 18.9687 9.95905C19.1747 10.1355 19.4194 10.2609 19.683 10.325C21.439 10.751 21.439 13.249 19.683 13.675C19.4192 13.7389 19.1742 13.8642 18.968 14.0407C18.7618 14.2172 18.6001 14.4399 18.4963 14.6907C18.3924 14.9414 18.3491 15.2132 18.3701 15.4838C18.3911 15.7544 18.4757 16.0162 18.617 16.248C19.557 17.791 17.791 19.558 16.247 18.618C16.0153 18.4769 15.7537 18.3924 15.4832 18.3715C15.2128 18.3506 14.9412 18.3938 14.6906 18.4975C14.44 18.6013 14.2174 18.7627 14.0409 18.9687C13.8645 19.1747 13.7391 19.4194 13.675 19.683C13.249 21.439 10.751 21.439 10.325 19.683C10.2611 19.4192 10.1358 19.1742 9.95929 18.968C9.7828 18.7618 9.56011 18.6001 9.30935 18.4963C9.05859 18.3924 8.78683 18.3491 8.51621 18.3701C8.24559 18.3911 7.98375 18.4757 7.752 18.617C6.209 19.557 4.442 17.791 5.382 16.247C5.5231 16.0153 5.60755 15.7537 5.62848 15.4832C5.64942 15.2128 5.60624 14.9412 5.50247 14.6906C5.3987 14.44 5.23726 14.2174 5.03127 14.0409C4.82529 13.8645 4.58056 13.7391 4.317 13.675C2.561 13.249 2.561 10.751 4.317 10.325C4.5808 10.2611 4.82578 10.1358 5.032 9.95929C5.23822 9.7828 5.39985 9.56011 5.50375 9.30935C5.60764 9.05859 5.65085 8.78683 5.62987 8.51621C5.60889 8.24559 5.5243 7.98375 5.383 7.752C4.443 6.209 6.209 4.442 7.753 5.382C8.749 5.99 10.049 5.452 10.325 4.317Z"
                  stroke="#25324B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12V12Z"
                  stroke="#25324B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span className="menu-item-label DMSansRegular">Settings</span>
          </a>
        </li>

        <li
          className={`ml-2 mt-80x ${
            currentPathName == "/company/help" ? "bg-bee-gray " : ""
          }`}
        >
          <a href="#">
            <span className="icon">
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                  stroke="#25324B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 17V17.01"
                  stroke="#25324B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 13.5C11.9816 13.1754 12.0692 12.8536 12.2495 12.583C12.4299 12.3125 12.6933 12.1079 13 12C13.3759 11.8563 13.7132 11.6272 13.9856 11.331C14.2579 11.0347 14.4577 10.6792 14.5693 10.2926C14.6809 9.90597 14.7013 9.49871 14.6287 9.10288C14.5562 8.70705 14.3928 8.33346 14.1513 8.01153C13.9099 7.68959 13.597 7.4281 13.2373 7.24763C12.8776 7.06716 12.4809 6.97265 12.0785 6.97154C11.6761 6.97042 11.2789 7.06273 10.9182 7.2412C10.5576 7.41967 10.2432 7.67942 10 8.00001"
                  stroke="#25324B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span className="menu-item-label DMSansRegular">Help Center</span>
          </a>
        </li>

        <li className="ml-2 ">
          <a
            href="#"
            onClick={() => {
              localStorage.clear();
              window.location.href = "/";
            }}
          >
            <span className="icon"></span>
            <span className="menu-item-label DMSansRegular">Logout</span>
          </a>
        </li>
      </ul>
    </>
  );
};
export default MenuItem;
