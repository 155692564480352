import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import AuthRoute from "./AuthRoute";

//authentication
import SignIn from "pages/auth/SignIn";
//404 page
import NotFound from "pages/notFound";

import Home from "pages/home/Home";
import Login from "pages/login/Login";
import Register from "pages/login/Register";
import Thankyou from "pages/login/Thankyou";

import JobSeekersDashboard from "pages/dashboard/jobseeker/index";
import CompanyDashboard from "pages/dashboard/company/index";
import PostAJob from "pages/dashboard/company/postAJob";

import JobBoard from "pages/jobboard/JobBoard";
import JobDetails from "pages/jobdetails/JobDetails";
import Education from "pages/education/Education";
import AboutUs from "pages/aboutus/AboutUs";
import RoadMap from "pages/roadmap/RoadMap";
import Mint from "pages/mint/Mint";
import MyApplicationDashboard from "pages/dashboard/jobseeker/myapplications";

import JobListingCompany from "pages/dashboard/company/jobListing";
import CompanyProfile from "pages/dashboard/company/profile/companyProfile";
import AllApplication from "pages/dashboard/company/applicant/all";
import AllApplicationAdmin from "pages/dashboard/admin/all";

import JobseekerProfile from "pages/dashboard/jobseeker/profile";
import WalletRegister from "pages/login/walletRegister";
import CVExplorer from "pages/dashboard/company/cvexplorer";

import Subscription from "pages/subscription/subscription";

import AdminDashboard from "pages/dashboard/admin/index";
import AdminUsers from "pages/dashboard/admin/users";
import AdminJobs from "pages/dashboard/admin/jobs";
import AdminCvs from "pages/dashboard/admin/cvs";
import CVGPT from "pages/dashboard/admin/cvgptbeta"; 
import ForgotPassword from "pages/login/forgotPassword";
import ResetPassword from "pages/login/resetPassword";
import UploadCVOptional from "pages/login/UploadCVOptional";
import Cvreview from "pages/cvreview";

const Router = () => {
  return (
    <Routes>
      {/* authentication */}
      <Route
        path="/signin"
        element={
          <AuthRoute>
            <SignIn />
          </AuthRoute>
        }
      />

      {/* home  */}
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
       
      <Route path="/githubcallback" element={<JobseekerProfile />} />

      <Route path="/job-board" element={<JobBoard />} />
      <Route path="/job-details/:id" element={<JobDetails />} />
      <Route path="/education" element={<Education />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/roadmap" element={<RoadMap />} />
      {/*@ts-ignore*/}
      {/*<Route path="/mint" element={<Mint />} />  */}
      <Route path="/register" element={<Register />} />
      <Route path="/email-verification" element={<Thankyou />} />
      <Route path="/onboarding-completed" element={<Thankyou />} />
      <Route path="/onboarding/upload-cv" element={<UploadCVOptional />} /> 
      
      <Route path="/cv-review" element={<Cvreview />} /> 

      <Route path="/jobseeker/dashboard" element={<JobSeekersDashboard />} />
      <Route
        path="/jobseeker/myapplication"
        element={<MyApplicationDashboard />}
      />
      <Route path="/company/dashboard" element={<CompanyDashboard />} />

      <Route path="/company/job-listing" element={<JobListingCompany />} />
      <Route path="/company/profile" element={<CompanyProfile />} />
      <Route path="/jobseeker/profile" element={<JobseekerProfile />} />

      <Route path="/company/all-applicants" element={<AllApplication />} />
      <Route path="/near-wallet-connect" element={<WalletRegister />} />
      <Route path="/company/cv-explorer" element={<CVExplorer />} />
      <Route path="/subscription-plans" element={<Subscription />} />

      <Route path="/admin/dashboard" element={<AdminDashboard />} />
      <Route path="/admin-login" element={<Login />} />
      <Route path="/admin/users" element={<AdminUsers />} />
      <Route path="/admin/jobs" element={<AdminJobs />} />
      <Route path="/admin/cvs" element={<AdminCvs />} />
      <Route path="/admin/cvgpt" element={<CVGPT />} />

      <Route path="/admin/all-applicants" element={<AllApplicationAdmin />} />

      <Route path="/company/postajob" element={<PostAJob />} />
      <Route path="/admin/editjob" element={<PostAJob />} />
      <Route path="/admin/postajob/:id/:title" element={<PostAJob />} />

      {/* not found */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
