import {
  LOGIN_USER,
  REGISTER_USER,
  VALIDATE_PHONE_AND_EMAIL,
  VALIDATE_REGISTRATION,
  RESEND_VERIFICATION,
  LOGOUT_USER,
  API_ERROR,
  CLEAR_API_ERROR,
  VERIFY_LOGIN,
  FORGOT_PASSWORD_OTP_VERIFY,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  EMPLOYER_PROFILE,
  EMPLOYER_PROFILE_SUCCESS,
  EMPLOYER_DASHBOARD,
  EMPLOYER_DASHBOARD_SUCCESS,
  JOB_SEEKER_PROFILE,
  JOB_SEEKER_PROFILE_SUCCESS,
  WALLET_REGISTER,
  WALLET_REGISTER_SUCCESS,
  JOB_SEEKER_PROFILE_UPDATE_SUCCESS,
  JOB_SEEKER_PROFILE_UPDATE,
  COMPANY_PROFILE_UPDATE_SUCCESS,
  COMPANY_PROFILE_UPDATE,
  APPLICANT_DASHBOARD,
  APPLICANT_DASHBOARD_SUCCESS,
  META_MASK_LOGIN,
  META_MASK_LOGIN_SUCCESS,
  META_MASK_LOGIN_VERIFY,
  META_MASK_LOGIN_VERIFY_SUCCESS,
  NEAR_LOGIN_VERIFY,
  NEAR_LOGIN_VERIFY_SUCCESS,
  GOOGLE_CALLBACK_REDIRECT,
  GOOGLE_CALLBACK_REDIRECT_SUCCESS,
  PASSWORD_RESET_TOP,
  PASSWORD_RESET_TOP_SUCCESS,
  USER_ONBOARD_CV_UPLOAD_SUCCESS,
  USER_ONBOARD_CV_UPLOAD,
} from "store/auth/constants";

const initialState = {
  error: "",
  apiError: null,
  loading: false,
  otp_data: {},
  user_token: null,
  user_data: null,
  profileDetails: [],
  employedDashboardDetails: [],
  applicantDashboardDetails: [],

  jobSeekerProfile: [],
  companyProfile: [],
  user_data_meta_message: null,
};

const auth = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_USER.REQUEST:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case FORGOT_PASSWORD.REQUEST:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case FORGOT_PASSWORD.SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
      };
      break;

    case FORGOT_PASSWORD.FAILURE:
      state = {
        ...state,
        loading: false,
        error: "error",
      };
      break;

    case FORGOT_PASSWORD_OTP_VERIFY.REQUEST:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case FORGOT_PASSWORD_OTP_VERIFY.SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
      };
      break;

    case FORGOT_PASSWORD_OTP_VERIFY.FAILURE:
      state = {
        ...state,
        loading: false,
        error: "error",
      };
      break;


    case CHANGE_PASSWORD.SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
      };
      break;

    case CHANGE_PASSWORD.FAILURE:
      state = {
        ...state,
        loading: false,
        error: "error",
      };
      break;

    case LOGIN_USER.SUCCESS:
      state = {
        ...state,
        user_token: action.payload.jwt_token,
        user_data: action.payload.user,
        loading: false,
        error: "",
      };
      break;

    case NEAR_LOGIN_VERIFY:
      state = {
        ...state,
        loading: false,
        error: "",
      };
      break;

    case NEAR_LOGIN_VERIFY_SUCCESS:
      state = {
        ...state,
        user_token: action.payload.token,
        user_data: action.payload,
        loading: false,
        error: "",
      };
      break;

    case GOOGLE_CALLBACK_REDIRECT:
      state = {
        ...state,
        loading: false,
        error: "",
      };
      break;

    case GOOGLE_CALLBACK_REDIRECT_SUCCESS:
      state = {
        ...state,
        user_token: action.payload.token,
        user_data: action.payload,
        loading: false,
        error: "",
      };
      break;

    case META_MASK_LOGIN_VERIFY:
      state = {
        ...state,
        loading: false,
        error: "",
      };
      break;

    case META_MASK_LOGIN_VERIFY_SUCCESS:
      state = {
        ...state,
        user_token: action.payload.token,
        user_data: action.payload,
        loading: false,
        error: "",
      };
      break;

    case META_MASK_LOGIN:
      state = {
        ...state,
        loading: false,
        error: "",
      };
      break;

    case META_MASK_LOGIN_SUCCESS:
      state = {
        ...state,
        user_data_meta_message: action.payload,
        loading: false,
        error: "",
      };
      break;

    case REGISTER_USER.REQUEST:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case REGISTER_USER.SUCCESS:
      state = {
        ...state,
        user_data: action.payload,
        loading: false,
        error: "",
      };
      break;

      case USER_ONBOARD_CV_UPLOAD:
        state = {
          ...state,
          loading: true,
          error: "",
        };
        break;
  
      case USER_ONBOARD_CV_UPLOAD_SUCCESS:
        state = {
          ...state,
          loading: false,
          error: "",
          user_data: action.payload,
        };
        break;


    case REGISTER_USER.FAILURE:
      state = {
        ...state,
        loading: false,
        error: "",
      };
      break;

    case EMPLOYER_PROFILE:
      state = {
        ...state,
        loading: true,
        error: "",
        profileDetails: action.payload,
      };
      break;

    case EMPLOYER_PROFILE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        profileDetails: action.payload,
      };
      break;

    case EMPLOYER_DASHBOARD:
      state = {
        ...state,
        loading: true,
        error: "",
        employedDashboardDetails: action.payload,
      };
      break;

    case APPLICANT_DASHBOARD:
      state = {
        ...state,
        loading: true,
        error: "",
        applicantDashboardDetails: action.payload,
      };
      break;

    case APPLICANT_DASHBOARD_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        applicantDashboardDetails: action.payload,
      };
      break;

    case JOB_SEEKER_PROFILE:
      state = {
        ...state,
        loading: true,
        error: "",
        jobSeekerProfile: action.payload,
      };
      break;

    case JOB_SEEKER_PROFILE_SUCCESS:
      state = {
        ...state,
        loading: true,
        error: "",
        jobSeekerProfile: action.payload,
      };
      break;

    case JOB_SEEKER_PROFILE_UPDATE:
      state = {
        ...state,
        loading: true,
        error: "",
        jobSeekerProfile: action.payload,
      };
      break;

    case JOB_SEEKER_PROFILE_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: true,
        error: "",
        jobSeekerProfile: action.payload,
      };
      break;

    case COMPANY_PROFILE_UPDATE:
      state = {
        ...state,
        loading: true,
        error: "",
        companyProfile: action.payload,
      };
      break;

    case COMPANY_PROFILE_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: true,
        error: "",
        companyProfile: action.payload,
      };
      break;

    case WALLET_REGISTER:
      state = {
        ...state,
        loading: true,
        error: "",
        user_data: action.payload,
      };
      break;

    case WALLET_REGISTER_SUCCESS:
      state = {
        ...state,
        loading: true,
        error: "",
        user_data: action.payload,
      };
      break;

    case PASSWORD_RESET_TOP:
      state = {
        ...state,
        loading: true,
        error: "",
        user_data: action.payload,
      };
      break;

    case PASSWORD_RESET_TOP_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        user_data: action.payload,
      };
      break;

    case EMPLOYER_DASHBOARD_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        employedDashboardDetails: action.payload,
      };
      break;

    case VALIDATE_PHONE_AND_EMAIL.REQUEST:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case VALIDATE_PHONE_AND_EMAIL.SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
      };
      break;

    case VALIDATE_REGISTRATION.REQUEST:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case VALIDATE_REGISTRATION.SUCCESS:
      state = {
        ...state,
        user_token: action.payload.jwt_token,
        user_data: action.payload.user,
        loading: false,
        error: "",
      };
      break;

    case RESEND_VERIFICATION.REQUEST:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case RESEND_VERIFICATION.SUCCESS:
      state = {
        ...state,
        otp_data: action.payload,
        loading: false,
        error: "",
      };
      break;

    case VERIFY_LOGIN:
      state = {
        ...state,
        otp_data: action.payload,
        loading: false,
      };
      break;

    case LOGOUT_USER.REQUEST:
      state = { ...state };
      break;

    case LOGOUT_USER.SUCCESS:
      state = { ...state };
      break;

    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;

    case CLEAR_API_ERROR:
      state = { ...state, error: "" };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default auth;
