import React, { useState, useEffect } from "react";

import VideoListCard from "components/videoListCard";

//components
import NavBar from "components/navBar";
import educationalIconImage from "assets/images/educational.png";
import howtobuyNFTIMage from "assets/images/how-to-buy-nft.svg";
import cryptoRegulationImage from "assets/images/crypto-regulations.png";
import ctaGrayImage from "assets/images/cta-gray-image.svg";
import Footer from "components/footer";

const Education: React.FC = () => {
  return (
    <>
      <NavBar />

      <div className=" py-20 lg:px-20 px-5 grid">
        <h1 className="text-2xl ArchivoBlack text-soft-gray">
          <span className="text-bee-orange ArchivoBlack">Educational</span>{" "}
          Content
        </h1>
        <p className="mt-4 DMSansRegular bee-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
          ullamcorper sodales dictum. Nam placerat, dui eget ultricies finibus,
          felis augue semper ex, sed fermentum enim libero at nunc. Sed risus
          odio, blandit a mi id, bibendum aliquam dui.{" "}
        </p>
      </div>

      <section className="text-gray-600 body-font">
        <div className="container-fluid lg:px-20 px-5 py-5 mx-auto">
          <div className="flex flex-wrap -m-4">
            <VideoListCard
              title={"What is a LP ?"}
              image={educationalIconImage}
            />
            <VideoListCard
              title={"How to buy an NFT ?"}
              image={howtobuyNFTIMage}
            />
            <VideoListCard
              title={"Crypto and regulation"}
              image={cryptoRegulationImage}
            />
            <VideoListCard
              title={"Crypto and regulation"}
              image={cryptoRegulationImage}
            />
            <VideoListCard
              title={"What is a LP ?"}
              image={educationalIconImage}
            />
            <VideoListCard
              title={"How to buy an NFT ?"}
              image={howtobuyNFTIMage}
            />
            <VideoListCard
              title={"Crypto and regulation"}
              image={cryptoRegulationImage}
            />
            <VideoListCard
              title={"Crypto and regulation"}
              image={cryptoRegulationImage}
            />
            <VideoListCard
              title={"Crypto and regulation"}
              image={cryptoRegulationImage}
            />
          </div>
        </div>
      </section>

      {/* <div className="grid mt-5 lg:px-20 justify-center md:grid-cols-3 lg:grid-cols-3 gap-5 lg:gap-7 my-10  w-1/10">
      <VideoListCard title={"What is a LP ?"} image={educationalIconImage}/>
      <VideoListCard title={"How to buy an NFT ?"} image={howtobuyNFTIMage}/>
      <VideoListCard title={"Crypto and regulation"} image={cryptoRegulationImage}/>
      <VideoListCard title={"Crypto and regulation"} image={cryptoRegulationImage}/>
      <VideoListCard title={"What is a LP ?"} image={educationalIconImage}/>
      <VideoListCard title={"How to buy an NFT ?"} image={howtobuyNFTIMage}/>
      <VideoListCard title={"Crypto and regulation"} image={cryptoRegulationImage}/>
      <VideoListCard title={"Crypto and regulation"} image={cryptoRegulationImage}/>
      <VideoListCard title={"Crypto and regulation"} image={cryptoRegulationImage}/>

      </div>*/}

      <Footer />
    </>
  );
};
export default Education;
