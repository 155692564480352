import React, { useState, useEffect } from "react";
import metamaskiconImage from "assets/images/metamaskicon.png";
import CryptoWalletImage from "assets/images/near-wallet.png";
import * as nearAPI from "near-api-js";
import { etherAddressShortener, connectionConfig } from "helpers";
import ClipLoader from "react-spinners/ClipLoader";
import { ethers } from "ethers";
import { connect } from "react-redux";

//redux
import { metaMaskLoginMessage, metaMaskLoginVefiry } from "store/actions";

const CryptoWallet = (props: any) => {
  const {
    metaMaskLoginMessage,
    error,
    metaMaskLoginVefiry,
    user_token,
    user_data,
    user_data_meta_message,
  } = props;

  const [showLoading, setShowLoading] = useState(false);
  const [signatures, setSignatures] = useState([] as any);
  //const [error, setError] = useState();
  const [errorMSG, setErrorMessage] = useState();

  useEffect(() => {
    metaMaskLoginMessage();
    setShowLoading(false);
  }, [1]);

  const signMessage = async ({ setErrorMessage, message }) => {
    try {
      console.log({ message });
      if (!window.ethereum)
        throw new Error("No crypto wallet found. Please install it.");
      await window.ethereum.send("eth_requestAccounts");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const signature = await signer.signMessage(message);
      const address = await signer.getAddress();
      return {
        message,
        signature,
        address,
      };
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  const handleMetaMaskSign = async () => {
    if (user_data_meta_message) {
      setShowLoading(false);
      const sig = await signMessage({
        setErrorMessage,
        message: user_data_meta_message.sign_message,
      });
      if (sig) {
        setSignatures([...signatures, sig]);
        setShowLoading(true);
        console.log(`   
        sign_message: ${user_data_meta_message.sign_message},
        signature: ${sig.signature},
        walletAddress: ${sig.address}
        `)
        
        metaMaskLoginVefiry({
          sign_message: user_data_meta_message.sign_message,
          signature: sig.signature,
          walletAddress: sig.address,
        });
      }
    } else {
      setShowLoading(true);
    }
  };

  const NEARLogin = async () => {
    const { connect, WalletConnection } = nearAPI;
    // connect to NEAR
    const nearConnection: any = await connect(connectionConfig);
    // create wallet connection
    const walletConnection = new WalletConnection(nearConnection, null);
    walletConnection.requestSignIn(
      "beexperience.near", // contract requesting access
      "Blockchain Education Experience", // optional title
      "https://beexperience.io/near-wallet-connect", // optional redirect URL on success
      "https://beexperience.io/near-wallet-failure" // optional redirect URL on failure
    );
  };
  return (
    <>
      {
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3
                        className="text-lg leading-6 font-medium ArchivoBold text-soft-gray"
                        id="modal-title"
                      >
                        Select Wallet Option
                      </h3>
                      <div className="mt-5">
                        {showLoading || user_data_meta_message == null ? (
                          <div className="text-center">
                            <ClipLoader
                              color={"#F1B729"}
                              loading={true}
                              size={50}
                            />
                          </div>
                        ) : (
                          <div className="grid grid-cols-2 py-2 lg:grid-cols-2">
                            <div className="hover:bg-gray-200 rounded-lg">
                              <button
                                className=" text-center p-6 rounded-lg"
                                onClick={() => {
                                  //  activate(injected).then(()=>{
                                  //   processMetaMaskLogin(account)
                                  // })
                                  handleMetaMaskSign();
                                }}
                              >
                                <img src={metamaskiconImage} />
                                {/* <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">MetaMask</h3>*/}

                                {signatures &&
                                  signatures[0] &&
                                  etherAddressShortener(signatures[0].address)}
                              </button>
                            </div>

                            <div className="hover:bg-gray-200 rounded-lg">
                              <button
                                className=" text-center  p-6 rounded-lg"
                                onClick={() => {
                                  NEARLogin();
                                }}
                              >
                                <img
                                  src={CryptoWalletImage}
                                  className="near-icon lg:ml-5"
                                />
                                {/* <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">NEAR Wallet</h3>*/}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    onClick={() => {
                      props.setShowWalletConnectOptions(false);
                    }}
                    type="button"
                    className=" ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, user_data_meta_message, user_token, user_data } =
    state.auth;
  return { error, loading, user_data_meta_message, user_token, user_data };
};

export default connect(mapStateToProps, {
  metaMaskLoginMessage,
  metaMaskLoginVefiry,
})(CryptoWallet);
