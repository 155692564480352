import React, { useState, useEffect } from "react";
//components
import NavBar from "components/navBar";
import SearchBar from "components/searchBar";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import JobListCard from "components/jobListCard";
import VideoListCard from "components/videoListCard";

import CtaImageTextButton from "components/ctaImageTextButton";
import CtaImageTextButtonMultiple from "components/ctaImageTextButtonMultiple";

import angularIconImage from "assets/images/angular.svg";
import compoundIconImage from "assets/images/compound.svg";
import skypeIconImage from "assets/images/skype.svg";

import educationalIconImage from "assets/images/educational.png";
import howtobuyNFTIMage from "assets/images/how-to-buy-nft.svg";
import cryptoRegulationImage from "assets/images/crypto-regulations.png";
//import ctaGrayImage from "assets/images/cta-gray-image.svg";
import ctaGrayImage from "assets/images/bee-mint-robot.png";
import grayedAvatarImage from "assets/images/grayed-avatar.png";

import Footer from "components/footer";
import { connect } from "react-redux";
import { loadingSkeleton } from "helpers";
import { makeid } from "helpers";

//redux
import { fetchJobListing } from "store/actions";

const Home: React.FC = (props: any) => {
  const { fetchJobListing, error, loading, jobs } = props;
  const [isonsearch, setIsOnSearch] = useState("false");
  const [loggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState("");
  useEffect(() => {
    fetchJobListing({});
    let itemtoken = localStorage.getItem("AuthData");
    if (itemtoken && JSON.parse(itemtoken).token !== null) {
      setIsLoggedIn(true);
      setUserType(itemtoken && JSON.parse(itemtoken).role);
    }
  }, [1]);
  return (
    <>
      <NavBar />
      <div className="float-search  lg:w-3/4x w-full  	lg:mt-40   ">
        <SearchBar />
      </div>

      <div className="sub-header-bar  orange-stripe-vector-small">
        <h1 className="text-3xl text-center lg:py-20 py-10 font-bold leading mt-0 mb-2 text-white ArchivoBold">
          The HUB of blockchain education and recruitment
        </h1>

        {/*
      <div className=" mt-80 lg:mt-60  py-5  lg:px-20 grid ArchivoBold">
       <h1 className="lg:text-3xl text-2xl ml-30 lg:ml-5 ArchivoBold text-soft-gray">Latest <span className="text-bee-orange ArchivoBold">job offers</span></h1>
      </div>*/}
        <div className="mt-80 lg:mt-40  py-5  lg:px-20 grid grid-cols-1 lg:grid-cols-2 "></div>
        <section className="text-gray-600 body-font">
          <div className="container px-5 py-5 mx-auto">
            <div className="-m-4 flex-wrap">
              <h1 className="lg:text-3xl text-2xl ml-5x  lg:mt-0 mt-20  lg:ml-20 ArchivoBold text-soft-gray">
                Latest Jobs
              </h1>
            </div>

            <div className="flex flex-wrap -m-4">
              <div className="grid  lg:mt-10 mt-10 lg:px-20 justify-center grid-cols-1 lg:grid-cols-4 gap-1 lg:gap-1 my-10 lg:p-0 p-4  w-1/10">
                {loading ? (
                  <>
                    {loadingSkeleton.map((item, index) => {
                      return (
                        <JobListCard
                          title={""}
                          company={""}
                          about={""}
                          price={""}
                          jobType={""}
                          mode={""}
                          link={``}
                          colorclass={
                            "text-amber-600 bg-amber-200 border-2 border-amber-600 mb-10  "
                          }
                          icon={""}
                        />
                      );
                    })}
                  </>
                ) : (
                  <>
                    {jobs &&
                      jobs.listings &&
                      jobs.listings.slice(0, 16).map((item, index) => {
                        return (
                          <JobListCard
                            key={index}
                            title={item.jobTitle}
                            company={item.company}
                            about={item.description}
                            price={item.salary == 0 ? "Negotiable" : item.salary}
                            jobType={item.employmentType}
                            mode={
                              item.remote == 1 ? "remote" : item.workLocation
                            }
                            link={`job-details/${item.jlid}BEE${makeid(8)}`}
                            colorclass={" mb-10  "}
                            icon={
                              
                              ( item.logo.length > 4 ? item.logo : item.user_listed && item.user_listed.icon) 
                              ||
                              `${grayedAvatarImage}`
                            }
                          />
                        );
                      })}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* 

      <div className="mt-10 lg:px-20 grid">
       <h1 className="lg:text-3xl text-2xl ml-5   lg:ml-20   ArchivoBold text-soft-gray"><span className="text-bee-orange ArchivoBold">Educational</span> Content </h1>
      </div>

   
       <section className="text-gray-600 body-font">
      <div className="container px-5 py-5 mx-auto">
      <div className="flex flex-wrap -m-4">
    
      <VideoListCard title={"What is a LP ?"} image={educationalIconImage}/>
      <VideoListCard title={"How to buy an NFT ?"} image={howtobuyNFTIMage}/>
      <VideoListCard title={"Crypto and regulation"} image={cryptoRegulationImage}/>
       

        </div>
      </div>
    </section>
*/}
        {/*
        <CtaImageTextButton
          image={ctaGrayImage}
          title={"Mint your membership"}
          description={
            "Please click here to mint your BEE NFT! This will allow you access to our advisory services and give  you priority on courses and workshops"
          }
          action={"#minting-closed"}
        />
      */}
        {userType == "employer" ? (
          <CtaImageTextButtonMultiple
            title={"Looking to expand your team?"}
            description={
              "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem repellat sequi ex necessitatibus \n assumenda quasi, quos modi ducimus quod, vitae possimus?"
            }
            actionButtonText1={"Post a Job Offer"}
            actionButton1URL={`${loggedIn ? "/company/postajob" : "/login"}`}
            actionButtonText2={"Explore resumes"}
            actionButton2URL={`${loggedIn ? "/company/dashboard" : "/login"}`}
          />
        ) : (
          <CtaImageTextButtonMultiple
            title={"Looking to expand your team?"}
            description={
              "If you are looking to hire some fantastic candidates for your team, click the button below!"
            }
            actionButtonText1={"Post a Job Offer"}
            actionButton1URL={`${loggedIn ? "/register" : "/register"}`}
            actionButtonText2={"Explore resumes"}
            actionButton2URL={`${loggedIn ? "/register" : "/register"}`}
          />
        )}
        <Footer />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, jobs } = state.job;
  return { error, loading, jobs };
};

export default connect(mapStateToProps, { fetchJobListing })(Home);
