import React, { useState, useEffect } from "react";
import Nav from "../partials/nav";
import "../dashboard.css";
import SideBarAdmin from "../partials/sidebarAdmin";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
//redux
import { fetchAdminCVsListing, postAdminPostCV , postAddCVCategory, fetchJobCategoryAdminsListing, deleteAdminDeleteCategoryListing} from "store/actions";
//import ApplicationDetails from "./details";
import { upload_preset,cloud_name,cloud_url, cvparserUrl  } from "helpers";
import TagComponent from "components/tagComponent";
import cogoToast from "cogo-toast";
import { dateFormatterHumanReadable, makeid } from "helpers";


const AdminCvs: React.FC = (props: any) => {
  const { fetchAdminCVsListing, postAdminPostCV, fetchJobCategoryAdminsListing,  postAddCVCategory, error, category_data, loadingCV, loading, adminCVsListingData, deleteAdminDeleteCategoryListing } = props;

  const [showModalDeleteConfirmation, setShowModalDeleteConfirmation] =
    useState(false);
 

  const [showApplicationDetails, setShowApplicationDetails] = useState(false);

  const [showCVModal, setShowCVModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);

  const [isUploading, setIsUploading] = useState(false);
  const [hasUploadedCV, setHasUploadedCV] = useState(false);
  const [cvURL, setCVUrl] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [cvFileName, setCvFileName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [categoryName, setCategoryName] = useState("");

  const fileUploadRef = React.useRef<HTMLInputElement>(null);
  const [isLoadingtoCloudinary, setIsLoadingtoCloudinary] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCVData, setFilteredCVData] = useState([]);

 




  useEffect(() => {
      _fetchDatax();
        // Cleanup function
      return () => {};
      
  }, [1]);
 
  const _fetchDatax = async () =>{
    await fetchAdminCVsListing({});
    await fetchJobCategoryAdminsListing({});
  }

  
   

  const filterTable = (value: string) => {
    if (value !== "") {
      const filteredData =
      adminCVsListingData && adminCVsListingData.data.filter((item: any) => {
          return Object.values(item)
            .join("")
            .toLowerCase()
            .includes(value.toLowerCase());
        });
        setFilteredCVData(filteredData);
    } else {
      setFilteredCVData(adminCVsListingData && adminCVsListingData.data);
    }
  };

 

  
  const onChangeOnboardingCVUpload =   (e) => {
    setIsLoadingtoCloudinary(true);
     const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("upload_preset", upload_preset);
    data.append("cloud_name", cloud_name);
    fetch(cloud_url, {
      method: "post",
      body: data,
    })
      .then((resp) => resp.json())
      .then((data) => {
        setIsLoadingtoCloudinary(false);
        cogoToast.success("CV Uploaded successfully.");
  
        setCVUrl(data.secure_url);
        setCvFileName(data.original_filename);

       // setHasUploadedCV(true)
      
      })
      .catch((err) => console.log(err));
   };

   // -
   const submitUpload = () =>{
    postAdminPostCV({
      cv: cvURL,
      category_id: categoryID,
     }); 
   }

   const submitAddCategory = async () =>{
   await  postAddCVCategory({
      name: categoryName
     }); 
     setTimeout(()=>{
      fetchJobCategoryAdminsListing({});
     },3000)
     setCategoryName("");
   }

   const deleteCategory = async (itemId) => {
    await deleteAdminDeleteCategoryListing(itemId);
    setTimeout(()=>{
       fetchJobCategoryAdminsListing({});
    },3000)
   
   }

 

  return (
    <>
      <div>
        <Nav title={"Manage CVs"} />
        <SideBarAdmin />
        {!showApplicationDetails ? (
          <>
            <div className="lg:ml-60 ml-0">
              <section className="is-hero-bar mt-20">
                <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0"></div>
              </section>
            </div>

            <div className="lg:ml-60 ml-0">
              <section className="cardx has-table lg:ml-2 mt-40x">
                <div className="card-contentx m-5x">
                  <div className="container-fluid mx-auto px-4 sm:px-8">
                    <div className="py-8x">
                      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8x py-4x overflow-x-auto">
                        <div className="inline-block min-w-full shadowx rounded-lg overflow-hidden">
                          <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                           
                    <div className="flex justify-between items-center pb-4">
                       {/* <label  className="sr-only">Search</label>
                        <div className="relative">
                            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                            </div>
                            <input type="text" id="table-search" 
                             onChange={(e: any) => {
                              searchItems(e.target.value);
                            }}
                            className="block p-2 pl-10 w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Filter by Skill or Job title" />
                        </div>
                        */}

                        <div className="w-3/6">
                        <div className="w-6/12">
                          
                          
                            <div className="flex ">
                            <button
                                onClick={() => {
                                  setShowCVModal(true);
                                 }}
                                className="inline-flex mb-10 lg:ml-0 text-white w-full bg-bee-orange border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600x rounded text-sm DMSansRegular text-center"
                              >
                                Upload CV 
                                <span className="icon">
                                  <svg
                                    width="16"
                                    height="21"
                                    viewBox="0 0 16 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M3 2.70508C2.73478 2.70508 2.48043 2.81043 2.29289 2.99797C2.10536 3.18551 2 3.43986 2 3.70508V17.7051C2 17.9703 2.10536 18.2246 2.29289 18.4122C2.48043 18.5997 2.73478 18.7051 3 18.7051H13C13.2652 18.7051 13.5196 18.5997 13.7071 18.4122C13.8946 18.2246 14 17.9703 14 17.7051L14 8.11929L8.58589 2.70518L3 2.70508ZM0.87868 1.58376C1.44129 1.02115 2.20435 0.705078 3 0.705078H8.586C9.11634 0.705192 9.62513 0.915939 10.0001 1.29097L15.414 6.70486C15.789 7.07984 15.9999 7.58853 16 8.11886V17.7051C16 18.5007 15.6839 19.2638 15.1213 19.8264C14.5587 20.389 13.7957 20.7051 13 20.7051H3C2.20435 20.7051 1.44129 20.389 0.87868 19.8264C0.31607 19.2638 0 18.5007 0 17.7051V3.70508C0 2.90943 0.31607 2.14637 0.87868 1.58376ZM4 10.7051C4 10.1528 4.44772 9.70508 5 9.70508H11C11.5523 9.70508 12 10.1528 12 10.7051C12 11.2574 11.5523 11.7051 11 11.7051H5C4.44772 11.7051 4 11.2574 4 10.7051ZM4 14.7051C4 14.1528 4.44772 13.7051 5 13.7051H11C11.5523 13.7051 12 14.1528 12 14.7051C12 15.2574 11.5523 15.7051 11 15.7051H5C4.44772 15.7051 4 15.2574 4 14.7051Z"
                                      fill="#fff"
                                    ></path>
                                  </svg>
                                </span>
                            </button>


                                    
                          
                            <button
                                onClick={() => {
                                  setShowCategoryModal(true);
                                 }}
                                className="ml-5x inline-flex mb-10 lg:ml-5 text-white w-full bg-bee-orange border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600x rounded text-sm DMSansRegular text-center"
                              >
                                Manage Category
                             
                            </button>
                            </div>
                   
                            </div>

                        <form className="flex items-center"  onSubmit={(e)=>{e.preventDefault();}} >   
                            <label  className="sr-only">Search</label>
                            <div className="relative w-full">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                </div>
                                <input type="text" id="simple-search" 
                                   onChange={ (e) => {
                                     setSearchQuery(e.target.value)
                                     filterTable(e.target.value);
                                     
                                   }}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search by skill or job title or category" required />
                            </div>
                            {/*<button  type="submit" className="p-2.5 ml-2 text-sm font-medium text-white bg-yellow-700x rounded-lg border border-blue-700c hover:bg-blue-800c focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600c dark:hover:bg-blue-700c dark:focus:ring-blue-800c bg-bee-orange">
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                <span className="sr-only">Search</span>
                            </button>*/}
                        </form>

                       
                        </div>

                        
                        <div className="w-1/6 text-center">
                      
                        </div>


                    </div> 
       
                            <table className="w-9 text-sm text-left text-gray-500 dark:text-gray-400">
                              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                

                                  <th scope="col" className="py-3 px-6">
                                    CV File (PDF)
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Name
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Email Address
                                  </th>
                                  
                                  <th scope="col"  className="py-3 px-6 w-64" >
                                    Category
                                  </th>
                                  <th scope="col" className="py-3 px-6" style={{width:"20%"}}>
                                    Skills
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Date Posted
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                             
                             { searchQuery !=="" ?
                              <tbody>
                                {!loading &&
                                  filteredCVData  && filteredCVData.map(
                                    (item, index) => {
                                      return (
                                        <>
                                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                           

                                            <td
                                              className="py-4 px-6"
                                              width={"10%"}
                                            >
                                              {/**  @ts-ignore */}
                                              <a href={item.cv} target="_blank text-orange">
                                              <span className="icon">
                                              <svg
                                                width="16"
                                                height="21"
                                                viewBox="0 0 16 21"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M3 2.70508C2.73478 2.70508 2.48043 2.81043 2.29289 2.99797C2.10536 3.18551 2 3.43986 2 3.70508V17.7051C2 17.9703 2.10536 18.2246 2.29289 18.4122C2.48043 18.5997 2.73478 18.7051 3 18.7051H13C13.2652 18.7051 13.5196 18.5997 13.7071 18.4122C13.8946 18.2246 14 17.9703 14 17.7051L14 8.11929L8.58589 2.70518L3 2.70508ZM0.87868 1.58376C1.44129 1.02115 2.20435 0.705078 3 0.705078H8.586C9.11634 0.705192 9.62513 0.915939 10.0001 1.29097L15.414 6.70486C15.789 7.07984 15.9999 7.58853 16 8.11886V17.7051C16 18.5007 15.6839 19.2638 15.1213 19.8264C14.5587 20.389 13.7957 20.7051 13 20.7051H3C2.20435 20.7051 1.44129 20.389 0.87868 19.8264C0.31607 19.2638 0 18.5007 0 17.7051V3.70508C0 2.90943 0.31607 2.14637 0.87868 1.58376ZM4 10.7051C4 10.1528 4.44772 9.70508 5 9.70508H11C11.5523 9.70508 12 10.1528 12 10.7051C12 11.2574 11.5523 11.7051 11 11.7051H5C4.44772 11.7051 4 11.2574 4 10.7051ZM4 14.7051C4 14.1528 4.44772 13.7051 5 13.7051H11C11.5523 13.7051 12 14.1528 12 14.7051C12 15.2574 11.5523 15.7051 11 15.7051H5C4.44772 15.7051 4 15.2574 4 14.7051Z"
                                                  fill="#f1b729"
                                                ></path>
                                              </svg>
                                            </span>
                                               </a>
                                            </td>
                                            <td className="py-4 px-6">
                                            {/**  @ts-ignore */}
                                            {item.name}
                                            </td>
                                            <td className="py-4 px-6">
                                            {/**  @ts-ignore */}
                                            {item.email}
                                            </td>
                                            <td className="py-4 px-6">
                                            {/**  @ts-ignore */}
                                            {item.cv_category && item.cv_category.name}
                                            </td>

                                            <td className="py-4x px-6 flex" style={{width:"20%"}}>
                                            {/**  @ts-ignore */}
                                            {item && item.tags && item.tags.slice(0,10).map((item, index) => {
                                                return (
                                                 
                                                    <TagComponent title={item} />
                                               
                                                );
                                              })}
                                             
                                            </td>

                                            <td className="py-4 px-6">
                                                {/**  @ts-ignore */}
                                              {dateFormatterHumanReadable( item.created_at
                                              )}
                                            </td>
                                            <td
                                              className="py-4 px-6"
                                              width={"30%"}
                                            >
                                              <button
                                                onClick={() => {
                                                  setShowModalDeleteConfirmation(
                                                    true
                                                  );
                                                }}
                                                className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded m-1"
                                              >
                                                {" "}
                                                Remove{" "}
                                              </button>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    }
                                  )}
                              </tbody>
                              :

                              <tbody>
                              {!loading &&
                                adminCVsListingData  && 
                                adminCVsListingData.data &&
                                adminCVsListingData.data.map(
                                  (item, index) => {
                                    return (
                                      <>
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                         

                                          <td
                                            className="py-4 px-6"
                                            width={"10%"}
                                          >
                                            {/**  @ts-ignore */}
                                            <a href={item.cv} target="_blank text-orange">
                                            <span className="icon">
                                            <svg
                                              width="16"
                                              height="21"
                                              viewBox="0 0 16 21"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M3 2.70508C2.73478 2.70508 2.48043 2.81043 2.29289 2.99797C2.10536 3.18551 2 3.43986 2 3.70508V17.7051C2 17.9703 2.10536 18.2246 2.29289 18.4122C2.48043 18.5997 2.73478 18.7051 3 18.7051H13C13.2652 18.7051 13.5196 18.5997 13.7071 18.4122C13.8946 18.2246 14 17.9703 14 17.7051L14 8.11929L8.58589 2.70518L3 2.70508ZM0.87868 1.58376C1.44129 1.02115 2.20435 0.705078 3 0.705078H8.586C9.11634 0.705192 9.62513 0.915939 10.0001 1.29097L15.414 6.70486C15.789 7.07984 15.9999 7.58853 16 8.11886V17.7051C16 18.5007 15.6839 19.2638 15.1213 19.8264C14.5587 20.389 13.7957 20.7051 13 20.7051H3C2.20435 20.7051 1.44129 20.389 0.87868 19.8264C0.31607 19.2638 0 18.5007 0 17.7051V3.70508C0 2.90943 0.31607 2.14637 0.87868 1.58376ZM4 10.7051C4 10.1528 4.44772 9.70508 5 9.70508H11C11.5523 9.70508 12 10.1528 12 10.7051C12 11.2574 11.5523 11.7051 11 11.7051H5C4.44772 11.7051 4 11.2574 4 10.7051ZM4 14.7051C4 14.1528 4.44772 13.7051 5 13.7051H11C11.5523 13.7051 12 14.1528 12 14.7051C12 15.2574 11.5523 15.7051 11 15.7051H5C4.44772 15.7051 4 15.2574 4 14.7051Z"
                                                fill="#f1b729"
                                              ></path>
                                            </svg>
                                          </span>
                                             </a>
                                          </td>
                                          <td className="py-4 px-6">
                                          {/**  @ts-ignore */}
                                          {item.name}
                                          </td>
                                          <td className="py-4 px-6">
                                          {/**  @ts-ignore */}
                                          {item.email}
                                          </td>
                                          <td className="py-4 px-6">
                                          {/**  @ts-ignore */}
                                          {item.cv_category && item.cv_category.name}
                                          </td>

                                          <td className="py-4x px-6 flex" style={{width:"20%"}}>
                                          {/**  @ts-ignore */}
                                          {item && item.tags && item.tags.slice(0,10).map((item, index) => {
                                              return (
                                               
                                                  <TagComponent title={item} />
                                             
                                              );
                                            })}
                                           
                                          </td>

                                          <td className="py-4 px-6">
                                              {/**  @ts-ignore */}
                                            {dateFormatterHumanReadable( item.created_at
                                            )}
                                          </td>
                                          <td
                                            className="py-4 px-6"
                                            width={"30%"}
                                          >
                                            <button
                                              onClick={() => {
                                                setShowModalDeleteConfirmation(
                                                  true
                                                );
                                              }}
                                              className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded m-1"
                                            >
                                              {" "}
                                              Remove{" "}
                                            </button>
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }
                                )}
                            </tbody>

                            }


                            </table>
                          

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                                    
                  {loading && (
                    <div className="text-center">
                      <ClipLoader color={"#F1B729"} loading={true} size={50} />
                    </div>
                  )}
                </div>
              </section>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      {showModalDeleteConfirmation && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 mr-4 sm:pb-2">
                  <div className="sm:flexc sm:items-startc">
                    <h1>Confirm Action</h1>
                    <p>
                      Once you remove a user, you won't be able to restore the
                      user.{" "}
                    </p>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    onClick={() => {
                      setShowModalDeleteConfirmation(false);
                    }}
                    type="button"
                    className="ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    YES, Proceed to delete
                  </button>
                  <button
                    onClick={() => {
                      setShowModalDeleteConfirmation(false);
                    }}
                    type="button"
                    className=" ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

{/** TODO filter later on */}
        {showCVModal &&
          <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4x sm:p-6x sm:pb-4x">
                  <div className="sm:flexx sm:items-startx">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left  ">
                      <h3
                        className="text-lg leading-6  font-medium ArchivoBold text-soft-gray mb-10"
                        id="modal-title"
                      >
                        Upload CV
                      </h3>

                    <div> 
                    <label className="ArchivoBold text-soft-gray text-sm bee-text">Select Category</label>   
                    <select  onChange={(e)=>{
                          setCategoryID(e.target.value)
                    }} className="border w-full  bg-transparentx px-4 py-2  DMSansRegular text-sm bee-text">
                      <option>-----</option>
                      {category_data &&  category_data.data &&  category_data.data.map((item, index)=>{
                      return(
                      <option key={index} value={item.ccid}>{item.name}</option>
                      )
                      }
                      )}
                   

                    </select>
                    </div>

                      <div>

                      {
                              isLoadingtoCloudinary? 
                               <ClipLoader
                               color={"#F1B729"}
                               loading={true}
                               size={50}
                             />
                             :
                            <>
                            <input
                                      type="file"
                                      className={` hide-fileuploaded `}
                                      onChange={onChangeOnboardingCVUpload}
                                      ref={fileUploadRef}
                                    />
                            <div> {cvFileName !=="" && `Selected CV: ${cvFileName}` }</div> 
                            <button
                                onClick={() => {
                                  setIsUploading(true);
                                  fileUploadRef.current?.click();
                                }}
                                className="inline-flex mb-10 lg:ml-0 text-white w-2/6 bg-bee-orange border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600x rounded text-sm DMSansRegular text-center"
                              >
                                {cvURL !=="" ? "Change Selected" :"Select CV " }
                                
                                <span className="icon">
                                  <svg
                                    width="16"
                                    height="21"
                                    viewBox="0 0 16 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M3 2.70508C2.73478 2.70508 2.48043 2.81043 2.29289 2.99797C2.10536 3.18551 2 3.43986 2 3.70508V17.7051C2 17.9703 2.10536 18.2246 2.29289 18.4122C2.48043 18.5997 2.73478 18.7051 3 18.7051H13C13.2652 18.7051 13.5196 18.5997 13.7071 18.4122C13.8946 18.2246 14 17.9703 14 17.7051L14 8.11929L8.58589 2.70518L3 2.70508ZM0.87868 1.58376C1.44129 1.02115 2.20435 0.705078 3 0.705078H8.586C9.11634 0.705192 9.62513 0.915939 10.0001 1.29097L15.414 6.70486C15.789 7.07984 15.9999 7.58853 16 8.11886V17.7051C16 18.5007 15.6839 19.2638 15.1213 19.8264C14.5587 20.389 13.7957 20.7051 13 20.7051H3C2.20435 20.7051 1.44129 20.389 0.87868 19.8264C0.31607 19.2638 0 18.5007 0 17.7051V3.70508C0 2.90943 0.31607 2.14637 0.87868 1.58376ZM4 10.7051C4 10.1528 4.44772 9.70508 5 9.70508H11C11.5523 9.70508 12 10.1528 12 10.7051C12 11.2574 11.5523 11.7051 11 11.7051H5C4.44772 11.7051 4 11.2574 4 10.7051ZM4 14.7051C4 14.1528 4.44772 13.7051 5 13.7051H11C11.5523 13.7051 12 14.1528 12 14.7051C12 15.2574 11.5523 15.7051 11 15.7051H5C4.44772 15.7051 4 15.2574 4 14.7051Z"
                                      fill="#fff"
                                    ></path>
                                  </svg>
                                </span>
                            </button>
                  
                            </>

                              }
                      </div>

                              
                    
                    </div>
                  </div>
                </div>
                <div className=" bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
               {
                 isSubmitting ? 
                 <ClipLoader
                 color={"#F1B729"}
                 loading={true}
                 size={50}
               />
                 :
                 <button
                 onClick={() => {
                   setIsSubmitting(true)
                   submitUpload();
                 }}
                 type="button"
                 className=" text-white bg-bee-orange ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium   hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm text-white"
               >
                 Submit
               </button>

               }  
             

                  <button
                    onClick={() => {
                      setShowCVModal(false);
                    }}
                    type="button"
                    className=" ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          </div>
          }



    {showCategoryModal &&
          <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4x sm:p-6x sm:pb-4x">
                  <div className="sm:flexx sm:items-startx">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left  ">
                      <h3
                        className="text-lg leading-6  font-medium ArchivoBold text-soft-gray mb-10"
                        id="modal-title"
                      >
                        Manage CV Categories
                      </h3>


                      <div className="flex">
                      <input value={categoryName} type="text" onChange={(e)=>{
                        setCategoryName(e.target.value);
                      }} className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 w-5/6 pl-3 mt-2x" placeholder="Enter category name" />
                      <button
                    onClick={() => {
                      submitAddCategory();
                    }}
                    type="button"
                    className=" text-white bg-bee-orange ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium   hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm text-white"
                  >
                    Add
                  </button>
                      </div>  
 
                       
                      
                      <div style={{height: 400, overflow:'scroll'}}>
                      <table className="mt-10 w-full text-sm text-left text-gray-500 dark:text-gray-400">

                      <tr>
                      <th>Categories</th>
                      <th></th>
                      </tr>
                      {category_data &&  category_data.data &&  category_data.data.map((item, index)=>{
                      return(
                      <tr key={index}>
                      <td>{item.name}</td>
                      <td><button onClick={  ()=>{
                        deleteCategory(item.ccid);
                      }}><i className="fa fa-trash"></i></button></td>
                      </tr>
                      )
                      })

                      }
                      {loading && "fetching categories..."} 
                      </table>
                      </div>
    


                    </div>
                  </div>
                </div>
                <div className=" bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                 
        


                  <button
                    onClick={() => {
                      setShowCategoryModal(false);
                    }}
                    type="button"
                    className=" ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          </div>
          }

    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, adminCVsListingData, category_data } = state.admin;
  const { loadingCV } = state.job;
  return { error, loading, adminCVsListingData, loadingCV, category_data };
};

export default connect(mapStateToProps, { fetchAdminCVsListing, postAdminPostCV, postAddCVCategory, fetchJobCategoryAdminsListing, deleteAdminDeleteCategoryListing })(AdminCvs);
