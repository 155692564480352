import React, { useState, useEffect } from "react";
import Nav from "../partials/nav";
import "../dashboard.css";
import DashboardCard from "components/DashboardCard";
import DashboardCardSmaller from "components/DashboardCardSmaller";
import { connect } from "react-redux";
import { todayGreeting } from "helpers/";
//redux
import { fetchAdminDashboard } from "store/actions";
import SideBarAdmin from "../partials/sidebarAdmin";
import { Chart } from "react-google-charts";

import ClipLoader from "react-spinners/ClipLoader";

const AdminDashboard: React.FC = (props: any) => {
  const { fetchAdminDashboard, error, loading, adminDashboardDetails } = props;
  
  //const [data, setData] = useState<any>();

  const data = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
  ];

  const options = {
    title: "My Daily Activities",
  };

  useEffect(() => {
    getChartData();
  }, [1]);

  const getChartData = () => {
    fetchAdminDashboard({});
  };

  return (
    <>
      {!loading && (
        <>
          <div>
            <Nav title={"My Dashboard"} />
            <SideBarAdmin profileDetails={null} />
            <div className="lg:ml-60 ml-0">
              <section className="is-hero-bar mt-20">
                <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
                  <div>
                    <h1 className=" text-small ArchivoBlack">
                      {todayGreeting()}, {"Kobie :) "}
                    </h1>
                    <p className="text-graisuh DMSansRegular text-sm">
                      Manage Beexperience application from this admin portal
                    </p>
                  </div>
                </div>
              </section>
              <div className="grid  grid-cols-2  lg:grid-cols-2 gap-2 lg:gap-2 shadow-lg m-2 rounded-xl p-2">
                {/*<div style={{ width: "70%", height: "70%" }}>
              <Chart
                  chartType="PieChart"
                  data={data}
                  options={options}
                  width={"100%"}
                  height={"400px"}
                />
                </div>*/}

                <DashboardCardSmaller
                  type={"orange-card"}
                  subtext={""}
                  title={"Total Applicants"}
                  count={
                    adminDashboardDetails &&
                    adminDashboardDetails.totalApplicationsCount
                  }
                />
                <DashboardCardSmaller
                  title={"Total Companies"}
                  subtext={""}
                  count={
                    adminDashboardDetails && adminDashboardDetails.companyCount
                  }
                />

                <DashboardCardSmaller
                  title={"Jobs Posted"}
                  subtext={""}
                  count={
                    adminDashboardDetails &&
                    adminDashboardDetails.totalJobsPostedCount
                  }
                />
                <DashboardCardSmaller
                  title={"Total CV"}
                  subtext={""}
                  count={adminDashboardDetails && adminDashboardDetails.cvCount}
                />
              </div>

              <div></div>
            </div>
          </div>
        </>
      )}
      {loading && (
        <div className="text-center mx-auto mt-40">
          <ClipLoader color={"#F1B729"} loading={true} size={250} />
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  const { error, loading, adminDashboardDetails } = state.admin;
  return { error, loading, adminDashboardDetails };
};
export default connect(mapStateToProps, {
  fetchAdminDashboard,
})(AdminDashboard);
