import { combineReducers } from "redux";

//User
import auth from "store/auth/reducer";
import job from "store/job/reducer";
import admin from "store/admin/reducer";

const rootReducer = combineReducers({
  //public
  job,
  auth,
  admin,
});

export default rootReducer;
