import React, { useState } from "react";

const VideoListCard = (props) => {
  return (
    <>
      <div className="p-4  md:w-1/3">
        <div className="h-full shadow-md  border-2x border-gray-200x border-opacity-60x rounded-lg overflow-hidden">
          {/*  <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="https://dummyimage.com/720x400" alt="blog" />
           */}
          <div className="group relative">
            <img
              alt="Placeholder"
              className="block h-48 w-full rounded"
              src={props.image}
            />
            <div className="absolute bg-black rounded bg-opacity-0 group-hover:bg-opacity-60 w-full h-full top-0 flex items-center group-hover:opacity-100 duration-700 transition justify-evenly">
              <button className="scale-110 text-white outline-none opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                <svg
                  v-if="this.is_playing == false"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="currentColor"
                  className="bi bi-play-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                </svg>
              </button>
            </div>
          </div>

          <div className="p-6">
            <div className="flex items-center flex-wrap ">
              <a className="text-white py-1 text-base justify-center text-soft-gray DMSansBold">
                {props.title}
              </a>
              <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2x border-gray-200x"></span>
              <span className="text-gray-400 inline-flex items-center leading-none text-sm DMSansItalic bee-text">
                5min
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoListCard;
