import React, { useState, useEffect } from "react";
import Nav from "../partials/nav";
import "../dashboard.css";
import SideBarAdmin from "../partials/sidebarAdmin";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { dateFormatterHumanReadable, makeid } from "helpers";
//redux
import { fetchAdminJobListing, deleteJobOfferAdmin } from "store/actions";
//import ApplicationDetails from "./details";

const AdminJobs: React.FC = (props: any) => {
  const {
    fetchAdminJobListing,
    error,
    loading,
    adminJobListingData,
    deleteJobOfferAdmin,
  } = props;

  const [showModalDeleteConfirmation, setShowModalDeleteConfirmation] =
    useState(false);
  const [
    showModalUpdateStatusConfirmation,
    setShowModalUpdateStatusConfirmation,
  ] = useState(false);
  const [jobID, setJobID] = useState();
  const [jobStatus, setJobStatus] = useState();

  const [showApplicationDetails, setShowApplicationDetails] = useState(false);
  const [applicationID, setApplicationID] = useState(0);
  const [applicantDetail, setApplicantDetail] = useState();
  const [uiddata, setUIDdata] = useState();

  useEffect(() => {
    fetchAdminJobListing({});
  }, [1]);

  return (
    <>
      <div>
        <Nav title={"Manage Jobs"} />
        <SideBarAdmin />
        {!showApplicationDetails ? (
          <>
            <div className="lg:ml-60 ml-0">
              <section className="is-hero-bar mt-20">
                <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0"></div>
              </section>
            </div>

            <div className="lg:ml-60 ml-0">
              <section className="cardx has-table lg:ml-2 mt-40x">
                <div className="card-contentx m-5x">
                  <div className="container-fluid mx-auto px-4 sm:px-8">
                    <div className="py-8">
                      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8x py-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadowx rounded-lg overflow-hidden">
                          <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                            {/* <div className="flex justify-between items-center pb-4">
                
                        <label  className="sr-only">Search</label>
                        <div className="relative">
                            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                            </div>
                            <input type="text" id="table-search" className="block p-2 pl-10 w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items" />
                        </div>
                    </div>*/}

                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                  <th scope="col" className="p-4">
                                    <div className="flex items-center">
                                      <input
                                        id="checkbox-all-search"
                                        type="checkbox"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      />
                                      <label className="sr-only">
                                        checkbox
                                      </label>
                                    </div>
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Job Title
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Company
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Status
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Location
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Date Posted
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {!loading &&
                                  adminJobListingData &&
                                  adminJobListingData.data &&
                                  adminJobListingData.data.map(
                                    (item, index) => {
                                      return (
                                        <>
                                        {console.log(`Jobs items==== ${JSON.stringify(item)}`)}
                                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <td className="p-4 w-4">
                                              <div className="flex items-center">
                                                <input
                                                  id="checkbox-table-search-3"
                                                  type="checkbox"
                                                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                                <label className="sr-only">
                                                  checkbox
                                                </label>
                                              </div>
                                            </td>
                                            <th
                                              scope="row"
                                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                              {item.jobTitle}
                                            </th>
                                            <td className="py-4 px-6">
                                              {item.company}
                                            </td>
                                            <td className="py-4 px-6">
                                              <span
                                                className={`text-white-900 ${
                                                  item.status === "approved"
                                                    ? "bg-green-200  border-green-600"
                                                    : "bg-red-200  border-red-600"
                                                }  border-1  border-red-600 opacity-70 rounded-slim px-2 mt-2 text-xs font-semibold inline-block  px-2   rounded   last:mr-0 mr-1 ArchivoBold`}
                                              >
                                                {item.status}
                                              </span>
                                            </td>
                                            <td className="py-4 px-6">
                                              {item.workLocation}
                                            </td>
                                            <td className="py-4 px-6">
                                              {dateFormatterHumanReadable(
                                                item.created_at
                                              )}
                                            </td>
                                            <td
                                              className="py-4 px-6"
                                              width={"30%"}
                                            >
                                              <a
                                                href={`${
                                                  window.location.protocol +
                                                  "//" +
                                                  window.location.host
                                                }/job-details/${
                                                  item.jlid
                                                }BEE${makeid(8)}`}
                                                target="_blank"
                                                className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-1 border border-blue-500 hover:border-transparent rounded m-1"
                                              >
                                                {" "}
                                                Details{" "}
                                              </a>
                                              <button
                                                onClick={() => {
                                                  window.location.href = `/admin/all-applicants?id=${item.uid}`;
                                                }}
                                                className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-1 border border-blue-500 hover:border-transparent rounded m-1"
                                              >
                                                Applicants{" "}
                                              </button>
                                              <button
                                                onClick={() => {
                                                  window.location.href = `/admin/editjob?id=${item.jlid}`;
                                                }}
                                                className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-1 border border-blue-500 hover:border-transparent rounded m-1"
                                              >
                                                Edit Job{" "}
                                              </button>
                                            
                                              <button
                                                onClick={() => {
                                                  setShowModalDeleteConfirmation(
                                                    true
                                                  );
                                                  setJobID(item.jlid);
                                                }}
                                                className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-1 border border-blue-500 hover:border-transparent rounded m-1"
                                              >
                                                {" "}
                                                Delete{" "}
                                              </button>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {loading && (
                    <div className="text-center">
                      <ClipLoader color={"#F1B729"} loading={true} size={50} />
                    </div>
                  )}
                </div>
              </section>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      {showModalDeleteConfirmation && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 mr-4 sm:pb-2">
                  <div className="sm:flexc sm:items-startc">
                    <h1>Confirm Action</h1>
                    <p>
                      Once you delete a job post, you won't be able to restore
                      it.{" "}
                    </p>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    onClick={() => {
                      deleteJobOfferAdmin(jobID);
                      setShowModalDeleteConfirmation(false);
                    }}
                    type="button"
                    className="ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    YES, Proceed to delete
                  </button>
                  <button
                    onClick={() => {
                      setShowModalDeleteConfirmation(false);
                    }}
                    type="button"
                    className=" ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModalUpdateStatusConfirmation && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 mr-4 sm:pb-2">
                  <div className="sm:flexc sm:items-startc">
                    <h1 className="mb-1 text-2xl font-extrabold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-2xl dark:text-white">
                      Update Job Status
                    </h1>
                    <br />

                    <p className="mb-2 mt-2">
                      <span className="mb-1 text-1xl font-extrabold tracking-tight leading-none text-gray-900 md:text-1xl lg:text-1xl dark:text-white">
                        Status:
                      </span>
                      <span
                        className={`text-white-900 ${
                          jobStatus === "approved"
                            ? "bg-green-200 ml-3  border-green-600"
                            : "bg-red-200  border-red-600"
                        }  border-1  border-red-600 opacity-70 rounded-slim px-2 mt-2 text-xs font-semibold inline-block  px-2   rounded   last:mr-0 mr-1 ArchivoBold`}
                      >
                        {jobStatus}
                      </span>
                    </p>

                    <p>
                      <span className="mb-1 mt-2 text-md font-extrabold tracking-tight leading-none text-gray-900 md:text-md lg:text-md dark:text-white">
                        Change Status:
                      </span>
                    </p>
                    <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option>------------</option>
                      <option>Approve</option>
                      <option>Dis approve</option>
                    </select>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    onClick={() => {
                      setShowModalUpdateStatusConfirmation(false);
                    }}
                    type="button"
                    className="ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Save Update
                  </button>
                  <button
                    onClick={() => {
                      setShowModalUpdateStatusConfirmation(false);
                    }}
                    type="button"
                    className=" ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, adminJobListingData } = state.admin;

  return { error, loading, adminJobListingData };
};

export default connect(mapStateToProps, {
  fetchAdminJobListing,
  deleteJobOfferAdmin,
})(AdminJobs);
