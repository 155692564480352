import React, { useState } from "react";
import {jobTypeColor} from 'helpers';
const ListLongThin = (props) => {
  return (
    <>
      <div className="flex items-center lg:w-full mx-auto card  border-black pb-5 mb-5 border-gray-200 sm:flex-row flex-col px-1x0 py-3 grayhi">
        <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0 lg:px-30 lg:ml-10 w-6x">
          <h2 className="DMSansRegular text-md text-soft-greenish-black">
            <a target="_blank" href={`/job-details/${props.jlid}`}>
              {props.title}
            </a>
          </h2>
        </div>

        <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0  w-6x">
          <small className={`text-gray-500  ${props.statusStyle}`} title="">
            {props.status}
          </small>
        </div>

        <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0  w-6x">
          <p className="DMSansRegular sub-adm text-soft-gray">
            {" "}
            {props.dateapplied}{" "}
          </p>
        </div>

        <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0  w-6x">
          <small className={`text-gray-500  ${props.jobTypeStyle}
            ${jobTypeColor(
              props.jobType
            )} 
          `} title="">
            {props.jobType}
          </small>
        </div>

        <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0 lg:px-30 lg:ml-10  w-6x">
          <h2 className="DMSansBoldx text-soft-gray">{props.applicants}</h2>
        </div>

        <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0  w-6x">
          {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
          </svg>  */}
        </div>
      </div>
    </>
  );
};

export default ListLongThin;
