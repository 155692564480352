import React, { useState } from "react";
import StageComponent from "components/stageComponent";

const TableRowCustom = ({
  data,
  jobs,
  setShowApplicationDetails,
  setApplicationID,
  setApplicantDetail,
  setUIDdata,
}) => {
  return (
    <>
      <div className="flex flex-col md:flex-row  border-gray-soft	 rounded-2xl w-full py-6  px-8 align-middle mt-4 hover:bg-gray-100	">
        <div className="lg:w-1/12">
          <input
            type="checkbox"
            value=""
            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
        </div>

        <div className="lg:w-3/12 flex">
          {/*<img src={"https://dummyimage.com/8x8"}  className="rounded-lg"/>*/}
          <span className="text-soft-gray whitespace-no-wrap DMSansBold">
            {" "}
            {data.user_applied.name}
          </span>
        </div>

        <div className="lg:w-2/12">
          <StageComponent hiring_stage={data.hiring_stage} />
        </div>

        <div className="lg:w-2/12">
          <p className="text-gray-900 whitespace-no-wrap DMSansRegular text-soft-gray">
            {new Date(data.created_at).toLocaleDateString("en-us", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </p>
        </div>

        <div className="lg:w-2/12">
          <p className="text-gray-900 whitespace-no-wrap DMSansRegular text-soft-gray">
            {jobs &&
              jobs.data.data.jlid &&
              jobs.data.data.jlid.map((item2, index2) => {
                return (
                  <>
                    <a href={`/job-details/${data.jlid}`} target="_blank">
                      {item2.jlid == data.jlid ? item2.jobTitle : ""}
                    </a>
                  </>
                );
              })}
          </p>
        </div>

        <div className="lg:w-4/12 text-center">
          <button
            onClick={() => {
              setShowApplicationDetails(true);
              setApplicationID(data.jlid);

              setApplicantDetail(data?.user_applied);
              setUIDdata(data);
            }}
            className="nav-btn-custom ArchivoExtraBold w-6/12  shadowx text-xs p-10x"
          >
            See Application
          </button>
        </div>
      </div>
    </>
  );
};

export default TableRowCustom;
