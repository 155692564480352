import { useState } from "react";
import { connect } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
//redux
import { forgotPassword } from "store/actions";

const ForgotPasswordPartials = (props) => {
  const { forgotPassword, error, loading, user_data } = props;
  const [email, setEmail] = useState();

  return (
    <>
      <p className="focus:outline-none text-3xl font-extrabold leading-6 text-centerx text-gray-800 ArchivoBlack">
        {`Forgot Password`}
      </p>

      <Formik
        enableReinitialize={true}
        initialValues={{
          email: email,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().required("Email Address required "),
        })}
        onSubmit={(values, { resetForm }) => {
          let data = {
            email: values.email || "",
            redirectUrl: "http://localhost:3000/reset-password",
          };

          forgotPassword(data);
        }}
      >
        {({ errors, status, touched }) => (
          <Form>
            <div className="mt-6  w-full">
              <label
                id="email"
                className="text-sm font-small leading-none DMSansBold  text-soft-gray"
              >
                Email Address
              </label>
              <Field
                name="email"
                type="text"
                className={
                  "bg-white-200 border rounded border-black-200 text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2" +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="mt-8">
              {loading ? (
                <div className="text-center">
                  <ClipLoader color={"#F1B729"} loading={true} size={50} />
                </div>
              ) : (
                <button
                  role="button"
                  type="submit"
                  className="focus:ring-2 focus:ring-offset-2  DMSansBold  text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded bg-bee-orange py-4 w-full"
                >
                  Send Reset Link
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
const mapStateToProps = (state) => {
  const { error, loading, user_data } = state.auth;
  return { error, loading, user_data };
};

export default connect(mapStateToProps, { forgotPassword })(
  ForgotPasswordPartials
);
