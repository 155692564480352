import StageComponent from "components/stageComponent";
import TimeAgo from "javascript-time-ago";
// English.
import en from "javascript-time-ago/locale/en";
TimeAgo.addDefaultLocale(en);
// Create formatter (English).
const timeAgo = new TimeAgo("en-US");
const Info = (props) => {
  return (
    <div className="p-x4 lg:w-full md:w-full mb-10 border-grayish-job-details mt-10  rounded-lg">
      <div className="flex    p-8 sm:flex-row flex-col">
        <img
          alt="blog"
          src={`${
            props.applicantDetail.icon !== null
              ? props.applicantDetail.icon
              : "https://dummyimage.com/104x104"
          }`}
          className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center lg:mr-5"
        />
        <div className="flex-grow">
          <h2 className="text-gray-900 text-md title-font text-left font-medium mb-3x text-soft-gray ArchivoBlack">
            {props.applicantDetail.name}
          </h2>
          <StageComponent hiring_stage={props.hiring_stage} />
          {/*<p className="leading-relaxed text-basez bee-text text-sm DMSansRegular">{props.applicantDetail.industry}</p> */}
        </div>
      </div>
      <div className="flex items-center flex-wrap pb-4 mb-4 border-b-2 border-gray-100 mt-auto m-8">
        <a className="text-indigo-500x inline-flex items-center bee-text DMSansRegular text-sm">
          Applied the job
        </a>
        <span className="text-gray-400 mr-3 inline-flex items-center ml-auto leading-none text-sm pr-3 py-1 border-r-2x bee-text border-gray-200 DMSansRegular">
          {timeAgo.format(new Date(props.applicantDetail.created_at))}
        </span>
      </div>
      <div className="flexx items-center flex-wrapx pb-4 mb-4 border-b-2x border-gray-100x mt-auto m-8">
        <p className="text-indigo-500x inline-flexx items-center text-soft-gray ArchivoBlack text-sm">
          {props.applicantDetail.headline}
        </p>
        <p className="inline-flex">
          <span className="bee-text DMSansRegular text-xs">Remote</span>
          <svg
            width="4"
            height="5"
            viewBox="0 0 4 5"
            className="mt-2 ml-2 mr-2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2.5" r="2" fill="#8083A3" />
          </svg>
          <span className="bee-text DMSansRegular text-xs">Full-time</span>
        </p>
      </div>
    </div>
  );
};
export default Info;
