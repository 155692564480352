const AboutInfo = (props) => {
  return (
    <div className="lg:p-4 lg:w-1/2x md:w-fullx">
      <div className="flex border-2  rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
        <div className="flex-grow w-1/6">
          <h2 className="text-gray-900 text-lg title-font font-medium mb-3 DMSansBold text-soft-darkshy">
            About Us
          </h2>
          <p className="leading-relaxed text-base desc-color DMSansRegular">
            {props.profileDetails &&
            props.profileDetails.data &&
            props.profileDetails.data.bio &&
            props.profileDetails.data.bio.indexOf("</") !== -1 ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: props.profileDetails.data.bio.replace(
                    /(<? *script)/gi,
                    "illegalscript"
                  ),
                }}
              ></div>
            ) : (
              props.profileDetails &&
              props.profileDetails.data &&
              props.profileDetails.data.bio
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
export default AboutInfo;
