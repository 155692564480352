import React, { useState, useEffect } from "react";
import SideBarAdmin from "../partials/sidebarAdmin";
import Nav from "../partials/nav";

import "../dashboard.css";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

//redux
import { fetchEmployerProfile } from "store/actions";
import ApplicantInfo from "./info";
import ApplicantSocial from "./social";
import BackActionComponent from "./backAction";
import ApplicantAbout from "./about";
const Details = (props) => {
  return (
    <>
      <div>
        <section className="text-gray-600 body-font overflow-hidden">
          <div className="container-fluid lg:ml-60 px-5 py-24 lg:mx-auto">
            <div className="-my-8s divide-y-2s divide-gray-100x">
              <div className="lg:py-8x lg:flex lg:flex-wrapx md:flex-nowrapx grid md:grid-cols-2x lg:grid-cols-2x">
                <div className="md:w-104 md:mb-0 mb-6 flex-shrink-0 flex flex-col  ">
                  <BackActionComponent
                    setShowApplicationDetails={props.setShowApplicationDetails}
                    uiddata={props.uiddata}
                  />
                  <ApplicantInfo
                    applicantDetail={props.applicantDetail}
                    hiring_stage={props.uiddata.hiring_stage}
                  />
                  <ApplicantSocial applicantDetail={props.applicantDetail} />
                </div>
                <div className="md:flex-grow mt-20 w-4/6">
                  <ApplicantAbout applicantDetail={props.applicantDetail} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      :<></>
    </>
  );
};
const mapStateToProps = (state) => {
  const { error, loading, profileDetails } = state.auth;
  return { error, loading, profileDetails };
};
export default Details;
//export default connect(mapStateToProps, { fetchEmployerProfile })(ApplicationDetails);
{
  /* 
    
  <div className="container-fluid px-5 py-24 mx-autox flexx flex-wrapx">
    <div className="flexx  flex-wrapx -m-4x grid md:grid-cols-2 lg:grid-cols-2  ">
    */
}
