import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "routes";
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";
import "App.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
const POLLING_INTERVAL = 12000;
const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};
const App = () => {
  if (process.env.REACT_APP_STAGE === "Production") {
    console.log = function no_console() {};
  }
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_KEY}`}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </GoogleOAuthProvider>
    </Web3ReactProvider>
  );
};
export default App;
