import {
  JOB_LISTING,
  JOB_LISTING_DETAILS,
  JOB_SUCCESS,
  JOB_DETAILS_SUCCESS,
  JOB_POST,
  JOB_POST_SUCCESS,
  API_ERROR,
  JOB_SUCCESS_EMPLOYER,
  JOB_LISTING_EMPLOYER,
  JOB_APPLICATION_POST,
  JOB_APPLICATION_POST_SUCCESS,
  JOB_ALL_APPLICANTS_SUCCESS,
  JOB_ALL_APPLICANTS,
  SEARCH_JOB_LISTING,
  SEARCH_JOB_LISTING_SUCCESS,
  JOB_MY_APPLICATION,
  JOB_MY_APPLICATION_SUCCESS,
  SUBSCRIPTION_LISTING_STATUS,
  SUBSCRIPTION_LISTING_STATUS_SUCCESS,
  UPDATE_JOB_APPLICATION,
  UPDATE_JOB_APPLICATION_SUCCESS,
  JOB_POST_ADMIN_CV,
  JOB_POST_ADMIN_CV_SUCCESS,
  UPDATE_HIRING_STAGE,
  UPDATE_HIRING_STAGE_SUCCESS,
  UPDATE_APPLICANT_CV,
  UPDATE_APPLICANT_CV_SUCCESS,
  JOB_POST_APPLICANT_CV,
  JOB_POST_APPLICANT_CV_SUCCESS,
  GET_APPLICANT_CV,
  GET_APPLICANT_CV_SUCCESS,
  SUBSCRIBE_CV,
  SUBSCRIBE_CV_SUCCESS,
  GET_CV_STATUS,
  GET_CV_STATUS_SUCCESS,
  POST_JOB_OFFER_ADMIN,
  POST_JOB_OFFER_ADMIN_SUCESS,
  POST_JOB_UPDATE_OFFER_ADMIN,
  POST_JOB__UPDATE_OFFER_ADMIN_SUCESS,
  CV_DOWNLOAD,
  CV_DOWNLOAD_SUCCESS,
  JOB_ALL_APPLICANTS_ADMIN_SUCCESS,
  JOB_ALL_APPLICANTS_ADMIN,
} from "store/job/constants";

export const fetchJobListing = (user: any) => {
  return {
    type: JOB_LISTING,
    payload: user,
  };
};

export const fetchJobListingDetails = (user: any) => {
  return {
    type: JOB_LISTING_DETAILS,
    payload: user,
  };
};

export const getApplicantCV = (user: any) => {
  return {
    type: GET_APPLICANT_CV,
    payload: user,
  };
};

export const getApplicantCVSuccess = (response: any) => {
  return {
    type: GET_APPLICANT_CV_SUCCESS,
    payload: response,
  };
};

export const getCvStatus = (user: any) => {
  return {
    type: GET_CV_STATUS,
    payload: user,
  };
};

export const getCvStatusSuccess = (response: any) => {
  return {
    type: GET_CV_STATUS_SUCCESS,
    payload: response,
  };
};

export const postJobOffer = (data: any) => {
  return {
    type: JOB_POST,
    payload: { data },
  };
};

export const postApplicantPostCV = (data: any) => {
  return {
    type: JOB_POST_APPLICANT_CV,
    payload: { data },
  };
};

export const postApplicantPostCVSuccess = (response: any) => {
  return {
    type: JOB_POST_APPLICANT_CV_SUCCESS,
    payload: response,
  };
};

export const postAdminPostCV = (data: any) => {
  return {
    type: JOB_POST_ADMIN_CV,
    payload: { data },
  };
};

 

export const postAdminPostCVSuccess = (response: any) => {
  return {
    type: JOB_POST_ADMIN_CV_SUCCESS,
    payload: response,
  };
};



export const postCvSubscribe = (data: any) => {
  return {
    type: SUBSCRIBE_CV,
    payload: { data },
  };
};

export const postCvSubscribeSuccess = (response: any) => {
  return {
    type: SUBSCRIBE_CV_SUCCESS,
    payload: response,
  };
};

export const postJobApplication = (data: any) => {
  return {
    type: JOB_APPLICATION_POST,
    payload: { data },
  };
};

export const postJobApplicationSuccess = (response: any) => {
  return {
    type: JOB_APPLICATION_POST_SUCCESS,
    payload: response,
  };
};

export const fetchJobAllApplicant = (data: any) => {
  return {
    type: JOB_ALL_APPLICANTS,
    payload: { data },
  };
};

export const fetchJobAllApplicantSuccess = (response: any) => {
  return {
    type: JOB_ALL_APPLICANTS_SUCCESS,
    payload: response,
  };
};

export const fetchJobAllApplicantAdmin = (data: any) => {
  return {
    type: JOB_ALL_APPLICANTS_ADMIN,
    payload: { data },
  };
};

export const fetchJobAllApplicantAdminSuccess = (response: any) => {
  return {
    type: JOB_ALL_APPLICANTS_ADMIN_SUCCESS,
    payload: response,
  };
};

export const updateJobApplicationDetails = (user: any) => {
  return {
    type: UPDATE_HIRING_STAGE,
    payload: user,
  };
};

export const updateJobApplicationDetailsSuccess = (user: any) => {
  return {
    type: UPDATE_HIRING_STAGE_SUCCESS,
    payload: user,
  };
};

export const updateApplicantCV = (user: any) => {
  return {
    type: UPDATE_APPLICANT_CV,
    payload: user,
  };
};

export const updateApplicantCVSuccess = (user: any) => {
  return {
    type: UPDATE_APPLICANT_CV_SUCCESS,
    payload: user,
  };
};

export const jobListingSuccess = (response: any) => {
  return {
    type: JOB_SUCCESS,
    payload: response,
  };
};

export const fetchEmployerJobListing = (user: any) => {
  return {
    type: JOB_LISTING_EMPLOYER,
    payload: user,
  };
};

export const fetchEmployerJobListingSuccess = (response: any) => {
  return {
    type: JOB_SUCCESS_EMPLOYER,
    payload: response,
  };
};

export const apiError2 = (error: any) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const jobListingDetailsSuccess = (response: any) => {
  return {
    type: JOB_DETAILS_SUCCESS,
    payload: response,
  };
};

export const fetchSearchJobListing = (user: any) => {
  return {
    type: SEARCH_JOB_LISTING,
    payload: user,
  };
};

export const fetchSearchJobListingSuccess = (response: any) => {
  return {
    type: SEARCH_JOB_LISTING_SUCCESS,
    payload: response,
  };
};

export const updateJobApplication = (data: any) => {
  return {
    type: UPDATE_HIRING_STAGE,
    payload: { data },
  };
};

export const updateJobApplicationSuccess = (response: any) => {
  return {
    type: UPDATE_HIRING_STAGE_SUCCESS,
    payload: response,
  };
};

export const postJobOfferSuccess = (response: any) => {
  return {
    type: JOB_POST_SUCCESS,
    payload: response,
  };
};

export const fetchApplicationJobApplied = (user: any) => {
  return {
    type: JOB_MY_APPLICATION,
    payload: user,
  };
};

export const fetchApplicationJobAppliedSuccess = (response: any) => {
  return {
    type: JOB_MY_APPLICATION_SUCCESS,
    payload: response,
  };
};

export const fetchSubscriptionListingStatus = (user: any) => {
  return {
    type: SUBSCRIPTION_LISTING_STATUS,
    payload: user,
  };
};

export const fetchSubscriptionListingStatusSuccess = (response: any) => {
  return {
    type: SUBSCRIPTION_LISTING_STATUS_SUCCESS,
    payload: response,
  };
};

export const postJobOfferAdmin = (data: any) => {
  return {
    type: POST_JOB_OFFER_ADMIN,
    payload: { data },
  };
};

export const postJobOfferAdminSuccess = (response: any) => {
  return {
    type: POST_JOB_OFFER_ADMIN_SUCESS,
    payload: response,
  };
};

export const postAdminUpdateJobOffer = (data: any) => {
  return {
    type: POST_JOB_UPDATE_OFFER_ADMIN,
    payload: { data },
  };
};

export const postAdminUpdateJobOfferSuccess = (response: any) => {
  return {
    type: POST_JOB__UPDATE_OFFER_ADMIN_SUCESS,
    payload: response,
  };
};


 export const fetchCVDownloads = (data: any) => {
  return {
    type: CV_DOWNLOAD,
    payload: { data },
  };
};

export const fetchCVDownloadsSuccess = (response: any) => {
  return {
    type: CV_DOWNLOAD_SUCCESS,
    payload: response,
  };
};
