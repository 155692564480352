import React, { useState, useEffect } from "react";

import JobListCardWider from "components/jobListCardWider";
import JobListCard from "components/jobListCard";
import { useParams } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import greenApproveImage from "assets/images/green-approve-2.svg";
import redXImage from "assets/images/red-x.svg";

import { loadingSkeleton, jobTypeColor } from "helpers";
import { makeid } from "helpers";

//components
import NavBar from "components/navBar";
import DetailsBar from "components/detailsBar";
import { numFormatter } from "helpers";

import crytoIconImage from "../../assets/images/crypto-icon.svg";
import Footer from "components/footer";
import angularIconImage from "assets/images/angular.svg";
import { connect } from "react-redux";
import grayedAvatarImage from "assets/images/grayed-avatar.png";

//redux
import { fetchJobListingDetails, fetchJobListing } from "store/actions";
import TagComponent from "components/tagComponent";

const JobDetails: React.FC = (props: any) => {
  let { id } = useParams();
  const {
    fetchJobListing,
    fetchJobListingDetails,
    error,
    loading,
    jobs,
    jobs_details,
  } = props;
  const [showLoadingSkeleton, setShowLoadingSkeleton] = useState(true);
  const [showsimilarjobs, setShowSimilarJobs] = useState(false);

  useEffect(() => {
    let parsedID = id?.split("BEE")[0];
    fetchJobListingDetails(parsedID);
    fetchJobListing({});
    setTimeout(() => {
      setShowLoadingSkeleton(false);
      setShowSimilarJobs(true);
    }, 3000);
  }, [1]);

  return (
    <>
      <SkeletonTheme baseColor="#f5f5f5" highlightColor="#ccc">
        <NavBar />

        {showLoadingSkeleton && (
          <div className="float-search lg:w-3/4x w-full	mt-40  ">
            <DetailsBar icon={""} jobs={""} />
          </div>
        )}

        {jobs_details &&
          jobs_details.listings &&
          jobs_details.listings[0] &&
          !showLoadingSkeleton && (
            <div className="float-search lg:w-3/4x w-full	mt-40    ">
              <DetailsBar
                icon={crytoIconImage}
                id={id}
                jobs={jobs_details.listings[0]}
              />
            </div>
          )}

        <div className="sub-header-bar  orange-stripe-vector-small"></div>
        {showLoadingSkeleton && (
          <div className="grid mt-40 lg:px-20 justify-center md:grid-cols-2 lg:grid-cols-2 gap-5 lg:gap-7 my-10  w-1/10">
            <div className="lg:m-0 m-3">
              <h3 className="font-semibold text-md leading-6 text-gray-700 my-2 ArchivoExtraBold">
                Job Description
              </h3>

              <p className="text-base font-light leading-relaxed mt-0 mb-4 text-gray-800">
                <Skeleton count={5} />
              </p>
            </div>
          </div>
        )}

        {jobs_details &&
          jobs_details.listings &&
          jobs_details.listings[0] &&
          !showLoadingSkeleton && (
            <div className="grid mt-40 lg:px-20 justify-center md:grid-cols-2 lg:grid-cols-2 gap-5 lg:gap-7 my-10  w-1/10">
              <div className="lg:m-0 m-3">
                <h3 className="font-semibold  mt-10 text-md leading-6 text-gray-700 my-2 ArchivoExtraBold">
                  Job Description
                </h3>
                <p className="text-base font-light leading-relaxed mt-0 mb-4 text-gray-800">
                  {jobs_details.listings[0].description.indexOf("</") !== -1 ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: jobs_details.listings[0].description.replace(
                          /(<? *script)/gi,
                          "illegalscript"
                        ).replace( /(<([^>]+)>)/gi, ''),
                      }}
                    ></div>
                  ) : (
                    jobs_details.listings[0].description
                  )}
                </p>

                <h3 className="font-semibold  mt-10 text-md leading-6 text-gray-700 my-2 ArchivoExtraBold">
                  What will you be doing?
                </h3>
                <p className="text-base font-light leading-relaxed mt-0 mb-4 text-gray-800">
                  {jobs_details.listings[0].note.indexOf("</") !== -1 ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: jobs_details.listings[0].note.replace(
                          /(<? *script)/gi,
                          "illegalscript"
                        ).replace( /(<([^>]+)>)/gi, ''),
                      }}
                    ></div>
                  ) : (
                    jobs_details.listings[0].note
                  )}
                </p>

                <h3 className="font-semibold mt-10 text-md leading-6 text-gray-700 my-2 ArchivoExtraBold">
                  Requirements
                </h3>
                <p className="text-base font-light leading-relaxed mt-0 mb-4 text-gray-800">
                  {jobs_details.listings[0].requirements.indexOf("</") !==
                  -1 ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: jobs_details.listings[0].requirements.replace(
                          /(<? *script)/gi,
                          "illegalscript"
                        ),
                      }}
                    ></div>
                  ) : (
                    jobs_details.listings[0].requirements
                  )}
                </p>
              </div>

              <div className="w-full  lg:ml-60 ml-5 mt-5">
                <h3 className="font-semibold text-lg leading-6 text-gray-700 my-2 ArchivoExtraBold">
                  General Information
                </h3>

                <div className="grid md:grid-cols-4 lg:grid-cols-4 gap-5 lg:gap-7 ">
                  <p className="text-sm bee-text ml-1x bee-text DMSansRegular">
                  Posted
                  </p>

                  <p className="text-sm bee-text font-bold ml-6 DMSansBold">
                  {new Date(jobs_details.listings[0].created_at).toLocaleDateString(
              "en-us",
              { year: "numeric", month: "short", day: "numeric" }
            )}
                   </p>
                </div>

                <div className="grid grid-cols-2 py-2  lg:grid-cols-4 gap-5 lg:gap-7 ">
                  <p className="text-sm bee-text  DMSansRegular">Job-type</p>
                  <div className="rounded-lg ">
                    <span
                      className={`text-red-600 bg-red-200 border-2 border-red-600 border-red-600 opacity-70 rounded-slim px-2 py-0	 ml-5 text-xs font-semibold inline-block py-1 px-2   rounded   last:mr-0 mr-1 DMSansBold
                      ${jobTypeColor(
                        jobs_details.listings[0].employmentType
                      )} 
                      `}
                    >
                      {jobs_details.listings[0].employmentType}
                    </span>
                  </div>
                </div>

                {/*<div className="grid grid-cols-2 py-2 lg:grid-cols-4 gap-5 lg:gap-7 ">
            <p className="text-sm bee-text  fontgilory">Place</p>
            <div className="rounded-lg ">
            <span className={`text-blue-600 bg-blue-200 border-2 border-blue-600 border-blue-600 opacity-70 rounded-slim px-2 py-0	 ml-5 text-xs font-semibold inline-block py-1 px-2   rounded   last:mr-0 mr-1 fontgiloryBold`}>Intership</span>
            </div>
          </div>*/}

                <div className="grid grid-cols-2 py-2 lg:grid-cols-4 gap-5 lg:gap-7 ">
                  <p className="text-sm bee-text  DMSansRegular">Salary</p>
                  <div className="rounded-lg ">
                    <p className="ml-8 text-sm bee-text font-bold  DMSansBold">
                      {jobs_details.listings[0].salary == 0
                        ? "Dependant on experience"
                        : jobs_details.listings[0].salary}
                      {/*numFormatter(jobs_details.listings[0].salary.split("-")[0]) || <Skeleton/> }{" - "+numFormatter(jobs_details.listings[0].salary.split("-")[1]) || <Skeleton/>*/}
                    </p>
                  </div>
                </div>

                <div className="grid grid-cols-2 py-2 lg:grid-cols-4 gap-5 lg:gap-7 ">
                  <p className="text-sm bee-text  DMSansRegular">
                    Salary in digital currency
                  </p>
                  <div className="rounded-lg ">
                    <img src={greenApproveImage} className="ml-10" />
                  </div>
                </div>

                <div className="grid grid-cols-2 py-2 lg:grid-cols-4 gap-5 lg:gap-7 ">
                  <p className="text-sm bee-text  DMSansRegular">
                    Accepting anonymous applicants?
                  </p>
                  <div className="rounded-lg ">
                    <img src={redXImage} className="ml-10" />
                  </div>
                </div>

                <h3 className="font-semibold text-lg mt-10 leading-6 text-gray-700 my-2 ArchivoExtraBold">
                  Job tags
                </h3>
                <div className="w-6/12">
                  {jobs_details &&
                    jobs_details.listings &&
                    jobs_details.listings[0] &&
                    typeof jobs_details.listings[0].tags == "object" &&
                    jobs_details.listings[0].tags &&
                    jobs_details.listings[0].tags.map((item) => {
                      return <TagComponent title={item} />;
                    })}
                </div>

                {/*
        <h3 className="font-semibold  mt-5 text-lg leading-6 text-gray-700 my-2 fontgiloryBold">Job tags</h3>
        <span  className={`tag-color border-red-600 opacity-70 rounded-slimx rounded  text-xs font-semibold inline-block py-1 px-2   roundedx   last:mr-0 mr-1 fontgilory`}>Figma</span> 
        <span  className={`tag-color border-red-600 opacity-70 rounded-slimx rounded  text-xs font-semibold inline-block py-1 px-2   roundedx   last:mr-0 mr-1 fontgilory`}>UI Design</span> 
          */}
              </div>
            </div>
          )}

        <div className="lg:px-20">
          <h3 className="font-semibold ml-5 lg:ml-0 text-lg leading-6 text-gray-700 my-2 fontgiloryBold">
            Similar Jobs
          </h3>

          <div className="grid mt-10 lg:px- justify-center md:grid-cols-4 lg:grid-cols-4 gap-5 lg:gap-7 my-10  w-full">
            {loading ? (
              <>
                {loadingSkeleton.map((item, index) => {
                  return (
                    <JobListCard
                      title={""}
                      company={""}
                      about={""}
                      price={""}
                      jobType={""}
                      mode={""}
                      link={``}
                      colorclass={
                        "text-amber-600 bg-amber-200 border-2 border-amber-600 mb-10 "
                      }
                      icon={""}
                    />
                  );
                })}
              </>
            ) : (
              <>
                {jobs &&
                  jobs.listings &&
                  jobs.listings.length != undefined &&
                  jobs.listings.slice(0, 4).map((item, index) => {
                    return (
                      <JobListCard
                        key={index}
                        title={item.jobTitle}
                        company={item.company}
                        about={item.description.substring(0, 60)}
                        price={item.salary == 0 ? "Negotiable" : item.salary}
                        jobType={item.employmentType}
                        mode={item.remote == 1 ? "remote" : item.workLocation}
                        link={`/job-details/${item.jlid}BEE${makeid(8)}`}
                        colorclass={
                          "text-amber-600 bg-amber-200 border-2 border-amber-600 mb-10 "
                        }
                        icon={
                          item?.logo.length > 4 ? item?.logo :
                          item.user_listed.icon ||
                          `${grayedAvatarImage}`
                        }
                      />
                    );
                  })}
              </>
            )}
          </div>
        </div>

        <Footer />
      </SkeletonTheme>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, jobs, jobs_details } = state.job;
  return { error, loading, jobs, jobs_details };
};

export default connect(mapStateToProps, {
  fetchJobListingDetails,
  fetchJobListing,
})(JobDetails);
