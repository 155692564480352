import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";

//style
import "./index.css";

import NavBar from "components/navBar";

import { getRedirect } from "helpers";

const SignIn = (props: any): JSX.Element => {
  return (
    <>
      <NavBar />
    </>
  );
};

export default SignIn;
