import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";

import SideBar from "../../partials/sidebar";
import Nav from "../../partials/nav";
import "../../dashboard.css";
import ProfileDetails from "./details";
import EditProfile from "./edit";
//redux
import { fetchJobSeekerProfile, postUpdateApplicantProfile } from "store/actions";
 
const JobseekerProfile: React.FC = (props: any) => {
  // let { code } = useParams();
  // Create a URL object
  const urlString = window.location.href;
  const url = new URL(urlString);
  // Get the value of the "code" parameter
  const code = url.searchParams.get("code");


  const { fetchJobSeekerProfile, error, loading, jobSeekerProfile, postUpdateApplicantProfile } = props;
  const [isOnEditProfile, setIsOnEditProfile] = useState(false);
  useEffect(() => {
    fetchJobSeekerProfile({});
    //cvUploadData LocalStorage
    const cvUploadData =  localStorage.getItem('cvUploadData');
    //@ts-ignore
    let parsedCVUpload =  JSON.parse(cvUploadData);

    if(cvUploadData !== null){
      //update profile and reload
      let  data = {
        name: parsedCVUpload && parsedCVUpload.name == null ? " ": parsedCVUpload.name,
        headline: parsedCVUpload && parsedCVUpload.designation == null ? " ": parsedCVUpload.designation[0] ,
        city: "",
        bio: parsedCVUpload && parsedCVUpload.designation == null ? " ": parsedCVUpload.designation.join(),
        email: "",
        instagram: "",
        twitter: "",
        website: "",
        //@ts-ignore
        address: parsedCVUpload && parsedCVUpload.skills.slice(0,10),
        typeOfEmployment: "fulltime",
      };
      
       postUpdateApplicantProfile(data);
       localStorage.removeItem("cvUploadData");
    }
  }, [1]);
  return (
    <>
      <div>
        <Nav title={"My public profile"} />
        <SideBar />
        <div className="container-fluidx lg:ml-5x">
          {!isOnEditProfile && (
            <ProfileDetails
              loadingstatus={loading}
              setIsOnEditProfile={setIsOnEditProfile}
              jobSeekerProfile={jobSeekerProfile}
              code={code}
            />
          )}
          {isOnEditProfile && (
            <EditProfile
              setIsOnEditProfile={setIsOnEditProfile}
              jobSeekerProfile={jobSeekerProfile}
            />
          )}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  const { error, loading, jobSeekerProfile } = state.auth;
  return { error, loading, jobSeekerProfile };
};
export default connect(mapStateToProps, { fetchJobSeekerProfile, postUpdateApplicantProfile })(
  JobseekerProfile
);

 