import { post, get, put, del } from "helpers/axios";
import {
  jobListingURL,
  jobListingDetailsURL,
  postJobOfferURL,
  employerJobListingURL,
  postJobApplicationURL,
  fetchJobAllApplicantURL,
  fetchJobAllApplicantAdminURL,
  fetchSearchJobListingURL,
  fetchApplicationJobAppliedURL,
  updateJobApplicationURL,
  updateApplicantCVURL,
  applicantPostCvURL,
  adminPostCvURL,
  getApplicantCVURL,
  cvSubscribeURL,
  fetchCVDownloadsURL,
  cvStatusURL,
  subscriptionListingStatusURL,
  postJobOfferAdminURL,
  postAdminUpdateJobOfferURL
} from "api/endpoints";

const fetchjobs = (config) => {
  return get(jobListingURL, config);
};

const fetchApplicationJobApplied = (config) => {
  return get(fetchApplicationJobAppliedURL, config);
};

const fetchEmployerJobListing = (config) => {
  return get(employerJobListingURL, config);
};

const fetchjobsDetails = (config) => {
  return get(jobListingDetailsURL + "/" + config, config);
};

const getApplicantCV = (config) => {
  return get(getApplicantCVURL + "/" + config, config);
};

const fetchsearchJobListing = (config) => {
  return get(fetchSearchJobListingURL + "/" + config, config);
};

const updateApplicantCV = (config) => {
  return put(updateApplicantCVURL, config);
};

const updateJobApplication = (config) => {
  let data = {
    hiring_stage: config.hiring_stage,
  };
  return put(updateJobApplicationURL + "/" + config.apid, data);
};

const postJobOffer = (data: object) => {
  return post(postJobOfferURL, data);
};
const postJobOfferAdmin = (data) => {
  return post(postJobOfferAdminURL + "/" + data.uid, data);
};

const postAdminUpdateJobOffer = (data) => {
  return put(postAdminUpdateJobOfferURL + "/" + data.jobId, data);
};

const postApplicantPostCV = (data: object) => {
  return post(applicantPostCvURL, data);
};

const postAdminPostCV = (data: object) => {
  return post(adminPostCvURL, data);
};


const postCvSubscribe = (data: object) => {
  return post(cvSubscribeURL, data);
};

const fetchJobAllApplicant = (config) => {
  return get(fetchJobAllApplicantURL, config);
};

const fetchJobAllApplicantAdmin = (config) => {
  console.log(`fetchJobAllApplicantAdmin === ${config.cid}`)
  return get(fetchJobAllApplicantAdminURL + "/" + config.cid, config);
};


const fetchSubscriptionListingStatus = (config) => {
  return get(subscriptionListingStatusURL, config);
};

const postJobApplication = (data: object) => {
  return post(postJobApplicationURL, data);
};

const getCvStatus = (config) => {
  return get(cvStatusURL, config);
};

const fetchCVDownloads = (data: object) => {
  //@ts-ignore
  return get(`${fetchCVDownloadsURL}/${data.search_query}`, data);
 };

const jobServices = {
  fetchjobs,
  fetchjobsDetails,
  postJobOffer,
  fetchEmployerJobListing,
  postJobApplication,
  fetchJobAllApplicant,
  fetchJobAllApplicantAdmin,
  fetchsearchJobListing,
  fetchApplicationJobApplied,
  updateJobApplication,
  updateApplicantCV,
  postApplicantPostCV,
  postAdminPostCV,
  getApplicantCV,
  postCvSubscribe,
  getCvStatus,
  fetchSubscriptionListingStatus,
  postJobOfferAdmin,
  postAdminUpdateJobOffer,
  fetchCVDownloads
};

export default jobServices;
