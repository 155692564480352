import React, { useState, useEffect } from "react";
import SideBar from "../partials/sidebar";
import Nav from "../partials/nav";
import "../dashboard.css";

import angularIconImage from "assets/images/angular.svg";
import compoundIconImage from "assets/images/compound.svg";
import ListLongThin from "components/ListLongThin";
import arrowRightImage from "assets/images/arrow-right.svg";
import DashboardCard from "components/DashboardCard";
import SideBarCompany from "../partials/sidebarCompany";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

//redux
import { fetchEmployerJobListing } from "store/actions";

const JobListingCompany: React.FC = (props: any) => {
  const { fetchEmployerJobListing, error, loading, jobs } = props;

  useEffect(() => {
    fetchEmployerJobListing({});
  }, [1]);

  return (
    <>
      <div>
        <Nav title={"My Dashboard"} />

        <SideBarCompany />

        <div className="lg:ml-60 ml-0">
          <section className="is-hero-bar mt-20">
            <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
              <div>
                <h1 className=" text-small ArchivoBlack">Job Listing</h1>
                <p className="text-graisuh text-xs DMSansRegular">
                  Here is your jobs listing posted by you
                </p>

                {/*  <div className="py-5 shadow-lg rounded-lg px-5 mt-3 filter-bg">

      <select className="bg-transparent DMSansRegular text-sm bee-text">
        <option>Roles</option>
      </select>

      <select  className="bg-transparent px-4  DMSansRegular text-sm bee-text">
        <option>Status</option>
      </select>


      <select  className="bg-transparent px-4  DMSansRegular text-sm bee-text">
        <option>Job Type</option>
      </select>

    </div>*/}
              </div>

              {/*<button className="button light">Button</button>*/}
              <div className="flex items-center justify-center">
                {/* <form method="GET">
        <div className="relative text-gray-600 focus-within:text-gray-400">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <button type="submit" className="p-1 focus:outline-none focus:shadow-outline DMSansRegular">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.8364 13.0122L10.3573 9.53317C11.3054 8.37364 11.7716 6.89406 11.6593 5.40048C11.5471 3.9069 10.8651 2.51358 9.75441 1.50874C8.64371 0.503889 7.18928 -0.0356119 5.69195 0.00182585C4.19463 0.0392636 2.76897 0.650775 1.70987 1.70987C0.650775 2.76897 0.0392636 4.19463 0.00182585 5.69195C-0.0356119 7.18928 0.503889 8.64371 1.50874 9.75441C2.51358 10.8651 3.9069 11.5471 5.40048 11.6593C6.89406 11.7716 8.37364 11.3054 9.53317 10.3573L13.0122 13.8364C13.1222 13.9426 13.2694 14.0013 13.4222 14C13.575 13.9987 13.7212 13.9374 13.8293 13.8293C13.9374 13.7212 13.9987 13.575 14 13.4222C14.0013 13.2694 13.9426 13.1222 13.8364 13.0122ZM5.8472 10.51C4.92498 10.51 4.02347 10.2366 3.25667 9.72421C2.48987 9.21185 1.89222 8.48361 1.5393 7.63159C1.18638 6.77957 1.09404 5.84203 1.27396 4.93753C1.45388 4.03302 1.89797 3.20219 2.55008 2.55008C3.20219 1.89797 4.03302 1.45388 4.93753 1.27396C5.84203 1.09404 6.77957 1.18638 7.63159 1.5393C8.48361 1.89222 9.21185 2.48987 9.72421 3.25667C10.2366 4.02347 10.51 4.92498 10.51 5.8472C10.5086 7.08343 10.0169 8.26864 9.14279 9.14279C8.26864 10.0169 7.08343 10.5086 5.8472 10.51Z" fill="#8083A3"/>
          </svg>

            </button>
          </span>
          <input type="search" name="q" className="py-2 text-sm text-white bg-white-900 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-white-900 border rounded-lg" placeholder="Search..."  />
        </div>
      </form>*/}
              </div>
            </div>
          </section>
        </div>

        <div className="lg:ml-60 ml-0">
          <section className="cardx has-table lg:ml-2 mt-40x">
            <div className="card-content m-5">
              <div className="flex items-center  web-only no-border lg:w-full mx-auto card  border-blackx pb-5 mb-5x border-gray-200x sm:flex-row flex-col px-10 py-3 grayhix">
                <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0 lg:px-30 lg:ml-10 w-6x">
                  <small className="DMSansRegula  bee-text">Roles</small>
                </div>

                <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0  w-6x">
                  <small className={`text-gray-500x bee-text`} title="">
                    Status
                  </small>
                </div>

                <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0  w-6x">
                  <p className="DMSansRegular sub-adm bee-text">
                    {" "}
                    Date Posted{" "}
                  </p>
                </div>

                <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0  w-6x">
                  <small className={`text-gray-500x bee-text`} title="">
                    Job Type
                  </small>
                </div>

                <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0 lg:px-30 lg:ml-10  w-6x">
                  <small className="DMSansBoldx bee-text">Applicants</small>
                </div>

                <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0  w-6x"></div>
              </div>

              {!loading &&
                jobs &&
                jobs.data &&
                jobs.data.map((item, index) => {
                  return (
                    <div key={index}>
                      <ListLongThin
                        icon={angularIconImage}
                        title={item.jobTitle}
                        jlid={item.jlid}
                        dateapplied={item.created_at.split("T")[0]}
                        status={"Live"}
                        location={" Paris, France ."}
                        mode={"Remote"}
                        jobType={item.employmentType}
                        statusStyle={"tag-colored-green"}
                        applicants={item.jobs_applied_count}
                        jobTypeStyle={`text-red-600 bg-red-200 border-2 border-red-600 border-red-600 opacity-70 rounded-slim px-2 py-0	 ml-5 text-xs font-semibold inline-block py-1 px-2   rounded   last:mr-0 mr-1 DMSansBold`}
                      />
                    </div>
                  );
                })}
              
              {loading && (
                <div className="text-center">
                  <ClipLoader color={"#F1B729"} loading={true} size={50} />
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, jobs } = state.job;
  return { error, loading, jobs };
};

export default connect(mapStateToProps, { fetchEmployerJobListing })(
  JobListingCompany
);
