import React, { useState, useEffect } from "react";
import Nav from "../../partials/nav";
import "../../dashboard.css";
import SideBarCompany from "../../partials/sidebarCompany";
import { connect } from "react-redux";
import ChangePasswordModal from "pages/dashboard/partials/changePasswordModal";

//redux
import { fetchEmployerProfile } from "store/actions";
import BasicInfo from "./basicInfo";
import AboutInfo from "./about";
import SocialInfo from "./social";
import EditProfile from "./edit";

const CompanyProfile: React.FC = (props: any) => {
  const { fetchEmployerProfile, error, loading, profileDetails } = props;
  const [showEdit, setShowEdit] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  useEffect(() => {
    fetchEmployerProfile({});
  }, [1]);
  return (
    <>
      <div>
        <Nav title={"My Dashboard"} profileData={profileDetails} />
        <SideBarCompany />
        {showEdit ? (
          <>
            <EditProfile
              profileDetails={profileDetails}
              setShowEdit={setShowEdit}
            />
          </>
        ) : (
          <>
            <BasicInfo
              profileDetails={profileDetails}
              setShowEdit={setShowEdit}
            />
            <section className="text-gray-600 body-font lg:ml-60">
              <div className="container-fluid px-5 py-24 mx-autox flexx flex-wrapx">
                <div className="flexx  flex-wrapx -m-4x grid md:grid-cols-2 lg:grid-cols-2  ">
                  <AboutInfo profileDetails={profileDetails} />
                  <div> 
                  <SocialInfo profileDetails={profileDetails} />

                 {/*Change Password*/}
                  <div className="flexx border-2 w-10/12 ml-5 mt-10 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
                    <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                      Update Password
                    </h2>
                    <button
                      className="nav-btn-custom DMSansBold  shadow text-xs p-10x"
                      onClick={() => {
                        setChangePasswordModal(true);
                      }}
                    >
                      Change Password
                    </button>
                  </div>
                  {/*Change Password*/}

                  {changePasswordModal && (
                   <ChangePasswordModal closeModal={setChangePasswordModal} />
                   )}
                  </div>
                   
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  const { error, loading, profileDetails } = state.auth;
  return { error, loading, profileDetails };
};
export default connect(mapStateToProps, { fetchEmployerProfile })(
  CompanyProfile
);
