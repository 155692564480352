import React, { useState, useEffect } from "react";
import Logo from "assets/images/logo.svg";
import { useLocation } from "react-router-dom";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import SideBar from "./sidebar";
import MenuItem from "./menuItems";
import { connect } from "react-redux";
import MenuItemCompany from "./menuItemsCompany";
//redux
import { fetchSubscriptionListingStatus } from "store/actions";
import MenuItemsAdmin from "./menuItemsAdmin";

const Nav = (props) => {
  const { fetchSubscriptionListingStatus, error, loading, listing_status } =
    props;

  const location = useLocation();

  const [loggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState("");
  const [userIcon, setUserIcon] = useState("");
  const [isPaneOpen, setisPaneOpen] = useState(false);
  const [currentPathName, setCurrentPathName] = useState(
    window.location.pathname
  );
  const [userIsSubscribed, setUserIsSubscribed] = useState(false);

  useEffect(() => {
    let itemtoken = localStorage.getItem("AuthData");
    let usericon = localStorage.getItem("usericon");
    if (itemtoken && JSON.parse(itemtoken).token !== null) {
      setIsLoggedIn(true);
      setUserType(itemtoken && JSON.parse(itemtoken).role);
    }

    if (usericon && usericon !== null) {
      setUserIcon(usericon.replaceAll('"', ""));
    }
  }, []);

  useEffect(() => {
    fetchSubscriptionListingStatus({});
  }, [1]);

  return (
    <>
      <nav id="navbar-main" className="navbar is-fixed-top web-only">
        <div className="navbar-brand">
          <a className="navbar-item mobile-aside-button">
            <span className="icon">
              <i className="mdi mdi-forwardburger mdi-24px"></i>
            </span>
          </a>
          <div className="navbar-item">
            {location.pathname !== "/company/postajob" &&
            location.pathname !== "/company/job-listing" &&
            location.pathname !== "/company/profile" ? (
              <div className="control">
                <h1 className="text-2xl text-center lg:py-20x py-10 font-bold leading mt-0 mb-2  ArchivoBlack">
                  {props.title}
                </h1>
              </div>
            ) : (
              <>
                <a className="inline-flex items-center">
                  <img
                    alt="logo"
                    src={
                      userIcon !== "n/a"
                        ? userIcon || "https://dummyimage.com/104x104"
                        : "https://dummyimage.com/104x104"
                    }
                    className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
                  />
                  <span className="flex-grow flex flex-col pl-4">
                    <span className="title-font font-mediumx text-sm grayishh text-gray-900 DMSansRegular">
                      {props.profileData &&
                        props.profileData.data &&
                        props.profileData.data.name}
                    </span>
                    {/* <span className="text-gray-400x text-xs tracking-widest mt-0.5 text-soft-gray DMSansBold">{props.profileData && props.profileData.data.email}</span>
                     */}
                  </span>
                </a>
              </>
            )}
          </div>
        </div>
        <div className="navbar-brand is-right">
          <a
            className="navbar-item --jb-navbar-menu-toggle"
            data-target="navbar-menu"
          >
            <span className="icon">
              <i className="mdi mdi-dots-vertical mdi-24px"></i>
            </span>
          </a>
        </div>
        <div className="navbar-menu" id="navbar-menu">
          {/*  location.pathname !=="/company/postajob" ? */}
          <div className="navbar-end">
            {userType == "employer" && (
              <span className="navbar-item desktop-icon-only mr-5">
                <button
                  onClick={() => {
                    window.location.href =
                      listing_status && listing_status.success
                        ? "/company/postajob"
                        : "/company/postajob";
                  }}
                  //"/subscription-plans";
                  className="nav-btn-custom-orange DMSansBold  text-white rounded-lg shadowx text-xs p-10x"
                >
                  Post a job offer{" "}
                </button>
              </span>
            )}

            <span className="navbar-item desktop-icon-only mr-20">
              <button
                onClick={() => {
                  window.location.href = "/";
                }}
                className="nav-btn-custom DMSansBold  shadowx text-xs p-10x"
              >
                Back to homepage
              </button>
            </span>
          </div>

          {/*  :
        <></>
    */}
        </div>
      </nav>

      <div className="inline relative mobile-only">
        <button
          type="button"
          onClick={() => {
            setisPaneOpen(true);
          }}
          className="inline-flex items-center relative px-2 border  border-gray-500 rounded-full hover:shadow-lg"
        >
          <div className="pl-1">
            <svg
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="presentation"
              focusable="false"
              style={{
                display: "block",
                fill: "none",
                height: "16px",
                width: "16px",
                stroke: "#000",
                strokeWidth: "3",
                overflow: "visible",
              }}
            >
              <g fill="none" fill-rule="nonzero">
                <path d="m2 16h28"></path>
                <path d="m2 24h28"></path>
                <path d="m2 8h28"></path>
              </g>
            </svg>
          </div>

          <div className="block flex-grow-0 flex-shrink-0 h-10 w-12 pl-5">
            {/* 
                      <img alt="logo" src={userIcon !=="n/a"  ?userIcon || "https://dummyimage.com/104x104"  : "https://dummyimage.com/104x104"} className="w-12x h-12x rounded-full mt-1.5 flex-shrink-0 object-cover object-center" />

                      */}
          </div>
        </button>
      </div>
      <SlidingPane
        className="sidebar-bg"
        overlayClassName="some-custom-overlay-class"
        isOpen={isPaneOpen}
        from="left"
        hideHeader={true}
        width={"70%"}
        title="Hey, it is optional pane title.  I can be React component too."
        subtitle="Optional subtitle."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setisPaneOpen(false);
        }}
      >
        <div className="w-full bg-whitex margin-top rounded-lg shadow-lgx lg:w-full">
          {userType === "employer" && <MenuItemCompany /> }
          {userType === "applicant" && <MenuItem />}
          {userType === "admin"  && <MenuItemsAdmin /> }

        </div>
      </SlidingPane>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, listing_status } = state.job;
  return { error, loading, listing_status };
};

export default connect(mapStateToProps, { fetchSubscriptionListingStatus })(
  Nav
);
