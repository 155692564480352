import { useState } from "react";
import { connect } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
//redux
import { loginUser } from "store/actions";
import CryptoWallet from "./cryptoWallets";
import GoogleLogin from "./GoogleLogin";

const LoginPartial = (props) => {
  const { loginUser, error, loading, user_data, currentPathName } = props;
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [showWalletConnectOptions, setShowWalletConnectOptions] =
    useState(false);

  return (
    <>
      <p className="focus:outline-none text-3xl font-extrabold leading-6 text-centerx text-gray-800 ArchivoBlack">
        {`${currentPathName === "/admin-login" ? "Admin Login" : "Login"}`}
      </p>
      {
        <>
          {currentPathName !== "/admin-login" ? (
            <>
              <div className="grid grid-cols-2	">
                <GoogleLogin />
                <button
                  aria-label="Connect Wallet"
                  onClick={() => {
                    setShowWalletConnectOptions(true);
                  }}
                  role="button"
                  className="focus:outline-none content-center	justify-center focus:ring-2 focus:ring-offset-1 focus:ring-gray-700 py-3.5 px-4 border rounded-lg border-gray-200 flex items-center  w-1/10 mt-10 ml-4"
                >
                  <p className="text-base font-small ml-4x font-bold text-sm-11  DMSansBold text-gray-700 ">
                    Connect Wallet
                  </p>
                </button>
              </div>

              <div className="w-full flex items-center justify-between py-5 mt-5">
                <hr className="w-1/4 bg-gray-400" />
                <p className="text-base  text-sm font-mediumx leading-4 text-bold px-2.5 text-gray-400 text-soft-gray DMSansRegular">
                  Login with email
                </p>
                <hr className="w-1/4 bg-gray-400  " />
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      }

      <Formik
        enableReinitialize={true}
        initialValues={{
          email: email,
          password: password,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().required("Email Address required "),
          password: Yup.string()
            .required("Password required")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
              "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
        })}
        onSubmit={(values, { resetForm }) => {
          let data = {
            email: values.email || "",
            password: values.password || "",
          };
          loginUser(data);
        }}
      >
        {({ errors, status, touched }) => (
          <Form>
            <div className="mt-6  w-full">
              <label
                id="email"
                className="text-sm font-small leading-none DMSansBold  text-soft-gray"
              >
                Email Address
              </label>
              <Field
                name="email"
                type="text"
                className={
                  "bg-white-200 border rounded border-black-200 text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2" +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="mt-6  w-full">
              <label className="text-sm font-small DMSansBold  text-soft-gray">
                Password
              </label>
              <div className="input-group auth-pass-inputgroup">
                <Field
                  name="password"
                  type="password"
                  autoComplete="true"
                  className={
                    "bg-white-200 border rounded border-black-200 text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2" +
                    (errors.password && touched.password ? " is-invalid" : "")
                  }
                />
                <button
                  className="btn btn-light "
                  type="button"
                  id="password-addon"
                >
                  <i className="mdi mdi-eye-outline"></i>
                </button>
              </div>
              <ErrorMessage
                name="password"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="mt-8">
              {loading ? (
                <div className="text-center">
                  <ClipLoader color={"#F1B729"} loading={true} size={50} />
                </div>
              ) : (
                <button
                  role="button"
                  type="submit"
                  className="focus:ring-2 focus:ring-offset-2  DMSansBold  text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded bg-bee-orange py-4 w-full"
                >
                  Continue
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
      {currentPathName !== "/admin-login" && (
        <p className="py-5 fontgilory bee-text text-sm">
          <a href="/forgot-password">
            <span className="text-orange DMSansBold ">
              I've lost my password
            </span>
          </a>
        </p>
      )}
      {showWalletConnectOptions && (
        <CryptoWallet
          setShowWalletConnectOptions={setShowWalletConnectOptions}
          showWalletConnectOptions={showWalletConnectOptions}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  const { error, loading, user_data } = state.auth;
  return { error, loading, user_data };
};

export default connect(mapStateToProps, { loginUser })(LoginPartial);
