import React, { useState, useEffect } from "react";
//components
import NavBar from "components/navBar";
import beeLogo from "assets/images/bee-logo.png";
 
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import cvFileUploadImage from "assets/images/cv-file-upload.svg";
import { upload_preset,cloud_name,cloud_url, cvparserUrl  } from "helpers";
import { userOnBoardCVUpload } from "store/actions";
import { Field } from "formik";
import thankYouGreen from "assets/images/green-approve.png";

//const CVReview: React.FC = (props) => {
  const CVReview = (props) => {

  const { userOnBoardCVUpload, error, loading, user_data } = props;
  
  const [userType, setUserType] = useState(1);
  const [showThankYou, setShowThankYou] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [hasUploadedCV, setHasUploadedCV] = useState(false);
  const fileUploadRef = React.useRef<HTMLInputElement>(null);

  const [cvURL, setCVUrl] = useState("");
  const [cvFileName, setCvFileName] = useState("");

  
  const onChangeOnboardingCVUpload =  (e) => {
    setIsUploading(true);
    const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("upload_preset", upload_preset);
    data.append("cloud_name", cloud_name);
    fetch(cloud_url, {
      method: "post",
      body: data,
    })
      .then((resp) => resp.json())
      .then((data) => {
        setIsUploading(false);
        setCVUrl(data.secure_url);
        setCvFileName(data.original_filename);
        userOnBoardCVUpload({
          cv: data.secure_url,
          tags: ["Web3"],
          microserviceURL: cvparserUrl+"?cvurl="+data.secure_url
        });
        //setHasUploadedCV(true)
      })
      .catch((err) => console.log(err));
  };

 useEffect(()=>{

   
  //update user profile

 },[user_data]);

  return (
    <>
         <NavBar />
         {/* <div className="float-search lg:ml-60x lg:w-full lg:mt-40	 "></div>
      <div className="sub-header-bar  orange-stripe-vector-small">
        <div className="mt-10x lg:px-20 grid ml-10 lg:ml-0  w-full ">
          <h1 className="text-2xl text-white ArchivoBlack mt-20 lg:text-center">
            BEE
            <span className="text-bee-orange ArchivoBlack"> &nbsp;CV Review</span>
          </h1>
        </div>
      </div> */}



      <section className="text-gray-600 body-font">
  <div className="container px-5 py-24 mx-auto flex flex-wrap">
    <div className="flex flex-wrap w-full">
      <div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
        <div className="flex relative pb-12">
          <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
            <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
          </div>
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-yellow-500 inline-flex items-center justify-center text-white relative z-10">
            
          </div>
          <div className="flex-grow pl-4">
            <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">STEP 1</h2>
            <p className="leading-relaxed">Upload your resume</p>
          </div>
        </div>
        <div className="flex relative pb-12">
          <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
            <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
          </div>
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-yellow-500 inline-flex items-center justify-center text-white relative z-10">
            
          </div>
          <div className="flex-grow pl-4">
            <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">STEP 2</h2>
            <p className="leading-relaxed">Our team of professional go through your resume and review it</p>
          </div>
        </div>
        <div className="flex relative pb-12">
          <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
            <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
          </div>
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-yellow-500 inline-flex items-center justify-center text-white relative z-10">
           
          </div>
          <div className="flex-grow pl-4">
            <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">STEP 3</h2>
            <p className="leading-relaxed">Review report is sent to your email address.</p>
          </div>
        </div>
     
      </div>
      {/* <img className="lg:w-3/5 md:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12" src="https://dummyimage.com/1200x500" alt="step" /> */}
   
 
   <div className="md:w-1/2 max-w-lg mx-auto my-24x px-4 py-100 shadow rounded-lg	">
      {   
      cvURL !== "" ?
        <>
         <img
              src={thankYouGreen}
              className="h-40 w-40 text-center lg:ml-40 sm:md-20 "
            />
            <p className="bee-text text-center">
            Your resume has been submitted for review. We will revert to you 
            once reviewed by our team. 
              </p>         
        
        </>
        :
            <>
            <h3 className="text-3xl   py-5 font-bold leading mt-0 mb-2x  text-soft-gray fontgiloryBold">
                    Upload your CV
                  </h3>
                  <p className="bee-text">
                  Upload your cv and get professional reviews on your CV
                  </p>
                
                  <br/>
                  <input
                name="email"
                placeholder="Enter your email address"
                type="text"
                className={
                  "bg-white-200 border rounded border-black-200 text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2"
                }
              />
              <br/>

  
                    {isUploading ? (
                                <>
                                  <ClipLoader
                                    color={"#F1B729"}
                                    loading={true}
                                    size={50}
                                  />
                                </>
                              ) : (
                                <div className="mt-1">
                                  <input
                                    type="file"
                                    className={` hide-fileuploaded `}
                                    onChange={onChangeOnboardingCVUpload}
                                    ref={fileUploadRef}
                                  />
                                  <a
                                    href="#upload"
                                    className={` ${
                                      cvURL !== "" && "hide-fileuploaded"
                                    }  `}
                                  >
                                    <img
                                      src={cvFileUploadImage}
                                      onClick={() => {
                                        fileUploadRef.current?.click();
                                      }}
                                    />
                                  </a>
                                  {cvURL !== "" && (
                                    <>
                                      <a
                                        href="#"
                                        className="text-orange  text-xs underline mt-1 "
                                      >
                                        {` ${cvFileName}`}
                                      </a>
                                      <a
                                        href="#"
                                        onClick={() => {
                                          fileUploadRef.current?.click();
                                        }}
                                        className="text-orangex text-xs underlinex ml-3 mt-1"
                                      >
                                        (Change CV)
                                      </a>
                                    </>
                                  )}
                                </div>
                              )}
    
                    <div className="text-centerx">
                      <br/>
                      <br/>
  
                    {/* { !loading &&
                    <button
                    onClick={() => {
                      window.location.href = "/onboarding-completed";
                    }}
                    role="button"
                    className="focus:ring-2 focus:ring-offset-2   text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded bg-bee-orange py-4 w-1/2 DMSansBold"
                  >
                   Submit
                  </button>
                    }  */}
                    <br/><br/>
                  
  
                  </div>
                    </>
        }
    </div>
    
    </div>
  </div>
</section>
      
      {/* <body className="bg-white py-5 px-5">
        <div className="flex min-h-screen bg-white">
          <div className="md:w-8/12 lg:w-6/12 mb-12 md:mb-0 gb-crime-yellowx rounded-3xl web-only">
           
      <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
      
      <div className="flex flex-col mb-10 lg:items-start items-center">
        <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-6 h-6" viewBox="0 0 24 24">
            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
          </svg>
        </div>
        <div className="flex-grow">
          <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Shooting Stars</h2>
          <p className="leading-relaxed text-base">Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>
          <a className="mt-3 text-indigo-500 inline-flex items-center">Learn More
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </a>
        </div>
      </div>

      </div>

         
          </div>

          <div className="md:w-1/2 max-w-lg mx-auto my-24 px-4 py-5 shadow-none">
            
          <h1 className="text-4xl   py-5 font-bold leading mt-0 mb-2x  text-soft-gray fontgiloryBold">
                  Upload your CV (Optional)
                </h1>
                <p className="bee-text">
                To get started on your profile, please upload your CV here.
                </p>
              

                  {isUploading ? (
                              <>
                                <ClipLoader
                                  color={"#F1B729"}
                                  loading={true}
                                  size={50}
                                />
                              </>
                            ) : (
                              <div className="mt-1">
                                <input
                                  type="file"
                                  className={` hide-fileuploaded `}
                                  onChange={onChangeOnboardingCVUpload}
                                  ref={fileUploadRef}
                                />
                                <a
                                  href="#upload"
                                  className={` ${
                                    cvURL !== "" && "hide-fileuploaded"
                                  }  `}
                                >
                                  <img
                                    src={cvFileUploadImage}
                                    onClick={() => {
                                      fileUploadRef.current?.click();
                                    }}
                                  />
                                </a>
                                {cvURL !== "" && (
                                  <>
                                    <a
                                      href="#"
                                      className="text-orange  text-xs underline mt-1 "
                                    >
                                      {` ${cvFileName}`}
                                    </a>
                                    <a
                                      href="#"
                                      onClick={() => {
                                        fileUploadRef.current?.click();
                                      }}
                                      className="text-orangex text-xs underlinex ml-3 mt-1"
                                    >
                                      (Change CV)
                                    </a>
                                  </>
                                )}
                              </div>
                            )}
 
                  <div className="text-centerx">
                    <br/>
                    <br/>

                  { !loading &&
                  <button
                  onClick={() => {
                    window.location.href = "/onboarding-completed";
                  }}
                  role="button"
                  className="focus:ring-2 focus:ring-offset-2   text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded bg-bee-orange py-4 w-1/2 DMSansBold"
                >
                 Continue
                </button>
                  } 
                  <br/><br/>
                <a href="/onboarding-completed" className="text-bee-orange text-underline">
                Skip this step
                 </a>

                </div>
 
          </div>
        </div>
      </body> */}
    </>
  );
};
 

const mapStateToProps = (state) => {
  const { error, loading, user_data } = state.auth;
  return { error, loading, user_data };
};

export default connect(mapStateToProps, { userOnBoardCVUpload })(CVReview);
