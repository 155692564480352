import React, { useState, useEffect } from "react";
import Nav from "../partials/nav";
import "../dashboard.css";
import SideBarAdmin from "../partials/sidebarAdmin";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
//redux
import { fetchAdminCVsListing, postAdminPostCV , postAddCVCategory, fetchJobCategoryAdminsListing, deleteAdminDeleteCategoryListing} from "store/actions";
//import ApplicationDetails from "./details";
import { upload_preset,cloud_name,cloud_url, cvparserUrl  } from "helpers";
import TagComponent from "components/tagComponent";
import cogoToast from "cogo-toast";
import { dateFormatterHumanReadable, makeid } from "helpers";
import axios from "axios";


const CVGPT: React.FC = (props: any) => {
  const { fetchAdminCVsListing, postAdminPostCV, fetchJobCategoryAdminsListing,  postAddCVCategory, error, category_data, loadingCV, loading, adminCVsListingData, deleteAdminDeleteCategoryListing } = props;

  const [showModalDeleteConfirmation, setShowModalDeleteConfirmation] =
    useState(false);
 

  const [showApplicationDetails, setShowApplicationDetails] = useState(false);

  const [showCVModal, setShowCVModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);

  const [isUploading, setIsUploading] = useState(false);
  const [hasUploadedCV, setHasUploadedCV] = useState(false);
  const [cvURL, setCVUrl] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [cvFileName, setCvFileName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [categoryName, setCategoryName] = useState("");

  const fileUploadRef = React.useRef<HTMLInputElement>(null);
  const [isLoadingtoCloudinary, setIsLoadingtoCloudinary] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCVData, setFilteredCVData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');


  useEffect(()=>{
    console.log(`messages=== ${JSON.stringify(messages)}`)
  },[messages])
  const handleSendMessage = () => {
    setIsLoading(true);
    if (newMessage.trim() !== '') {
      //@ts-ignore
      // setMessages([...messages, { text: newMessage, sender: 'user' }]);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: newMessage, sender: 'user' },
      ]);
      getGPTResult(newMessage);
      setNewMessage('');
    }

  };

  //TODO: fix later on

  const getGPTResult = async (newMessage) => {
    try {
      const response = await axios.post('https://workbrook.us/webhook/llm', {
        input: newMessage,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // console.log(JSON.stringify(response.data.message));
      // @ts-ignore
      // setMessages([...messages, { text: response.data.message.replaceAll('\n','<br/>'), sender: 'beegpt' }]);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: response.data.message.replaceAll('\n','<br/>'), sender: 'beegpt' },
      ]);
      setIsLoading(false);

      
    } catch (error) {
      console.error(error);
      setIsLoading(false);

    }

  }

  useEffect(() => {
      _fetchDatax();
        // Cleanup function
      return () => {};
      
  }, [1]);
 
  const _fetchDatax = async () =>{
    await fetchAdminCVsListing({});
    await fetchJobCategoryAdminsListing({});
  }

  
   

  const filterTable = (value: string) => {
    if (value !== "") {
      const filteredData =
      adminCVsListingData && adminCVsListingData.data.filter((item: any) => {
          return Object.values(item)
            .join("")
            .toLowerCase()
            .includes(value.toLowerCase());
        });
        setFilteredCVData(filteredData);
    } else {
      setFilteredCVData(adminCVsListingData && adminCVsListingData.data);
    }
  };

 

  
  const onChangeOnboardingCVUpload =   (e) => {
    setIsLoadingtoCloudinary(true);
     const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("upload_preset", upload_preset);
    data.append("cloud_name", cloud_name);
    fetch(cloud_url, {
      method: "post",
      body: data,
    })
      .then((resp) => resp.json())
      .then((data) => {
        setIsLoadingtoCloudinary(false);
        cogoToast.success("CV Uploaded successfully.");
  
        setCVUrl(data.secure_url);
        setCvFileName(data.original_filename);

       // setHasUploadedCV(true)
      
      })
      .catch((err) => console.log(err));
   };

   // -
   const submitUpload = () =>{
    postAdminPostCV({
      cv: cvURL,
      category_id: categoryID,
     }); 
   }

   const submitAddCategory = async () =>{
   await  postAddCVCategory({
      name: categoryName
     }); 
     setTimeout(()=>{
      fetchJobCategoryAdminsListing({});
     },3000)
     setCategoryName("");
   }

   const deleteCategory = async (itemId) => {
    await deleteAdminDeleteCategoryListing(itemId);
    setTimeout(()=>{
       fetchJobCategoryAdminsListing({});
    },3000)
   
   }

 

  return (
    <>
      <div>
        <Nav title={"CV GPT"} />
        <SideBarAdmin />
        
      </div>

      
      <div className="h-screen bg-gray-100 flex items-center justify-centerx">
      <div className="bg-white rounded-lg shadow-md w-2/3" style={{marginLeft: '20%', marginTop: '5%'}}>
        <div className="p-4 border-b">
          <p className="text-xl font-semibold">CV A.I Database</p>
        </div>
        <div className="p-4 overflow-y-auto" style={{height: 450, overflowY: 'scroll'}}>
          {messages.map((message, index) => (
            <div
              key={index}
              className={`my-2 ${
                //@ts-ignore
                message.sender === 'user' ? 'text-right' : 'text-left'
              }`}
            >
              <div
                className={`inline-block rounded p-2 break-words ${
                    //@ts-ignore
                  message.sender === 'user'
                    ? 'gb-crime-yellow text-white'
                    : 'bg-gray-200'
                }`}
              >
            
                {/* {message.text} */}
                {/** @ts-ignore */}
                <div dangerouslySetInnerHTML={{ __html: message.text }} />

              </div>
            </div>
          ))}
        </div>
        <div className="p-4">
          <input
            type="text"
            className="w-full p-2 border rounded"
            placeholder="Tell me what type of candidate you need"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
         {
            isLoading ?    
            <ClipLoader color={"#F1B729"} loading={true} size={50} />
            :
         <button
            className="mt-2 gb-crime-yellow text-white py-2 px-4 rounded"
            onClick={handleSendMessage}
          >
            Send
          </button>}
        </div>
      </div>
    </div>


  

    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, adminCVsListingData, category_data } = state.admin;
  const { loadingCV } = state.job;
  return { error, loading, adminCVsListingData, loadingCV, category_data };
};

export default connect(mapStateToProps, { fetchAdminCVsListing, postAdminPostCV, postAddCVCategory, fetchJobCategoryAdminsListing, deleteAdminDeleteCategoryListing })(CVGPT);
