import React, { useState } from "react";
const CustomTagInput = ({ tags, setSelectedTag }) => {
  const [tagData, setTagData] = React.useState(tags);
  const removeTagData = (indexToRemove) => {
    setTagData([...tagData.filter((_, index) => index !== indexToRemove)]);
    setSelectedTag([...tagData.filter((_, index) => index !== indexToRemove)]);
  };
  const addTagData = (event) => {
    if (event.target.value !== "") {
      setTagData([...tagData, event.target.value]);
      setSelectedTag([...tagData, event.target.value]);
      event.target.value = "";
    }
  };
  return (
    <div className="tag-input">
      {setSelectedTag}
      {tagData &&
        tagData.map((tag, index) => (
          <span key={index} className="tag rti--tag2 rounded-2xl">
            <span className="tag-title ">{tag}</span>
            <span
              className="tag-close-icon"
              onClick={() => removeTagData(index)}
            >
              &nbsp;&nbsp;x
            </span>
          </span>
        ))}
      <div>
        <input
          type="text"
          className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 w-3/6 pl-3 mt-4"
          onKeyUp={(event) =>
            event.key === "Enter" ? addTagData(event) : null
          }
          placeholder="Press enter to add a tag"
        />
      </div>
    </div>
  );
};
export default CustomTagInput;
