import React, { useState, useEffect } from "react";
//components
import NavBar from "components/navBar";
import beeLogo from "assets/images/bee-logo.png";
import SignUp from "./SignUpPartial";
import ThankYouPartial from "./ThankYouPartial";
import thankYouGreen from "assets/images/green-approve.png";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import cvFileUploadImage from "assets/images/cv-file-upload.svg";
import { upload_preset,cloud_name,cloud_url, cvparserUrl  } from "helpers";
import { userOnBoardCVUpload } from "store/actions";
//const UploadCVOptional: React.FC = (props) => {
  const UploadCVOptional = (props) => {

  const { userOnBoardCVUpload, error, loading, user_data } = props;
  
  const [userType, setUserType] = useState(1);
  const [showThankYou, setShowThankYou] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [hasUploadedCV, setHasUploadedCV] = useState(false);
  const fileUploadRef = React.useRef<HTMLInputElement>(null);

  const [cvURL, setCVUrl] = useState("");
  const [cvFileName, setCvFileName] = useState("");

  
  const onChangeOnboardingCVUpload =  (e) => {
    setIsUploading(true);
    const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("upload_preset", upload_preset);
    data.append("cloud_name", cloud_name);
    fetch(cloud_url, {
      method: "post",
      body: data,
    })
      .then((resp) => resp.json())
      .then((data) => {
        setIsUploading(false);
        setCVUrl(data.secure_url);
        setCvFileName(data.original_filename);

        userOnBoardCVUpload({
          cv: data.secure_url,
          tags: ["Web3"],
          microserviceURL: cvparserUrl+"?cvurl="+data.secure_url
        });
    
        //setHasUploadedCV(true)
      
      })
      .catch((err) => console.log(err));
  };

 useEffect(()=>{

   
  //update user profile

 },[user_data]);

  return (
    <>
      <div className="mobile-only">
        <NavBar />
      </div>
      
      <body className="bg-white py-5 px-5">
        <div className="flex min-h-screen bg-white">
          <div className="md:w-8/12 lg:w-6/12 mb-12 md:mb-0 gb-crime-yellow rounded-3xl web-only">
            <div className="w-1/2x bg-cover  bottomize-containerx  md:block hidden left-login-bgx">
              <div className="px-10 py-10 mt-80 bottomizex k-screen-only-login-mtx welcome-leftx">
                <a href="/">
                  <img src={beeLogo} className="h-20 w-20" />
                </a>
                <h1 className="text-6xl  mt-20 py-10x font-bold leading mt-0 mb-2 text-white ArchivoBlack">
                  Welcome to <br />
                  BEExperience
                </h1>
              </div>
            </div>

            <div className="mt-5x ml-5x  grid   md:grid-cols-2 lg:grid-cols-2 gap-5 lg:gap-7 bg-red-900 rounded-br-3xl rounded-bl-3xl gb-crime-gray py-5 px-10">
              
              <div className="text-center">
               {/* <button
                  onClick={() => {
                    window.location.href = "register";
                  }}
                  role="button"
                  className="focus:ring-2 focus:ring-offset-2   text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded bg-bee-orange py-4 w-1/2 DMSansBold"
                >
                 
                </button> */}
              </div>
            </div>
          </div>

          <div className="md:w-1/2 max-w-lg mx-auto my-24 px-4 py-5 shadow-none">
            
          <h1 className="text-4xl   py-5 font-bold leading mt-0 mb-2x  text-soft-gray fontgiloryBold">
                  Upload your CV (Optional)
                </h1>
                <p className="bee-text">
                To get started on your profile, please upload your CV here.
                </p>
              

                  {isUploading ? (
                              <>
                                <ClipLoader
                                  color={"#F1B729"}
                                  loading={true}
                                  size={50}
                                />
                              </>
                            ) : (
                              <div className="mt-1">
                                <input
                                  type="file"
                                  className={` hide-fileuploaded `}
                                  onChange={onChangeOnboardingCVUpload}
                                  ref={fileUploadRef}
                                />
                                <a
                                  href="#upload"
                                  className={` ${
                                    cvURL !== "" && "hide-fileuploaded"
                                  }  `}
                                >
                                  <img
                                    src={cvFileUploadImage}
                                    onClick={() => {
                                      fileUploadRef.current?.click();
                                    }}
                                  />
                                </a>
                                {cvURL !== "" && (
                                  <>
                                    <a
                                      href="#"
                                      className="text-orange  text-xs underline mt-1 "
                                    >
                                      {` ${cvFileName}`}
                                    </a>
                                    <a
                                      href="#"
                                      onClick={() => {
                                        fileUploadRef.current?.click();
                                      }}
                                      className="text-orangex text-xs underlinex ml-3 mt-1"
                                    >
                                      (Change CV)
                                    </a>
                                  </>
                                )}
                              </div>
                            )}
 
                  <div className="text-centerx">
                    <br/>
                    <br/>

                  { !loading &&
                  <button
                  onClick={() => {
                    window.location.href = "/onboarding-completed";
                  }}
                  role="button"
                  className="focus:ring-2 focus:ring-offset-2   text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded bg-bee-orange py-4 w-1/2 DMSansBold"
                >
                 Continue
                </button>
                  } 
                  <br/><br/>
                <a href="/onboarding-completed" className="text-bee-orange text-underline">
                Skip this step
                 </a>

                </div>
 
          </div>
        </div>
      </body>
    </>
  );
};
 

const mapStateToProps = (state) => {
  const { error, loading, user_data } = state.auth;
  return { error, loading, user_data };
};

export default connect(mapStateToProps, { userOnBoardCVUpload })(UploadCVOptional);
