import React, { useState, useEffect } from "react";
import thankYouGreen from "assets/images/green-approve.png";
const ThankYouPartial = ({}) => {
  return (
    <>
      <div className="text-center">
        <h1 className="text-4xl   py-5 font-bold leading mt-0 mb-2x  text-soft-gray fontgiloryBold">
          Thank you!
        </h1>
        <p className="bee-text">
          Registration successful! Please proceed to login
        </p>
        <p>
          <a href="login" className="text-bee-orange">
            Proceed
          </a>
        </p>
      </div>
    </>
  );
};
export default ThankYouPartial;
