import React, { useState, useEffect } from "react";
import SideBarCompany from "../partials/sidebarCompany";
import Nav from "../partials/nav";
import "../dashboard.css";
import DashboardCard from "components/DashboardCard";
import DashboardCardSmaller from "components/DashboardCardSmaller";
import { connect } from "react-redux";
import { todayGreeting } from "helpers/";
//redux
import { fetchEmployerDashboard, fetchEmployerProfile } from "store/actions";

const CompanyDashboard: React.FC = (props: any) => {
  const {
    fetchEmployerDashboard,
    error,
    loading,
    fetchEmployerProfile,
    profileDetails,
    employedDashboardDetails,
  } = props;
  useEffect(() => {
    fetchEmployerDashboard({});
    fetchEmployerProfile({});
  }, [1]);
  return (
    <>
      <div>
        <Nav title={"My Dashboard"} />
        <SideBarCompany
          profileDetails={profileDetails && profileDetails.data}
        />
        <div className="lg:ml-60 ml-0">
          <section className="is-hero-bar mt-20">
            <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
              <div>
                <h1 className=" text-small ArchivoBlack">
                  {todayGreeting()},{" "}
                  {`  ${
                    profileDetails &&
                    profileDetails.data &&
                    profileDetails.data.name
                  }`}
                </h1>
                <p className="text-graisuh DMSansRegular text-sm">
                  Here is what's happening with your job search applications.
                </p>
              </div>
            </div>
          </section>
          <div className="grid  grid-cols-1  lg:grid-cols-3 gap-2 lg:gap-2">
            <DashboardCardSmaller
              type={"orange-card"}
              subtext={"to review"}
              title={"Applicants"}
              count={
                employedDashboardDetails &&
                employedDashboardDetails.applicationsToReviewCount
              }
            />
            <DashboardCardSmaller
              title={"Job open"}
              subtext={""}
              count={
                employedDashboardDetails &&
                employedDashboardDetails.jobsOpenCount
              }
            />
            <DashboardCardSmaller
              title={"Applicants"}
              subtext={"in total"}
              count={
                employedDashboardDetails &&
                employedDashboardDetails.applicantCount
              }
            />
          </div>
          <div className="card has-table lg:ml-2"></div>
          <DashboardCard
            title={"Total Jobs Posted"}
            count={
              employedDashboardDetails &&
              employedDashboardDetails.totalJobsPostedCount
            }
          />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  const { error, loading, employedDashboardDetails, profileDetails } =
    state.auth;
  return { error, loading, employedDashboardDetails, profileDetails };
};
export default connect(mapStateToProps, {
  fetchEmployerDashboard,
  fetchEmployerProfile,
})(CompanyDashboard);