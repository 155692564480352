import React, { useState } from "react";
const getCssClass = (stage: string) => {
  let classtg = "";
  switch (stage) {
    case "Hired":
      classtg = "tag-colored-green";
      break;
    case "Applied":
      classtg = "tag-colored-yellow";
      break;
    case "Shortlisted":
      classtg = "tag-colored-blue";
      break;
    case "Interviewed":
      classtg = "tag-colored-purple";
      break;
    case "In Review":
      classtg = "tag-colored-picrt";
      break;
    case "Declined":
      classtg = "tag-colored-red";
      break;
    default:
      break;
  }
  return classtg;
};
const StageComponent = (props) => {
  return (
    <>
      <small
        className={`text-gray-500  ${getCssClass(props.hiring_stage)} `}
        title=""
      >
        {props.hiring_stage}
      </small>
    </>
  );
};
export default StageComponent;
