import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

//Images assets
import Logo from "assets/images/logo.svg";
import grayLineImage from "assets/images/line-gray.svg";
import avatarProfileImage from "assets/images/avatarprofile.svg";
import grayedAvatarImage from "assets/images/grayed-avatar.png";

import { useWeb3React } from "@web3-react/core";

const NavBar = () => {
  const { activate, deactivate, account } = useWeb3React();

  const [isAuthenticated, setisAuthenticated] = useState(false);
  const [currentPathName, setCurrentPathName] = useState(
    window.location.pathname
  );

  const [toggleMenu, setToggleMenu] = useState(false);
  const [isPaneOpen, setisPaneOpen] = useState(false);
  const [loggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState("");
  const [userIcon, setUserIcon] = useState("");

  useEffect(() => {
    let itemtoken = localStorage.getItem("AuthData");
    let usericon = localStorage.getItem("usericon");
    if (itemtoken && JSON.parse(itemtoken).token !== null) {
      setIsLoggedIn(true);
      setUserType(itemtoken && JSON.parse(itemtoken).role);
    }
    if (usericon && usericon !== null) {
      setUserIcon(usericon.replaceAll('"', ""));
    }
  }, []);

  const getDashboardUrl = () => {
    let url = "";
    if (userType == "employer") {
      url = "/company/dashboard";
    } else if (userType == "applicant") {
      url = "/jobseeker/dashboard";
    } else if (userType == "admin") {
      url = "/admin/dashboard";
    } else {
      //do nothing
    }
    return url;
  };
  return (
    <>
      <nav className="shadowx bg-white w-full flex relative justify-between items-center mx-auto px-8 h-20 border-b">
        <div className="inline-flex">
          <a className="" href="/">
            <img src={Logo} className="object-contain h-8 w-80 web-only" />
          </a>
        </div>

        <div className="block">
          <div className="inline relative mobile-only">
            <button
              type="button"
              onClick={() => {
                setisPaneOpen(true);
              }}
              className="inline-flex items-center relative px-2 border  border-gray-500 rounded-full hover:shadow-lg"
            >
              <div className="pl-1">
                <svg
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="presentation"
                  focusable="false"
                  style={{
                    display: "block",
                    fill: "none",
                    height: "16px",
                    width: "16px",
                    stroke: "#000",
                    strokeWidth: "3",
                    overflow: "visible",
                  }}
                >
                  <g fill="none" fill-rule="nonzero">
                    <path d="m2 16h28"></path>
                    <path d="m2 24h28"></path>
                    <path d="m2 8h28"></path>
                  </g>
                </svg>
              </div>

              <div className="block flex-grow-0 flex-shrink-0 h-10 w-12 pl-5">
                {isAuthenticated && (
                  <svg
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="presentation"
                    focusable="false"
                    style={{
                      display: "block",
                      height: "100%",
                      width: "100%",
                      fill: "currentcolor",
                    }}
                  >
                    <path d="m16 .7c-8.437 0-15.3 6.863-15.3 15.3s6.863 15.3 15.3 15.3 15.3-6.863 15.3-15.3-6.863-15.3-15.3-15.3zm0 28c-4.021 0-7.605-1.884-9.933-4.81a12.425 12.425 0 0 1 6.451-4.4 6.507 6.507 0 0 1 -3.018-5.49c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5a6.513 6.513 0 0 1 -3.019 5.491 12.42 12.42 0 0 1 6.452 4.4c-2.328 2.925-5.912 4.809-9.933 4.809z"></path>
                  </svg>
                )}
              </div>
            </button>
          </div>
        </div>

        <div className="hidden sm:block flex-shrink flex-grow-0 justify-start px-2">
          <div className="inline-block">
            <div className="inline-flex items-center max-w-full">
              {/* <a className={`px-4 py-2 mt-2 text-sm   bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline text-soft-gray DMSansRegular  ${currentPathName == "/" ? "text-bee-orange DMSansBold " : ""}`} href="/">Home</a> */}
              <a
                className={`px-4 py-2 mt-2 text-sm   bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline text-soft-gray DMSansRegular  ${
                  currentPathName == "/job-board"
                    ? "text-bee-orange DMSansBold"
                    : ""
                }`}
                href="/job-board"
              >
                Job Board
              </a>
              {/*<a className={`px-4 py-2 mt-2 text-sm   bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline text-soft-gray DMSansRegular  ${currentPathName == "/education" ? "text-bee-orange DMSansBold" : ""}`} href="/education">Education {}</a>*/}
              {/* <a className={`px-4 py-2 mt-2 text-sm   bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline text-soft-gray DMSansRegular  ${currentPathName == "/mint" ? "text-bee-orange DMSansBold" : "/mint"}`} href="/mint">Mint</a>
               */}
              <a
                className={`px-4 py-2 mt-2 text-sm   bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline text-soft-gray  DMSansRegular ${
                  currentPathName == "/about-us"
                    ? "text-bee-orange DMSansBold"
                    : "/about-us"
                }`}
                href="/about-us"
              >
                About Us
              </a>
              
              {/* <a
                className={`px-4 py-2 mt-2 text-sm   bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline text-soft-gray  DMSansRegular ${
                  currentPathName == "/cv-review"
                    ? "text-bee-orange DMSansBold"
                    : "/cv-review"
                }`}
                href="/cv-review"
              >
                CV Review
              </a> */}

              {/*<a
                className={`px-4 py-2 mt-2 text-sm   bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline text-soft-gray  DMSansRegular ${
                  currentPathName == "/roadmap"
                    ? "text-bee-orange DMSansBold"
                    : "/roadmap"
                }`}
                href="/roadmap"
              >
                Roadmap
              </a>*/}
            </div>
          </div>
        </div>

        <div className="flex-initial">
          <div className="flex justify-end items-center relative">
            <div className="flex mr-4 items-center  web-only  ">
              {!loggedIn && (
                <>
                  <a
                    href="/login"
                    className="bg-bee-gray hover:bg-blue-dark  text-soft-gray   py-2 px-4 rounded  DMSansRegular"
                  >
                    Login
                  </a>
                  <img src={grayLineImage} className="object-contain h-8 w-8" />
                  <a
                    href="/register"
                    className="bg-bee-orange hover:bg-blue-dark text-white   py-2 px-4 rounded DMSansRegular "
                  >
                    Sign up
                  </a>
                </>
              )}
              {loggedIn && (
                <>
                  <a
                    href={`${getDashboardUrl()}`}
                    className="nav-btn-custom-orange hover:bg-blue-dark  text-soft-grayx  text-xs py-2 px-4 rounded text-white DMSansRegular"
                  >
                    Go to Dashboard
                  </a>
                  <a
                    href={` ${
                      userType == "employer"
                        ? "/company/profile"
                        : "/jobseeker/profile"
                    } `}
                  >
                    <img
                      src={userIcon}
                      className="object-contain h-8 w-8 lg:ml-2 rounded-full"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = `${grayedAvatarImage}`;
                      }}
                    />
                  </a>
                </>
              )}

              <div className="block relative">
                <button
                  type="button"
                  className="inline-block py-2 px-3 hover:bg-gray-200 rounded-full relative "
                >
                  <div className="flex items-center h-5">
                    <div className="_xpkakx"></div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <SlidingPane
        className="some-custom-class"
        overlayClassName="some-custom-overlay-class"
        isOpen={isPaneOpen}
        from="left"
        hideHeader={true}
        width={"70%"}
        title="Hey, it is optional pane title.  I can be React component too."
        subtitle="Optional subtitle."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setisPaneOpen(false);
        }}
      >
        <Link to="/">
          <img src={Logo} className="w-18 h-8    image-logo" />
        </Link>

        <div className="w-full bg-whitex margin-top rounded-lg shadow-lgx lg:w-full">
          <ul className="divide-y-2 divide-gray-100">
            <li className="flex black-text justify-between p-3  hover:bg-gray-200 hover:text-white-700">
              <a
                className={`px-4x py-2x mt-2x text-sm   bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline text-soft-gray DMSansRegular  ${
                  currentPathName == "/" ? "text-bee-orange DMSansBold " : ""
                }`}
                href="/"
              >
                Home
              </a>
            </li>

            <li className="flex black-text justify-between margin-top p-3 hover:bg-gray-200 hover:text-white-700">
              <a
                className={`px-4x py-2x mt-2x text-sm   bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline text-soft-gray DMSansRegular  ${
                  currentPathName == "/job-board"
                    ? "text-bee-orange DMSansBold"
                    : ""
                }`}
                href="/job-board"
              >
                Job Board
              </a>
            </li>

            {/*
                <li className="flex black-text justify-between margin-top p-3 hover:bg-gray-200 hover:text-white-700">
                <a className={`px-4x py-2x mt-2x text-sm   bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline text-soft-gray DMSansRegular  ${currentPathName == "/education" ? "text-bee-orange DMSansBold" : ""}`} href="/education">Education {}</a>
   
                </li>*/}

            {/*
                <li className="flex  black-text justify-between margin-top p-3 hover:bg-gray-200 hover:text-white-700">
                <a className={`px-4x py-2x mt-2x text-sm   bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline text-soft-gray DMSansRegular  ${currentPathName == "/mint" ? "text-bee-orange DMSansBold" : "/mint"}`} href="/mint">Mint</a>
                </li>
*/}
            <li className="flex black-text  justify-between margin-top p-3 hover:bg-gray-200 hover:text-white-700">
              <a
                className={`px-4x py-2x mt-2x text-sm   bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline text-soft-gray  DMSansRegular ${
                  currentPathName == "/about-us"
                    ? "text-bee-orange DMSansBold"
                    : "/about-us"
                }`}
                href="/about-us"
              >
                About Us
              </a>
            </li>

            {/* <li className="flex black-text  justify-between margin-top p-3 hover:bg-gray-200 hover:text-white-700">
              <a
                className={`px-4x py-2x mt-2x text-sm   bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline text-soft-gray  DMSansRegular ${
                  currentPathName == "/roadmap"
                    ? "text-bee-orange DMSansBold"
                    : "/roadmap"
                }`}
                href="/roadmap"
              >
                Roadmap
              </a>
            </li>*/}

            {!loggedIn && (
              <>
                <li className="flex black-text  justify-between margin-top p-3 hover:bg-gray-200 hover:text-white-700">
                  <a
                    className={`px-4x py-2x mt-2x text-sm   bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline text-soft-gray  DMSansRegular ${
                      currentPathName == "/login"
                        ? "text-bee-orange DMSansBold"
                        : "/login"
                    }`}
                    href="/login"
                  >
                    Login
                  </a>
                </li>

                <li className="flex black-text  justify-between margin-top p-3 hover:bg-gray-200 hover:text-white-700">
                  <a
                    className={`px-4x py-2x mt-2x text-sm   bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline text-soft-gray  DMSansRegular ${
                      currentPathName == "/register"
                        ? "text-bee-orange DMSansBold"
                        : "/register"
                    }`}
                    href="/register"
                  >
                    Register
                  </a>
                </li>
              </>
            )}

            {loggedIn && (
              <li className="flex black-text  justify-between margin-top p-3 hover:bg-gray-200 hover:text-white-700">
                <a
                  href={` ${
                    userType == "employer"
                      ? "/company/dashboard"
                      : "/jobseeker/dashboard"
                  } `}
                  className="nav-btn-custom-orange hover:bg-blue-dark  text-soft-grayx  text-xs py-2 px-4 rounded text-white DMSansRegular"
                >
                  Go to Dashboard
                </a>
              </li>
            )}
          </ul>
        </div>
      </SlidingPane>
    </>
  );
};

export default NavBar;
