import StageComponent from "components/stageComponent";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

//redux
import { updateJobApplicationDetails } from "store/actions";

const BackActionComponent = (props) => {
  const {
    updateJobApplicationDetails,
    error,
    loading,
    jobs_application_details,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const processUpdateStage = () => {
    setIsLoading(true);
    let data = {
      hiring_stage: valueHiringStage,
      apid: props.uiddata.apid,
    };
    updateJobApplicationDetails(data);
  };

  const [showHiringStageSelect, setShowHiringStageSelect] = useState(false);
  const [valueHiringStage, setValueHiringStage] = useState();

  const handleChange = (e) => {
    setValueHiringStage(e.target.value);
  };
  return (
    <>
      <div className="mt-1 mb-0">
        <button
          onClick={() => {
            props.setShowApplicationDetails(false);
          }}
        >
          <svg
            width="20"
            height="16"
            style={{ display: "inline" }}
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 8H19M8 15L1 8L8 15ZM1 8L8 1L1 8Z"
              stroke="#25324B"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span className="lg:ml-5 ArchivoBlack text-soft-gray">
            Application Details
          </span>
        </button>
      </div>
      {!showHiringStageSelect ? (
        <div className="mt-3 lg:ml-10">
          <button
            onClick={() => {
              setShowHiringStageSelect(true);
            }}
            className="text-soft-gray-bg font-bold py-2 px-4 rounded m-1 DMSansBold text-xs  text-white "
          >
            {" "}
            Update Hiring Stage
          </button>
        </div>
      ) : (
        <div className="mt-3 lg:ml-0 shadow py-1 px-3 rounded border-gray-300">
          <select
            value={valueHiringStage}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="0">Select Stage</option>
            <option value="Hired">Hired</option>
            <option value="Shortlisted">Shortlisted</option>
            <option value="Interviewed">Interviewed</option>
            <option value="In Review">In Review</option>
            <option value="Declined">Declined</option>
          </select>
          {isLoading ? (
            <ClipLoader color={"#F1B729"} loading={true} size={50} />
          ) : (
            <>
              <button
                onClick={() => {
                  processUpdateStage();
                }}
                className="nav-btn-custom-orange font-bold py-2 px-4 rounded m-1 DMSansBold text-xs  text-white"
              >
                {" "}
                Save{" "}
              </button>

              <button
                onClick={() => {
                  setShowHiringStageSelect(false);
                }}
                className="nav-btn-custom-crimson font-bold py-2 px-4 rounded m-1 DMSansBold text-xs  text-white"
              >
                {" "}
                Cancel{" "}
              </button>
            </>
          )}
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  const { error, loading, companyProfile, jobs_application_details } =
    state.auth;
  return { error, loading, companyProfile, jobs_application_details };
};

export default connect(mapStateToProps, { updateJobApplicationDetails })(
  BackActionComponent
);
