import React, { useState, useEffect } from "react";

//components
import NavBar from "components/navBar";
import Footer from "components/footer";
import TeamsComponent from "components/teamsComponent";

//images
import kobieImage from "assets/images/kobie2.png";
import naweedImage from "assets/images/naweed.jpeg";
import ammenImage from "assets/images/ammen.png";
import davidoluyaleImage from "assets/images/david2.png";
import feedemImage from "assets/images/feedem.png";
import feedemrealImage from "assets/images/feedemfpf.jpeg";
import sharyImage from "assets/images/shary.png";
import teamTwoImage from "assets/images/teams02.png";
import teamThreeImage from "assets/images/teams03.png";
import CtaImageTextButtonMultiple from "components/ctaImageTextButtonMultiple";

const AboutUs: React.FC = () => {
  return (
    <>
      <NavBar />
      <div className="float-search lg:ml-60x lg:w-full lg:mt-40	 "></div>
      <div className="sub-header-bar  orange-stripe-vector-small">
        <div className="mt-10x lg:px-20 grid ml-10 lg:ml-0  w-full ">
          <h1 className="text-2xl text-white ArchivoBlack mt-20 lg:text-center">
            Our
            <span className="text-bee-orange ArchivoBlack"> &nbsp;Vision</span>
          </h1>
        </div>
      </div>

      <div className=" py-20 lg:px-20 grid ">
        <p className="mt-4 DMSansRegular bee-text text-center">
          BEExperience aims to be to go-to HUB for education and recruitment
          within web3. We will provide essential infrastructure to help
          facilitate the onboarding process for the next wave of users entering
          the crypto space.
        </p>
        <p className="mt-4 DMSansRegular bee-text text-center">
          We will establish campaigns to identify and attract an untapped pool
          of talent that is not currently involved within the web3 ecosystem. We
          will leverage our educational infrastructure to onboard new users to
          web3 and provide the resources to enable their development of web3
          skills that will provide hiring entities with a consistent source of
          talent to aid in building and scaling their project.
        </p>
      </div>

      <h1 className="text-2xl  mt-10 ArchivoBlack text-soft-gray text-center">
        Meet the <span className="text-bee-orange">team</span>
      </h1>

      <div className="grid mt-5 lg:px-20 justify-center md:grid-cols-3 lg:grid-cols-3 gap-5 lg:gap-7 my-10  w-1/10">
        <TeamsComponent
          image={kobieImage}
          username={"Kobie McGlashan"}
          jobtitle={"Founder/CEO"}
          description={
            "Kobie has a wealth of experience landing change and transformation at a regional level within the telecoms industry. Being in the web3 industry from 2019, Kobie focused his efforts on marketing and has since been responsible for the successful growth of several multi-million dollar companies."
          }
          twitterUrl={"#"}
          linkedinUrl={"#"}
        />

        {/* <TeamsComponent
          image={naweedImage}
          username={"Naweed Yusufzazi "}
          jobtitle={"COO"}
          description={
            "Naweed Yusufzai is a non-profit executive turned web3 with a vision of mass adoption. He has co-founded several US-based 501(c)-3 organizations and was responsible for the brand strategy and operations of several multi-million dollar healthcare institutes over the past 5+ years."
          }
          //Recently, Naweed is the co-founder of the Antisocial Studios, a blockchain accelerator and infrastructure development studio supporting NFT projects on NEAR. Naweed has successfully managed and negotiated partnerships with various organizations, establishing the use of his company’s services by some of the industry’s top builders."}
          twitterUrl={"#"}
          linkedinUrl={"#"}
        /> */}

        <TeamsComponent
          image={teamTwoImage}
          username={"Diofinance "}
          jobtitle={"CTO"}
          description={
            "diofinance has 5+ years professional experience as a blockchain developer, working across several ecosystems including Ethereum, Polkadot, Cosmos, and NEAR. He has a background in solidity smart contract auditing and distributed systems, working for names such as Kava, Cosmos, and Snowfork."
          }
          twitterUrl={"#"}
          linkedinUrl={"#"}
        />

        <TeamsComponent
          image={davidoluyaleImage}
          username={"David Oluyale"}
          jobtitle={"Frontend Engineer"}
          description={
            "David has 5+ years professional experience in Software , Systems Engineering and Frontend Development cutting across Web, Mobile, Internet Of Things, and Blockchain technology. Working for early stage Startups and Multi-Million dollar Companies across Europe, Africa, U.S and Asia. He Holds a Bachelors degree in Computer Science."
          }
          twitterUrl={"#"}
          linkedinUrl={"#"}
        />
{/* 
twitterUrl={"https://twitter.com/david_oluyale"}
          linkedinUrl={"https://www.linkedin.com/in/david-oluyale/"} */}
        <TeamsComponent
          image={ammenImage}
          username={"Ameen Mathers"}
          jobtitle={"Backend Engineer"}
          description={
            "Ameen is a full stack developer with 5+ years in Laravel and over 3 years as a flutter developer working for a variety of start-ups. Passionate about the web3 space, Ameen has been offering services as a backend engineer since the beginning of 2022."
          }
          twitterUrl={"#"}
          linkedinUrl={"#"}
        />

        <TeamsComponent
          image={feedemrealImage}
          username={"Feedthem"}
          jobtitle={"Designer"}
          description={
            "Feedthem has been involved in the crypto space since 2017. He has a Bachelor’s in Art direction and over 5 years’ experience in design and implementation using frameworks such as FIGMA, Photoshop and Illustrator."
          }
          twitterUrl={"#"}
          linkedinUrl={"#"}
        />

        <TeamsComponent
          image={feedemImage}
          username={"Mark Sugden"}
          jobtitle={"Advisor"}
          description={
            "Mark has been involved in the blockchain space since 2017. More recently, working in business development and partnerships for the NEAR foundation, Mark has been working with teams ranging from newly minted startups, right through to multi-billion dollar behemoths of the crypto space. His unique experience in the industry is a valuable resource for teams looking to build."
          }
          twitterUrl={"#"}
          linkedinUrl={"#"}
        />

        {/* <TeamsComponent
          image={sharyImage}
          username={"Shreyas"}
          jobtitle={"Advisor"}
          description={
            "Shreyas is a community builder with over a decade of community building experience. He's passionate about building a decentralized & distributed open web. He's a computer science engineer by education, but succumbed to the lure of building communities & took up community building in open source as a full-time profession. He's been involved in Web3 since 2014. He's currently leading the community support efforts at the NEAR Foundation. Previously, he's worked at companies like CoinList, Tyk, HasGeek etc., building & executing their developer community strategy."
          }
          twitterUrl={"#"}
          linkedinUrl={"#"}
        /> */}
      </div>

      <CtaImageTextButtonMultiple
        title={"Want to join the team?"}
        description={""}
        actionButtonText1={"Contact Us"}
        actionButton1URL={"mailto:info@beexperience.io"}
        actionButtonText2={""}
        actionButton2URL={"mailto:info@beexperience.io"}
      />

      <Footer />
    </>
  );
};
export default AboutUs;
