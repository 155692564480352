import React, { useState, useEffect } from "react";
import Nav from "../partials/nav";
import "../dashboard.css";
import tfourImage from "assets/images/204x204.png";
import compoundIconImage from "assets/images/compound.svg";
import ListLong from "components/ListLong";
import arrowRightImage from "assets/images/arrow-right.svg";
import DashboardCard from "components/DashboardCard";
import SideBarAdmin from "../partials/sidebarAdmin";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import TableRowCustom from "components/tableRowCustom";
import { dateFormatterHumanReadable, makeid } from "helpers";
//redux
import { fetchAdminUserListing } from "store/actions";
//import ApplicationDetails from "./details";

const AdminUsers: React.FC = (props: any) => {
  const { fetchAdminUserListing, error, loading, adminUserListingData } = props;

  const [showModalDeleteConfirmation, setShowModalDeleteConfirmation] =
    useState(false);
  const [
    showModalUpdateStatusConfirmation,
    setShowModalUpdateStatusConfirmation,
  ] = useState(false);
  const [jobID, setJobID] = useState();
  const [jobStatus, setJobStatus] = useState();
  const [searchInput, setSearchInput] = useState("");

  const [showApplicationDetails, setShowApplicationDetails] = useState(false);
  const [applicationID, setApplicationID] = useState(0);
  const [applicantDetail, setApplicantDetail] = useState();
  const [uiddata, setUIDdata] = useState();
  const [usersData, setUsersData] = useState();

  useEffect(() => {
    fetchAdminUserListing({});
  }, [1]);

  const searchItems = (value: string) => {
    setSearchInput(value);
    if (value !== "") {
      const filteredData =
        adminUserListingData &&
        adminUserListingData.data.filter((item: any) => {
          return Object.values(item)
            .join("")
            .toLowerCase()
            .includes(value.toLowerCase());
        });
      setUsersData(filteredData);
    } else {
    }
  };
  
  return (
    <>
      <div>
        <Nav title={"Manage Users"} />
        <SideBarAdmin />
        {!showApplicationDetails ? (
          <>
            <div className="lg:ml-60 ml-0">
              <section className="is-hero-bar mt-20">
                <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0"></div>
              </section>
            </div>

            <div className="lg:ml-60 ml-0">
              <section className="cardx has-table lg:ml-2 mt-40x">
                <div className="card-contentx m-5x">
                  <div className="container-fluid mx-auto px-4 sm:px-8">
                    <div className="py-8">
                      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8x py-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadowx rounded-lg overflow-hidden">
                          <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                            {/*<div className="flex justify-between items-center pb-4">
                
                        <label  className="sr-only">Search</label>
                        <div className="relative">
                            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                            </div>
                            <input type="text" id="table-search" 
                             onChange={(e: any) => {
                              searchItems(e.target.value);
                            }}
                            className="block p-2 pl-10 w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items" />
                        </div>
                    </div> */}

                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                  <th scope="col" className="p-4">
                                    <div className="flex items-center">
                                      <input
                                        id="checkbox-all-search"
                                        type="checkbox"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                      />
                                      <label className="sr-only">
                                        checkbox
                                      </label>
                                    </div>
                                  </th>
                                  <th scope="col" className="py-3 px-6"></th>
                                  <th scope="col" className="py-3 px-6">
                                    Name
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Email
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    User Role
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Location
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Date Posted
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {!loading &&
                                  adminUserListingData &&
                                  adminUserListingData.data &&
                                  adminUserListingData.data.map(
                                    (item, index) => {
                                      return (
                                        <>
                                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <td className="p-4 w-4">
                                              <div className="flex items-center">
                                                <input
                                                  id="checkbox-table-search-3"
                                                  type="checkbox"
                                                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                                <label className="sr-only">
                                                  checkbox
                                                </label>
                                              </div>
                                            </td>
                                            <th
                                              scope="row"
                                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            ></th>
                                            <th
                                              scope="row"
                                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                              <div className="flex">
                                                <img
                                                  className="w-8 h-8 rounded-lg shadow-md"
                                                  src={
                                                    item.icon === "n/a"
                                                      ? tfourImage
                                                      : item.icon
                                                  }
                                                />
                                                <span className="ml-3">
                                                  {item.name}
                                                </span>
                                              </div>
                                            </th>
                                            <td
                                              className="py-4 px-6"
                                              width={"10%"}
                                            >
                                              {item.email}
                                            </td>
                                            <td className="py-4 px-6">
                                              {item.role}
                                            </td>
                                            <td className="py-4 px-6">
                                              {item.workLocation}
                                            </td>
                                            <td className="py-4 px-6">
                                              {dateFormatterHumanReadable(
                                                item.created_at
                                              )}
                                            </td>
                                            <td
                                              className="py-4 px-6"
                                              width={"50%"}
                                            >
                                              <button
                                                onClick={() => {
                                                  window.location.href = `/admin/postajob/${item.uid}/${item.name}`;
                                                }}
                                                className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded m-1"
                                              >
                                                {" "}
                                                Post a Job{" "}
                                              </button>

                                              <button
                                                onClick={() => {
                                                  setShowModalDeleteConfirmation(
                                                    true
                                                  );
                                                }}
                                                className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded m-1"
                                              >
                                                {" "}
                                                Remove{" "}
                                              </button>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {loading && (
                    <div className="text-center">
                      <ClipLoader color={"#F1B729"} loading={true} size={50} />
                    </div>
                  )}
                </div>
              </section>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      {showModalDeleteConfirmation && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 mr-4 sm:pb-2">
                  <div className="sm:flexc sm:items-startc">
                    <h1>Confirm Action</h1>
                    <p>
                      Once you remove a user, you won't be able to restore the
                      user.{" "}
                    </p>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    onClick={() => {
                      setShowModalDeleteConfirmation(false);
                    }}
                    type="button"
                    className="ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    YES, Proceed to delete
                  </button>
                  <button
                    onClick={() => {
                      setShowModalDeleteConfirmation(false);
                    }}
                    type="button"
                    className=" ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, adminUserListingData } = state.admin;
  return { error, loading, adminUserListingData };
};

export default connect(mapStateToProps, { fetchAdminUserListing })(AdminUsers);
