import { useState } from "react";
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  Elements,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import cardIconsImg from "assets/images/cardicons.png";
import cogoToast from "cogo-toast";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";
//redux
import { postCvSubscribe } from "store/actions";
const CheckoutForm = (props) => {
  const {
    postCvSubscribe,
    error,
    loading,
    subscription_data,
    pricePostAmount,
  } = props;
  const [showLoadingPayment, setShowLoadingPayment] = useState(false);
  const stripe: any = useStripe();
  const elements = useElements();

  const getPriceID = (amount) => {
    let priceID = "";
    switch (amount) {
      case 169:
        priceID = `${process.env.REACT_APP_STRIPE_PRICE_169}`;
        break;
      case 269:
        priceID = `${process.env.REACT_APP_STRIPE_PRICE_269}`;
        break;
      case 400:
        priceID = `${process.env.REACT_APP_STRIPE_PRICE_400}`;
        break;
      case 369:
        priceID = `${process.env.REACT_APP_STRIPE_PRICE_369}`;
        break;
      default:
    }
    return priceID;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let price_id = getPriceID(pricePostAmount);

    if (elements == null) {
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });
    await stripe
      .createToken(elements.getElement(CardNumberElement))
      .then(function (result) {
        if (!result.error) {
          setShowLoadingPayment(true);
          if (!error) {
            const { id } = paymentMethod;
            try {
              //post to backend
              let data = {
                stripeToken: result.token.id,
                paymentMethod: id,
                price: price_id,
              };
              postCvSubscribe(data);
              //cogoToast.success("Subscription Successful").then(()=>{
              //   window.location.href="/";
              //setShowLoadingPayment(false)
              //});

              if (paymentMethod < 100) {
                console.log("This is wrong... ");
              }
            } catch (error) {
              console.log(error);
              setShowLoadingPayment(false);
            }
          }
        }
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      {/*<CardElement />*/}
      <div className="">
        <label className="text-sm font-bold ArchivoBold text-gray-400">
          Card number
        </label>

        <CardNumberElement
          options={{
            showIcon: true,
          }}
        />
        <img
          src={cardIconsImg}
          className="w-24x h-8  justify-items-end ml-80"
        />
      </div>
      <div className="flex">
        <div className="w-6/12">
          <label className="text-sm font-bold ArchivoBold text-gray-400">
            Expiration
          </label>
          <CardExpiryElement />
        </div>
        <div className="w-6/12 ml-2  ">
          <label className="text-sm font-bold ArchivoBold text-gray-400">
            CVC
          </label>
          <CardCvcElement />
        </div>
      </div>

      <div className="text-center mb-5">
        {showLoadingPayment ? (
          <div className="text-center">
            <ClipLoader color={"#F1B729"} loading={true} size={50} />
          </div>
        ) : (
          <button
            type="submit"
            disabled={!stripe || !elements}
            className="bg-blue-500 hover:bg-blue-700 align-center text-white text-center font-bold py-2 px-4 w-5/12 rounded-md"
          >
            Pay
          </button>
        )}
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, user_data } = state.auth;
  return { error, loading, user_data };
};

export default connect(mapStateToProps, { postCvSubscribe })(CheckoutForm);
