import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { fetchSearchJobListing } from "store/actions";
import { connect } from "react-redux";

const TagComponent = (props) => {
  const {fetchSearchJobListing, title}  =props;
  const search = (title) => {
    fetchSearchJobListing(title);
  };
  return (
    <>
      <span onClick={()=>{
          search(title);
      }} 
      style={{cursor: 'pointer'}}
      className="tag-color border-red-600 opacity-70 bee-text rounded-slimx rounded  text-xs font-semibold inline-block py-1 px-2   roundedx   last:mr-0 mr-1 DMSansItalic">
        <span>{title}</span>
      </span>
    </>
  );
};
const mapStateToProps = (state) => {
  const { error_search, loading_search, job_search_result } = state.job;
  return { error_search, loading_search, job_search_result };
};

export default connect(mapStateToProps, { fetchSearchJobListing })(TagComponent);
