import React, { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const TeamsComponent = (props) => {
  return (
    <>
      <SkeletonTheme baseColor="#f5f5f5" highlightColor="#ccc">
        <div className="row-span-2 p-6 border border-white-100 rounded-xl bg-white-50 text-center sm:p-8">
          <div className="h-full flex flex-col justify-center space-y-4">
            <img
              className="w-24 h-24 mx-auto object-cover	 rounded-full"
              src={props.image}
              alt="user avatar"
              height="220"
              width="220"
              loading="lazy"
            />
            <div>
              <h6 className="text-lg font-semibold leading-none ArchivoExtraBold text-soft-gray">
                {props.username}
              </h6>
              <span className="text-xs DMSansItalic bee-text text-gray-500">
                {props.jobtitle}
              </span>
            </div>

            <p className="text-gray-600 md:text-sm  bee-text DMSansRegular">
              {props.description}
            </p>

            <div className="grid   justify-center md:grid-cols-6 lg:grid-cols-6 gap-5 lg:gap-7 my-10  w-full">
              <div></div>
              <div></div>
              {props.twitterUrl !== "#" && (
                <a href={props.twitterUrl}>
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.1498 3.48905C16.1612 3.64217 16.1612 3.79532 16.1612 3.94844C16.1612 8.61873 12.4493 14 5.66499 14C3.57488 14 1.63326 13.4203 0 12.4141C0.296966 12.4469 0.582471 12.4578 0.890863 12.4578C2.61546 12.4578 4.20305 11.9 5.47083 10.9485C3.84899 10.9156 2.48985 9.89845 2.02156 8.49844C2.25001 8.53123 2.47842 8.55311 2.71829 8.55311C3.0495 8.55311 3.38074 8.50934 3.6891 8.43282C1.99875 8.10467 0.730936 6.68281 0.730936 4.96562V4.92189C1.22204 5.18439 1.79315 5.34845 2.39844 5.3703C1.40478 4.73591 0.753788 3.65311 0.753788 2.4281C0.753788 1.77186 0.936496 1.1703 1.25631 0.645296C3.07232 2.78904 5.80203 4.18902 8.86293 4.34217C8.80583 4.07967 8.77155 3.80626 8.77155 3.53282C8.77155 1.58592 10.4162 0 12.4607 0C13.5228 0 14.4822 0.426561 15.1561 1.11562C15.9898 0.962507 16.7893 0.66718 17.4975 0.262502C17.2233 1.08283 16.6409 1.7719 15.8756 2.20937C16.618 2.13284 17.3376 1.93592 18 1.66251C17.4975 2.36248 16.8693 2.9859 16.1498 3.48905Z"
                      fill="#25324B"
                    />
                  </svg>
                </a>
              )}

              {props.linkedinUrl !== "#" && (
                <a href={props.linkedinUrl}>
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.633 14.0944H11.2623V10.4243C11.2623 9.54911 11.2464 8.4225 10.0293 8.4225C8.79445 8.4225 8.60552 9.376 8.60552 10.3606V14.0941H6.23483V6.54691H8.5107V7.5783H8.54261C9.006 6.79509 9.87081 6.32555 10.7881 6.35923C13.191 6.35923 13.634 7.92156 13.634 9.95412L13.633 14.0944ZM3.55983 5.51524C2.8051 5.51524 2.18376 4.90139 2.18376 4.15532C2.18376 3.40924 2.80481 2.79532 3.55955 2.79532C4.31421 2.79532 4.93519 3.4091 4.93533 4.15503C4.93533 4.90097 4.31443 5.51517 3.55983 5.51524ZM4.74518 14.0944H2.37198V6.54691H4.74518V14.0944ZM14.8149 0.618997H1.18062C0.54061 0.611852 0.00780069 1.12631 0 1.75898V15.2927C0.00751442 15.9258 0.540252 16.4408 1.18062 16.434H14.8149C15.4568 16.442 15.9914 15.927 16 15.2927V1.75806C15.9914 1.12404 15.4561 0.610649 14.8149 0.618997Z"
                      fill="#25324B"
                    />
                  </svg>
                </a>
              )}
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </>
  );
};

export default TeamsComponent;
