import React, { useState, useEffect } from "react";
import searchIcon from "../../assets/images/searchbar.svg";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

//redux
import { fetchSearchJobListing } from "store/actions";

const SearchBar = (props) => {
  let { id } = useParams();
  const location = useLocation();

  const {
    fetchSearchJobListing,
    error_search,
    loading_search,
    job_search_result,
  } = props;
  const buttonRefSearch = React.useRef<HTMLButtonElement>(null);

  const [companySearchParam, setCompanySearchParam] = useState("");
  const [locationSearchParam, setLocationSearchParam] = useState("");

  const processSearch = () => {
    if (location.pathname == "/job-board") {
      props.setShowJobDetails(false);
      props.setIsOnSearch("true");
      fetchSearchJobListing(companySearchParam || locationSearchParam);
    } else {
      localStorage.setItem("companySearchParam", companySearchParam);
      localStorage.setItem("locationSearchParam", locationSearchParam);

      window.location.href = "/job-board";
    }
  };

  useEffect(() => {
    let params = localStorage.getItem("companySearchParam");
    let paramsloca = localStorage.getItem("locationSearchParam");

    if (params !== null) {
      props.setIsOnSearch("true");
      setCompanySearchParam(params);
      if (paramsloca !== null) {
        setLocationSearchParam(paramsloca);
      }

      fetchSearchJobListing(params || paramsloca);
    }

    localStorage.removeItem("companySearchParam");
    localStorage.removeItem("locationSearchParam");
  }, [1]);
  return (
    <>
      <div
        className={`shadow bg-white ${
          location.pathname == "/job-board" ? "lg:ml-20x" : "lg:ml-40x"
        }    px-4 py-4  lg:mt-0 mt-40  place-content-centerx rounded-3xl lg:w-3/6 mx-auto	`}
      >
        <div className="flex flex-wrap items-end -mx-3">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 lg:ml-5">
            <label className="block   tracking-wide text-left text-black text-md   mb-2 DMSansBold text-soft-gray">
              Role, company
            </label>
            <div className="relative text-gray-600 focus-within:text-gray-400">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <button
                  type="submit"
                  className="p-1 focus:outline-none focus:shadow-outline"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.8364 13.0122L10.3573 9.53317C11.3054 8.37364 11.7716 6.89406 11.6593 5.40048C11.5471 3.9069 10.8651 2.51358 9.75441 1.50874C8.64371 0.503889 7.18928 -0.0356119 5.69195 0.00182585C4.19463 0.0392636 2.76897 0.650776 1.70987 1.70987C0.650776 2.76897 0.0392636 4.19463 0.00182585 5.69195C-0.0356119 7.18928 0.503889 8.64371 1.50874 9.75441C2.51358 10.8651 3.9069 11.5471 5.40048 11.6593C6.89406 11.7716 8.37364 11.3054 9.53317 10.3573L13.0122 13.8364C13.1222 13.9426 13.2694 14.0013 13.4222 14C13.575 13.9987 13.7212 13.9374 13.8293 13.8293C13.9374 13.7212 13.9987 13.575 14 13.4222C14.0013 13.2694 13.9426 13.1222 13.8364 13.0122ZM5.8472 10.51C4.92498 10.51 4.02347 10.2366 3.25667 9.72421C2.48987 9.21185 1.89222 8.48361 1.5393 7.63159C1.18638 6.77957 1.09404 5.84203 1.27396 4.93753C1.45388 4.03302 1.89797 3.20219 2.55008 2.55008C3.20219 1.89797 4.03302 1.45388 4.93753 1.27396C5.84203 1.09404 6.77957 1.18638 7.63159 1.5393C8.48361 1.89222 9.21185 2.48987 9.72421 3.25667C10.2366 4.02347 10.51 4.92498 10.51 5.8472C10.5086 7.08343 10.0169 8.26864 9.14279 9.14279C8.26864 10.0169 7.08343 10.5086 5.8472 10.51Z"
                      fill="#8083A3"
                    />
                  </svg>
                </button>
              </span>

              <input
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    buttonRefSearch.current?.click();
                  }
                }}
                onChange={(e) => {
                  setCompanySearchParam(e.target.value);
                }}
                value={companySearchParam}
                className="appearance-none pl-10  block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-5 px-4 leading-tight focus:outline-none focus:bg-white focus:border-grey"
                id="grid-city"
                type="text"
                placeholder="Enter Job Role e.g React"
              />
            </div>
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label className="block   tracking-wide text-left text-black text-md DMSansBold mb-2 text-soft-gray">
              Where?
            </label>
            <div className="relative text-gray-600 focus-within:text-gray-400">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <button
                  type="submit"
                  className="p-1 focus:outline-none focus:shadow-outline"
                >
                  <svg
                    width="12"
                    height="15"
                    viewBox="0 0 12 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 0C4.40934 0.00181943 2.88433 0.659546 1.7595 1.82889C0.634683 2.99823 0.00190936 4.58371 0 6.23749C0 7.84374 1.19627 10.3575 3.55576 13.7087C3.83665 14.1088 4.20442 14.4343 4.62916 14.6587C5.05389 14.883 5.52358 15 6 15C6.47642 15 6.94611 14.883 7.37084 14.6587C7.79558 14.4343 8.16335 14.1088 8.44424 13.7087C10.8037 10.3575 12 7.84374 12 6.23749C11.9981 4.58371 11.3653 2.99823 10.2405 1.82889C9.11567 0.659546 7.59066 0.00181943 6 0ZM6 8.72374C5.52442 8.72374 5.05952 8.57711 4.66409 8.30241C4.26866 8.02771 3.96046 7.63726 3.77847 7.18045C3.59647 6.72363 3.54885 6.22097 3.64163 5.73601C3.73441 5.25106 3.96343 4.80561 4.29971 4.45598C4.636 4.10635 5.06445 3.86824 5.53089 3.77178C5.99733 3.67532 6.48081 3.72483 6.92019 3.91405C7.35957 4.10326 7.73511 4.42369 7.99932 4.83482C8.26354 5.24594 8.40457 5.72929 8.40457 6.22374C8.40457 6.88678 8.15123 7.52266 7.70029 7.9915C7.24934 8.46034 6.63773 8.72374 6 8.72374Z"
                      fill="#8083A3"
                    />
                  </svg>
                </button>
              </span>
              <input
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    buttonRefSearch.current?.click();
                  }
                }}
                onChange={(e) => {
                  setLocationSearchParam(e.target.value);
                }}
                value={locationSearchParam}
                className="appearance-none pl-10  block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-5 px-4 leading-tight focus:outline-none focus:bg-white focus:border-grey"
                id="grid-city"
                type="text"
                placeholder="Location"
              />
            </div>
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 mobile-only">
            {loading_search ? (
              <ClipLoader color={"#F1B729"} loading={true} size={50} />
            ) : (
              <button
                ref={buttonRefSearch}
                onClick={() => {
                  processSearch();
                }}
                className="font-bold leading-tight content-center	content-between	  bg-bee-orange  hover:bg-red-light border border-red hover:border-red-light w-full text-white uppercase tracking-wide py-3 px-4 rounded full-width-mobile"
              >
                <img src={searchIcon} />
              </button>
            )}
          </div>

          <div className="web-only">
            {loading_search ? (
              <ClipLoader color={"#F1B729"} loading={true} size={50} />
            ) : (
              <button
                onClick={() => {
                  processSearch();
                }}
                className="font-bold leading-tight   bg-bee-orange  hover:bg-red-light border border-red hover:border-red-light w-full text-white uppercase tracking-wide py-3 px-4 rounded full-width-mobile"
              >
                <img src={searchIcon} />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error_search, loading_search, job_search_result } = state.job;
  return { error_search, loading_search, job_search_result };
};

export default connect(mapStateToProps, { fetchSearchJobListing })(SearchBar);
