import {
  JOB_LISTING,
  JOB_SUCCESS,
  API_ERROR,
  JOB_LISTING_DETAILS,
  JOB_DETAILS_SUCCESS,
  JOB_POST_SUCCESS,
  JOB_POST,
  JOB_LISTING_EMPLOYER,
  JOB_SUCCESS_EMPLOYER,
  JOB_APPLICATION_POST,
  JOB_APPLICATION_POST_SUCCESS,
  JOB_ALL_APPLICANTS,
  JOB_ALL_APPLICANTS_SUCCESS,
  SEARCH_JOB_LISTING,
  SEARCH_JOB_LISTING_SUCCESS,
  JOB_MY_APPLICATION,
  JOB_MY_APPLICATION_SUCCESS,
  UPDATE_JOB_APPLICATION,
  UPDATE_JOB_APPLICATION_SUCCESS,
  UPDATE_HIRING_STAGE_SUCCESS,
  UPDATE_HIRING_STAGE,
  UPDATE_APPLICANT_CV_SUCCESS,
  UPDATE_APPLICANT_CV,
  JOB_POST_APPLICANT_CV_SUCCESS,
  JOB_POST_APPLICANT_CV,
  JOB_POST_ADMIN_CV,
  JOB_POST_ADMIN_CV_SUCCESS,
  GET_APPLICANT_CV,
  GET_APPLICANT_CV_SUCCESS,
  SUBSCRIBE_CV,
  SUBSCRIBE_CV_SUCCESS,
  GET_CV_STATUS,
  GET_CV_STATUS_SUCCESS,
  SUBSCRIPTION_LISTING_STATUS,
  SUBSCRIPTION_LISTING_STATUS_SUCCESS,
  POST_JOB_OFFER_ADMIN_SUCESS,
  POST_JOB_OFFER_ADMIN,
  POST_JOB__UPDATE_OFFER_ADMIN_SUCESS,
  POST_JOB_UPDATE_OFFER_ADMIN,
  CV_DOWNLOAD,
  CV_DOWNLOAD_SUCCESS,
  JOB_ALL_APPLICANTS_ADMIN,
  JOB_ALL_APPLICANTS_ADMIN_SUCCESS
} from "store/job/constants";

const initialState = {
  error: "",
  apiError: null,
  loading: false,
  loadingCV:false,
  loading_search: false,
  error_search: "",
  jobs: [],
  job_search_result: [],
  jobs_details: [],
  my_job_applications: [],
  listing_status: [],
  jobs_application_details: [],
  applicant_cv: [],
  subscription_data: [],
  applicant_cv_detail: [],
  cv_download_lists:[],

};

const job = (state = initialState, action: any) => {
  switch (action.type) {
    case JOB_LISTING:
      state = {
        ...state,
        loading: true,
        error: "",
        jobs: action.payload,
      };
      break;

    case JOB_LISTING_EMPLOYER:
      state = {
        ...state,
        loading: true,
        error: "",
        jobs: action.payload,
      };
      break;

    case JOB_LISTING_DETAILS:
      state = {
        ...state,
        loading: true,
        error: "",
        jobs: action.payload,
      };
      break;

    case GET_APPLICANT_CV:
      state = {
        ...state,
        loading: true,
        error: "",
        applicant_cv_detail: action.payload,
      };
      break;

    case GET_APPLICANT_CV_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        applicant_cv_detail: action.payload,
      };
      break;

    case GET_CV_STATUS:
      state = {
        ...state,
        loading: true,
        error: "",
        subscription_data: action.payload,
      };
      break;

    case GET_CV_STATUS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        subscription_data: action.payload,
      };
      break;

    case JOB_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        jobs: action.payload,
      };
      break;

    case JOB_SUCCESS_EMPLOYER:
      state = {
        ...state,
        loading: false,
        error: "",
        jobs: action.payload,
      };
      break;

    case JOB_POST:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case JOB_POST_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        jobs: action.payload,
      };
      break;

    case JOB_POST_APPLICANT_CV:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case JOB_POST_APPLICANT_CV_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        applicant_cv: action.payload,
      };
      break;

    case JOB_POST_ADMIN_CV:
      state = {
        ...state,
        loadingCV: true,
        error: "",
      };
      break;
    
    case JOB_POST_ADMIN_CV_SUCCESS:
      state = {
        ...state,
        loadingCV: true,
        error: "",
        applicant_cv: action.payload,
      };
      break;

      case CV_DOWNLOAD:
        state = {
          ...state,
          loading_search: true,
          error: "",
        };
        break;
  
      case CV_DOWNLOAD_SUCCESS:
        state = {
          ...state,
          loading_search: false,
          error: "",
          cv_download_lists: action.payload,
        };
        break;


    case SUBSCRIBE_CV:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case SUBSCRIBE_CV_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        subscription_data: action.payload,
      };
      break;

    case POST_JOB_OFFER_ADMIN:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case POST_JOB_OFFER_ADMIN_SUCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        jobs: action.payload,
      };
      break;

    case POST_JOB_UPDATE_OFFER_ADMIN:
        state = {
          ...state,
          loading: true,
          error: "",
        };
        break;
  
      case POST_JOB__UPDATE_OFFER_ADMIN_SUCESS:
        state = {
          ...state,
          loading: false,
          error: "",
          jobs: action.payload,
        };
        break;
        
    case JOB_APPLICATION_POST:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case JOB_APPLICATION_POST_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        jobs: action.payload,
      };
      break;

    case JOB_ALL_APPLICANTS:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case JOB_ALL_APPLICANTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        jobs: action.payload,
      };
      break;

      case JOB_ALL_APPLICANTS_ADMIN:
        state = {
          ...state,
          loading: true,
          error: "",
        };
        break;
  
      case JOB_ALL_APPLICANTS_ADMIN_SUCCESS:
        state = {
          ...state,
          loading: false,
          error: "",
          jobs: action.payload,
        };
        break;

    case JOB_MY_APPLICATION:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case JOB_MY_APPLICATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        my_job_applications: action.payload,
      };
      break;

    case SUBSCRIPTION_LISTING_STATUS:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case SUBSCRIPTION_LISTING_STATUS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        listing_status: action.payload,
      };
      break;

    case JOB_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        jobs_details: action.payload,
      };
      break;

    case UPDATE_JOB_APPLICATION:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case UPDATE_JOB_APPLICATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        jobs_application_details: action.payload,
      };
      break;

    case UPDATE_HIRING_STAGE:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case UPDATE_HIRING_STAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        jobs_application_details: action.payload,
      };
      break;

    case UPDATE_APPLICANT_CV:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case UPDATE_APPLICANT_CV_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        jobs_application_details: action.payload,
      };
      break;

    case SEARCH_JOB_LISTING:
      state = {
        ...state,
        loading_search: true,
        error_search: "",
        job_search_result: action.payload,
      };
      break;

    case SEARCH_JOB_LISTING_SUCCESS:
      state = {
        ...state,
        loading_search: false,
        error_search: "",
        job_search_result: action.payload,
      };
      break;

    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default job;
