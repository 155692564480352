import React, { useState } from "react";

const DashboardCard = (props) => {
  return (
    <>
      <div className="p-4 md:w-1/3">
        <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
          <div className="p-6">
            <h1 className="title-font text-md font-medium text-gray-900 mb-3 DMSansBold">
              {props.title}
            </h1>
            <hr />
            <div className="flex items-center flex-wrap dashboard-card">
              <h1 className="text-8xl font-normal leading-normal mt-0 mb-2 text-soft-gray ArchivoBlack">
                {props.count}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardCard;
