import React, { useState, useEffect } from "react";
import SideBar from "../partials/sidebar";
import Nav from "../partials/nav";
import "../dashboard.css";
import { connect } from "react-redux";

import angularIconImage from "assets/images/angular.svg";
import compoundIconImage from "assets/images/compound.svg";
import ListLong from "components/ListLong";
import arrowRightImage from "assets/images/arrow-right.svg";
import DashboardCard from "components/DashboardCard";
import { todayGreeting } from "helpers/";
import TableRowCustomApplicantApplications from "components/tableRowCustomApplicantApplications";

//redux
import {
  fetchApplicantDashboard,
  fetchApplicationJobApplied,
  fetchJobSeekerProfile,
} from "store/actions";

const JobSeekersDashboard: React.FC = (props: any) => {
  const {
    fetchApplicantDashboard,
    error,
    loading,
    applicantDashboardDetails,
    fetchJobSeekerProfile,
    jobSeekerProfile,
    fetchApplicationJobApplied,
    my_job_applications,
  } = props;

  const [showApplicationDetails, setShowApplicationDetails] = useState(false);
  const [applicationID, setApplicationID] = useState(0);
  const [applicantDetail, setApplicantDetail] = useState();
  const [uiddata, setUIDdata] = useState();

  useEffect(() => {
    fetchApplicantDashboard({});
    fetchJobSeekerProfile({});
    fetchApplicationJobApplied({});
  }, [1]);

  return (
    <>
      <div>
        <Nav title={"My Dashboard"} />
        <SideBar applicantDashboardDetails={applicantDashboardDetails} />
        <div className="lg:ml-60 ml-0">
          <section className="is-hero-bar mt-20">
            <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
              <div>
                <h1 className=" text-small ArchivoBlack">
                  {todayGreeting()},
                  {` ${
                    jobSeekerProfile &&
                    jobSeekerProfile.authUserProfile &&
                    jobSeekerProfile.authUserProfile.name
                  }`}
                </h1>
                <p className="text-graisuh DMSansRegular text-sm">
                  Here is what's happening with your job search applications.
                </p>
              </div>
              {/*<button className="button light">Button</button>*/}
            </div>
          </section>
          <div className="notification white shadow lg:ml-2">
            <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0">
              <div>
                <b className="DMSansBold">Recent Applications History</b>
              </div>
            </div>
          </div>
          <div className="card has-table lg:ml-2">
            <div className="card-content m-5">
              {my_job_applications &&
                my_job_applications.data &&
                my_job_applications.data.data &&
                my_job_applications.data.data.emid &&
                my_job_applications.data.data.emid
                  .slice(0, 2)
                  .map((item, index) => {
                    return (
                      <TableRowCustomApplicantApplications
                        data={item}
                        jobs={my_job_applications}
                        setShowApplicationDetails={setShowApplicationDetails}
                        setApplicationID={setApplicationID}
                        setApplicantDetail={setApplicantDetail}
                        setUIDdata={setUIDdata}
                      />
                    );
                  })}

              <div className="text-center py-5">
                <a href="/jobseeker/myapplication">
                  <span className="DMSansBold mr-20 inline-block">
                    View application history
                  </span>
                  <img src={arrowRightImage} className="inline-block" />
                </a>
              </div>
            </div>
          </div>

          <DashboardCard
            title={"Total Jobs Applied"}
            count={
              applicantDashboardDetails &&
              applicantDashboardDetails.totalJobsApplied
            }
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, applicantDashboardDetails, jobSeekerProfile } =
    state.auth;
  const { my_job_applications } = state.job;

  return {
    error,
    loading,
    applicantDashboardDetails,
    jobSeekerProfile,
    my_job_applications,
  };
};

export default connect(mapStateToProps, {
  fetchApplicantDashboard,
  fetchJobSeekerProfile,
  fetchApplicationJobApplied,
})(JobSeekersDashboard);
