import React, { useState } from "react";

const DashboardCardSmaller = (props) => {
  return (
    <>
      <div className="p-4 md:w-full">
        <div
          className={` ${
            props.type == "orange-card" ? "orange-card" : " dashboard-card"
          } h-sm border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden `}
        >
          <div className="p-6">
            <div className="flex items-center flex-wrap  ">
              <h1
                className={` ${
                  props.type == "orange-card"
                    ? "orange-card-text"
                    : "text-soft-gray"
                } text-3xl font-normal leading-normal mt-0 mb-2  ArchivoBlack`}
              >
                {props.count}
              </h1>
              <h1
                className={`${
                  props.type == "orange-card"
                    ? "orange-card-text"
                    : "text-soft-gray"
                } text-1xl ml-5   mb-1 DMSansBold`}
              >
                {props.title}
                <br />
                {props.subtext}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardCardSmaller;
