import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { postNewPassword } from "store/actions";
import { connect } from "react-redux";

const ChangePasswordModal = (props) => {
  const { closeModal, postNewPassword,loading } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();

  return (
    <>
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 mr-4 sm:pb-2">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <div className="flex">
                      <div className="w-full ml-1">
                        <h3
                          className="text-md leading-6x font-mediumx   inline-block ArchivoBold  text-soft-gray"
                          id="modal-title"
                        >
                          Change Password
                        </h3>
                      </div>

                      <div className="w-1/2 ml-1">
                        <button
                          type="button"
                          onClick={() => {
                            closeModal(false);
                          }}
                          className="text-gray-400 bg-transparent lg:ml-40 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                          data-modal-toggle="defaultModal"
                        >
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </div>
                    </div>
                    
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        old_password: password,
                        new_password: newPassword,
                      }}
                      validationSchema={Yup.object().shape({
                        old_password: Yup.string()
                          .required("Password required")
                          .matches(
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
                            "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                          ),
                          new_password: Yup.string()
                          .required("Password required")
                          .matches(
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
                            "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                          ),
                      })}
                      onSubmit={(values, { resetForm }) => {
                        let data = {
                          old_password: values.old_password || "",
                          password: values.new_password || "",
                          confirm_password: values.new_password || ""
                        };
                        postNewPassword(data);
                      }}
                    >
                      {({ errors, status, touched }) => (
                        <Form>
                          <div className="mt-6  w-full">
                            <label
                              id="old_password"
                              className="text-sm font-small leading-none DMSansBold  text-soft-gray"
                            >
                              Old Password
                            </label>
                            <Field
                              name="old_password"
                              type="password"
                              autoComplete="true"
                              className={
                                "bg-white-200 border rounded border-black-200 text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2" +
                                (errors.old_password && touched.old_password
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="old_password"
                              component="div"
                              className="invalid-feedback"
                            />

                            <label
                              id="new_password"
                              className="text-sm font-small leading-none DMSansBold  text-soft-gray"
                            >
                              New Password
                            </label>
                            <Field
                              name="new_password"
                              type="password"
                              autoComplete="true"
                              className={
                                "bg-white-200 border rounded border-black-200 text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2" +
                                (errors.new_password && touched.new_password
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="new_password"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          
                          <div className="mt-8">
                            {loading ? (
                              <div className="text-center">
                                <ClipLoader
                                  color={"#F1B729"}
                                  loading={true}
                                  size={50}
                                />
                              </div>
                            ) : (
                              <button
                                role="button"
                                type="submit"
                                className="focus:ring-2 focus:ring-offset-2  DMSansBold  text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded bg-bee-orange py-4 w-full"
                              >
                                Submit
                              </button>
                            )}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading } = state.job;
  return { error, loading };
};

export default connect(mapStateToProps, { postNewPassword })(
  ChangePasswordModal
);
