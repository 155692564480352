import React, { useState, useEffect } from "react";

//components
import NavBar from "components/navBar";
import Footer from "components/footer";
import TeamsComponent from "components/teamsComponent";

//images
import teamOneImage from "assets/images/teams01.png";
import teamTwoImage from "assets/images/teams02.png";
import teamThreeImage from "assets/images/teams03.png";
import CtaImageTextButtonMultiple from "components/ctaImageTextButtonMultiple";
import roadMapImage from "assets/images/roadmapbg.jpg";
const RoadMap: React.FC = () => {
  const [currentYear, setCurrentYear] = useState("2022");
  return (
    <>
      <NavBar />
      <div className="float-search lg:ml-60x lg:w-full lg:mt-40	 "></div>
      <div className="sub-header-bar  orange-stripe-vector-small">
        <div className="mt-10x lg:px-20 grid ml-10 lg:ml-0  w-full ">
          <h1 className="text-2xl text-white ArchivoBlack mt-20 lg:text-center">
            BEE
            <span className="text-bee-orange ArchivoBlack"> &nbsp;Roadmap</span>
          </h1>
        </div>
      </div>

      <div className=" py-10 lg:px-20 grid">
        <p className="mt-4 fontgilory bee-text text-center"></p>

        <div className="text-center">
          <button
            type="button"
            onClick={() => {
              setCurrentYear("2022");
            }}
            className={`display-button   ${
              currentYear == "2022"
                ? "bg-gray-800  text-white"
                : " bg-white  text-gray-900"
            } hoverx:bg-gray-900x focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 DMSansBold hover:border-gray-300 border-transparent border-2  `}
          >
            2022
          </button>
          <button
            type="button"
            onClick={() => {
              setCurrentYear("2023");
            }}
            className={`display-button  ${
              currentYear == "2023"
                ? "bg-gray-800 text-white"
                : " bg-white  text-gray-900"
            } bg-white border border-gray-300 focus:outline-none hoverx:bg-gray-100x focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 DMSansBold hover:border-gray-300 border-transparent border-2  `}
          >
            2023
          </button>
        </div>
      </div>

      <div>
        <img
          src={roadMapImage}
          className="lg:w-1/2 w-full  lg:h-auto mx-auto h-32 object-cover object-center rounded"
        />
      </div>

      <div className="grid mt-5 lg:px-60 px-10 justify-center md:grid-cols-2 lg:grid-cols-2 gap-5 lg:gap-7 my-10  w-full">
        {currentYear == "2022" ? (
          <ol className="relative border-l border-black-400 dark:border-black-700 no-style">
            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-orange-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-lg font-normal leading-none text-gray-400 dark:text-gray-500 text-bee-orange ArchivoExtraBold">
                Q1
              </time>
              <p>
                <ul className="no-style">
                  <li className="DMSansRegular text-sm text-black-col mt-5">
                    1. Establish branding & socials ✅
                  </li>
                  <li className="DMSansRegular text-sm text-black-col mt-5">
                    2. Commence design phase for website ✅
                  </li>
                  <li className="DMSansRegular text-sm text-black-col mt-5">
                    3. Launch discord ✅
                  </li>
                </ul>
              </p>
            </li>

            <li className="mb-10 ml-4">
              <div className="absolute w-3 h-3 bg-orange-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-lg font-normal leading-none text-gray-400 dark:text-orange-500 text-bee-orange ArchivoExtraBold">
                Q2
              </time>
              <p>
                <ul className="no-style">
                  <li className="DMSansRegular text-sm text-black-col mt-5">
                    1. Begin talent acquisition ✅
                  </li>
                  <li className="DMSansRegular text-sm text-black-col mt-5">
                    2. Begin content creation for educational program ✅
                  </li>
                  <li className="DMSansRegular text-sm text-black-col mt-5">
                    3. Talent pools - strategic partnerships ✅
                  </li>
                  <li className="DMSansRegular text-sm text-black-col mt-5">
                    4. Launch headhunter/agency service ✅
                  </li>
                  <li className="DMSansRegular text-sm text-black-col mt-5">
                    5. Finish website ✅
                  </li>
                </ul>
              </p>
            </li>

            <li className="ml-4">
              <div className="absolute w-3 h-3 bg-orange-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-lg font-normal leading-none text-gray-400 dark:text-orange-500 text-bee-orange ArchivoExtraBold">
                Q3
              </time>
              <li className="DMSansRegular text-sm text-black-col mt-5">
                1. Platform launch ✅
              </li>
              <li className="DMSansRegular text-sm text-black-col mt-5">
                2. Launch NFT
              </li>
              <li className="DMSansRegular text-sm text-black-col mt-5">
                3. Establish partnership with NEAR
              </li>
              <li className="DMSansRegular text-sm text-black-col mt-5">
                5. Funding round
              </li>
            </li>

            <li className="ml-4 mt-10">
              <div className="absolute w-3 h-3 bg-orange-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-lg  font-normal leading-none text-gray-400 dark:text-orange-500 text-bee-orange ArchivoExtraBold">
                Q4
              </time>
              <li className="DMSansRegular text-sm text-black-col mt-5">
                1. Implement badge system for education/recruitment utility
              </li>
              <li className="DMSansRegular text-sm text-black-col mt-5">
                2. Launch series content for educational program
              </li>
              <li className="DMSansRegular text-sm text-black-col mt-5">
                3. Curated courses{" "}
              </li>
              <li className="DMSansRegular text-sm text-black-col mt-5">
                4. Continue talent acquisition
              </li>
              <li className="DMSansRegular text-sm text-black-col mt-5">
                5. Commence 'Gifted Individuals' program
              </li>
              <li className="DMSansRegular text-sm text-black-col mt-5">
                6. Begin workshop creation
              </li>
            </li>
          </ol>
        ) : (
          <ol className="relative border-l border-black-400 dark:border-black-700 no-style">
            <li className="ml-4 mt-10">
              <div className="absolute w-3 h-3 bg-orange-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
              <time className="mb-1 text-lg  font-normal leading-none text-gray-400 dark:text-orange-500 text-bee-orange ArchivoExtraBold">
                Q1
              </time>
              <li className="DMSansRegular text-sm text-black-col mt-5">
                1. Establish website rankings for users
              </li>
              <li className="DMSansRegular text-sm text-black-col mt-5">
                2. Incentivize learning/helping educate community members by
                launching an education event/campaign
              </li>
              <li className="DMSansRegular text-sm text-black-col mt-5">
                3. Seek out universities and explore opportunities to be offered
                to BEE holders
              </li>
              <li className="DMSansRegular text-sm text-black-col mt-5">
                4. Begin metaverse exploration for educational workshop
                experiences
              </li>
              <li className="DMSansRegular text-sm text-black-col mt-5">
                5. Begin exploring in-person workshops/events
              </li>
            </li>
          </ol>
        )}
      </div>

      <Footer />
    </>
  );
};
export default RoadMap;
