import React, { useState, useEffect } from "react";
import SideBarAdmin from "../partials/sidebarAdmin";

import Nav from "../partials/nav";
import "../dashboard.css"; 
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import TableRowCustom from "components/tableRowCustom";

//redux
import { fetchJobAllApplicantAdmin } from "store/actions";
import ApplicationDetails from "./details";
import { useParams } from "react-router-dom";

const All: React.FC = (props: any) => {
  const { fetchJobAllApplicantAdmin, error, loading, jobs } = props;
  
  const [showApplicationDetails, setShowApplicationDetails] = useState(false);
  const [applicationID, setApplicationID] = useState(0);
  const [applicantDetail, setApplicantDetail] = useState();
  const [uiddata, setUIDdata] = useState();
 
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const job_id = params.get("id");
    fetchJobAllApplicantAdmin({cid:job_id})
  }, [1]);
  
  return (
    <>
      <div>
        <Nav title={"My Applicants"} />

        <SideBarAdmin />

        {!showApplicationDetails ? (
          <>
            <div className="lg:ml-60 ml-0">
              <section className="is-hero-bar mt-20">
                <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
                  <div>
                    <h1 className=" text-small ArchivoBlack">All applicants</h1>
                    <p className="text-graisuh text-xs DMSansRegular">
                      Here is all your applicants
                    </p>

                    <div className="py-5 shadow-lg rounded-lg px-5 mt-3 filter-bg">
                      <select className="bg-transparent DMSansRegular text-sm bee-text">
                        <option>Stages</option>
                      </select>

                      <select className="bg-transparent px-4  DMSansRegular text-sm bee-text">
                        <option>Job Role</option>
                      </select>
                    </div>
                  </div>

                  <div className="flex items-center justify-center">
            {/*<form method="GET">
              <div className="relative text-gray-600 focus-within:text-gray-400">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <button type="submit" className="p-1 focus:outline-none focus:shadow-outline DMSansRegular">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.8364 13.0122L10.3573 9.53317C11.3054 8.37364 11.7716 6.89406 11.6593 5.40048C11.5471 3.9069 10.8651 2.51358 9.75441 1.50874C8.64371 0.503889 7.18928 -0.0356119 5.69195 0.00182585C4.19463 0.0392636 2.76897 0.650775 1.70987 1.70987C0.650775 2.76897 0.0392636 4.19463 0.00182585 5.69195C-0.0356119 7.18928 0.503889 8.64371 1.50874 9.75441C2.51358 10.8651 3.9069 11.5471 5.40048 11.6593C6.89406 11.7716 8.37364 11.3054 9.53317 10.3573L13.0122 13.8364C13.1222 13.9426 13.2694 14.0013 13.4222 14C13.575 13.9987 13.7212 13.9374 13.8293 13.8293C13.9374 13.7212 13.9987 13.575 14 13.4222C14.0013 13.2694 13.9426 13.1222 13.8364 13.0122ZM5.8472 10.51C4.92498 10.51 4.02347 10.2366 3.25667 9.72421C2.48987 9.21185 1.89222 8.48361 1.5393 7.63159C1.18638 6.77957 1.09404 5.84203 1.27396 4.93753C1.45388 4.03302 1.89797 3.20219 2.55008 2.55008C3.20219 1.89797 4.03302 1.45388 4.93753 1.27396C5.84203 1.09404 6.77957 1.18638 7.63159 1.5393C8.48361 1.89222 9.21185 2.48987 9.72421 3.25667C10.2366 4.02347 10.51 4.92498 10.51 5.8472C10.5086 7.08343 10.0169 8.26864 9.14279 9.14279C8.26864 10.0169 7.08343 10.5086 5.8472 10.51Z" fill="#8083A3"/>
                </svg>

                  </button>
                </span>
                <input type="search" name="q" className="py-2 text-sm text-white bg-white-900 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-white-900 border rounded-lg" placeholder="Search..."  />    
              </div>

            
              <div className="mt-10">
              <a href="#downloadSelectedCvs" className="text-soft-gray-bg font-bold py-2 px-4 rounded m-1 DMSansBold text-xs text-white ">Download CVs selected</a>
              <a href="#downloadAllCvs"  className="nav-btn-custom-orange font-bold py-2 px-4 rounded m-1 DMSansBold text-xs  text-white ">Download all CVs</a>
              </div> 

            </form>*/}
                  </div>
                </div>
              </section>
            </div>

            <div className="lg:ml-60 ml-0">
              <section className="cardx has-table lg:ml-2 mt-40x">
                <div className="card-contentx m-5x">
                  <div className="container-fluid mx-auto px-4 sm:px-8">
                    <div className="py-8">
                      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8x py-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadowx rounded-lg overflow-hidden">
                          <div className="flex flex-col md:flex-row  web-only  m-3 lg:ml-8">
                            <div className="lg:w-1/12">
                              <input
                                type="checkbox"
                                value=""
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                            </div>

                            <div className="lg:w-2/12 bg-redx text-centerx bee-text text-xs flex">
                              <span className="mr-5x">Full Name</span>
                              <svg
                                className="mt-1 ml-3"
                                width="8"
                                height="12"
                                viewBox="0 0 10 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 11L5 15L1 11"
                                  stroke="#8083A3"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1 5L5 0.999999L9 5"
                                  stroke="#8083A3"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>

                            <div className="lg:w-2/12 bg-greenx text-centerx bee-text text-xs flex">
                              <span className="mr-5x">Hiring Stages</span>
                              <svg
                                className="mt-1 ml-3"
                                width="8"
                                height="12"
                                viewBox="0 0 10 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 11L5 15L1 11"
                                  stroke="#8083A3"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1 5L5 0.999999L9 5"
                                  stroke="#8083A3"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>

                            <div className="lg:w-2/12 bg-greenx text-centerx bee-text text-xs flex">
                              <span className="mr-5x">Applied Date</span>
                              <svg
                                className="mt-1 ml-3"
                                width="8"
                                height="12"
                                viewBox="0 0 10 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 11L5 15L1 11"
                                  stroke="#8083A3"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1 5L5 0.999999L9 5"
                                  stroke="#8083A3"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>

                            <div className="lg:w-2/12 bg-greenx text-centerx bee-text text-xs flex">
                              <span className="mr-5x">Job Role</span>
                              <svg
                                className="mt-1 ml-3"
                                width="8"
                                height="12"
                                viewBox="0 0 10 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 11L5 15L1 11"
                                  stroke="#8083A3"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1 5L5 0.999999L9 5"
                                  stroke="#8083A3"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>

                            <div className="lg:w-2/12 bg-greenx text-centerx bee-text text-xs flex">
                              <span className="mr-5x">Action</span>
                              <svg
                                className="mt-1 ml-3"
                                width="8"
                                height="12"
                                viewBox="0 0 10 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 11L5 15L1 11"
                                  stroke="#8083A3"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1 5L5 0.999999L9 5"
                                  stroke="#8083A3"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>

                          {!loading &&
                            jobs &&
                            jobs.data &&
                            jobs.data.data &&
                            jobs.data.data.uid.map((item, index) => {
                              return (
                                <TableRowCustom
                                  data={item}
                                  jobs={jobs}
                                  setShowApplicationDetails={
                                    setShowApplicationDetails
                                  }
                                  setApplicationID={setApplicationID}
                                  setApplicantDetail={setApplicantDetail}
                                  setUIDdata={setUIDdata}
                                />
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>

                  {loading && (
                    <div className="text-center">
                      <ClipLoader color={"#F1B729"} loading={true} size={50} />
                    </div>
                  )}
                </div>
              </section>
            </div>
          </>
        ) : (
          <ApplicationDetails
            setShowApplicationDetails={setShowApplicationDetails}
            showApplicationDetails={showApplicationDetails}
            applicationID={applicationID}
            setApplicationID={setApplicationID}
            setApplicantDetail={setApplicantDetail}
            applicantDetail={applicantDetail}
            uiddata={uiddata}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, jobs } = state.job;
  return { error, loading, jobs };
};

export default connect(mapStateToProps, { fetchJobAllApplicantAdmin })(All);
