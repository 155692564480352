import React, { useState } from "react";
const CtaImageTextButtonMultiple = (props) => {
  return (
    <>
      <section className="container mx-auto bg-white-800">
        <div className="px-8 py-20 lg:flex lg:justify-center">
          <div className="">
            <h3
              data-testid="title"
              className="text-3xl text-white ArchivoBlack text-center bee-text-dark "
            >
              {props.title}
            </h3>
            <p
              data-testid="description"
              className="mt-2 bee-text text-center   DMSansRegular text-sm py-2 "
            >
              {props.description}
            </p>
            <div className="items-center justify-center mt-4 lg:flex lg:gap-2">
              {props.actionButtonText1 !== "" && (
                <a
                  className="
                block
                px-8
                py-2
                mt-6
                hover:border-gray-500 border-transparent border-2  
                text-lg
                font-medium
                text-center text-white-color
                font-bold
                bg-yellowis
                 rounded
                md:mt-0
                hover:bg-yellowis
                DMSansBold
              "
                  data-testid="actionbutton1"
                  href={props.actionButton1URL}
                >
                  {props.actionButtonText1}
                </a>
              )}

              {/*
                props.actionButtonText2 !=="" && 
                <a
                className="
                  block
                  px-8
                  py-2
                   font-bold
                  mt-6
                  text-lg
                  text-white-color
                  font-medium
                  text-center text-gray-900
                  rounded
                  bee-text-dark-bg
                  md:mt-0
                  DMSansBold
                "
                data-testid="actionbutton2"
                href={props.actionButton2URL}
                >{props.actionButtonText2}</a >
        */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CtaImageTextButtonMultiple;
