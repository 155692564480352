import { useEffect, useState } from "react";
import { TagsInput } from "react-tag-input-component";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";

import greenApproveImage from "assets/images/green-approve-2.svg";
import grayApproveImage from "assets/images/gray-approve.svg";
import redRejectImage from "assets/images/red-reject.svg";
import grayRejectImage from "assets/images/gray-reject.svg";
import grayedAvatarImage from "assets/images/grayed-avatar.png";

//redux
import { postUpdateApplicantProfile } from "store/actions";
import CustomTagInput from "components/CustomTagInput";

const EditProfile = (props) => {
  const { postUpdateApplicantProfile, error, loading, jobSeekerProfile } =
    props;

  const [remote, setRemote] = useState(0);
  const [openForOpportunity, setOpenForOpportunity] = useState(0);
  const [urlProfile, setUrlProfile] = useState("");
  const [selectedTag, setSelectedTag] = useState(["Web3"]);
  const [isUploading, setIsUploading] = useState(false);
  const [savingUpdatesLoading, setSavingUpdatesLoading] = useState(false);

  const [name, setName] = useState("");
  const [headline, setHeadline] = useState("");
  const [city, setCity] = useState("");
  const [bio, setBio] = useState("");
  const [email, setEmail] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [website, setWebsite] = useState("");
  const [icon, setIcon] = useState("");
  const [aboutMeCount, setAboutMeCount] = useState(0);
  const [jobTags, setTags] = useState();
  const [type_of_employement, setTypeOfEmployment] = useState("");

  useEffect(() => {
    if (props.jobSeekerProfile && props.jobSeekerProfile.authUserProfile) {
      setName(props.jobSeekerProfile.authUserProfile.name);
      setHeadline(props.jobSeekerProfile.authUserProfile.headline);
      setCity(props.jobSeekerProfile.authUserProfile.city);
      setBio(props.jobSeekerProfile.authUserProfile.bio);
      setEmail(props.jobSeekerProfile.authUserProfile.email);
      setInstagram(props.jobSeekerProfile.authUserProfile.instagram);
      setTwitter(props.jobSeekerProfile.authUserProfile.twitter);
      setWebsite(props.jobSeekerProfile.authUserProfile.website);
      setIcon(props.jobSeekerProfile.authUserProfile.icon);
      setTypeOfEmployment(
        props.jobSeekerProfile.authUserProfile.typeOfEmployment
      );
      setUrlProfile(icon);
      setAboutMeCount(
        props.jobSeekerProfile.authUserProfile.bio &&
          props.jobSeekerProfile.authUserProfile.bio.length
      );
      setSelectedTag(
        props.jobSeekerProfile.authUserProfile.address &&
          props.jobSeekerProfile.authUserProfile.address !== "n/a" &&
          JSON.parse(props.jobSeekerProfile.authUserProfile.address)
      );
      // setTags(props.jobSeekerProfile.authUserProfile.address && JSON.parse(props.jobSeekerProfile.authUserProfile.address) || []);
    }
  }, [1]);
  const onChangeProfileUpload = (e) => {
    setIsUploading(true);
    const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("upload_preset", "ltxrniwp");
    data.append("cloud_name", "do87mx9l1");
    fetch("  https://api.cloudinary.com/v1_1/do87mx9l1/image/upload", {
      method: "post",
      body: data,
    })
      .then((resp) => resp.json())
      .then((data) => {
        setIsUploading(false);
        //alert(JSON.stringify(data));
        setUrlProfile(data.secure_url);
      })
      .catch((err) => console.log(err));
  };
  const saveUpdate = () => {
    setName(props.jobSeekerProfile.authUserProfile.name);
    setHeadline(props.jobSeekerProfile.authUserProfile.headline);
    setCity(props.jobSeekerProfile.authUserProfile.city);
    setBio(props.jobSeekerProfile.authUserProfile.bio);
    setEmail(props.jobSeekerProfile.authUserProfile.email);
    setInstagram(props.jobSeekerProfile.authUserProfile.instagram);
    setTwitter(props.jobSeekerProfile.authUserProfile.twitter);
    setWebsite(props.jobSeekerProfile.authUserProfile.website);
    setAboutMeCount(
      props.jobSeekerProfile.authUserProfile.bio &&
        props.jobSeekerProfile.authUserProfile.bio.length
    );
    let data = {};
    if (urlProfile !== null && urlProfile != "") {
      data = {
        name: name,
        headline: headline,
        city: city,
        bio: bio,
        email: email,
        instagram: instagram,
        twitter: twitter,
        website: website,
        icon: urlProfile,
        address: selectedTag,
        typeOfEmployment: type_of_employement,
      };
      localStorage.setItem("usericon", urlProfile);
    } else {
      data = {
        name: name,
        headline: headline,
        city: city,
        bio: bio,
        email: email,
        instagram: instagram,
        twitter: twitter,
        website: website,
        address: selectedTag,
        typeOfEmployment: type_of_employement,
      };
    }
    setSavingUpdatesLoading(true);
    //alert(JSON.stringify(data)); //update-applicant-profile
    postUpdateApplicantProfile(data);
  };
  return (
    <>
      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container-fluid lg:ml-60 px-5 py-24 lg:mx-auto">
          <div className="-my-8 divide-y-2 divide-gray-100">
            <div className="lg:py-8 lg:flex lg:flex-wrap md:flex-nowrap">
              <div className="md:w-full md:mb-0 mb-6 flex-shrink-0 flex flex-col  ">
                <div className="mt-0 mb-0">
                  <button
                    onClick={() => {
                      props.setIsOnEditProfile(false);
                    }}
                  >
                    <svg
                      width="20"
                      height="16"
                      style={{ display: "inline" }}
                      viewBox="0 0 20 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 8H19M8 15L1 8L8 15ZM1 8L8 1L1 8Z"
                        stroke="#25324B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span className="lg:ml-5 ArchivoBlack text-soft-gray">
                      Edit profile
                    </span>
                  </button>
                </div>
                <div className="grid lg:grid-cols-2 mt-20 gap-5 lg:gap-7">
                  <div className="">
                    <div className="mt-5">
                      <label className="DMSansBold text-soft-gray text-xs block">
                        Name
                      </label>
                      <input
                        placeholder=""
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        value={name}
                        className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 w-5/6 pl-3 mt-2x"
                      />
                    </div>
                    <div className=" mt-5 ">
                      <label className="DMSansBold text-soft-gray text-xs block">
                        Occupation
                      </label>
                      <input
                        placeholder=""
                        onChange={(e) => {
                          setHeadline(e.target.value);
                        }}
                        value={headline}
                        className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 w-5/6 pl-3 mt-2x"
                      />
                    </div>
                    <div className=" mt-5 ">
                      <label className="DMSansBold text-soft-gray text-xs block">
                        Type of employment
                      </label>
                      <input
                        placeholder=""
                        onChange={(e) => {
                          setTypeOfEmployment(e.target.value);
                        }}
                        value={type_of_employement}
                        className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 w-5/6 pl-3 mt-2x"
                      />
                    </div>
                    <div className=" mt-5 ">
                      <label className="DMSansBold text-soft-gray text-xs w-full block">
                        Location
                      </label>
                      <input
                        placeholder=""
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                        value={city}
                        className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 w-5/6	 pl-3 mt-2x"
                      />
                    </div>
                    <section className="is-hero-barx  mt-6">
                      <div className="grid  lg:grid-cols-2">
                        <div className="md:w-100 mr-5  md:mb-0 mb-6 flex-shrink-0x flexx flex-colz">
                          <span className="DMSansBold text-soft-gray text-xs">
                            Remote
                          </span>
                        </div>
                        <div className="md:flex-grow lg:ml-20">
                          <div className="ml-10">
                            <a
                              href="#accept-anonymous"
                              onClick={() => {
                                setRemote(1);
                              }}
                            >
                              <img
                                src={
                                  remote == 1
                                    ? greenApproveImage
                                    : grayApproveImage
                                }
                                className={`${
                                  remote == 1
                                    ? " border-yellow-300-image-2x"
                                    : "no-border"
                                } inline  `}
                              />
                            </a>
                            <a
                              href="#reject-anonymous"
                              onClick={() => {
                                setRemote(2);
                              }}
                            >
                              <img
                                src={
                                  remote == 2 ? redRejectImage : grayRejectImage
                                }
                                className={`ml-5 ${
                                  remote == 2
                                    ? "  border-yellow-300-image-2x"
                                    : "no-border"
                                } inline  `}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="is-hero-barx  mt-6">
                      <div className="grid  lg:grid-cols-2">
                        <div className="md:w-100 mr-5  md:mb-0 mb-6 flex-shrink-0x flexx flex-colz">
                          <span className="DMSansBold text-soft-gray text-xs">
                            Open for opportunities
                          </span>
                        </div>
                        <div className="md:flex-grow lg:ml-20">
                          <div className="ml-10 ">
                            <a
                              href="#accept-anonymous"
                              onClick={() => {
                                setOpenForOpportunity(1);
                              }}
                            >
                              <img
                                src={
                                  openForOpportunity == 1
                                    ? greenApproveImage
                                    : grayApproveImage
                                }
                                className={`${
                                  openForOpportunity == 1
                                    ? " border-yellow-300-image-2x"
                                    : "no-border"
                                } inline  `}
                              />
                            </a>
                            <a
                              href="#reject-anonymous"
                              onClick={() => {
                                setOpenForOpportunity(2);
                              }}
                            >
                              <img
                                src={
                                  openForOpportunity == 2
                                    ? redRejectImage
                                    : grayRejectImage
                                }
                                className={`ml-5 ${
                                  openForOpportunity == 2
                                    ? "  border-yellow-300-image-2x"
                                    : "no-border"
                                } inline  `}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </section>
                    <div className="mt-10">
                      <label className="leading-7 text-sm text-gray-600 text-whitex  DMSansBold text-soft-gray text-xs block">
                        About me
                      </label>
                      <textarea
                        cols={90}
                        rows={4}
                        placeholder={"Message..."}
                        onChange={(event) => {
                          //(event.target.value);
                          setBio(event.target.value);
                          setAboutMeCount(event.target.value.length);
                        }}
                        value={bio && bio.substring(0, 900)}
                        className="border-none-colors  full-widthx rounded-lg resize-none w-5/6 h-full bg-white-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1x px-3 leading-8 transition-colors duration-200 ease-in-out"
                      ></textarea>
                      <p className="text-xs DMSansBold text-soft-gray ">
                        {aboutMeCount > 900 ? "900" : aboutMeCount} /900
                      </p>
                    </div>
                  </div>
                  <div className="lg:justify-self-centerx">
                    {!isUploading ? (
                      <img
                        alt="useravatar"
                        src={` ${
                          urlProfile !== ""
                            ? urlProfile
                            : icon && icon.length > 3
                            ? icon
                            : `${grayedAvatarImage}`
                        }`}
                        className="w-16 h-16 rounded-full flex-shrink-0 object-cover object-center"
                      />
                    ) : (
                      <ClipLoader color={"#F1B729"} loading={true} size={50} />
                    )}
                    <p className="desc-color DMSansRegular text-xs mt-2">
                      We recommend an image of at least 300x300. <br />
                      Max 5mb.
                    </p>
                    <div className="mt-5">
                      <input
                        type="file"
                        className={` ${
                          urlProfile !== "" && "hide-fileuploadedx"
                        } custom-file-input-gray `}
                        onChange={onChangeProfileUpload}
                      />
                    </div>
                    <p className="mt-10 DMSansBold text-soft-gray">Contact</p>
                    <div className=" mt-5 ">
                      <label className="DMSansBold text-soft-gray text-xs block">
                        Email
                      </label>
                      <input
                        placeholder=""
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                        className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 w-5/6 pl-3 mt-2x"
                      />
                    </div>

                    <div className=" mt-5 ">
                      <label className="DMSansBold text-soft-gray text-xs block">
                        Instagram
                      </label>
                      <input
                        placeholder=""
                        onChange={(e) => {
                          setInstagram(e.target.value);
                        }}
                        value={instagram}
                        className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 w-5/6 pl-3 mt-2x"
                      />
                    </div>

                    <div className=" mt-5 ">
                      <label className="DMSansBold text-soft-gray text-xs block">
                        Twitter
                      </label>
                      <input
                        placeholder=""
                        onChange={(e) => {
                          setTwitter(e.target.value);
                        }}
                        value={twitter}
                        className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 w-5/6 pl-3 mt-2x"
                      />
                    </div>
                    <div className=" mt-5 ">
                      <label className="DMSansBold text-soft-gray text-xs block">
                        Website
                      </label>
                      <input
                        placeholder=""
                        onChange={(e) => {
                          setWebsite(e.target.value);
                        }}
                        value={website}
                        className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 w-5/6 pl-3 mt-2x"
                      />
                    </div>
                    <p className="mt-10 DMSansBold text-soft-gray mb-2">
                      My job tags
                    </p>
                    {jobTags && JSON.parse(jobTags).length}
                    {/*
                    jobTags  && JSON.parse(jobTags).map((item, index)=>{
                        return(
                            <span className="rti--tag2"><span>{item}</span><button type="button" aria-label="remove Web3">✕</button></span>
                        )
                    })
                    */}
                    <div className="mt-1x">
                      {jobTags && JSON.parse(jobTags).length > 0 ? (
                        <CustomTagInput
                          tags={jobTags && JSON.parse(jobTags)}
                          setSelectedTag={setSelectedTag}
                        />
                      ) : props.jobSeekerProfile.authUserProfile &&
                        props.jobSeekerProfile.authUserProfile.address !==
                          "n/a" ? (
                        <CustomTagInput
                          tags={
                            (props.jobSeekerProfile.authUserProfile.address &&
                              JSON.parse(
                                props.jobSeekerProfile.authUserProfile.address
                              )) || [""]
                          }
                          setSelectedTag={setSelectedTag}
                        />
                      ) : (
                        <CustomTagInput
                          tags={[]}
                          setSelectedTag={setSelectedTag}
                        />
                      )}
                    </div>
                  </div>
                  {savingUpdatesLoading ? (
                    <ClipLoader color={"#F1B729"} loading={true} size={50} />
                  ) : (
                    <button
                      onClick={() => {
                        saveUpdate();
                      }}
                      className="inline-flexx lg:ml-0 text-white w-5/6 mt-0 bg-bee-orange border-0 py-1 px-6 focus:outline-none hover:bg-indigo-600x rounded text-sm DMSansRegular"
                    >
                      Save Update
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, user_data } = state.auth;
  return { error, loading, user_data };
};

export default connect(mapStateToProps, { postUpdateApplicantProfile })(
  EditProfile
);
