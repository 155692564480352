export const ADMIN_DASHBOARD = "ADMIN_DASHBOARD";
export const ADMIN_DASHBOARD_SUCCESS = "ADMIN_DASHBOARD_SUCCESS";
export const API_ERROR = "API_ERROR";
export const ADMIN_JOB_LISTING = "ADMIN_JOB_LISTING";
export const ADMIN_JOB_LISTING_SUCCESS = "ADMIN_JOB_LISTING_SUCCESS";
export const ADMIN_USER_LISTING = "ADMIN_USER_LISTING";
export const ADMIN_USER_LISTING_SUCCESS = "ADMIN_USER_LISTING_SUCCESS";
export const ADMIN_CVS_LISTING = "ADMIN_CVS_LISTING";
export const ADMIN_CVS_LISTING_SUCCESS = "ADMIN_CVS_LISTING_SUCCESS";

export const DELETE_POST_JOB_OFFER_ADMIN = "POST_JOB_OFFER_ADMIN";
export const DELETE_POST_JOB_OFFER_ADMIN_SUCESS = "POST_JOB_OFFER_ADMIN_SUCESS";

export const POST_JOB_CATEGORY_ADMIN = "POST_JOB_CATEGORY_ADMIN";
export const POST_JOB_CATEGORY_ADMIN_SUCCESS = "POST_JOB_CATEGORY_ADMIN_SUCCESS";

export const FETCH_JOB_CATEGORY_ADMIN = "FETCH_JOB_CATEGORY_ADMIN";
export const FETCH_JOB_CATEGORY_ADMIN_SUCCESS = "FETCH_JOB_CATEGORY_ADMIN_SUCCESS";

export const DELETE_ADMIN_CATEGORY_LISTING = "DELETE_ADMIN_CATEGORY_LISTING";
export const DELETE_ADMIN_CATEGORY_LISTING_SUCCESS = "DELETE_ADMIN_CATEGORY_LISTING_SUCCESS";

 