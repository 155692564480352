const SocialInfo = (props) => {
  return (
    <div className="lg:p-4  lg:mt-0 mt-5 lg:w-1/2x md:w-fullx">
      <div className="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
        <div className="flex-grow">
          <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
            Contact
          </h2>
          <div className="flex items-center lg:w-3/5x mx-autox border-bx pb-10x mb-10x border-gray-200 sm:flex-row flex-col">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 8L10.89 13.26C11.2187 13.4793 11.6049 13.5963 12 13.5963C12.3951 13.5963 12.7813 13.4793 13.11 13.26L21 8M5 19H19C19.5304 19 20.0391 18.7893 20.4142 18.4142C20.7893 18.0391 21 17.5304 21 17V7C21 6.46957 20.7893 5.96086 20.4142 5.58579C20.0391 5.21071 19.5304 5 19 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V17C3 17.5304 3.21071 18.0391 3.58579 18.4142C3.96086 18.7893 4.46957 19 5 19Z"
                stroke="#8083A3"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="flex-grow sm:text-left text-center mt-6x ml-2 sm:mt-0">
              <p className="leading-relaxed text-base bee-text DMSansRegular mt-7 ">
                Email
              </p>
              <p className="leading-relaxed text-base bee-textx  DMSansBold text-sm text-bee-orange ">
                {" "}
                {props.profileDetails &&
                  props.profileDetails.data &&
                  props.profileDetails.data.email}{" "}
              </p>
            </div>
          </div>
          <div className="flex items-center lg:w-3/5x mx-autox border-bx pb-10x mb-10x border-gray-200 sm:flex-row flex-col">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 4H8C5.79086 4 4 5.79086 4 8V16C4 18.2091 5.79086 20 8 20H16C18.2091 20 20 18.2091 20 16V8C20 5.79086 18.2091 4 16 4Z"
                stroke="#8083A3"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                stroke="#8083A3"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 7.5V7.501"
                stroke="#8083A3"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="flex-grow sm:text-left text-center mt-6x ml-2 sm:mt-0">
              <p className="leading-relaxed text-base bee-text DMSansRegular mt-7 ">
                Instagram
              </p>
              <a
                target="_blank"
                href={`${
                  (props.profileDetails &&
                    props.profileDetails.data &&
                    props.profileDetails.data.instagram &&
                    props.profileDetails.data.instagram.replace("@", "")) ||
                  "..."
                }`}
                className="leading-relaxed text-base bee-textx  DMSansBold text-sm text-bee-orange "
              >
                {(props.profileDetails &&
                  props.profileDetails.data &&
                  props.profileDetails.data.instagram) ||
                  "..."}
              </a>
            </div>
          </div>
          <div className="flex items-center lg:w-3/5x mx-autox border-bx pb-10x mb-10x border-gray-200 sm:flex-row flex-col">
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 2.8C19.2483 3.12609 18.4534 3.34166 17.64 3.44C18.4982 2.92732 19.1413 2.12078 19.45 1.17C18.6436 1.65006 17.7608 1.9883 16.84 2.17C16.2245 1.50257 15.405 1.0583 14.5098 0.906851C13.6147 0.755406 12.6945 0.905358 11.8938 1.33319C11.093 1.76102 10.4569 2.44253 10.0852 3.27084C9.71355 4.09914 9.62729 5.02739 9.84 5.91C8.20943 5.82753 6.61444 5.40295 5.15865 4.66386C3.70287 3.92477 2.41885 2.88769 1.39 1.62C1.02914 2.25017 0.839519 2.96382 0.84 3.69C0.83872 4.36438 1.00422 5.02862 1.32176 5.62356C1.63929 6.21851 2.09902 6.72571 2.66 7.1C2.00798 7.08226 1.36989 6.9073 0.8 6.59V6.64C0.804887 7.58489 1.13599 8.49909 1.73731 9.22797C2.33864 9.95684 3.17326 10.4556 4.1 10.64C3.74326 10.7486 3.37288 10.8058 3 10.81C2.74189 10.807 2.48442 10.7836 2.23 10.74C2.49391 11.5528 3.00462 12.2631 3.69107 12.7722C4.37753 13.2812 5.20558 13.5636 6.06 13.58C4.6172 14.7153 2.83588 15.3349 1 15.34C0.665735 15.3411 0.331736 15.3211 0 15.28C1.87443 16.4903 4.05881 17.1327 6.29 17.13C7.82969 17.146 9.35714 16.855 10.7831 16.2741C12.2091 15.6931 13.505 14.8339 14.5952 13.7465C15.6854 12.6591 16.548 11.3654 17.1326 9.94091C17.7172 8.51642 18.012 6.98973 18 5.45C18 5.28 18 5.1 18 4.92C18.7847 4.33481 19.4615 3.61742 20 2.8Z"
                fill="#8083A3"
              />
            </svg>
            <div className="flex-grow sm:text-left text-center mt-6x ml-2 sm:mt-0">
              <p className="leading-relaxed text-base bee-text DMSansRegular mt-7 ">
                Twitter
              </p>
              <a
                target="_blank"
                href={`${
                  (props.profileDetails &&
                    props.profileDetails.data &&
                    props.profileDetails.data.twitter &&
                    props.profileDetails.data.twitter.replace("@", "")) ||
                  "..."
                }`}
                className="leading-relaxed text-base bee-textx  DMSansBold text-sm text-bee-orange "
              >
                {(props.profileDetails &&
                  props.profileDetails.data &&
                  props.profileDetails.data.twitter) ||
                  "..."}
              </a>
            </div>
          </div>
          <div className="flex items-center lg:w-3/5x mx-autox border-bx pb-10x mb-10x border-gray-200 sm:flex-row flex-col">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                stroke="#8083A3"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.60001 9H20.4"
                stroke="#8083A3"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.60001 15H20.4"
                stroke="#8083A3"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.5 3C9.81535 5.69961 8.92221 8.81787 8.92221 12C8.92221 15.1821 9.81535 18.3004 11.5 21"
                stroke="#8083A3"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.5 3C14.1847 5.69961 15.0778 8.81787 15.0778 12C15.0778 15.1821 14.1847 18.3004 12.5 21"
                stroke="#8083A3"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="flex-grow sm:text-left text-center mt-6x ml-2 sm:mt-0">
              <p className="leading-relaxed text-base bee-text DMSansRegular mt-7 ">
                Website
              </p>
              <a
                target="_blank"
                href={
                  (props.profileDetails &&
                    props.profileDetails.data &&
                    props.profileDetails.data.website) ||
                  "..."
                }
                className="leading-relaxed text-base bee-textx  DMSansBold text-sm text-bee-orange "
              >
                {(props.profileDetails &&
                  props.profileDetails.data &&
                  props.profileDetails.data.website) ||
                  "..."}{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SocialInfo;
