import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import axios from "axios";

import ClipLoader from "react-spinners/ClipLoader";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { create } from "ipfs-http-client";
import TagComponent from "components/tagComponent";
import cogoToast from "cogo-toast";
//github-logo
import githubLogo from "assets/images/github-logo.png";
//solana-logo
import solanaLogo from "assets/images/solana-logo.png";
 
//redux
import { updateApplicantCV, postApplicantPostCV } from "store/actions";
import ChangePasswordModal from "pages/dashboard/partials/changePasswordModal";

// const { Configuration, OpenAIApi } = require("openai");
// const configuration = new Configuration({
//   apiKey: process.env.OPENAI_API_KEY,
// });
// const openai = new OpenAIApi(configuration);


const ProfileDetails = (props) => {
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const {
    updateApplicantCV,
    postApplicantPostCV,
    error,
    loading,
    code,
    jobs_application_details,
  } = props;

  const clientId = process.env.REACT_APP_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH_CALLBACK_URL;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
  const callbackUrl  = process.env.REACT_APP_AUTH_CALLBACK_URL;
  const github_access_token_url = process.env.REACT_APP_GITHUB_ACCESS_TOKEN_URL;

  const [isUploadingCV, setIsUploadingCV] = useState(false);
  const [showFileUploadPopup, setShowFileUploadPopup] = useState(false);
  const [url, setUrl] = useState("");
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [githubAccessToken, setGithubAccessToken] = useState('');
  const [githubBio, setGithubBio] = useState('');
  const [githubAvatar, setGithubAvatar] = useState('');
  const [githubName, setGithubName] = useState('');
  const [location, setLocation] = useState('');
  const [githubEmail, setGithubEmail] = useState('');
  const [githubTwitter, setGithubTwitter] = useState('');
  const [aiBio, setAIBio] = useState('');
  const [githubTags, setGithubTags] = useState([]);

   
   
  const [uploadedfilename, setUploadedFilename] = useState();
  const [uploadedfileformat, setUploadedFileFormat] = useState();
  const fileUploadRef = React.useRef<HTMLInputElement>(null);

  const onChangeFileUpload = (e) => {
    setIsUploadingCV(true);
    const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("upload_preset", "ltxrniwp");
    data.append("cloud_name", "do87mx9l1");
    fetch("  https://api.cloudinary.com/v1_1/do87mx9l1/image/upload", {
      method: "post",
      body: data,
    })
      .then((resp) => resp.json())
      .then((data) => {
        //alert(JSON.stringify(data));
        setUrl(data.secure_url);

        setUploadedFilename(data.original_filename.substring(0, 40));
        setUploadedFileFormat(data.format);

        //upload to server

        setIsUploadingCV(false);
        //updateApplicantCV({
        // "cv":data.secure_url
        //});
        postApplicantPostCV({
          cv: data.secure_url,
          tags: ["Web3"],
        });
        //cogoToast.success("CV Updated Successfully");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setUrl(
      (props.jobSeekerProfile &&
        props.jobSeekerProfile.cvs &&
        props.jobSeekerProfile.cvs.sort(
          (a, b) => parseFloat(b.cvid) - parseFloat(a.cvid)
        )[0] &&
        props.jobSeekerProfile.cvs.sort(
          (a, b) => parseFloat(b.cvid) - parseFloat(a.cvid)
        )[0].cv) ||
        ""
    );
  }, [props.jobSeekerProfile]);

  //TODO refactor if approved
  useEffect(() => {
    // alert(code)
      if(code !== null )
      {
        processGithubProfile();
      }
  }, [code])

  const processGithubProfile = async () => {
    try {
      const data = {
        code: code,
        redirect_uri: callbackUrl,
      };
      const response = await axios.post(
        `${github_access_token_url}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("token")?.replaceAll('"', "").replaceAll('"','').replaceAll('\\','')
          },
        }
      );
      console.log((` ${(response.data.data.split('&')[0].replace('access_token=',''))}`));
      let tokens = response.data.data.split('&')[0].replace('access_token=','');
      setGithubAccessToken(tokens); 
      await getUserGithubDetail(tokens); 
    } catch (error) {
      console.error(error);
    }
  }

  const getUserGithubDetail = async (tokens) => {
    try {
      console.log(`githubAccessToken== ${tokens}`)
      const data = null;
      const response = await axios.post(
        `https://api.github.com/user`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + tokens
          },
        }
      );
      console.log((` getUserGithubDetailresponse=== ${JSON.stringify(response) }`));
      let resp = response?.data;
      //@ts-ignore
      setGithubBio(resp?.bio);
      setGithubAvatar(resp?.avatar_url)
      setGithubName(resp?.name)
      setLocation(resp?.location)
      setGithubEmail(resp?.email)
      setGithubTwitter(resp?.twitter_username)
      getUserGithubUserRepos(resp?.login)
    } catch (error) {
      console.error(error);
    }
  }

   

  const getUserGithubUserRepos = async (username) => {
    try {
      const data = null;
      const response = await axios.get(
        `https://api.github.com/users/${username}/repos`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log((` getUserGithubUserRepos=== ${JSON.stringify(response) }`));
      let resp = response?.data;
      const result = resp.map(item => {
        return {
            name: item.name,
            description: item.description,
            language: item.language
        };
    });
    
    setGithubTags(result)
    console.log(`getUserGithubUserReposResult ${JSON.stringify(result)}`);
    const encodedPromptKeyWords = 'Create a resume summary based on the below json data. The resume should summarise the users experience, skills languages, strength and any other useful information. Make sure it strictly follows the json data provided : '+result; //querystring.escape(promptKeyWords);
    callGPT(encodedPromptKeyWords);

    } catch (error) {
      console.error(error);
    }
  }
  //TODO end refactor if approved

  const callGPT = async (encodedPromptKeyWords) =>{
  
    const myHeaders = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+process.env.REACT_APP_OPENAI_API_KEY,
    };

    const data = {
      model: 'text-davinci-003',
      prompt: encodedPromptKeyWords,
      temperature: 0,
      max_tokens: 3000,
    };

    try {
      const response = await axios.post('https://api.openai.com/v1/completions', data, {
        headers: myHeaders,
      });

      console.log(`callGPT=== ${JSON.stringify(response.data)} `);
      let choices = response.data.choices[0].text;
      setAIBio(choices);
    } catch (error) {
      console.error('Error:', error);
    }


  }

  const handleSuccess = () => {

  }

  const handleFailure = () => {

  }

  const handleLogin = () => {

  }
  return (
    <>

     
 
      <section className="text-gray-600 body-font lg:ml-60">
        <div className="container lg:px-5 lg:py-24 mx-auto flex flex-wrap items-centerx">
        
          <div className="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
            <div className="flex border-2  mt-8 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
              {/*  props.jobSeekerProfile &&  props.jobSeekerProfile.authUserProfile &&  */}
              <div className="flex-grow lg:w-1/6">
                <div className="lg:grid lg:grid-cols-2">
                  <div>
                    <img
                      alt="profileimage"
                      src={
                        (props.jobSeekerProfile &&
                          props.jobSeekerProfile.authUserProfile &&
                          props.jobSeekerProfile.authUserProfile.icon !==
                            "n/a" &&
                          props.jobSeekerProfile.authUserProfile.icon) ||
                        (code !== null ? githubAvatar : "https://dummyimage.com/104x104")
                      }
                      className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
                    />
                    <h2 className="text-gray-900 text-lg title-font font-medium mb-3x mt-3 DMSansBold text-soft-darkshy text-soft-gray">
                      {githubName || (props.jobSeekerProfile &&
                        props.jobSeekerProfile.authUserProfile &&
                        props.jobSeekerProfile.authUserProfile.name)}{" "}
                    </h2>
                    <p className="bee-text text-xs DMSansRegular">
                      {" "}
                      {props.jobSeekerProfile &&
                        props.jobSeekerProfile.authUserProfile &&
                        props.jobSeekerProfile.authUserProfile.tags}{" "}
                    </p>
                  </div>

                  <div className="lg:justify-self-end 	">
                    <button
                      className="nav-btn-custom DMSansBold  shadow text-xs p-10x"
                      onClick={() => {
                        props.setIsOnEditProfile(true);
                      }}
                    >
                      Edit Profile
                    </button> 

                  </div>
                </div>

                <div className="mt-5">
                  <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2x border-gray-200 bee-text text-xs DMSansRegular">
                    <svg
                      width="12"
                      height="20"
                      viewBox="0 0 18 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.5 9.00051C11.5 7.61924 10.3808 6.5 9.00051 6.5C7.61924 6.5 6.5 7.61924 6.5 9.00051C6.5 10.3808 7.61924 11.5 9.00051 11.5C10.3808 11.5 11.5 10.3808 11.5 9.00051Z"
                        stroke="#8083A3"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.99951 19.5C7.80104 19.5 1.5 14.3984 1.5 9.06329C1.5 4.88664 4.8571 1.5 8.99951 1.5C13.1419 1.5 16.5 4.88664 16.5 9.06329C16.5 14.3984 10.198 19.5 8.99951 19.5Z"
                        stroke="#8083A3"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>{" "}
                    &nbsp;
                    {location || (props.jobSeekerProfile &&
                      props.jobSeekerProfile.authUserProfile &&
                      props.jobSeekerProfile.authUserProfile.city)}
                  </span>
                </div>

                <div className="mt-2">
                  <span className=" color-light-green shadow-xs rounded px-3 py-3 text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none  pr-3 py-1 border-r-2x border-gray-200 bee-text   DMSansBold text-xs-small">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 5V21"
                        stroke="#43AA8B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M19 5V14"
                        stroke="#43AA8B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 4.99999C5.93464 4.08386 7.19124 3.57071 8.5 3.57071C9.80876 3.57071 11.0654 4.08386 12 4.99999C12.9346 5.91613 14.1912 6.42928 15.5 6.42928C16.8088 6.42928 18.0654 5.91613 19 4.99999"
                        stroke="#43AA8B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 14C5.93464 13.0839 7.19124 12.5707 8.5 12.5707C9.80876 12.5707 11.0654 13.0839 12 14C12.9346 14.9161 14.1912 15.4293 15.5 15.4293C16.8088 15.4293 18.0654 14.9161 19 14"
                        stroke="#43AA8B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    &nbsp; OPEN FOR OPPORTUNITIES
                  </span>
                </div>

                <div className="mt-5">
                  {/* <button 
                onClick={()=>{
                  
                }}
                className="nav-btn-custom-orange DMSansBold  text-white rounded-lg shadow text-xs p-10x">
                Upload my CV </button>*/}
                  {isUploadingCV ? (
                    <div className="text-center">
                      <ClipLoader color={"#F1B729"} loading={true} size={50} />
                    </div>
                  ) : (
                    <div>
                      <div className="mt-1">
                        {url !== "" && (
                          <>
                            <button
                              onClick={() => {
                                setIsPaneOpen(true);
                              }}
                              className="text-orange  text-xs underline mt-1 "
                            >
                              {` ${
                                uploadedfilename ||
                                (url &&
                                  url
                                    .replace("https://cloudinary.com", "")
                                    .replace("https://res.cloudinary.com", "")
                                    .replace("undefined.", ""))
                              }.${uploadedfileformat || ""}`}
                            </button>
                            <a
                              href="#"
                              onClick={() => {
                                fileUploadRef.current?.click();
                              }}
                              className="text-orangex text-xs underlinex ml-3 mt-1"
                            >
                              (Change portfolio)
                            </a>
                          </>
                        )}
                      </div>

                      <input
                        type="file"
                        className={` ${
                          url !== "" && "hide-fileuploaded"
                        } custom-file-input`}
                        onChange={onChangeFileUpload}
                        ref={fileUploadRef}
                      />
                      {/*
                      <button 
                        className={` ${ url !=="" && 'hide-fileuploaded'} custom-file-input`}
                        onClick={()=>{setShowFileUploadPopup(true)}}></button>
                      */}
                    </div>
                  )}
                </div>
              </div>
              {/* */}
            </div>

            <div className="flex border-2 mt-10 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
              <div className="flex-grow lg:w-1/6">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3 DMSansBold text-soft-darkshy">
                  About Me
                </h2>
                <p className="leading-relaxed text-base desc-color DMSansRegular">
                  {props.jobSeekerProfile &&
                    props.jobSeekerProfile.authUserProfile &&
                    props.jobSeekerProfile.authUserProfile.bio}
                </p>

                <p className="leading-relaxed text-base desc-color DMSansRegular">
                    {githubBio}
                </p>

                <p className="leading-relaxed text-base desc-color DMSansRegular">
                {aiBio}
                </p>
              </div>
            </div>
          </div>

          <div className="lg:w-2/6 md:w-1/2 bg-gray-100x rounded-lg  lg:p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
            <div className="flexx border-2  rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3 text-center">
                Contact
              </h2>
              <div className="flex items-center lg:w-3/5x mx-autox border-bx pb-10x mb-10x border-gray-200 sm:flex-row flex-col">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 8L10.89 13.26C11.2187 13.4793 11.6049 13.5963 12 13.5963C12.3951 13.5963 12.7813 13.4793 13.11 13.26L21 8M5 19H19C19.5304 19 20.0391 18.7893 20.4142 18.4142C20.7893 18.0391 21 17.5304 21 17V7C21 6.46957 20.7893 5.96086 20.4142 5.58579C20.0391 5.21071 19.5304 5 19 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V17C3 17.5304 3.21071 18.0391 3.58579 18.4142C3.96086 18.7893 4.46957 19 5 19Z"
                    stroke="#8083A3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div className="flex-grow sm:text-left text-center mt-6x ml-2 sm:mt-0">
                  <p className="leading-relaxed text-base bee-text DMSansRegular mt-7 ">
                    Email
                  </p>
                  <p className="leading-relaxed text-base bee-textx  DMSansBold text-sm text-bee-orange ">
                    { (props.jobSeekerProfile &&
                      props.jobSeekerProfile.authUserProfile &&
                      props.jobSeekerProfile.authUserProfile.email) ||
                      ("...")}
                  </p>
                  <p className="leading-relaxed text-base bee-textx  DMSansBold text-sm text-bee-orange ">
                  {githubEmail}
                  </p>
                </div>
              </div>

              <div className="flex items-center lg:w-3/5x mx-autox border-bx pb-10x mb-10x border-gray-200 sm:flex-row flex-col">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 4H8C5.79086 4 4 5.79086 4 8V16C4 18.2091 5.79086 20 8 20H16C18.2091 20 20 18.2091 20 16V8C20 5.79086 18.2091 4 16 4Z"
                    stroke="#8083A3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                    stroke="#8083A3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.5 7.5V7.501"
                    stroke="#8083A3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div className="flex-grow sm:text-left text-center mt-6x ml-2 sm:mt-0">
                  <p className="leading-relaxed text-base bee-text DMSansRegular mt-7 ">
                    Instagram
                  </p>
                  <a
                    target="_blank"
                    href={`${
                      (props.jobSeekerProfile &&
                        props.jobSeekerProfile.authUserProfile &&
                        props.jobSeekerProfile.authUserProfile.instagram &&
                        props.jobSeekerProfile.authUserProfile.instagram.replace(
                          "@",
                          ""
                        )) ||
                      "..."
                    }`}
                    className="leading-relaxed text-base bee-textx  DMSansBold text-sm text-bee-orange "
                  >
                    {(props.jobSeekerProfile &&
                      props.jobSeekerProfile.authUserProfile &&
                      props.jobSeekerProfile.authUserProfile.instagram) ||
                      "..."}
                  </a>
                </div>
              </div>

              <div className="flex items-center lg:w-3/5x mx-autox border-bx pb-10x mb-10x border-gray-200 sm:flex-row flex-col">
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 2.8C19.2483 3.12609 18.4534 3.34166 17.64 3.44C18.4982 2.92732 19.1413 2.12078 19.45 1.17C18.6436 1.65006 17.7608 1.9883 16.84 2.17C16.2245 1.50257 15.405 1.0583 14.5098 0.906851C13.6147 0.755406 12.6945 0.905358 11.8938 1.33319C11.093 1.76102 10.4569 2.44253 10.0852 3.27084C9.71355 4.09914 9.62729 5.02739 9.84 5.91C8.20943 5.82753 6.61444 5.40295 5.15865 4.66386C3.70287 3.92477 2.41885 2.88769 1.39 1.62C1.02914 2.25017 0.839519 2.96382 0.84 3.69C0.83872 4.36438 1.00422 5.02862 1.32176 5.62356C1.63929 6.21851 2.09902 6.72571 2.66 7.1C2.00798 7.08226 1.36989 6.9073 0.8 6.59V6.64C0.804887 7.58489 1.13599 8.49909 1.73731 9.22797C2.33864 9.95684 3.17326 10.4556 4.1 10.64C3.74326 10.7486 3.37288 10.8058 3 10.81C2.74189 10.807 2.48442 10.7836 2.23 10.74C2.49391 11.5528 3.00462 12.2631 3.69107 12.7722C4.37753 13.2812 5.20558 13.5636 6.06 13.58C4.6172 14.7153 2.83588 15.3349 1 15.34C0.665735 15.3411 0.331736 15.3211 0 15.28C1.87443 16.4903 4.05881 17.1327 6.29 17.13C7.82969 17.146 9.35714 16.855 10.7831 16.2741C12.2091 15.6931 13.505 14.8339 14.5952 13.7465C15.6854 12.6591 16.548 11.3654 17.1326 9.94091C17.7172 8.51642 18.012 6.98973 18 5.45C18 5.28 18 5.1 18 4.92C18.7847 4.33481 19.4615 3.61742 20 2.8Z"
                    fill="#8083A3"
                  />
                </svg>

                <div className="flex-grow sm:text-left text-center mt-6x ml-2 sm:mt-0">
                  <p className="leading-relaxed text-base bee-text DMSansRegular mt-7 ">
                    Twitter
                  </p>
                  <a
                    target="_blank"
                    href={`${
                      (props.jobSeekerProfile &&
                        props.jobSeekerProfile.authUserProfile &&
                        props.jobSeekerProfile.authUserProfile.twitter &&
                        props.jobSeekerProfile.authUserProfile.twitter.replace(
                          "@",
                          ""
                        )) ||
                      "..."
                    }`}
                    className="leading-relaxed text-base bee-textx  DMSansBold text-sm text-bee-orange "
                  >
                    {(props.jobSeekerProfile &&
                      props.jobSeekerProfile.authUserProfile &&
                      props.jobSeekerProfile.authUserProfile.twitter) ||
                      githubTwitter}
                  </a>
                </div>
              </div>
              <div className="flex items-center lg:w-3/5x mx-autox border-bx pb-10x mb-10x border-gray-200 sm:flex-row flex-col">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                    stroke="#8083A3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.60001 9H20.4"
                    stroke="#8083A3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.60001 15H20.4"
                    stroke="#8083A3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.5 3C9.81535 5.69961 8.92221 8.81787 8.92221 12C8.92221 15.1821 9.81535 18.3004 11.5 21"
                    stroke="#8083A3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.5 3C14.1847 5.69961 15.0778 8.81787 15.0778 12C15.0778 15.1821 14.1847 18.3004 12.5 21"
                    stroke="#8083A3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div className="flex-grow sm:text-left text-center mt-6x ml-2 sm:mt-0">
                  <p className="leading-relaxed text-base bee-text DMSansRegular mt-7 ">
                    Website
                  </p>
                  <a
                    target="_blank"
                    href={
                      (props.jobSeekerProfile &&
                        props.jobSeekerProfile.authUserProfile &&
                        props.jobSeekerProfile.authUserProfile.website) ||
                      "..."
                    }
                    className="leading-relaxed text-base bee-textx  DMSansBold text-sm text-bee-orange "
                  >
                    {(props.jobSeekerProfile &&
                      props.jobSeekerProfile.authUserProfile &&
                      props.jobSeekerProfile.authUserProfile.website) ||
                      "..."}
                  </a>
                </div>

 

                
              </div>



              
            </div>

            {/*Job Tags*/}

            <div className="flexx border-2 mt-10 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Job tags
              </h2>
              {props.jobSeekerProfile &&
                props.jobSeekerProfile.authUserProfile &&
                props.jobSeekerProfile.authUserProfile.address !== "n/a" &&
                props.jobSeekerProfile.authUserProfile.address !== "0" &&
                props.jobSeekerProfile.authUserProfile.address &&
                props.jobSeekerProfile.authUserProfile.address.length > 0 &&
                JSON.parse(props.jobSeekerProfile.authUserProfile.address).map(
                  (item, index) => {
                    return (
                      <div>
                        <TagComponent title={item} />
                      </div>
                    );
                  }
                )}

                {
                  //@ts-ignore
                  githubTags.map( (item, index)=>{
                    return(
                      <div>
                        {/** @ts-ignore */}
                      <TagComponent title={item.language} />
                    </div>
                    )
                  })
                }
            </div>
            {/*Job Tags*/}

            {/*Change Password*/}

            <div className="flexx border-2 mt-10 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Update Password
              </h2>
              <button
                className="nav-btn-custom DMSansBold  shadow text-xs p-10x"
                onClick={() => {
                  setChangePasswordModal(true);
                }}
              >
                Change Password
              </button>
            </div>
            {/*Change Password*/}

            {changePasswordModal && (
              <ChangePasswordModal closeModal={setChangePasswordModal} />
            )}
          </div>
        </div>
      </section>

      <SlidingPane
        className="some-custom-class"
        overlayClassName="some-custom-overlay-class"
        isOpen={isPaneOpen}
        hideHeader={true}
        title="Hey, it is optional pane title.  I can be React component too."
        subtitle="Optional subtitle."
        onRequestClose={() => {
          setIsPaneOpen(false);
        }}
      >
        <div className="mt-10">
          <button
            onClick={() => {
              setIsPaneOpen(false);
            }}
            type="button"
            className=" ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Close CV Preview
          </button>

          <iframe
            src={url && url.replaceAll('"', "")}
            height="700"
            width="100%"
            className="mt-5"
            title="description"
          ></iframe>
        </div>
      </SlidingPane>

      { (code == null) &&
      <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 mr-4 sm:pb-2">
                  <div className="sm:flexc sm:items-startc">
                    <h1 className="text-2xl  ArchivoBlack">Auto Generate your Resume profile</h1>
                    {/* Solana and */}
                    <p>
                      Auto Generate your Resume/Profile from your Github profile
                    </p>
                  
                     <div className="flex" style={{marginTop: '5%'}}>
                       <div className="text-centerx w-2/6">
                       <a
                       className="login-link connect-github-link"
                          href={`https://github.com/login/oauth/authorize?scope=user&client_id=${clientId}&redirect_uri=${redirectUri}`}
                          onClick={() => {
                            //setData({ ...data, errorMessage: "" });
                          }}
                        >
                       <img src={githubLogo} style={{height: 100, width: 100}} />
                       {/* <p className="ArchivoBlack bee-text">Github</p> */}
                       </a>
                       </div>
                       
                    

                       <div className="text-centerx w-2/6">
                       {/* <img src={solanaLogo} style={{height: 100, width: 100}}/> */}
                       {/* <p className="ArchivoBlack bee-text" >Solana</p> */}
                       </div>
                    
                     </div>
                    {/* 
                    <div style={{marginTop: '5%'}}>
                    <div className="flex">
                    <input type="text" className="border rounded-l-md p-2" placeholder="Enter text..." />
                    <button className="bg-bee-orange text-white rounded-r-md p-2">Connect</button>
                   </div>
                      </div> */}


                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  {/* <button
                    onClick={() => {
                    
                    }}
                    type="button"
                    className="ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    
                  </button> */}
                  <button
                    onClick={() => {
                   
                    }}
                    type="button"
                    className=" ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Not Intrested
                  </button>

                  
                </div>
              </div>
            </div>
          </div>
      </div>
        }
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, jobs_application_details } = state.auth;
  return { error, loading, jobs_application_details };
};
export default connect(mapStateToProps, {
  updateApplicantCV,
  postApplicantPostCV,
})(ProfileDetails);
