import { call, put, takeEvery } from "redux-saga/effects";
import cogoToast from "cogo-toast";

//services
import jobServices from "api/services/jobs";

//redux
import {
  JOB_LISTING,
  JOB_SUCCESS,
  JOB_LISTING_DETAILS,
  JOB_POST,
  JOB_LISTING_EMPLOYER,
  JOB_APPLICATION_POST,
  SUBSCRIPTION_LISTING_STATUS,
  JOB_APPLICATION_POST_SUCCESS,
  JOB_ALL_APPLICANTS,
  SEARCH_JOB_LISTING,
  JOB_MY_APPLICATION,
  UPDATE_JOB_APPLICATION,
  UPDATE_HIRING_STAGE,
  UPDATE_APPLICANT_CV,
  JOB_POST_APPLICANT_CV,
  JOB_POST_ADMIN_CV,
  GET_APPLICANT_CV,
  SUBSCRIBE_CV,
  GET_CV_STATUS,
  POST_JOB_OFFER_ADMIN,
  POST_JOB_UPDATE_OFFER_ADMIN,
  CV_DOWNLOAD,
  JOB_ALL_APPLICANTS_ADMIN
} from "./constants";
import {
  jobListingSuccess,
  postJobOfferSuccess,
  postJobOfferAdminSuccess,
  postAdminUpdateJobOfferSuccess,
  fetchEmployerJobListingSuccess,
  jobListingDetailsSuccess,
  apiError2,
  postJobApplicationSuccess,
  fetchJobAllApplicantSuccess,
  fetchSearchJobListingSuccess,
  fetchApplicationJobAppliedSuccess,
  updateApplicantCVSuccess,
  postApplicantPostCVSuccess,
  postAdminPostCVSuccess,
  getApplicantCVSuccess,
  postCvSubscribeSuccess,
  getCvStatusSuccess,
  fetchSubscriptionListingStatusSuccess,
  fetchCVDownloadsSuccess,
} from "./actions";
import { ResponseGenerator } from "store/type";

function* fetchJobListing({ payload }: any) {
  try {
    const { user } = payload;
    const response: ResponseGenerator = yield call(jobServices.fetchjobs, user);
    if (response.data) {
      yield put(fetchEmployerJobListingSuccess(response.data));
    }
  } catch (error) {
    const message = "Error occured";
    //yield put(apiError(message));
  }
}

function* fetchSubscriptionListingStatus({ payload }: any) {
  try {
    const { user } = payload;
    const response: ResponseGenerator = yield call(
      jobServices.fetchSubscriptionListingStatus,
      user
    );
    if (response.data) {
      yield put(fetchSubscriptionListingStatusSuccess(response.data));
    }
  } catch (error) {
    const message = "Error occured";
    //yield put(apiError(message));
  }
}

function* fetchApplicationJobApplied({ payload }: any) {
  try {
    const { user } = payload;
    const response: ResponseGenerator = yield call(
      jobServices.fetchApplicationJobApplied,
      user
    );
    if (response.data) {
      yield put(fetchApplicationJobAppliedSuccess(response.data));
    }
  } catch (error) {
    const message = "Error occured";
  }
}

function* fetchEmployerJobListing({ payload }: any) {
  try {
    const { user } = payload;

    const response: ResponseGenerator = yield call(
      jobServices.fetchEmployerJobListing,
      user
    );

    if (response.data) {
      yield put(jobListingSuccess(response.data));
    }
  } catch (error) {}
}

function* fetchCVDownloads({ payload }: any) {
  try {
    const { user } = payload;

    const response: ResponseGenerator = yield call(
      jobServices.fetchCVDownloads,
      user
    );

    if (response.data) {
      yield put(fetchCVDownloadsSuccess(response.data));
    }
  } catch (error) {}
}

function* fetchJobAllApplicant({ payload }: any) {
  try {
    const { user } = payload;

    const response: ResponseGenerator = yield call(
      jobServices.fetchJobAllApplicant,
      user
    );

    if (response.data) {
      yield put(fetchJobAllApplicantSuccess(response.data));
    }
  } catch (error) {}
}

function* fetchJobAllApplicantAdmin({ payload }: any) {
  try {
    const { data } = payload;

    const response: ResponseGenerator = yield call(
      jobServices.fetchJobAllApplicantAdmin,
      data
    );

    if (response.data) {
      yield put(fetchJobAllApplicantSuccess(response.data));
    }
  } catch (error) {}
}

 

function* postJobOffer({ payload }: any) {
  try {
    const { data } = payload;

    const response: ResponseGenerator = yield call(
      jobServices.postJobOffer,
      data
    );

    if (response.data.success) {
      yield put(postJobOfferSuccess(response.data));

      if (response.data) {
        cogoToast
          .success(
            "Congratulations! Your Job Offer has been Posted Successfully"
          )
          .then(() => {
            window.location.href = "/company/job-listing";
          });
      }
    }
  } catch (error) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";

    yield put(apiError2(message));
    cogoToast.error("Error occured while Posting Job. Please try again");
  }
}

function* postJobOfferAdmin({ payload }: any) {
    try {
    const { data } = payload;
    const response: ResponseGenerator = yield call(
      jobServices.postJobOfferAdmin,
      data
    );
    if (response.data.success) {
      yield put(postJobOfferAdminSuccess(response.data));
      if (response.data) {
        cogoToast
          .success(
            "Congratulations! Your Job Offer has been Posted Successfully"
          )
          .then(() => {
            window.location.href = "/admin/jobs";
          });
      }
    }
  } catch (error) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";

    yield put(apiError2(message));
   // cogoToast.error("Error occured while Posting Job. Please try again");
  }
}

function* postAdminUpdateJobOffer({ payload }: any) {
  try {
  const { data } = payload;
  const response: ResponseGenerator = yield call(
    jobServices.postAdminUpdateJobOffer,
    data
  );
  if (response.data.success) {
    yield put(postAdminUpdateJobOfferSuccess(response.data));
    if (response.data) {
      cogoToast
        .success(
          "Congratulations! Your Job Offer has been Updated Successfully"
        )
        .then(() => {
          window.location.href = "/admin/jobs";
        });
    }
  }
} catch (error) {
  const message =
    error["response"]["data"]["message"] || error.message || "network error";

  yield put(apiError2(message));
  cogoToast.error("Error occured while Updating Job. Please try again");
}
}

function* postApplicantPostCV({ payload }: any) {
  try {
    const { data } = payload;

    const response: ResponseGenerator = yield call(
      jobServices.postApplicantPostCV,
      data
    );
    if (response.data.success) {
      yield put(postApplicantPostCVSuccess(response.data));
      if (response.data) {
        cogoToast.success("CV Uploaded successfully").then(() => {
          //window.location.href="/company/job-listing";
        });
      }
    }
  } catch (error) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    yield put(apiError2(message));
    cogoToast.error("Error occurred while uploading document");
  }
}

function* postAdminPostCV({ payload }: any) {
  try {
    const { data } = payload;

    const response: ResponseGenerator = yield call(
      jobServices.postAdminPostCV,
      data
    );
    if (response.data.success) {
      yield put(postAdminPostCVSuccess(response.data));
      if (response.data) {
      //  cogoToast.success("CV Uploaded successfully").then(() => {
          window.location.href="/admin/cvs";
       // });
      }
    }
  } catch (error) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    yield put(apiError2(message));
    cogoToast.error("Error occurred while uploading document");
  }
}

function* postCvSubscribe({ payload }: any) {
  try {
    const { data } = payload;

    const response: ResponseGenerator = yield call(
      jobServices.postCvSubscribe,
      data
    );
    if (response.data.success) {
      yield put(postCvSubscribeSuccess(response.data));
      if (response.data) {
        cogoToast.success("Subscription Successful").then(() => {
          window.location.href = "/";
        });
      }
    }
  } catch (error) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    yield put(apiError2(message));
    cogoToast.error("Payment error occurred. Please try again");
  }
}

function* postJobApplication({ payload }: any) {
  try {
    const { data } = payload;

    const response: ResponseGenerator = yield call(
      jobServices.postJobApplication,
      data
    );

    if (response.data.success) {
      yield put(postJobApplicationSuccess(response.data));
      if (response.data) {
        cogoToast
          .success("Congratulations! You have successfully Applied")
          .then(() => {
            window.location.href = "/jobseeker/myapplication";
          });
      }
    }
  } catch (error) {
    const message =
      error["response"]["data"]["message"] || error.message || "network error";
    yield put(apiError2(message));
    cogoToast.error(
      "Error occured while Applying to Job Post. Please try again"
    );
  }
}

function* updateApplicantCV({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(
      jobServices.updateApplicantCV,
      payload
    );
    if (response.data) {
      yield put(updateApplicantCVSuccess(response.data));
      cogoToast.success("CV Updated Successfully").then(() => {
        window.location.href = "/jobseeker/profile";
      });
    }
  } catch (error) {
    const message = "error";
    // error["response"]["data"]["message"] || error.message || "network error";
    //yield put(apiError(message));
  }
}

function* updateJobApplicationDetails({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(
      jobServices.updateJobApplication,
      payload
    );
    if (response.data) {
      yield put(jobListingDetailsSuccess(response.data));

      cogoToast.success("Stage Updated Successfully").then(() => {
        window.location.href = "/company/all-applicants";
      });
    }
  } catch (error) {
    const message = "error";
    // error["response"]["data"]["message"] || error.message || "network error";
    //yield put(apiError(message));
  }
}

function* fetchJobListingDetails({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(
      jobServices.fetchjobsDetails,
      payload
    );
    if (response.data) {
      yield put(jobListingDetailsSuccess(response.data));
    }
  } catch (error) {
    const message = "error";
    // error["response"]["data"]["message"] || error.message || "network error";
    //yield put(apiError(message));
  }
}

function* getCvStatus({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(
      jobServices.getCvStatus,
      payload
    );
    if (response.data) {
      yield put(getCvStatusSuccess(response.data));
    }
  } catch (error) {
    const message = "error";
    // error["response"]["data"]["message"] || error.message || "network error";
    //yield put(apiError(message));
  }
}

function* getApplicantCV({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(
      jobServices.getApplicantCV,
      payload
    );
    if (response.data) {
      yield put(getApplicantCVSuccess(response.data));
    }
  } catch (error) {
    const message = "error";
    // error["response"]["data"]["message"] || error.message || "network error";
    //yield put(apiError(message));
  }
}

function* fetchSearchJobListing({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(
      jobServices.fetchsearchJobListing,
      payload
    );
    if (response.data) {
      yield put(fetchSearchJobListingSuccess(response.data));
    }
  } catch (error) {
    const message = "error";
  }
}

function* jobSaga() {
  yield takeEvery(JOB_LISTING, fetchJobListing);
  yield takeEvery(JOB_POST, postJobOffer);
  yield takeEvery(POST_JOB_OFFER_ADMIN, postJobOfferAdmin);
  yield takeEvery(POST_JOB_UPDATE_OFFER_ADMIN, postAdminUpdateJobOffer);
  yield takeEvery(JOB_LISTING_DETAILS, fetchJobListingDetails);
  yield takeEvery(JOB_LISTING_EMPLOYER, fetchEmployerJobListing);
  yield takeEvery(JOB_APPLICATION_POST, postJobApplication);
  yield takeEvery(JOB_ALL_APPLICANTS, fetchJobAllApplicant);
  yield takeEvery(JOB_ALL_APPLICANTS_ADMIN, fetchJobAllApplicantAdmin);
  yield takeEvery(SEARCH_JOB_LISTING, fetchSearchJobListing);
  yield takeEvery(JOB_MY_APPLICATION, fetchApplicationJobApplied);
  yield takeEvery(UPDATE_HIRING_STAGE, updateJobApplicationDetails);
  yield takeEvery(UPDATE_APPLICANT_CV, updateApplicantCV);
  yield takeEvery(JOB_POST_APPLICANT_CV, postApplicantPostCV);
  yield takeEvery(JOB_POST_ADMIN_CV, postAdminPostCV);

  yield takeEvery(SUBSCRIBE_CV, postCvSubscribe);
  yield takeEvery(GET_APPLICANT_CV, getApplicantCV);
  yield takeEvery(GET_CV_STATUS, getCvStatus);
  yield takeEvery(SUBSCRIPTION_LISTING_STATUS, fetchSubscriptionListingStatus);
  yield takeEvery(CV_DOWNLOAD, fetchCVDownloads);


   
}
export default jobSaga;
