import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import CryptoWallet from "./cryptoWallets";
import GoogleLogin from "./GoogleLogin";
//redux
import { registerUser } from "store/actions";

const SignUp = (props) => {
  const { registerUser, error, loading, user_data } = props;
  const [showWalletConnectOptions, setShowWalletConnectOptions] =
    useState(false);
  const [email, setEmail] = useState();
  const [fullname, setFullname] = useState();
  const [password, setPassword] = useState();
  const [userType, setUserType] = useState("applicant");
  const [account, setAccount] = useState();
  const responseGoogleSignUp = (response) => {
   // console.log(response);
  };
  return (
    <>
      <div className="flex space-x-2 justify-center mb-10">
        <button
          type="button"
          onClick={() => {
            setUserType("applicant");
          }}
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className={` DMSansBold ${
            userType == "applicant" ? "activated-gray DMSansBold" : ""
          } inline-block border-blacked  px-6 py-2.5 bg-white-600 text-black font-medium text-xs leading-tight uppercasex rounded shadow-mdx   hover:shadow-lg focus:bg-white-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out text-soft-gray`}
        >
          User
        </button>
        {/* <button
            type="button"
            onClick={()=>{setUserType("student")}}
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className={`DMSansBold ${userType == "student" ? 'activated-gray DMSansBold':''} inline-block border-blacked  px-6 py-2.5 bg-white-600 text-black font-medium text-xs leading-tight uppercasex rounded shadow-mdx   hover:shadow-lg focus:bg-white-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out text-soft-gray`}
          >Student</button>*/}
        <button
          type="button"
          onClick={() => {
            setUserType("employer");
          }}
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className={`DMSansBold ${
            userType == "employer" ? "activated-gray DMSansBold" : ""
          } inline-block px-6 border-blacked py-2.5 bg-white-600 text-black font-medium text-xs leading-tight uppercasex rounded shadow-mdx  hover:shadow-lg focus:bg-white-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out text-soft-gray`}
        >
          Company
        </button>
      </div>
      <p className="focus:outline-none text-3xl font-extrabold leading-6 text-center text-gray-800 ArchivoBlack">
        Get more opportunities{" "}
      </p>
      {userType === "applicant" && (
        <div className="grid grid-cols-2	">
          <GoogleLogin />
          {
          account ? (
            <button
              aria-label="Disconnect Wallet"
              onClick={() => {
                // deactivate()
              }}
              role="button"
              className="focus:outline-none content-center	justify-center focus:ring-2 focus:ring-offset-1 focus:ring-gray-700 py-3.5 px-4 border rounded-lg border-gray-200 flex items-center  w-1/10 mt-10 ml-4"
            >
              <p className="text-base font-small ml-4x font-bold text-sm-11  DMSansBold text-gray-700 ">
                Disconnect Wallet
              </p>
            </button>
          ) : (
            <button
              aria-label="Connect Wallet"
              onClick={() => {
                setShowWalletConnectOptions(true);
                //activate(injected)
              }}
              role="button"
              className="focus:outline-none content-center	justify-center focus:ring-2 focus:ring-offset-1 focus:ring-gray-700 py-3.5 px-4 border rounded-lg border-gray-00 flex items-center  w-1/10 mt-10 ml-4"
            >
              <p className="text-base font-small ml-4x font-bold text-sm-11  DMSansBold text-gray-700 ">
                Connect Wallet
              </p>
            </button>
          )}
        </div>
      )}
      <div className="w-full flex items-center justify-between py-5 mt-5">
        <hr className="w-1/4 bg-gray-400" />
        <p className="lg:text-base  text-sm font-mediumx leading-4 text-bold px-2.5 text-gray-400 DMSansRegular text-soft-gray">
          Sign up with email
        </p>
        <hr className="w-1/4 bg-gray-400  " />
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: email,
          fullname: fullname,
          password: password,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().required("Email Address required "),
          fullname: Yup.string().required(
            userType == "employer"
              ? "Company name required"
              : "Full Name required"
          ),

          password: Yup.string()
            .required("Password required")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
              "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
        })}
        onSubmit={(values, { resetForm }) => {
          let data = {
            name: values.fullname || "",
            role: userType,
            bio: "n/a",
            address: "n/a",
            headline: "n/a",
            phone: "n/a",
            currentlyEmployed: 0,
            dateOfBirth: "1995/04/19", //for now.
            industry: "na",
            city: "na",
            email: values.email || "",
            password: values.password || "",
            icon: "n/a",
            typeOfEmployment: "null",
          };
          registerUser(data);
          /*
          setShowThankYou(true)
          */
        }}
      >
        {({ errors, status, touched }) => (
          <Form>
            <div>
              <label
                id="fullname"
                className="text-sm font-small leading-none DMSansBold  text-soft-gray"
              >
                {userType == "employer" ? "Company Name" : "Full name"}
              </label>

              <Field
                name="fullname"
                type="text"
                className={
                  "bg-white-200 border rounded border-black-200-200 text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2" +
                  (errors.fullname && touched.fullname ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="fullname"
                component="div"
                className="invalid-feedback"
              />
            </div>
            
            <div className="mt-6  w-full">
              <label
                id="email"
                className="text-sm font-small leading-none DMSansBold  text-soft-gray"
              >
                Email Address
              </label>
              <Field
                name="email"
                type="text"
                className={
                  "bg-white-200 border rounded border-black-200 text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2" +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="mt-6  w-full">
              <label className="text-sm font-small DMSansBold  text-soft-gray">
                Password
              </label>
              <div className="input-group auth-pass-inputgroup">
                <Field
                  name="password"
                  type="password"
                  autoComplete="true"
                  className={
                    "bg-white-200 border rounded border-black-200 text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2" +
                    (errors.password && touched.password ? " is-invalid" : "")
                  }
                />
                <button
                  className="btn btn-light "
                  type="button"
                  id="password-addon"
                >
                  <i className="mdi mdi-eye-outline"></i>
                </button>
              </div>
              <ErrorMessage
                name="password"
                component="div"
                className="invalid-feedback"
              />

              {/*<div className="relativex flex items-center justify-center">
                    
                    
                <Field
                     id="pass"
                      name="password"
                      type="text"
                      className={
                      "bg-white-200 border rounded border-black-200 text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2" +
                      (errors.password && touched.password
                      ? " is-invalid"
                      : "")
                      }
                      />
                      <ErrorMessage
                       name="password"
                       component="div"
                       className="invalid-feedback"
                /> */}

              {/*<div className="absolute right-0 mt-2 mr-3 cursor-pointer">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.99978 2C11.5944 2 14.5851 4.58667 15.2124 8C14.5858 11.4133 11.5944 14 7.99978 14C4.40511 14 1.41444 11.4133 0.787109 8C1.41378 4.58667 4.40511 2 7.99978 2ZM7.99978 12.6667C9.35942 12.6664 10.6787 12.2045 11.7417 11.3568C12.8047 10.509 13.5484 9.32552 13.8511 8C13.5473 6.67554 12.8031 5.49334 11.7402 4.64668C10.6773 3.80003 9.35864 3.33902 7.99978 3.33902C6.64091 3.33902 5.32224 3.80003 4.25936 4.64668C3.19648 5.49334 2.45229 6.67554 2.14844 8C2.45117 9.32552 3.19489 10.509 4.25787 11.3568C5.32085 12.2045 6.64013 12.6664 7.99978 12.6667ZM7.99978 11C7.20413 11 6.44106 10.6839 5.87846 10.1213C5.31585 9.55871 4.99978 8.79565 4.99978 8C4.99978 7.20435 5.31585 6.44129 5.87846 5.87868C6.44106 5.31607 7.20413 5 7.99978 5C8.79543 5 9.55849 5.31607 10.1211 5.87868C10.6837 6.44129 10.9998 7.20435 10.9998 8C10.9998 8.79565 10.6837 9.55871 10.1211 10.1213C9.55849 10.6839 8.79543 11 7.99978 11ZM7.99978 9.66667C8.4418 9.66667 8.86573 9.49107 9.17829 9.17851C9.49085 8.86595 9.66644 8.44203 9.66644 8C9.66644 7.55797 9.49085 7.13405 9.17829 6.82149C8.86573 6.50893 8.4418 6.33333 7.99978 6.33333C7.55775 6.33333 7.13383 6.50893 6.82126 6.82149C6.5087 7.13405 6.33311 7.55797 6.33311 8C6.33311 8.44203 6.5087 8.86595 6.82126 9.17851C7.13383 9.49107 7.55775 9.66667 7.99978 9.66667Z" fill="#71717A"/>
                            </svg>
                            
                    </div>
                    </div> */}
            </div>

            <div className="mt-8">
              {loading ? (
                <div className="text-center">
                  <ClipLoader color={"#F1B729"} loading={true} size={50} />
                </div>
              ) : (
                <button
                  role="button"
                  type="submit"
                  className="focus:ring-2 focus:ring-offset-2  DMSansBold  text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded bg-bee-orange py-4 w-full"
                >
                  Continue
                </button>
              )}
            </div>
            
          </Form>
        )}
      </Formik>

      <p className="py-5   bee-text text-sm DMSansRegular">
        By clicking 'Continue', you acknowledge that you have read and accept
        the
        <a href="#">
          <span className="text-orange DMSansBold "> Terms of Service</span>{" "}
        </a>
        and{" "}
        <a href="#">
          <span className="text-orange DMSansBold "> Privacy Policy</span>
        </a>
        .
      </p>

      {showWalletConnectOptions && (
        <CryptoWallet
          setShowWalletConnectOptions={setShowWalletConnectOptions}
          showWalletConnectOptions={showWalletConnectOptions}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, user_data } = state.auth;
  return { error, loading, user_data };
};

export default connect(mapStateToProps, { registerUser })(SignUp);
