import React, { useState, useEffect } from "react";
import Logo from "assets/images/logo.svg";
import MenuItem from "./menuItems";
import MenuItemCompany from "./menuItemsCompany";

const SideBar = (props: any) => {
  const [currentPathName, setCurrentPathName] = useState(
    window.location.pathname
  );
  const [userIcon, setUserIcon] = useState("");
  const [userType, setUserType] = useState("");

  useEffect(() => {
    let usericon = localStorage.getItem("usericon");
    let itemtoken = localStorage.getItem("AuthData");
    if (usericon && usericon !== null) {
      setUserIcon(usericon.replaceAll('"', ""));
    }
  }, []);

  return (
    <>
      <aside className="aside sidebar-bg is-placed-left is-expanded">
        <div className="aside-tools">
          <div>
            <a
              href="/"
              className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <img src={Logo} className="" />
            </a>
          </div>
        </div>
        <div className="menu is-menu-main">
          <MenuItem />
        </div>
      </aside>
    </>
  );
};

export default SideBar;
