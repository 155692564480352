import StageComponent from "components/stageComponent";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

//redux
import { updateJobApplicationDetails } from "store/actions";

const BackActionComponent = (props) => {
  const {
    updateJobApplicationDetails,
    error,
    loading,
    jobs_application_details,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const processUpdateStage = () => {
    setIsLoading(true);
    let data = {
      hiring_stage: valueHiringStage,
      apid: props.uiddata.apid,
    };
    updateJobApplicationDetails(data);
  };

  const [showHiringStageSelect, setShowHiringStageSelect] = useState(false);
  const [valueHiringStage, setValueHiringStage] = useState();

  const handleChange = (e) => {
    setValueHiringStage(e.target.value);
  };
  return (
    <>
      <div className="mt-1 mb-0">
        <button
          onClick={() => {
            props.setShowApplicationDetails(false);
          }}
        >
          <svg
            width="20"
            height="16"
            style={{ display: "inline" }}
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 8H19M8 15L1 8L8 15ZM1 8L8 1L1 8Z"
              stroke="#25324B"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span className="lg:ml-5 ArchivoBlack text-soft-gray">
            Edit profile
          </span>
        </button>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  const { error, loading, companyProfile, jobs_application_details } =
    state.auth;
  return { error, loading, companyProfile, jobs_application_details };
};

export default connect(mapStateToProps, { updateJobApplicationDetails })(
  BackActionComponent
);
