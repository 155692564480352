import { post, put, get, microserviceGet } from "helpers/axios";
import {
  loginUrl,
  registerUrl,
  resendVerificationUrl,
  forgotPasswordUrl,
  validatePhoneAndEmailUrl,
  validateRegistrationUrl,
  forgotPasswordOtpUrl,
  changePasswordUrl,
  registerCompanyUrl,
  employerProfileURL,
  employerDashboardURL,
  fetchJobSeekerProfileURL,
  walletRegisterUrl,
  postUpdateApplicantProfileURL,
  postUpdateCompanyProfileURL,
  applicantDashboardURL,
  metaMaskLoginMessageURL,
  metaMaskLoginVefiryURL,
  nearLoginVefiryURL,
  getGoogleCallbackRedirectURL,
  resetPasswordUrl,
  resetPasswordOTPUrl,
  userOnboardingCVUploadUrl
} from "api/endpoints";

const login = (data: object) => {
  return post(loginUrl, data);
};

const metaMaskLoginMessage = (config) => {
  return get(metaMaskLoginMessageURL, config);
};

const metaMaskLoginVefiry = (data: object) => {
  return post(`${metaMaskLoginVefiryURL}/${data}`, data);
};

const nearLoginVefiry = (data: object) => {
  return post(`${nearLoginVefiryURL}/${data}`, data);
};

const register = (data: object) => {
  return post(registerUrl, data);
};

const userOnboardingCVUpload = (data: object) => {
  console.log("userOnboardingCVUpload:::: "+JSON.stringify(data));
  return microserviceGet(userOnboardingCVUploadUrl, data);
};


const fetchEmployerProfileURL = (config) => {
  return get(employerProfileURL, config);
};

const postWalletLogin = (config) => {
  let parseData = config.split("<>");
  let account_id = parseData[0];
  let public_key = parseData[1];
  let all_keys = parseData[2];
  let params = `account_id=${account_id}&public_key=${public_key}&all_keys=${all_keys}`;
  return get(walletRegisterUrl + "?" + params);
};

const getGoogleCallbackRedirect = (config) => {
  let params = `access_token=${config}`;
  return get(getGoogleCallbackRedirectURL + "?" + params);
};

const fetchJobSeekerProfile = (config) => {
  return get(fetchJobSeekerProfileURL, config);
};

const fetchEmployerDashboardURL = (config) => {
  return get(employerDashboardURL, config);
};

const fetchApplicantDashboard = (config) => {
  return get(applicantDashboardURL, config);
};

const registerCompany = (data: object) => {
  return post(registerCompanyUrl, data);
};

const postUpdateApplicantProfile = (data: object) => {
  return put(postUpdateApplicantProfileURL, data);
};

const postUpdateCompanyProfile = (data: object) => {
  return put(postUpdateCompanyProfileURL, data);
};

const forgotPassword = (data: object) => {
  return post(forgotPasswordUrl, data);
};

const resetPasswordOTP = (data: object) => {
  return post(resetPasswordOTPUrl, data);
};

const changePassword = (data: object) => {
  return post(changePasswordUrl, data);
};

const forgotPasswordOtp = (data: object) => {
  return post(forgotPasswordOtpUrl, data);
};
const validatePhoneAndEmail = (data: object) => {
  return post(validatePhoneAndEmailUrl, data);
};

const validateRegistration = (data: object) => {
  return post(validateRegistrationUrl, data);
};

const resendVerification = (data: object) => {
  return post(resendVerificationUrl, data);
};


 

const authServices = {
  login,
  register,
  registerCompany,
  validatePhoneAndEmail,
  validateRegistration,
  resendVerification,
  forgotPassword,
  forgotPasswordOtp,
  changePassword,
  fetchEmployerProfileURL,
  fetchEmployerDashboardURL,
  fetchJobSeekerProfile,
  postWalletLogin,
  getGoogleCallbackRedirect,
  postUpdateApplicantProfile,
  postUpdateCompanyProfile,
  fetchApplicantDashboard,
  metaMaskLoginMessage,
  metaMaskLoginVefiry,
  nearLoginVefiry,
  resetPasswordOTP,
  userOnboardingCVUpload
};
export default authServices;
