import {
  ADMIN_CVS_LISTING,
  ADMIN_CVS_LISTING_SUCCESS,
  ADMIN_DASHBOARD,
  ADMIN_DASHBOARD_SUCCESS,
  ADMIN_JOB_LISTING,
  ADMIN_JOB_LISTING_SUCCESS,
  ADMIN_USER_LISTING,
  ADMIN_USER_LISTING_SUCCESS,
  DELETE_POST_JOB_OFFER_ADMIN,
  DELETE_POST_JOB_OFFER_ADMIN_SUCESS,
  POST_JOB_CATEGORY_ADMIN_SUCCESS,
  POST_JOB_CATEGORY_ADMIN,
  FETCH_JOB_CATEGORY_ADMIN,
  FETCH_JOB_CATEGORY_ADMIN_SUCCESS,
  DELETE_ADMIN_CATEGORY_LISTING_SUCCESS,
  DELETE_ADMIN_CATEGORY_LISTING,
} from "store/admin/constants";

const initialState = {
  error: "",
  apiError: null,
  loading: false,
  user_token: null,
  user_data: null,
  adminDashboardDetails: [],
  adminJobListingData: [],
  adminUserListingData: [],
  adminCVsListingData: [],
  applicant_cv: [],
  category_data: [],
};

const admin = (state = initialState, action: any) => {
  switch (action.type) {
    case ADMIN_DASHBOARD:
      state = {
        ...state,
        loading: true,
        error: "",
        adminDashboardDetails: action.payload,
      };
      break;
    case ADMIN_DASHBOARD_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        adminDashboardDetails: action.payload,
      };
      break;

    case ADMIN_JOB_LISTING:
      state = {
        ...state,
        loading: true,
        error: "",
        adminJobListingData: action.payload,
      };
      break;
    case ADMIN_JOB_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        adminJobListingData: action.payload,
      };
      break;

    case DELETE_POST_JOB_OFFER_ADMIN:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case DELETE_POST_JOB_OFFER_ADMIN_SUCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        adminJobListingData: action.payload,
      };
      break;

      case DELETE_ADMIN_CATEGORY_LISTING:
        state = {
          ...state,
          loading: true,
          error: "",
        };
        break;
  
      case DELETE_ADMIN_CATEGORY_LISTING_SUCCESS:
        state = {
          ...state,
          loading: false,
          error: "",
          //category_data: action.payload,
        };
        break;

        

      case FETCH_JOB_CATEGORY_ADMIN:
        state = {
          ...state,
          loading: true,
          error: "",
          category_data: action.payload,
        };
        break;
  
      case FETCH_JOB_CATEGORY_ADMIN_SUCCESS:
        state = {
          ...state,
          loading: false,
          error: "",
          category_data: action.payload,
        };
        break;




    case POST_JOB_CATEGORY_ADMIN:
        state = {
          ...state,
          loading: false,
          error: "",
        };
        break;
      
    case POST_JOB_CATEGORY_ADMIN_SUCCESS:
        state = {
          ...state,
          loading: false,
          error: "",
          applicant_cv: action.payload,
        };
        break;


    case ADMIN_USER_LISTING:
      state = {
        ...state,
        loading: true,
        error: "",
        adminUserListingData: action.payload,
      };
      break;
    case ADMIN_USER_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        adminUserListingData: action.payload,
      };
      break;

    case ADMIN_CVS_LISTING:
      state = {
        ...state,
        loading: true,
        error: "",
        adminCVsListingData: action.payload,
      };
      break;
    case ADMIN_CVS_LISTING_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        adminCVsListingData: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default admin;
