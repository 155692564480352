import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import greenApproveImage from "assets/images/green-approve-2.svg";
import grayApproveImage from "assets/images/gray-approve.svg";
import redRejectImage from "assets/images/red-reject.svg";
import grayRejectImage from "assets/images/gray-reject.svg";

import cvFileUploadImage from "assets/images/cv-file-upload.svg";
import portfolioImageUpload from "assets/images/portfolio-file.svg";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import cogoToast from "cogo-toast";
import ClipLoader from "react-spinners/ClipLoader";

import { upload_preset,cloud_name,cloud_url  } from "helpers";
import grayedAvatarImage from "../../assets/images/grayed-avatar.png";


//redux
import { postJobApplication, postApplicantPostCV } from "store/actions";

import "react-loading-skeleton/dist/skeleton.css";
const DetailsBar = (props) => {
  const {
    postJobApplication,
    postApplicantPostCV,
    error,
    loading,
    user_data,
    applicant_cv,
  } = props;

  const [showRequestModal, setShowRequestModal] = useState(false);
  const [userData, setUserData] = useState("");
  const [showApplyBtn, setShowApplyBtn] = useState(false);
  const [showLoginToApplyBtn, setShowLoginToApplyBtn] = useState(false);
  const [acceptDigitalCurrency, setAcceptDigitalCurrency] = useState(12);
  const [acceptClassicPayment, setAcceptClassicPayment] = useState(12);
  const fileUploadRef = React.useRef<HTMLInputElement>(null);
  const fileUploadRefPortfolio = React.useRef<HTMLInputElement>(null);

  const [isUploading, setIsUploading] = useState(false);
  const [cvURL, setCVUrl] = useState("");
  const [cvFileName, setCvFileName] = useState("");

  const [isUploadingPortfolio, setIsUploadingPortfolio] = useState(false);
  const [cvURLPortfolio, setCVUrlPortfolio] = useState("");
  const [cvFileNamePortfolio, setCvFileNamePortfolio] = useState("");

  const [coverLetter, setCoverLetter] = useState("");
  //tempoary - refactor upload to helpers later  as must go live today
 
  const applyToJob = () => {
    //console.log("applyToJob=="+props.jobs)

    //  let userData = localStorage.getItem("AuthData");
    // if(coverLetter == ""){
    //  alert("Cover letter is compulsory");
    // cogoToast.error("Form error: Fill in form Fields");

    //}
    //else{
    let data = {
      jlid: props.jobs.jlid,
      emid: props.jobs.uid,
      cover_letter: coverLetter || "n/a",
      digitalPayment: null,
      classicPayment: null,
      cvid: applicant_cv.cvid,
    };
    postJobApplication(data);
    //}
  };

  useEffect(() => {
    let userData = localStorage.getItem("AuthData");
    if (userData !== null) {
      setUserData(JSON.stringify(userData));
      if (JSON.parse(userData).role !== "employer") {
        setShowApplyBtn(true);
      }
    } else {
      setShowApplyBtn(true);
      setShowLoginToApplyBtn(true);
    }
  }, [userData]);

  const onChangeCVUpload = (e) => {
    setIsUploading(true);
    const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("upload_preset", upload_preset);
    data.append("cloud_name", cloud_name);
    fetch(cloud_url, {
      method: "post",
      body: data,
    })
      .then((resp) => resp.json())
      .then((data) => {
        setIsUploading(false);
        setCVUrl(data.secure_url);
        setCvFileName(data.original_filename);
        postApplicantPostCV({
          cv: data.secure_url,
          tags: ["Web3"],
        });
      })
      .catch((err) => console.log(err));
  };

  const onChangePortfolioUpload = (e) => {
    setIsUploadingPortfolio(true);
    const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("upload_preset", upload_preset);
    data.append("cloud_name", cloud_name);
    fetch(cloud_url, {
      method: "post",
      body: data,
    })
      .then((resp) => resp.json())
      .then((data) => {
        setIsUploadingPortfolio(false);
        setCVUrlPortfolio(data.secure_url);
        setCvFileNamePortfolio(data.original_filename);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <SkeletonTheme baseColor="#f5f5f5" highlightColor="#ccc">
        <div className="shadow bg-white  px-5x py-2   mx-auto  place-content-center rounded-lg w-5/6	">
          <div className="flex items-center lg:w-4/6 mx-auto border-bx pb-10x mb-10x border-gray-200 sm:flex-row flex-col">
            <div className="mr-10">
              <div className="h-12 w-12 text-center justify-center py-2 px-1 rounded shadowx logo-gray-coverx bordered inline-block ">
                <img
                  className="h-8 lg:h-8 w-8 object-cover ml-1 rounded-2xl "
                  src={
                    (props.jobs && props.jobs.logo.length > 4  ?  props.jobs.logo:  (
                      props.jobs &&
                      props.jobs.user_listed &&
                      props.jobs.user_listed.icon) )||
                      `${grayedAvatarImage}`
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `${grayedAvatarImage}`;
                  }}
                  alt=""
                /> 
              </div>
            </div>

            <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
              <h3 className="font-semibold text-lg leading-6 text-gray-700 my-2x ArchivoBold">
                {props.jobs.jobTitle}
              </h3>
              <p className="text-xs ml-7x bee-text">
                {props.jobs.company || <Skeleton />} -{" "}
                {props.jobs.employmentType || <Skeleton />} -{" "}
                {(props.jobs.remote !== 0 && "Remote") || <Skeleton />}{" "}
              </p>
            </div>

            <div className="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-indigo-100x text-indigo-500 flex-shrink-0">
              {showApplyBtn && (
                <button
                  role="button"
                  onClick={() => {
                    if (showLoginToApplyBtn) {
                      window.location.href = "/login";
                    } else {
                     if(props.jobs.referral_link !== null && props.jobs.referral == 1){
                      //redirect user to job application on new tab 
                      window.open(props.jobs.referral_link, "_blank") || window.location.replace(props.jobs.referral_link);
                     }else{
                      setShowRequestModal(true);
                     }
                   
                    }
                    // applyToJob()
                  }}
                  className="focus:ring-2 focus:ring-offset-2  mt-4 text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded bg-bee-orange py-4 w-full"
                >
                  {showLoginToApplyBtn ? "Login to Apply" : `Apply`}
                </button>
              )}
            </div>
          </div>
        </div>

        {showRequestModal && (
          <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 mr-4 sm:pb-2">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="flex">
                          <div className="w-1/3">
                            <div className="h-8 w-8 text-center justify-center py-1 rounded shadow logo-gray-cover bordered inline-block ">
                              <img
                                className="h-6 lg:h-6 w-6 object-cover ml-1 rounded-2xl "
                                src={
                                  (props.jobs &&
                                    props.jobs.user_listed &&
                                    props.jobs.user_listed.icon) ||
                                  `${grayedAvatarImage}`
                                }
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src =
                                    `${grayedAvatarImage}`;
                                }}
                                alt=""
                              />
                            </div>
                          </div>

                          <div className="w-2/1 ml-1">
                            <h3
                              className="text-md leading-6x font-mediumx   inline-block ArchivoBold  text-soft-gray"
                              id="modal-title"
                            >
                              <span className="ml-2x DMSansBold">
                                {showApplyBtn && `${props.jobs.jobTitle}`}
                              </span>
                              {!showApplyBtn && "Join BEE "}
                              <span className="text-xs block ml-2x bee-text DMSansRegular">
                                {props.jobs.company || <Skeleton />} -{" "}
                                {props.jobs.employmentType || <Skeleton />} -{" "}
                                {(props.jobs.remote !== 0 && "Remote") || (
                                  <Skeleton />
                                )}
                              </span>
                            </h3>
                          </div>

                          <div className="w-1/2 ml-1">
                            <button
                              type="button"
                              onClick={() => {
                                setShowRequestModal(false);
                              }}
                              className="text-gray-400 bg-transparent lg:ml-40 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                              data-modal-toggle="defaultModal"
                            >
                              <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                              <span className="sr-only">Close modal</span>
                            </button>
                          </div>
                        </div>

                        {!showApplyBtn && (
                          <p className="leading-7 text-sm text-gray-600 inline text-whitex GilroyLight">
                            You need to login or signup to apply for this role.
                          </p>
                        )}

                        <hr className="mt-5" />

                        <div className="mt-2">
                          <div className="flex flex-wrap -m-2 mt-1">
                            <section className="text-gray-600 body-font">
                              <div className="containerx px-5x py-24x mx-autox flex flex-wrapx">
                                <h2 className="sm:text-2xl text-2xl text-gray-900 font-medium title-font mb-2 md:w-full text-soft-gray">
                                  &nbsp;Apply to this job <br />
                                  <span className="text-xs block ml-2 bee-text DMSansRegular">
                                    Fill the following form to apply.
                                  </span>
                                </h2>

                                <div className="md:w-full md:pl-6">
                                  <div className="flex md:mt-4 mt-6">
                                    <a className="text-bee-orangex text-white inline-flex items-center ml-6 text-xs">
                                      Apply with BEExperience Profile
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </section>

                            <section className="containerx w-full mt-5">
                              <div className="">
                                <label className="DMSansBold text-soft-gray text-xs ">
                                  Full Name
                                </label>
                                <input
                                  placeholder="Enter your full name"
                                  className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2x"
                                />
                              </div>

                              <div className="mt-3">
                                <label className="DMSansBold text-soft-gray text-xs">
                                  E-mail
                                </label>
                                <input
                                  placeholder="Enter your e-mail"
                                  className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2x"
                                />
                              </div>

                              <div className="mt-3">
                                <label className="DMSansBold text-soft-gray text-xs">
                                  Linkedin (optional)
                                </label>
                                <input
                                  placeholder="Paste your link"
                                  className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2x"
                                />
                              </div>

                              <section className="is-hero-barx  mt-6">
                                <div className="py-8x flex flex-wrapx md:flex-nowrapx">
                                  <div className="md:w-100 mr-5  md:mb-0 mb-6 flex-shrink-0x flexx flex-colz">
                                    <span className="DMSansBold text-soft-gray text-xs ">
                                      Classic Payments
                                    </span>
                                  </div>
                                  <div className="md:flex-grow lg:ml-20">
                                    <div className="ml-10 ">
                                      <a
                                        href="#accept-anonymous"
                                        onClick={() => {
                                          setAcceptClassicPayment(1);
                                        }}
                                      >
                                        <img
                                          src={
                                            acceptClassicPayment == 1
                                              ? greenApproveImage
                                              : grayApproveImage
                                          }
                                          className={`${
                                            acceptClassicPayment == 1
                                              ? " border-yellow-300-image-2x"
                                              : "no-border"
                                          } inline  `}
                                        />
                                      </a>

                                      <a
                                        href="#reject-anonymous"
                                        onClick={() => {
                                          setAcceptClassicPayment(2);
                                        }}
                                      >
                                        <img
                                          src={
                                            acceptClassicPayment == 2
                                              ? redRejectImage
                                              : grayRejectImage
                                          }
                                          className={`ml-5 ${
                                            acceptClassicPayment == 2
                                              ? "  border-yellow-300-image-2x"
                                              : "no-border"
                                          } inline  `}
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <section className="is-hero-barx  mt-6">
                                <div className="py-8x flex flex-wrapx md:flex-nowrapx">
                                  <div className="md:w-100 mr-5  md:mb-0 mb-6 flex-shrink-0x flexx flex-colz">
                                    <span className="DMSansBold text-soft-gray text-xs ">
                                      Digital Currencies Payments
                                    </span>
                                  </div>
                                  <div className="md:flex-grow ml-5">
                                    <div className="ml-10 ">
                                      <a
                                        href="#accept-anonymous"
                                        onClick={() => {
                                          setAcceptDigitalCurrency(1);
                                        }}
                                      >
                                        <img
                                          src={
                                            acceptDigitalCurrency == 1
                                              ? greenApproveImage
                                              : grayApproveImage
                                          }
                                          className={`${
                                            acceptDigitalCurrency == 1
                                              ? "  border-yellow-300-image-2x"
                                              : "no-border"
                                          } inline  `}
                                        />
                                      </a>

                                      <a
                                        href="#reject-anonymous"
                                        onClick={() => {
                                          setAcceptDigitalCurrency(2);
                                        }}
                                      >
                                        <img
                                          src={
                                            acceptDigitalCurrency == 2
                                              ? redRejectImage
                                              : grayRejectImage
                                          }
                                          className={`ml-5 ${
                                            acceptDigitalCurrency == 2
                                              ? "  border-yellow-300-image-2x"
                                              : "no-border"
                                          } inline  `}
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </section>

                            {isUploading ? (
                              <>
                                <ClipLoader
                                  color={"#F1B729"}
                                  loading={true}
                                  size={50}
                                />
                              </>
                            ) : (
                              <div className="mt-1">
                                <input
                                  type="file"
                                  className={` hide-fileuploaded `}
                                  onChange={onChangeCVUpload}
                                  ref={fileUploadRef}
                                />
                                <a
                                  href="#upload"
                                  className={` ${
                                    cvURL !== "" && "hide-fileuploaded"
                                  }  `}
                                >
                                  <img
                                    src={cvFileUploadImage}
                                    onClick={() => {
                                      fileUploadRef.current?.click();
                                    }}
                                  />
                                </a>

                                {cvURL !== "" && (
                                  <>
                                    <a
                                      href="#"
                                      className="text-orange  text-xs underline mt-1 "
                                    >
                                      {` ${cvFileName}`}
                                    </a>
                                    <a
                                      href="#"
                                      onClick={() => {
                                        fileUploadRef.current?.click();
                                      }}
                                      className="text-orangex text-xs underlinex ml-3 mt-1"
                                    >
                                      (Change CV)
                                    </a>
                                  </>
                                )}
                              </div>
                            )}

                            {isUploadingPortfolio ? (
                              <>
                                <ClipLoader
                                  color={"#F1B729"}
                                  loading={true}
                                  size={50}
                                />
                              </>
                            ) : (
                              <div className="mt-1">
                                <input
                                  type="file"
                                  className={` hide-fileuploaded `}
                                  onChange={onChangePortfolioUpload}
                                  ref={fileUploadRefPortfolio}
                                />
                                <a
                                  href="#upload"
                                  className={` ${
                                    cvURLPortfolio !== "" && "hide-fileuploaded"
                                  }  `}
                                >
                                  <img
                                    src={portfolioImageUpload}
                                    onClick={() => {
                                      fileUploadRefPortfolio.current?.click();
                                    }}
                                  />
                                </a>

                                {cvURLPortfolio !== "" && (
                                  <>
                                    <a
                                      href="#"
                                      className="text-orange  text-xs underline mt-1 "
                                    >
                                      {` ${cvFileNamePortfolio}`}
                                    </a>
                                    <a
                                      href="#"
                                      onClick={() => {
                                        fileUploadRefPortfolio.current?.click();
                                      }}
                                      className="text-orangex text-xs underlinex ml-3 mt-1"
                                    >
                                      (Change CV)
                                    </a>
                                  </>
                                )}
                              </div>
                            )}

                            {showApplyBtn && (
                              <div className="p-2x w-full mt-4">
                                <div className="relative">
                                  <label className="leading-7 text-sm text-gray-600 text-whitex  DMSansBold text-soft-gray text-xs">
                                    Additional information (optional){" "}
                                  </label>
                                  <textarea
                                    cols={90}
                                    rows={4}
                                    placeholder={"Message..."}
                                    onChange={(event) => {
                                      //(event.target.value);
                                      setCoverLetter(event.target.value);
                                    }}
                                    className="border-none-colors  full-width rounded-lg resize-none w-full h-full bg-white-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1x px-3 leading-8 transition-colors duration-200 ease-in-out"
                                  ></textarea>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    {showApplyBtn && (
                      <>
                        {loading ? (
                          <div className="text-center">
                            <ClipLoader
                              color={"#F1B729"}
                              loading={true}
                              size={50}
                            />
                          </div>
                        ) : (
                          <button
                            onClick={() => {
                              applyToJob();
                            }}
                            className="inline-flexx lg:ml-0 text-white w-full bg-bee-orange border-0 py-1 px-6 focus:outline-none hover:bg-indigo-600x rounded text-sm DMSansRegular"
                          >
                            Submit Application
                          </button>
                        )}

                        {/*
                  !loading &&
                <button
                onClick={()=>{
                  setShowRequestModal(false);
                }}
                type="button" className=" ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Cancel</button>
              */}
                      </>
                    )}

                    {!showApplyBtn && (
                      <>
                        <button
                          onClick={() => {
                            window.location.href = "/login";
                          }}
                          className="inline-flexx ml-40 text-white  bg-bee-orange border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-md ArchivoBold"
                        >
                          Login to apply
                        </button>

                        {/*
                <button
                onClick={()=>{
                  setShowRequestModal(false);
                }}
                type="button" className=" ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Cancel</button>
                 */}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </SkeletonTheme>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, user_data, applicant_cv } = state.job;
  return { error, loading, user_data, applicant_cv };
};

export default connect(mapStateToProps, {
  postJobApplication,
  postApplicantPostCV,
})(DetailsBar);
