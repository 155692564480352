// @ts-nocheck
import * as nearAPI from "near-api-js";

//url redirect
export const getRedirect = (
  data: { search: string },
  backupRoute: string
): string => {
  const urlParams = new URLSearchParams(data.search);

  const redirect = urlParams.get("redirect");

  const route = redirect
    ? redirect[0] === "/"
      ? `${redirect}`
      : `/${redirect}`
    : `/${backupRoute}`;
  return route;
};

//get current timestamp
export const getCurrentTimestamp = (day = 0) => {
  let old_date = new Date();

  let date = new Date(old_date.setDate(old_date.getDate() + day));

  // return moment(date, "YYYY-MM-DD HH:mm:ss.SSS").toDate();
  return date.toISOString().split("T")[0];
};

export const numFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
};

//for loading skelenton
export const loadingSkeleton = [
  {
    id: 1,
    title: "",
  },
  {
    id: 2,
    title: "",
  },
  {
    id: 3,
    title: "",
  },
  {
    id: 4,
    title: "",
  },
];

export const makeid = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const jobTypeColor = (jtype) => {
  let colorclass = "";
  if (jtype == "Internship") {
    colorclass = "text-amber-600 bg-amber-200 border-2 border-amber-600";
  } else if (jtype == "Remote") {
    colorclass = "text-red-600 bg-red-200 border-2 border-red-600";
  } else if (jtype == "Part-Time") {
    colorclass = "text-green-600 bg-green-200 border-2 border-green-600";
  } else if (jtype == "Full-Time") {
    colorclass = "text-blue-600 bg-blue-200 border-2 border-blue-600";
  } else {
  }
  return colorclass;
};

export const todayGreeting = () => {
  let result = "";
  let today = new Date();
  let curHr = today.getHours();

  if (curHr < 12) {
    result = "Good Morning";
  } else if (curHr < 18) {
    result = "Good Afternoon";
  } else {
    result = "Good Evening";
  }
  return result;
};

export const etherAddressShortener = (address) =>
  `${address.slice(0, 5)}...${address.slice(address.length - 4)}`;

const { keyStores } = nearAPI;
export const connectionConfig: any = {
  networkId: "mainnet",
  nodeUrl: "https://rpc.mainnet.near.org",
  keyStore: new keyStores.BrowserLocalStorageKeyStore(),
  walletUrl: "https://wallet.mainnet.near.org",
  helperUrl: "https://helper.mainnet.near.org",
  explorerUrl: "https://explorer.mainnet.near.org",
};

export const dateFormatterHumanReadable = (datestring) => {
  return new Date(datestring).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const upload_preset = "ltxrniwp";
export const cloud_name = "do87mx9l1";
export const cloud_url = "https://api.cloudinary.com/v1_1/do87mx9l1/image/upload";
export const cvparserUrl = "http://146.190.156.67:5000";