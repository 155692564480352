/**
 * API environment url
 */

////////////auth
export const loginUrl = "login";

export const walletRegisterUrl = "applicant-wallet";

export const registerUrl = "applicant-register";
export const registerCompanyUrl = "company-register";

//Basic Auth endpoints
export const validatePhoneAndEmailUrl = "auth/validate_auth_phone_and_email";
export const validateRegistrationUrl = "auth/verify_registration_otp";
export const resendVerificationUrl = "auth/resend_authentication_otp";
export const forgotPasswordUrl = "forgot-password";
export const changePasswordUrl = "change-password";
export const forgotPasswordOtpUrl = "auth/verify_forgot_password_otp";
export const resetPasswordUrl = "auth/validate_forgot_password";
export const resetPasswordOTPUrl = "reset-password";

export const userOnboardingCVUploadUrl = "?cvurl=";


//Job Listings Endpoints
export const jobListingURL = "job-listings";
export const jobListingDetailsURL = "job-listing-detail";
export const postJobOfferURL = "post-job-offer";
export const postJobOfferAdminURL = "admin-post-job-offer";
export const deleteJobOfferAdminURL = "admin-delete-job-listing";
export const postAdminUpdateJobOfferURL="admin-update-job-offer";

export const employerJobListingURL = "employer-job-listings";
export const employerProfileURL = "employer-profile";
export const employerDashboardURL = "employer-dashboard";

export const postJobApplicationURL = "applicant-job-apply";
export const fetchJobAllApplicantURL = "all-applicants";
export const fetchJobAllApplicantAdminURL = "admin-all-applicants";
export const fetchSearchJobListingURL = "search-job-listing";
export const fetchJobSeekerProfileURL = "applicant-profile";

export const postUpdateApplicantProfileURL = "update-applicant-profile";
export const postUpdateCompanyProfileURL = "update-employer-profile";

export const fetchApplicationJobAppliedURL = "applicant-jobs-applied";
export const updateJobApplicationURL = "update-job-application";

export const updateApplicantCVURL = "update-applicant-cv";

export const applicantPostCvURL = "applicant-post-cv";
export const adminPostCvURL ="admin-upload-cv";

export const getApplicantCVURL = "get-applicant-cv";

export const applicantDashboardURL = "applicant-dashboard";

export const metaMaskLoginMessageURL = "metamask-login-message";
export const metaMaskLoginVefiryURL = "metamask-login-verify";

export const nearLoginVefiryURL = "near-login-verify";
export const getGoogleCallbackRedirectURL = "google-callback-redirect";

export const cvSubscribeURL = "cv-subscribe";
export const cvStatusURL = "cv-status";
export const subscriptionListingStatusURL = "listing-status";

export const adminDashboardURL = "admin-dashboard";
export const adminJobListingURL = "admin-job-listings";
export const adminUserListingURL = "admin-employer-subscriptions";
export const adminCVsListingURL = "admin-all-cvs";

export const fetchCVDownloadsURL = "search-cv";

export const adminAddCVCategoryURL = "admin-add-cv-category";
export const adminAllCVCategoryURL = "admin-all-cv-category";
export const adminDeleteCategoryListingURL = "admin-delete-cv-category";

