import { post, put, get, del } from "helpers/axios";
import {
  adminDashboardURL,
  adminJobListingURL,
  adminUserListingURL,
  adminCVsListingURL,
  deleteJobOfferAdminURL,
  adminAddCVCategoryURL,
  adminAllCVCategoryURL,
  adminDeleteCategoryListingURL,
} from "api/endpoints";
const fetchAdminDashboardURL = (config) => {
  return get(adminDashboardURL, config);
};
const fetchAdminJobListingURL = (config) => {
  return get(adminJobListingURL, config);
};
const fetchAdminUserListingURL = (config) => {
  return get(adminUserListingURL, config);
};

const postAddCVCategoryURL = (config) => {
  return post(adminAddCVCategoryURL, config);
};

const fetchAdminAllCVCategoryURL = (config) => {
  return get(adminAllCVCategoryURL, config);
};

const fetchAdminCVsListingURL = (config) => {
  return get(adminCVsListingURL, config);
};

const deleteJobOfferAdmin = (data) => {
  return put(deleteJobOfferAdminURL + "/" + data, data);
};

const deleteAdminDeleteCategoryListingURL = (data) => {
  return del(adminDeleteCategoryListingURL + "/" + data, data);
};

const adminServices = {
  fetchAdminDashboardURL,
  fetchAdminJobListingURL,
  fetchAdminUserListingURL,
  fetchAdminCVsListingURL,
  deleteJobOfferAdmin,
  postAddCVCategoryURL,
  fetchAdminAllCVCategoryURL,
  deleteAdminDeleteCategoryListingURL,
};

export default adminServices;
