import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
//redux
import { postWalletLoginSuccess, nearLoginVefiry } from "store/actions";

const WalletRegister = (props) => {
  const { postWalletLoginSuccess, nearLoginVefiry, error, loading, user_data } =
    props;

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const account_id = params.get("account_id");
  const public_key = params.get("public_key");
  const all_keys = params.get("all_keys");

  useEffect(() => {
    let data = `${account_id}<>${public_key}<>${all_keys}`;
    nearLoginVefiry(account_id && account_id);
    //postWalletLoginSuccess(data);
  }, [1]);
  return (
    <>
      {/*
            <p>Success</p>
            <p>{account_id}</p>
            <p>{public_key}</p>
            <p>{all_keys}</p>
            */}
      <p>Processing... Please Wait</p>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, user_data } = state.auth;
  return { error, loading, user_data };
};

export default connect(mapStateToProps, {
  postWalletLoginSuccess,
  nearLoginVefiry,
})(WalletRegister);
