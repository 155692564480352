import TagComponent from "components/tagComponent";
import { useEffect } from "react";
import { connect } from "react-redux";

//redux
import { getApplicantCV } from "store/actions";

const About = (props) => {
  const { getApplicantCV, error, loading, applicant_cv_detail } = props;

  useEffect(() => {
    getApplicantCV(props.applicantDetail && props.applicantDetail.uid);
  }, [1]);

  return (
    <>
      <div className="flex border-2 lg:ml-5 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col hgt-600 mt-10 ">
        <div className="flex-grow w-1/6">
          <h2 className="text-gray-900 text-md title-font font-medium mb-3 DMSansBold text-soft-gray">
            About Me
          </h2>
          <p className="leading-relaxed text-base desc-color DMSansRegular">
            {props.applicantDetail.bio || "n/a"}
          </p>

          <p className="mt-10 DMSansBold text-soft-gray">Current Job</p>
          <p className="leading-relaxed text-base desc-color DMSansRegular">
            {props.applicantDetail.headline}
            {console.log(JSON.stringify(props.applicantDetail))}
          </p>

          <p className="mt-10 DMSansBold text-soft-gray">Tags</p>

          {props.applicantDetail &&
            props.applicantDetail.address !== "n/a" &&
            JSON.parse(props.applicantDetail.address).map((item, index) => {
              return (
                <div className="inline mr-2">
                  <TagComponent title={item} />
                </div>
              );
            })}
        </div>
      </div>

      <div className="flex  bg-red-900x mt-5">
        <div className="w-11/12"></div>
        <div className="w-6/12 lg:ml-40 bg-green-900x">
          {
            //@ts-ignore

            applicant_cv_detail && applicant_cv_detail.data && (
              <a
                href={
                  applicant_cv_detail &&
                  applicant_cv_detail.data &&
                  applicant_cv_detail.data.length > 0 &&
                  applicant_cv_detail.data.sort(
                    (a, b) => parseFloat(b.cvid) - parseFloat(a.cvid)
                  )[0].cv
                }
                className="nav-btn-custom-orange font-bold py-2 px-4 rounded m-1 DMSansBold text-sm  text-white  "
                download
              >
                Download CV
              </a>
            )
          }
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, applicant_cv_detail } = state.job;
  return { error, loading, applicant_cv_detail };
};

export default connect(mapStateToProps, { getApplicantCV })(About);
