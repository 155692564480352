//jobs
export const JOB_LISTING = "JOB_LISTING";
export const JOB_LISTING_DETAILS = "JOB_LISTING_DETAILS";
export const JOB_SUCCESS = "JOB_SUCCESS";
export const JOB_DETAILS_SUCCESS = "JOB_DETAILS_SUCCESS";
export const JOB_POST = "JOB_POST";
export const JOB_POST_SUCCESS = "JOB_POST_SUCCESS";
export const JOB_LISTING_EMPLOYER = "JOB_LISTING_EMPLOYER";
export const JOB_SUCCESS_EMPLOYER = "JOB_SUCCESS_EMPLOYER";
export const JOB_APPLICATION_POST = "JOB_APPLICATION_POST";
export const JOB_APPLICATION_POST_SUCCESS = "JOB_APPLICATION_POST_SUCCESS";

export const JOB_ALL_APPLICANTS = "JOB_ALL_APPLICANTS";
export const JOB_ALL_APPLICANTS_SUCCESS = "JOB_ALL_APPLICANTS_SUCCESS";
export const JOB_ALL_APPLICANTS_ADMIN = "JOB_ALL_APPLICANTS_ADMIN";
export const JOB_ALL_APPLICANTS_ADMIN_SUCCESS = "JOB_ALL_APPLICANTS_ADMIN_SUCCESS";
export const SEARCH_JOB_LISTING = "SEARCH_JOB_LISTING";
export const SEARCH_JOB_LISTING_SUCCESS = "SEARCH_JOB_LISTING_SUCCESS";
export const API_ERROR = "API_ERROR";

export const JOB_MY_APPLICATION = "JOB_MY_APPLICATION";
export const JOB_MY_APPLICATION_SUCCESS = "JOB_MY_APPLICATION_SUCCESS";

export const SUBSCRIPTION_LISTING_STATUS = "SUBSCRIPTION_LISTING_STATUS";
export const SUBSCRIPTION_LISTING_STATUS_SUCCESS =
  "SUBSCRIPTION_LISTING_STATUS_SUCCESS";

export const UPDATE_JOB_APPLICATION = "UPDATE_JOB_APPLICATION";
export const UPDATE_JOB_APPLICATION_SUCCESS = "UPDATE_JOB_APPLICATION_SUCCESS";

export const UPDATE_HIRING_STAGE = "UPDATE_HIRING_STAGE";
export const UPDATE_HIRING_STAGE_SUCCESS = "UPDATE_HIRING_STAGE_SUCCESS";

export const UPDATE_APPLICANT_CV = "UPDATE_APPLICANT_CV";
export const UPDATE_APPLICANT_CV_SUCCESS = "UPDATE_APPLICANT_CV_SUCCESS";

export const JOB_POST_APPLICANT_CV = "JOB_POST_APPLICANT_CV";
export const JOB_POST_APPLICANT_CV_SUCCESS = "JOB_POST_APPLICANT_CV_SUCCESS";

export const JOB_POST_ADMIN_CV = "JOB_POST_ADMIN_CV";
export const JOB_POST_ADMIN_CV_SUCCESS = "JOB_POST_ADMIN_CV_SUCCESS";
 


export const GET_APPLICANT_CV = "GET_APPLICANT_CV";
export const GET_APPLICANT_CV_SUCCESS = "GET_APPLICANT_CV_SUCCESS";

export const SUBSCRIBE_CV = "SUBSCRIBE_CV";
export const SUBSCRIBE_CV_SUCCESS = "SUBSCRIBE_CV_SUCCESS";

export const GET_CV_STATUS = "GET_CV_STATUS";
export const GET_CV_STATUS_SUCCESS = "GET_CV_STATUS_SUCCESS";

export const POST_JOB_OFFER_ADMIN = "POST_JOB_OFFER_ADMIN";
export const POST_JOB_OFFER_ADMIN_SUCESS = "POST_JOB_OFFER_ADMIN_SUCESS";


export const POST_JOB_UPDATE_OFFER_ADMIN = "POST_JOB_UPDATE_OFFER_ADMIN";
export const POST_JOB__UPDATE_OFFER_ADMIN_SUCESS = "POST_JOB__UPDATE_OFFER_ADMIN_SUCESS";

export const CV_DOWNLOAD = "CV_DOWNLOAD";
export const CV_DOWNLOAD_SUCCESS = "CV_DOWNLOAD_SUCCESS";

 