import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkoutForm";

const StripePayment = (props: any) => {
  const { pricePostAmount } = props;
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
  const [defaultJobPostNumber, setDefaultJobPostNumber] = useState(`1`);
  return (
    <>
      {
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4x sm:p-6x sm:pb-4x">
                  <div className="sm:flexx sm:items-startx">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left  ">
                      <h3
                        className="text-lg leading-6  font-medium ArchivoBold text-soft-gray mb-10"
                        id="modal-title"
                      >
                        Subscribe {`$${pricePostAmount}`}/month
                      </h3>
                      <div>
                        {/* <label className="text-sm font-bold ArchivoBold text-gray-400">1 post for $169/month</label>
                    <input className="amount-stripe" type="number" value={defaultJobPostNumber} 
                    onChange={(e)=>{setDefaultJobPostNumber(e.target.value)}}
                    />
                    <hr className="mt-10 mb-10"/>*/}
                        <Elements stripe={stripePromise}>
                          <CheckoutForm pricePostAmount={pricePostAmount} />
                        </Elements>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    onClick={() => {
                      props.setShowStripeModal(false);
                    }}
                    type="button"
                    className=" ArchivoBold mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};
export default StripePayment;
