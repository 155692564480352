import React, { useState, useEffect } from "react";

import JobListCardWider from "components/jobListCardWider";

//components
import NavBar from "components/navBar";
import SearchBar from "components/searchBar";
import angularIconImage from "assets/images/angular.svg";
import compoundIconImage from "assets/images/compound.svg";
import skypeIconImage from "assets/images/skype.svg";
import grayedAvatarImage from "assets/images/grayed-avatar.png";

import JobDetailsCard from "components/jobDetailsCard";
import Footer from "components/footer";

import { connect } from "react-redux";

//redux
import { fetchJobListing } from "store/actions";

//for loading skelenton
const loadingSkeleton = [
  {
    id: 1,
    title: "",
  },
];

const JobBoard: React.FC = (props: any) => {
  const {
    fetchJobListing,
    error,
    loading,
    loading_search,
    job_search_result,
    jobs,
  } = props;

  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [jobType, setJobType] = useState("");
  const [mode, setMode] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState("");
  const [about, setAbout] = useState("");
  const [jobID, setJobID] = useState("");
  const [showJobDetails, setShowJobDetails] = useState(true);
  const [note, setNote] = useState("");
  const [requirement, setRequirement] = useState("");
  const [salary, setSalary] = useState("");
  const [tags, setTags] = useState([]);
  const [jobsearchdata, setJobSearchData] = useState([]);
  const [isonsearch, setIsOnSearch] = useState("false");
  const [isloadingsearch, setIsLoadingsearch] = useState("false");
  useEffect(() => {
    fetchJobListing({});
  }, [1]);

  useEffect(() => {
    if(jobs &&
      jobs.listings &&
      jobs.listings)
      {
          let job = jobs?.listings[0];
          setTitle(job.jobTitle);
          setCompany(job.company);
          setJobType(job.typeOfEmployment);
          setMode(job.remote == 1 ? "remote" : job.workLocation);
          setDescription(job.description);
          setIcon('icon');
          setAbout(job.description);
          setJobID(job.jlid);
          setNote(job.note);
          setRequirement(job.requirements);
          setSalary(job.salary);
          setTags(job.tags);  
      }
  },[loading]);
  return (
    <>
      <NavBar />

      <div className="float-search lg:ml-60x lg:w-full lg:mt-40	 ">
        <SearchBar
          setJobSearchData={setJobSearchData}
          jobsearchdata={jobsearchdata}
          isonsearch={isonsearch}
          setShowJobDetails={setShowJobDetails}
          setIsOnSearch={setIsOnSearch}
          setIsLoadingsearch={setIsLoadingsearch}
          isloadingsearch={isloadingsearch}
        />
      </div>
      <div className="sub-header-bar  orange-stripe-vector-small">
        <div className="mt-10x lg:px-20 grid ml-10 lg:ml-0  w-full ">
          <h1 className="text-2xl text-white ArchivoBlack mt-20 lg:text-center">
            Find a{" "}
            <span className="text-bee-orange ArchivoBlack"> &nbsp;job</span>
          </h1>
        </div>
      </div>

      <div className="lg:mt-20 web-only lg:px-20 grid">
        {isonsearch == "true" && (
          <h1 className="text-2xl ArchivoBlack">
            Search <span className="text-bee-orange ArchivoBlack"> Result</span>
          </h1>
        )}
        {isonsearch == "false" && (
          <h1 className="text-2xl ArchivoBlack">
            All <span className="text-bee-orange ArchivoBlack">jobs</span>
          </h1>
        )}
      </div>

      <div className="mobile-only container-fluid  lg:mt-0 mt-80  ">
        {loading ? (
          <>
            {loadingSkeleton.map((item, index) => {
              return (
                <div className="mt-5">
                  <JobListCardWider
                    title={""}
                    company={""}
                    jobType={""}
                    mode={""}
                    link={``}
                    colorclass={
                      "text-amber-600 bg-amber-200 border-2 border-amber-600 "
                    }
                    icon={""}
                    tags={[]}
                  />
                </div>
              );
            })}
          </>
        ) : (
          <>
            {jobs &&
              jobs.listings &&
              jobs.listings.map((item, index) => {
                return (
                  <div className="mt-5 ">
                    <JobListCardWider
                      title={item.jobTitle}
                      company={item.company}
                      about={item.description}
                      requirements={item.requirements}
                      note={item.note}
                      tags={typeof item.tags == "object" ? item.tags : []}
                      setTags={setTags}
                      salary={item.salary}
                      jobType={item.employmentType}
                      mode={item.remote == 1 ? "remote" : item.workLocation}
                      link={`job-details/${item.jlid}`}
                      colorclass={
                        "text-amber-600 bg-amber-200 border-2 border-amber-600 "
                      }
                      // icon={
                      //   (item.user_listed && item.user_listed.icon) ||
                      //   `${grayedAvatarImage}`
                      // }
                      icon={
                        item?.logo.length > 4 ? item?.logo :
                        item.user_listed.icon ||
                        `${grayedAvatarImage}`
                      }
                      setTitle={setTitle}
                      setCompany={setCompany}
                      setJobType={setJobType}
                      setMode={setMode}
                      setDescription={setDescription}
                      setAbout={setAbout}
                      setNote={setNote}
                      setRequirement={setRequirement}
                      setSalary={setSalary}
                      setIcon={setIcon}
                    />
                  </div>
                );
              })}
          </>
        )}
      </div>

      <div className="grid  web-only mt-10 lg:px-20 justify-center md:grid-cols-2 lg:grid-cols-2 gap-5 lg:gap-7 my-10  w-1/10  ">
        <div className="height-scrollable">
          {loading ? (
            <>
              {loadingSkeleton.map((item, index) => {
                return (
                  <div className="mt-5">
                    <JobListCardWider
                      title={""}
                      company={""}
                      jobType={""}
                      mode={""}
                      link={"#jobs"}
                      colorclass={
                        "text-amber-600 bg-amber-200 border-2 border-amber-600 "
                      }
                      icon={""}
                      tags={[]}
                    />
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {isonsearch == "true" ? (
                <>
                  {/*Search result -- move to component later*/}
                  {loading_search ? (
                    <>
                      {loadingSkeleton.map((item, index) => {
                        return (
                          <div className="mt-5">
                            <JobListCardWider
                              title={""}
                              company={""}
                              jobType={""}
                              mode={""}
                              link={``}
                              colorclass={
                                "text-amber-600 bg-amber-200 border-2 border-amber-600 "
                              }
                              icon={""}
                              tags={[]}
                            />
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    job_search_result &&
                    job_search_result.map((item, index) => {
                      return (
                        <div className="mt-5  ">
                          <JobListCardWider
                            title={item.jobTitle}
                            company={item.company}
                            jlid={item.jlid}
                            about={item.description}
                            note={item.note}
                            salary={item.salary}
                            requirements={item.requirements}
                            tags={item.tags}
                            setTags={setTags}
                            jobType={item.employmentType}
                            mode={
                              item.remote == 1 ? "remote" : item.workLocation
                            }
                            link={"#jobs"}
                            colorclass={
                              "text-amber-600 bg-amber-200 border-2 border-amber-600 "
                            }
                            // icon={
                            //   (item.user_listed && item.user_listed.icon) ||
                            //   `${grayedAvatarImage}`
                            // }
                            icon={
                              item?.logo.length > 4 ? item?.logo :
                              item.user_listed.icon ||
                              `${grayedAvatarImage}`
                            }
                            setTitle={setTitle}
                            setCompany={setCompany}
                            setJobType={setJobType}
                            setJobID={setJobID}
                            setMode={setMode}
                            setDescription={setDescription}
                            setNote={setNote}
                            setRequirement={setRequirement}
                            setSalary={setSalary}
                            setAbout={setAbout}
                            setShowJobDetails={setShowJobDetails}
                            setIcon={setIcon}
                          />
                        </div>
                      );
                    })
                  )}
                  {/*Search result -- move to component later*/}
                </>
              ) : (
                jobs &&
                jobs.listings &&
                jobs.listings.map((item, index) => {
                  return (
                    <div className="mt-5  ">
                      <JobListCardWider
                        title={item.jobTitle}
                        company={item.company}
                        jlid={item.jlid}
                        about={item.description}
                        note={item.note}
                        salary={item.salary}
                        requirements={item.requirements}
                        tags={item.tags}
                        setTags={setTags}
                        jobType={item.employmentType}
                        mode={item.remote == 1 ? "remote" : item.workLocation}
                        link={"#jobs"}
                        colorclass={
                          "text-amber-600 bg-amber-200 border-2 border-amber-600 "
                        }
                        // icon={
                        //   (item.user_listed && item.user_listed.icon) ||
                        //   `${grayedAvatarImage}`
                        // }
                        icon={
                          item?.logo.length > 4 ? item?.logo :
                          item.user_listed.icon ||
                          `${grayedAvatarImage}`
                        }
                        setTitle={setTitle}
                        setCompany={setCompany}
                        setJobType={setJobType}
                        setJobID={setJobID}
                        setMode={setMode}
                        setDescription={setDescription}
                        setNote={setNote}
                        setRequirement={setRequirement}
                        setSalary={setSalary}
                        setAbout={setAbout}
                        setShowJobDetails={setShowJobDetails}
                        setIcon={setIcon}
                      />
                    </div>
                  );
                })
              )}
            </>
          )}
        </div>

        <div className="w-full mt-5">
          {showJobDetails && (
            <JobDetailsCard
              title={title}
              company={company}
              jobType={jobType}
              mode={mode}
              description={description}
              about={about}
              jobID={jobID}
              requirements={requirement}
              salary={salary}
              note={note}
              tags={tags}
              link={"/job-details"}
              colorclass={
                "text-amber-600 bg-amber-200 border-2 border-amber-600 "
              }
              icon={icon}
            />
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, jobs, loading_search, job_search_result } = state.job;
  return { error, loading, jobs, loading_search, job_search_result };
};

export default connect(mapStateToProps, { fetchJobListing })(JobBoard);
