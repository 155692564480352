import React, { useState, useEffect } from "react";
//components
import NavBar from "components/navBar";
import beeLogo from "assets/images/bee-logo.png";
import LoginPartial from "./LoginPartial";
import { useLocation } from "react-router-dom";

const Login: React.FC = () => {
  const [userType, setUserType] = useState(1);
  const [showThankYou, setShowThankYou] = useState(false);
  const location = useLocation();
  const [currentPathName, setCurrentPathName] = useState(
    window.location.pathname
  );
  return (
    <>
      <div className="mobile-only">
        <NavBar />
      </div>
      <section className="h-screenx">
        <div className="container-fuild px-6 py-12 h-full">
          <div className="lg:flex justify-centerx lg:ml-10 items-center flex-wrap h-full g-6 text-gray-800">
            <div className="md:w-8/12 lg:w-6/12 mb-12 md:mb-0 gb-crime-yellow rounded-3xl web-only">
              <div className="w-1/2x bg-cover  bottomize-containerx  md:block hidden left-login-bgx">
                <div className="px-10 py-10 mt-80 bottomizex k-screen-only-login-mtx welcome-leftx">
                  <a href="/">
                    <img src={beeLogo} className="h-20 w-20" />
                  </a>
                  <h1 className="text-6xl  mt-20 py-10x font-bold leading mt-0 mb-2 text-white ArchivoBlack">
                    Welcome to <br />
                    BEExperience
                  </h1>
                </div>
              </div>

              {currentPathName !== "/admin-login" && (
                <div className="mt-5x ml-5x  grid   md:grid-cols-2 lg:grid-cols-2 gap-5 lg:gap-7 bg-red-900 rounded-br-3xl rounded-bl-3xl gb-crime-gray py-5 px-10">
                  <p className="text-white mt-5x DMSansBold">
                    You don't have an account?
                  </p>
                  <div className="text-center">
                    <button
                      onClick={() => {
                        window.location.href = "register";
                      }}
                      role="button"
                      className="focus:ring-2 focus:ring-offset-2   text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded bg-bee-orange py-4 w-1/2 DMSansBold"
                    >
                      Sign up
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="md:w-8/12 lg:w-4/12 lg:ml-20">
              <LoginPartial
                setUserType={setUserType}
                userType={userType}
                showThankYou={showThankYou}
                currentPathName={currentPathName}
                setShowThankYou={setShowThankYou}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;
