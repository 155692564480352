import React, { useEffect, useRef, useState } from "react";
import { TagsInput } from "react-tag-input-component";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";

import greenApproveImage from "assets/images/green-approve-2.svg";
import grayApproveImage from "assets/images/gray-approve.svg";
import redRejectImage from "assets/images/red-reject.svg";
import grayRejectImage from "assets/images/gray-reject.svg";
import grayedAvatarImage from "assets/images/grayed-avatar.png";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

//redux
import { postUpdateCompanyProfile } from "store/actions";

const EditProfile = (props) => {
  const { postUpdateCompanyProfile, error, loading, companyProfile } = props;

  const [remote, setRemote] = useState(0);
  const [openForOpportunity, setOpenForOpportunity] = useState(0);
  const [urlProfile, setUrlProfile] = useState("");
  const [selectedTag, setSelectedTag] = useState(["Web3"]);
  const [isUploading, setIsUploading] = useState(false);

  const [name, setName] = useState("");
  const [founded, setFounded] = useState("");
  const [employees, setEmployees] = useState("");
  const [bio, setBio] = useState("");
  const [email, setEmail] = useState("");
  const [instagram, setInstagram] = useState("");
  const [industry, setIndustry] = useState("");
  const [city, setCity] = useState("");
  const [twitter, setTwitter] = useState("");
  const [website, setWebsite] = useState("");
  const [icon, setIcon] = useState("");
  const [aboutMeCount, setAboutMeCount] = useState(0);

  useEffect(() => {
    if (props.profileDetails && props.profileDetails.data) {
      setName(props.profileDetails.data.name);
      setFounded(props.profileDetails.data.founded);
      setEmployees(props.profileDetails.data.employees);
      setBio(props.profileDetails.data.bio);
      setEmail(props.profileDetails.data.email);
      setInstagram(props.profileDetails.data.instagram);
      setTwitter(props.profileDetails.data.twitter);
      setWebsite(props.profileDetails.data.website);
      setIcon(props.profileDetails.data.icon);
      setCity(props.profileDetails.data.city);
      setIndustry(props.profileDetails.data.industry);
      setUrlProfile(icon);
    }
  }, [1]);

  const onChangeProfileUpload = (e) => {
    setIsUploading(true);
    const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("upload_preset", "ltxrniwp");
    data.append("cloud_name", "do87mx9l1");
    fetch("  https://api.cloudinary.com/v1_1/do87mx9l1/image/upload", {
      method: "post",
      body: data,
    })
      .then((resp) => resp.json())
      .then((data) => {
        setIsUploading(false);
        //alert(JSON.stringify(data));
        setUrlProfile(data.secure_url);
      })
      .catch((err) => console.log(err));
  };

  const saveUpdate = () => {
    // props.profileDetails
    let data = {};

    if (urlProfile !== null && urlProfile != "") {
      data = {
        name: name,
        founded: founded,
        employees: employees,
        bio: bio,
        email: email,
        instagram: instagram,
        twitter: twitter,
        website: website,
        icon: urlProfile,
        address: selectedTag,
        city: city,
        industry: industry,
      };
      localStorage.setItem("usericon", urlProfile);
    } else {
      data = {
        name: name,
        founded: founded,
        employees: employees,
        bio: bio,
        email: email,
        instagram: instagram,
        twitter: twitter,
        website: website,
        address: selectedTag,
        city: city,
        industry: industry,
      };
    }

    //alert(JSON.stringify(data));

    postUpdateCompanyProfile(data);
  };
  return (
    <>
      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container-fluid lg:ml-60 px-5 py-24 lg:mx-auto">
          <div className="-my-8 divide-y-2 divide-gray-100">
            <div className="lg:py-8 lg:flex lg:flex-wrap md:flex-nowrap">
              <div className="md:w-full md:mb-0 mb-6 flex-shrink-0 flex flex-col  ">
                <div className="mt-0 mb-0">
                  <button
                    onClick={() => {
                      props.setShowEdit(false);
                    }}
                  >
                    <svg
                      width="20"
                      height="16"
                      style={{ display: "inline" }}
                      viewBox="0 0 20 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 8H19M8 15L1 8L8 15ZM1 8L8 1L1 8Z"
                        stroke="#25324B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span className="lg:ml-5 ArchivoBlack text-soft-gray">
                      Edit profile
                    </span>
                  </button>
                </div>

                <div className="grid lg:grid-cols-2 mt-2 gap-5 lg:gap-7">
                  <div className="">
                    <div className=" mt-5 ">
                      <label className="DMSansBold text-soft-gray text-xs block">
                        Display name
                      </label>
                      <input
                        placeholder=""
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        value={name}
                        className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 lg:w-5/6 w-full pl-3 mt-2x"
                      />
                    </div>

                    <div className=" mt-5 ">
                      <label className="DMSansBold text-soft-gray text-xs block">
                        Date Founded
                      </label>
                      <input
                        placeholder=""
                        type="date"
                        onChange={(e) => {
                          setFounded(e.target.value);
                        }}
                        value={founded}
                        className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 lg:w-5/6 w-full pl-3 mt-2x"
                      />
                    </div>

                    <div className=" mt-5 ">
                      <label className="DMSansBold text-soft-gray text-xs block">
                        Number of employees
                      </label>
                      <input
                        placeholder=""
                        type="number"
                        onChange={(e) => {
                          setEmployees(e.target.value);
                        }}
                        value={employees}
                        className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 lg:w-5/6 w-full pl-3 mt-2x"
                      />
                    </div>

                    <div className=" mt-5 ">
                      <label className="DMSansBold text-soft-gray text-xs w-full block">
                        Location
                      </label>
                      <input
                        placeholder=""
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                        value={city}
                        className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 lg:w-5/6 w-full	 pl-3 mt-2x"
                      />
                    </div>

                    <div className=" mt-5 ">
                      <label className="DMSansBold text-soft-gray text-xs w-full block">
                        Industry
                      </label>
                      <input
                        placeholder=""
                        onChange={(e) => {
                          setIndustry(e.target.value);
                        }}
                        value={industry}
                        className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 lg:w-5/6 w-full	 pl-3 mt-2x"
                      />
                    </div>

                    <div className="mt-3">
                      <label className="leading-7 text-sm text-gray-600 text-whitex  DMSansBold text-soft-gray text-xs block">
                        About Us
                      </label>
                      <ReactQuill
                        theme="snow"
                        value={bio}
                        onChange={(v) => {
                          if (aboutMeCount < 5000) {
                            setBio(v);
                            setAboutMeCount(v.length);
                          }
                        }}
                        style={{ minHeight: "100px", width: "100%" }}
                      />
                      <p className="text-xs DMSansBold text-soft-gray ">
                        {aboutMeCount > 5000 ? "5000" : aboutMeCount} /5000
                      </p>
                    </div>
                  </div>

                  <div className="lg:justify-self-centerx">
                    {!isUploading ? (
                      <img
                        alt="useravatar"
                        src={` ${
                          urlProfile !== ""
                            ? urlProfile
                            : icon && icon.length > 3
                            ? icon
                            : `${grayedAvatarImage}`
                        }`}
                        className="w-16 h-16 rounded-full flex-shrink-0 object-cover object-center"
                      />
                    ) : (
                      <ClipLoader color={"#F1B729"} loading={true} size={50} />
                    )}

                    <p className="desc-color DMSansRegular text-xs mt-2">
                      We recommend an image of at least 300x300. <br />
                      Max 5mb.
                    </p>

                    <div className="mt-5">
                      <input
                        type="file"
                        className={` ${
                          urlProfile !== "" && "hide-fileuploadedx"
                        } custom-file-input-gray `}
                        onChange={onChangeProfileUpload}
                      />
                    </div>

                    <p className="mt-10 DMSansBold text-soft-gray">Contact</p>

                    <div className=" mt-5 ">
                      <label className="DMSansBold text-soft-gray text-xs block">
                        Email
                      </label>
                      <input
                        placeholder=""
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                        className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 lg:w-5/6 w-full pl-3 mt-2x"
                      />
                    </div>

                    <div className=" mt-5 ">
                      <label className="DMSansBold text-soft-gray text-xs block">
                        Instagram
                      </label>
                      <input
                        placeholder=""
                        onChange={(e) => {
                          setInstagram(e.target.value);
                        }}
                        value={instagram}
                        className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 lg:w-5/6 w-full pl-3 mt-2x"
                      />
                    </div>

                    <div className=" mt-5 ">
                      <label className="DMSansBold text-soft-gray text-xs block">
                        Twitter
                      </label>
                      <input
                        placeholder=""
                        onChange={(e) => {
                          setTwitter(e.target.value);
                        }}
                        value={twitter}
                        className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 lg:w-5/6 w-full pl-3 mt-2x"
                      />
                    </div>

                    <div className=" mt-5 ">
                      <label className="DMSansBold text-soft-gray text-xs block">
                        Website
                      </label>
                      <input
                        placeholder="e.g www.beexperience.io"
                        onChange={(e) => {
                          setWebsite(e.target.value);
                        }}
                        value={website}
                        className="bg-white-200 border rounded border-gray text-xs font-medium leading-none text-gray-800 py-3 lg:w-5/6 w-full pl-3 mt-2x"
                      />
                    </div>
                  </div>

                  {loading ? (
                    <ClipLoader color={"#F1B729"} loading={true} size={50} />
                  ) : (
                    <button
                      onClick={() => {
                        saveUpdate();
                      }}
                      className="inline-flexx lg:ml-0 text-white  lg:w-5/6 w-full mt-0 bg-bee-orange border-0 py-1 px-6 focus:outline-none hover:bg-indigo-600x rounded text-sm DMSansRegular"
                    >
                      Save Update
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, companyProfile } = state.auth;
  return { error, loading, companyProfile };
};

export default connect(mapStateToProps, { postUpdateCompanyProfile })(
  EditProfile
);
