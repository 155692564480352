import React, { useState, useEffect } from "react";
//components
import NavBar from "components/navBar";
import beeLogo from "assets/images/bee-logo.png";
import SignUp from "./SignUpPartial";
import ThankYou from "./ThankYouPartial";
import thankYouGreen from "assets/images/green-approve.png";

const Register: React.FC = () => {
  const [userType, setUserType] = useState(1);
  const [showThankYou, setShowThankYou] = useState(false);

  return (
    <>
      <div className="mobile-only">
        <NavBar />
      </div>

      <section className="h-screenx  ">
        <div className="lg:container-fuild  px-6x py-12 h-full">
          <div className="flex justify-centerx lg:ml-10 items-center flex-wrap h-full g-6 text-gray-800">
            <div className="md:w-8/12 lg:w-6/12 mb-12 md:mb-0 gb-crime-yellow rounded-3xl web-only">
              <div className="w-1/2x bg-cover  bottomize-containerx  md:block hidden left-login-bgx">
                <div className="px-10 py-10 mt-80 bottomizex k-screen-only-login-mtx welcome-leftx">
                  <a href="/">
                    <img src={beeLogo} className="h-20 w-20" />
                  </a>
                  <h1 className="text-6xl  mt-20 py-10x font-bold leading mt-0 mb-2 text-white ArchivoBlack">
                    Welcome to <br />
                    BEExperience
                  </h1>
                </div>
              </div>

              <div className="mt-5x ml-5x  grid   md:grid-cols-2 lg:grid-cols-2 gap-5 lg:gap-7 bg-red-900 rounded-br-3xl rounded-bl-3xl gb-crime-gray py-5 px-10">
                <p className="text-white mt-5x DMSansBold">
                  You already have an account?
                </p>
                <div className="text-center">
                  <button
                    onClick={() => {
                      window.location.href = "login";
                    }}
                    role="button"
                    className="focus:ring-2 focus:ring-offset-2   text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded bg-bee-orange py-4 w-1/2 DMSansBold"
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>

            <div className="md:w-8/12 lg:w-4/12 lg:ml-20  px-10 lg:px-0">
              {!showThankYou ? (
                <SignUp
                  setUserType={setUserType}
                  userType={userType}
                  showThankYou={showThankYou}
                  setShowThankYou={setShowThankYou}
                />
              ) : (
                <>
                  <img
                    src={thankYouGreen}
                    className="h-40 w-40 text-center lg:ml-40 sm:md-20 "
                  />
                  <ThankYou />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Register;
