import {
  API_ERROR,
  ADMIN_DASHBOARD,
  ADMIN_DASHBOARD_SUCCESS,
  ADMIN_JOB_LISTING,
  ADMIN_JOB_LISTING_SUCCESS,
  ADMIN_USER_LISTING,
  ADMIN_USER_LISTING_SUCCESS,
  ADMIN_CVS_LISTING,
  ADMIN_CVS_LISTING_SUCCESS,
  DELETE_POST_JOB_OFFER_ADMIN,
  DELETE_POST_JOB_OFFER_ADMIN_SUCESS,
  POST_JOB_CATEGORY_ADMIN,
  POST_JOB_CATEGORY_ADMIN_SUCCESS,
  FETCH_JOB_CATEGORY_ADMIN,
  FETCH_JOB_CATEGORY_ADMIN_SUCCESS,
  DELETE_ADMIN_CATEGORY_LISTING_SUCCESS,
  DELETE_ADMIN_CATEGORY_LISTING,
} from "store/admin/constants";
export const fetchAdminDashboard = (user: any) => {
  return {
    type: ADMIN_DASHBOARD,
    payload: user,
  };
};
export const fetchAdminDashboardSuccess = (response: any) => {
  return {
    type: ADMIN_DASHBOARD_SUCCESS,
    payload: response,
  };
};

export const fetchAdminJobListing = (user: any) => {
  return {
    type: ADMIN_JOB_LISTING,
    payload: user,
  };
};
export const fetchAdminJobListingSuccess = (response: any) => {
  return {
    type: ADMIN_JOB_LISTING_SUCCESS,
    payload: response,
  };
};

export const fetchAdminUserListing = (user: any) => {
  return {
    type: ADMIN_USER_LISTING,
    payload: user,
  };
};
export const fetchAdminUserListingSuccess = (response: any) => {
  return {
    type: ADMIN_USER_LISTING_SUCCESS,
    payload: response,
  };
};

export const fetchAdminCVsListing = (user: any) => {
  return {
    type: ADMIN_CVS_LISTING,
    payload: user,
  };
};
export const fetchAdminCVsListingSuccess = (response: any) => {
  return {
    type: ADMIN_CVS_LISTING_SUCCESS,
    payload: response,
  };
};

export const fetchJobCategoryAdminsListing = (user: any) => {
  return {
    type: FETCH_JOB_CATEGORY_ADMIN,
    payload: user,
  };
};
export const fetchJobCategoryAdminsListingSuccess = (response: any) => {
  return {
    type: FETCH_JOB_CATEGORY_ADMIN_SUCCESS,
    payload: response,
  };
};


export const postAddCVCategory = (data: any) => {
  return {
    type: POST_JOB_CATEGORY_ADMIN,
    payload: { data },
  };
};

export const postAddCVCategorySuccess = (response: any) => {
  return {
    type: POST_JOB_CATEGORY_ADMIN_SUCCESS,
    payload: response,
  };
};



export const deleteJobOfferAdmin = (data: any) => {
  return {
    type: DELETE_POST_JOB_OFFER_ADMIN,
    payload: { data },
  };
};

export const deleteJobOfferAdminSuccess = (response: any) => {
  return {
    type: DELETE_POST_JOB_OFFER_ADMIN_SUCESS,
    payload: response,
  };
};


 export const deleteAdminDeleteCategoryListing = (data: any) => {
  return {
    type: DELETE_ADMIN_CATEGORY_LISTING,
    payload: { data },
  };
};

export const deleteAdminDeleteCategoryListingSuccess = (response: any) => {
  return {
    type: DELETE_ADMIN_CATEGORY_LISTING_SUCCESS,
    payload: response,
  };
};